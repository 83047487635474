
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/App';
import store from './store';
import './style/main.scss';
import FastClick from 'fastclick';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import TagManager from 'react-gtm-module';
import { isLoged } from "./utile/utile"

FastClick.attach(document.body);

/*ReactDOM.render(
  <Provider store={store} >
  <App />
</Provider>,
  document.getElementById('app')
);*/

const userId =  store.getState().account.getIn(['identifiant', 'value']) || "";
const langue = store.getState().demandeCredit.getIn(['langues', 'value']) || "fr";
const userStatus = store.getState().account.get('authenticationStatus') === "success" ? "logged": "not-logged";

const tagManagerArgs = {
  gtmId: 'GTM-PPHTC94',
  dataLayer: isLoged(userId, {
      langue,
      userStatus,
      BU: "particulier"
    }
  ) 
}

window.addEventListener("hashchange", () => {
  TagManager.dataLayer({
    dataLayer: isLoged(userId, {
      langue,
      userStatus,
      BU: "particulier"
    })
  })
}, false);

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  
  <I18nextProvider  i18n={ i18n }>
  <Provider store={store} >
    <App />
   </Provider>
  </I18nextProvider>,
  document.getElementById('app')
);

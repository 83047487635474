/* eslint-disable */
import React from "react";
import Select from "react-select";
import { inputChange } from "../../action_creators";
import filter from "lodash/filter";
import InputError from "../../containers/Inputs/InputError";
import i18n from "../../i18n";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
};

const ClearIndicatorWithTooltip = (props) => {
  const { innerProps = {}, getStyles = () => ({}), clearValue } = props;
  return (
    <div {...innerProps} style={getStyles('clearIndicator', props)}>
      <span
        className="clearValue"
        onClick={clearValue}
        title={i18n.t("recapEventIcon.label")}
      >
        X
      </span>
    </div>
  );
};

const onChange = (event, storeKey, dispatch) => {
  const value = event ? event.value : null;
  return dispatch(inputChange(value, storeKey));
};

const SelectEvent = (props) => {
  const finalValue = filter(props.items.toJS(), (item) => {
    return item.value == props.value;
  });
  return (
    <div style={{ marginTop: "10px" }}>
      <Select
        options={props.items.toJS()}
        onChange={(event) => onChange(event, props.storeKey, props.dispatch)}
        defaultValue={finalValue[0]}
        placeholder={props.placeholder}
        name={props.name}
        isClearable
        styles={customStyles}
        components={{
          ClearIndicator: ClearIndicatorWithTooltip,
        }}
      />
      <InputError storeKey={props.storeKey} />
    </div>
  );
};

export default SelectEvent;

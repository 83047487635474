import { connect } from 'react-redux/dist/react-redux';
import Felicitation from '../components/Felicitation';
import { showHide } from '../action_creators';
function mapStateToProps(state) {
   return {
    langues: state.demandeCredit.getIn(['langues', 'value']), 
   };
  }
function mapDispatchToProps(dispatch) {
    return {
      show: (s) => dispatch(showHide(s)),
      dispatch: (v) => dispatch(v)
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Felicitation);
  
import { Map } from 'immutable';

export default function (state = new Map(), action) {
  if (action.type !== 'INPUT_CHANGE' || action.store !== 'demandeCredit') {
    return state;
  }
  switch (action.key.join('.')) {
    case 'boautrerevenu':
      return state.withMutations((st) => {
        st.delete('autreactivite');
        st.delete('autrerevenu');
      });
    case 'request':
      return state.deleteIn(['request', 'status']);
    default:
      return state;
  }
}

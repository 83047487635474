import { connect } from 'react-redux/dist/react-redux';
import TimelineEP from '../components/EspacePerso/TimelineEP';

function mapStateToProps(state) {
  return {
    langues: state.demandeCredit.getIn(['langues', 'value']), 
    steps: state.timelineEP.get('steps'),
    current: state.timelineEP.get('current'),
  };
}

export default connect(
  mapStateToProps
)(TimelineEP);

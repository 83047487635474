import { connect } from 'react-redux';
import { scrolllToSection } from '../../action_creators';

import Footer from '../../components/Inputs/Footer';
function mapStateToProps(state) {
  return {
    langues: state.demandeCredit.getIn(['langues', 'value']),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    scrolllToSection: (source, id) => dispatch(scrolllToSection(source, id))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

/* eslint-disable */
import React, { Component } from 'react';
import HeaderAuth from '../../containers/HeaderAuth';
import Logo from '../../assets/images/logo_bankpopulaire.jpg';
import DemandePret from "../../assets/images/ddepret.png";
import ContactImg from "../../assets/images/contact_us_icn.png"
import ContactUsDes from "../../assets/images/contact_us_active_icn.png";
import i18n from "../../i18n";
import { hashHistory } from 'react-router';
import TagManager from 'react-gtm-module';
import {isLoged} from '../../utile/utile'
class Header extends Component {
  componentWillMount() {
    this.shoHidePop = this.shoHidePop.bind(this);
    this.logout = this.logout.bind(this);
    this.handleLanguage = this.handleLanguage.bind(this);
  }
  logout() {
    window.location.href = '/canalcredit';
  }
  shoHidePop(s) {
    this.props.show(s);
  }
  handleLanguage(lang) {
    this.props.inputChange(lang, 'demandeCredit.langues');
  }

  demandePret = () => {
    TagManager.dataLayer({
      dataLayer: isLoged(this.props.userId,{
          event: "user_navigation",
          item: "header",
          target: "demande_de_pret",
          userStatus: this.props.userStatus,
          langue: this.props.langues,
          BU: 'particulier'
      }),
    })
    this.shoHidePop('demandeCredit.isChaabiClient');
    this.props.inputChange("header", 'demandeCredit.origin');
    TagManager.dataLayer({
      dataLayer: isLoged(this.props.userId,{
          event: "virtual_page",
          virtual_page_name: "initiation de la demande",
          url: "/initiation-de-la-demande",
          form_step: "1",
          origin: this.props.origin,
          target: "demande_de_pret",
          userStatus: this.props.userStatus,
          langue: this.props.langues,
          BU: 'particulier',
          sp_montant: this.props.origin === "header" ? null: this.props.montantCredit,
          sp_duree: this.props.origin === "header" ? null: this.props.nbAnnee,
          sp_differe: this.props.origin === "header" ? null: this.props.dureeDiff,
          sp_taux: this.props.origin === "header" ? null: this.props.taux,
          sp_echeance: this.props.origin === "header" ? null: this.props.echeance,
      }),
    })
  }

  handleLanguage(lang){
    TagManager.dataLayer({
      dataLayer: isLoged( this.props.userId, {
          event: "user_navigation",
          item: "header",
          target: lang,
          userStatus: this.props.userStatus,
          langue: this.props.langues,
          BU: 'particulier'
      }),
    })
    this.props.inputChange(lang,'demandeCredit.langues');
    i18n.changeLanguage(lang);
  }
  handleClick = (e, selector) => {
    e.preventDefault();
    if (document.querySelector(selector)) {
      document.querySelector(selector) && document.querySelector(selector).scrollIntoView({
        behavior: "smooth",
      });
    } else {
      this.props.scrolllToSection(this.props.isHome ? "home" : "other", selector);
      hashHistory.push('/')
    }
  }
  render() {

    return (
      <div>
        <header className="public_header unprentable">
          <div id="account_opening_steps" className="main_head fixed clearfix">
            <div className="public_humberger_container">
              <button id="public_humberger" className="">
                <span>
                  <span className="ico-bar"></span>
                  <span className="ico-bar"></span>
                  <span className="ico-bar"></span>
                </span>
              </button>
            </div>
            <div className="logo_container">
              <h1>
                <a onClick={() =>{
                      TagManager.dataLayer({
                        dataLayer: isLoged(this.props.userId,{
                            event: "user_navigation",
                            item: "header",
                            target: "logo",
                            userStatus: this.props.userStatus,
                            langue: this.props.langues,
                            BU: 'particulier'
                        }),
                      })
                  hashHistory.push('/')}} >
                  <img className="image_logo" src={Logo} alt="banque populaire" width="198" />
                </a>
              </h1>
            </div>
            <div className="single_menu_links single_menu_Width">
              {this.props.authenticationStatus === 'success' ? null
                : <div className="menu_link">
                  <button
                    data-text="demander un prêt"
                    className="menu-btn"
                    onClick={this.demandePret}
                  >
                    <span className="link_visu">
                      <img
                        src={DemandePret}
                        alt="Demande de prêt"
                        className="default_img"
                        width="36"
                      />
                    </span>
                    <br />
                    {i18n.t("pret.label")}
                  </button>
                </div>
              }
              <div className="menu_link">
                <a href="/#contact_section"
                  onClick={(e) => {
                    TagManager.dataLayer({
                      dataLayer: isLoged(this.props.userId,{
                          event: "user_navigation",
                          item: "header",
                          target: "contactez_nous",
                          userStatus: this.props.userStatus,
                          langue: this.props.langues,
                          BU: 'particulier'
                      }),
                    })
                    this.handleClick(e, "#contact_section")
                  }}
                  className="scrollto_anchor">
                  <span className="link_visu">
                    <img
                      src={ContactImg}
                      alt="Contactez-nous"
                      className="default_img"
                      width="28"
                    />
                    <img
                      src={ContactUsDes}
                      alt="Contactez-nous"
                      className="active_img"
                      width="28"
                    />
                  </span><br />
                  {i18n.t("contacter.label")}
                </a>
              </div>
              {this.props.authenticationStatus === 'success' ?
                <div className="invers_bg picto-profil ">

                  <button
                    data-text="Se déconnecter"
                    className="flat_btn purple_btn height_btn"
                    onClick={this.logout}
                  >
                    <br />
                    <span>Se déconnecter</span>
                  </button>
                </div>
                :
                <div className="invers_bg  picto-profil">

                  <button
                    data-text={i18n.t("espacePerso.label")}
                    className="flat_btn purple_btn height_btn"
                    onClick={() => {
                      TagManager.dataLayer({
                        dataLayer: isLoged(this.props.userId,{
                            event: "user_navigation",
                            item: "header",
                            target: "log_in",
                            userStatus: this.props.userStatus,
                            langue: this.props.langues,
                            BU: 'particulier'
                        }),
                      })
                      this.shoHidePop('demandeCredit.authentificate'); }}
                  >
                    <br />
                    <span> {i18n.t("espacePerso.label")}</span>
                  </button>
                </div>
              }
              <div className="Changelangue">

                <button
                  data-text="Français"
                  className="language_btn purple_btn change_langue"
                  onClick={() => this.handleLanguage("fr")}
                >
                  <span>Fr</span>
                </button>
                <button
                  data-text="العربية"
                  className="language_btn purple_btn change_langue"
                  onClick={() => this.handleLanguage("ar")}
                >
                  <span>ar</span>
                </button>
                <button
                  data-text="english"
                  className="language_btn purple_btn change_langue"
                  onClick={() => this.handleLanguage("en")}
                >
                  <span>En</span>
                </button>
              </div>
            </div>
          </div>
          <div className="mobile_menu">
            <ul>
              <li>
                <a href="#section">
                  Demande de prêt
                </a>
              </li>
              <li>
                <a href="#section" >
                  Nos produits
                </a>
              </li>
              <li>
                <a href="#section" >
                  nous contacter
                </a>
              </li>
            </ul>
          </div>
        </header>
        {this.props.isChaabiClient ?
          null :
          <HeaderAuth {...this.props} />
        }
      </div>
    );
  }
}
Header.propTypes = {
  dispatch: React.PropTypes.func,
  show: React.PropTypes.func,
  isChaabiClient: React.PropTypes.bool,
  authenticationStatus: React.PropTypes.bool,
  token: React.PropTypes.string,
  inputChange: React.PropTypes.func,
};
export default Header;

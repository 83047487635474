/* eslint-disable */
import React, { Component } from 'react';
import Text from '../containers/Text';
import Radio from '../containers/Inputs/Radio';
import SelectDate from './Inputs/SelectDate';
import Select from '../containers/Inputs/Select';
import { fromJS } from 'immutable';
import SelectCountry from '../containers/Inputs/SelectCountry';
import Title from './Title';
import NavigationBar from '../containers/NavigationBar';
import icone_info from '../assets/images/icone_info.png';
import i18n from '../i18n';
import TagManager from 'react-gtm-module';
import {isLoged} from '../utile/utile'
import NextButton from '../containers/NextButton';


const profession = fromJS([
  { label: 'Chef d’entreprise', value: '0811' },
  { label: 'Cadre de la fonction publique', value: '0791' },
  { label: 'Cadre d’entreprise privée ou semi-publique', value: '0391' },
  { label: 'Employé ou agent de la fonction publique', value: '3111' },
  { label: 'Employé d’entreprise privée ou semi-publique', value: '3911' },
  { label: 'Profession libérale', value: '2691' },
  { label: 'Profession libérale médicale', value: '1151' },
  { label: 'Commerçant et assimilés', value: '4181' },
  { label: 'Artisan et assimilés', value: '6911' },
  { label: 'Agriculteur exploitant ', value: '5131' },
  { label: 'Ouvrier', value: '9911' },
  { label: 'Etudiant ', value: 'XX211' },
  { label: 'Retraité ', value: 'XX311' },
  { label: 'Inactif / Recherche d’emploi ', value: 'XX111' },
]);

const activityOptions = fromJS([
  { label: 'ADMINISTRATEURS DELEGUES ', value: '115' },
  { label: 'ADMINISTRATEURS DE SOCIETES ', value: '120' },
  { label: 'ARCHITECTES ', value: '005' },
  { label: 'AUTRES ARTISANS ', value: '945' },
  { label: 'AUTRES EMPLOYES DE BUREAUX ', value: '470' },
  { label: 'AVOCATS ', value: '010' },
  { label: 'CADRE SUPERIEUR ADMINISTRATION ', value: '172' },
  { label: 'CHEF DE SERVICE/DEPARTEMENT ', value: '210' },
  { label: 'CHOMEURS ', value: '805' },
  { label: 'CONDUCTEURS D`ENGINS/CHAUFFEUR ', value: '865' },
  { label: 'DENTISTES ', value: '020' },
  { label: 'DIPLOMATES ', value: '180' },
  { label: 'DIRECTEURS D`USINES ', value: '125' },
  { label: 'DIRECTEURS FINACIERS ', value: '130' },
  { label: 'DIRECTEURS GENERAUX ', value: '110' },
  { label: 'DIRECTEUR MARKETING/COMMERCIAL ', value: '140' },
  { label: 'DIRECTEURS RESSOURCES HUMAINES ', value: '135' },
  { label: 'ETUDIANTS -ELEVES-STAGIAIRES ', value: '820' },
  { label: 'INGENIEUR ', value: '220' },
  { label: 'INGENIEURS CONSEILS/ CABINET', value: '035' },
  { label: 'JOURNALISTES INDEPENDANTS ', value: '670' },
  { label: 'JUGES ET PROCUREURS ', value: '190' },
  { label: 'MEDECINS ', value: '015' },
  { label: 'MEMBRES CORPS LEGSLATIFS ', value: '175' },
  { label: 'OUVRIER NON QUALIFIE/MANOEUVRE ', value: '848' },
  { label: 'OUVRIERS QUALIFIERS PECIALISES ', value: '860' },
  { label: 'PHARMACIENS ', value: '030' },
  { label: 'PILOTES/OFFICIERS MECANICIENS ', value: '760' },
  { label: 'PROFESSEUR DU SECONDAIRE ', value: '510' },
  { label: 'PROFESSEUR ENSEIG.SUPERIEUR ', value: '505' },
  { label: 'SECRETAIRE-ASSISTANCE ', value: '465' },
  { label: 'TECHNICIENS ', value: '215' },
  { label: 'VENDEURS ', value: '480' },
  { label: 'VETERINAIRES ', value: '025' },
]);

const situationLogementOptions = fromJS([
  { label: 'Locataire', value: '1' },
  { label: 'Propriétaire logement principal', value: '2' },
  { label: 'Avec la famille', value: '3' },
  { label: 'Logement de fonction', value: '4' },
  { label: 'Propriétaire logement secondaire', value: '5' }
]);
const Pieceidentite = fromJS([
  { label: 'C.I.N' },
  //{ label: 'PASSEPORT' },
  //{ label: 'CARTE DE SEJOUR' },
]);

const SituationFamiliale = fromJS([
  { label: 'Célibataire', value: 'Célibataire' },
  { label: 'Marié(e)', value: 'Marié(e)' },
  { label: 'Divorcé(e)', value: 'Divorcé(e)' },
  { label: 'Veuf(ve)', value: 'Veuf(ve)' },

]);

const infobulleMsg = {
  InfoAuresRevenu : "Merci d'indiquer la somme de vos autre revenus si applicable : Loyer, Allocations familiales, Pension (CNSS,CIMR, Invalidité ou veuvage), Allocations familiales. Prière de noter que tous ces revenus devront être justifiés",
  InfoEcheanceAutresPrets : "Merci d'indiquer la somme des échéances payées mensuellement pour les crédits contractés auprès des différents établissements financiers",
};

const sourceAutreRevenu = fromJS([
  { label: 'Location vide', value: 'Location vide' },
  { label: 'Location meublée', value: 'Location meublée' },
  { label: 'Revenus capitaux mobiliers ou plus-value de cessions', value: 'Revenus capitaux mobiliers ou plus-value de cessions' },
  { label: 'Profession indépendante', value: 'Profession indépendante' },
  { label: 'Bénéfices entreprise avec parts', value: 'Bénéfices entreprise avec parts' },
]);

const requiredFields = [
  'demandeCredit.date_naissanceDay',
  'demandeCredit.date_naissanceMonth',
  'demandeCredit.date_naissanceYear',
  'demandeCredit.paysderesidence',
  'demandeCredit.pieceidentite',
  'demandeCredit.numpieceidentite',
  'demandeCredit.situationfamiliale',
  'demandeCredit.activite',
  'demandeCredit.employeur',
  'demandeCredit.secteur',
  'demandeCredit.date_embaucheDay',
  'demandeCredit.date_embaucheMonth',
  'demandeCredit.date_embaucheYear',
  'demandeCredit.revenu',
  'demandeCredit.autresecheances',
  'demandeCredit.situationLogementDemandeur'
];

class InfosPerso extends Component {

  constructor(props) {
    super(props);
    this.gtmPush = this.gtmPush.bind(this);
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    this.props.displayErrors('non');
    this.props.inputChange(this.props.chaabiClient ? 'Oui' : 'Non','demandeCredit.clientbcp');
    document.body.classList.remove('hiddenScroll');
  }

  componentWillReceiveProps(prevProps){
    if (prevProps.demandeCredit.numpieceidentite !== undefined && prevProps.demandeCredit.numpieceidentite.includes(" ")){
      let numpieceidentite =  prevProps.demandeCredit.numpieceidentite.replace(" ","")
      this.props.inputChange(numpieceidentite,'demandeCredit.numpieceidentite')
    }
  }
  componentDidMount() {
    TagManager.dataLayer({
      dataLayer: isLoged(this.props.userId,{
          event: "form_pret_step_3",
          origin: this.props.origin,
          userStatus: this.props.userStatus,
          langue: this.props.langues,
          sp_montant: this.props.origin === "header" ? null: this.props.montantCredit,
          sp_duree: this.props.origin === "header" ? null: this.props.nbAnnee,
          sp_differe: this.props.origin === "header" ? null: this.props.dureeDiff,
          sp_taux: this.props.origin === "header" ? null: this.props.taux,
          sp_echeance: this.props.origin === "header" ? null: this.props.echeance,
          BU: 'particulier'
      }),
    })
  }

  gtmPush(){

    let inputsError = ( (this.props.dateNaissanceDayError == undefined || this.props.dateNaissanceDayError == true ) ? "[date_naissanceDay],":"")
                    .concat( this.props.dateNaissanceDayError == undefined || this.props.dateNaissanceDayError == true ? "[date_naissanceMonth],":"")
                    .concat( this.props.dateNaissanceDayError == undefined || this.props.dateNaissanceDayError == true ? "[date_naissanceYear],":"")
                    .concat( this.props.paysderesidenceError == undefined || this.props.paysderesidenceError == true ? "[paysderesidence],":"")
                    .concat( this.props.pieceidentiteError == undefined  ? "[pieceidentite],":"")
                    .concat( this.props.numpieceidentiteError == undefined || this.props.numpieceidentiteError == true ? "[numpieceidentite],":"")
                    .concat( this.props.situationfamilialeError == undefined || this.props.situationfamilialeError == true ? "[situationfamiliale],":"")
                    .concat( this.props.activiteError == undefined || this.props.activiteError == true ? "[activite],":"")
                    .concat( this.props.employeurError == undefined || this.props.employeurError == true ? "[employeur],":"")
                    .concat( this.props.revenuError == undefined || this.props.revenuError == true ? "[revenu],":"")
                    .concat( this.props.situationLogementDemandeurError == undefined || this.props.situationLogementDemandeurError == true ? "[situationLogementDemandeur],":"")


    TagManager.dataLayer({
      dataLayer: isLoged(this.props.userId,{
          event: "form_pret_step_3",
          origin: this.props.origin,
          item: "suivant",
          userStatus: this.props.userStatus,
          langue: this.props.langues,
          BU: 'particulier',
          champs_refuse: inputsError
          //gclid: this.props.gclid,
      }),
    })
  }

  suivant = () => {
    this.props.goNext();
  }
  render() {
    const autreRevenuOptions = [
      { label: i18n.t("oui.label"), value: 'Oui' },
      { label: i18n.t("non.label"), value: 'Non' , checked: true},
  ];
  const Secteurs = [
    { label: i18n.t("infoPerso22.label"), value: 'Public', checked: true },
    { label: i18n.t("infoPerso23.label"), value: 'Prive' },
  ];
  const typeContrat = [
    { label: i18n.t("infoPerso20.label"), value: 'CDI', checked: true },
    { label: i18n.t("infoPerso21.label"), value: 'CDD' },
  ];
    return (
      <div className="body">
        <div className="form_wrapper">
          <div className="title" id="title_container">
            <div className="block_title mb50" id="titre">
              <Title>{i18n.t("infoPerso1.label")}</Title>
              <p className="title_desc_step mb20">{i18n.t("subtitleForm1.label")} </p>
            </div>
          </div>
          <div className="control_group clearfix">
            <div className="left_cls">
            {this.props.clientbcp==='Non' ?
              < SelectDate
                storeKey="demandeCredit.date_naissance"
                yearFrom={(new Date()).getFullYear()} yearTo={1900}
              >
               {i18n.t("infoPerso2.label")}
              </SelectDate> : < SelectDate
                storeKey="demandeCredit.date_naissance"
                yearFrom={(new Date()).getFullYear()}
                yearTo={1900}
                disabled
              >
               {i18n.t("infoPerso2.label")}
              </SelectDate>
            }
            </div>
            <div className="right_cls">
              <SelectCountry
                storeKey="demandeCredit.paysderesidence"
                placeholder={i18n.t("infoPerso6.label")}
              >
            {i18n.t("infoPerso3.label")}
              </SelectCountry>
            </div>
          </div>
          <div className="control_group clearfix">
            <div className="left_cls">

              <Select
                items={Pieceidentite}
                storeKey="demandeCredit.pieceidentite"
                placeholder={i18n.t("infoPerso6.label")}
              > {i18n.t("infoPerso4.label")}
                <div className="input-has-icon">
                </div>
              </Select>
            </div>
            <div className="right_cls">
            {this.props.clientbcp==='Non' ?
              <Text storeKey="demandeCredit.numpieceidentite">
                {i18n.t('infoPerso5.label')}
              </Text> :
              <Text storeKey="demandeCredit.numpieceidentite" disabled>
               {i18n.t('infoPerso5.label')}
              </Text>
            }
            </div>
          </div>
          <div className="control_group clearfix">
            <div className="left_cls">
              <Select
                items={SituationFamiliale}
                storeKey="demandeCredit.situationfamiliale"
                placeholder={i18n.t("infoPerso6.label")}
              >{i18n.t("infoPerso7.label")}</Select>
            </div>
            <div className="right_cls">
              <Text type="number" maxLength="35" storeKey="demandeCredit.nbrenfants">
              {i18n.t("infoPerso8.label")}
              </Text>
            </div>
          </div>
          <div className="control_group radio_group clearfix">
            <div className="left_cls">
              <Select
                items={profession}
                storeKey="demandeCredit.profession"
                placeholder={i18n.t("infoPerso6.label")}
              >  {i18n.t("infoPerso9.label")}</Select>
            </div>
            <div className="right_cls">
              <Radio
                items={typeContrat} storeKey="demandeCredit.naturecontrat"
                isLabel
              >
                <div className="radio-input label_radio_txt radio-input-label">
                    {i18n.t("infoPerso10.label")}
                </div>
              </Radio>
            </div>
          </div>
          <div className="control_group clearfix">
            <div className="left_cls">
              <Text
                storeKey="demandeCredit.employeur"
              >  {i18n.t("infoPerso12.label")} </Text>
            </div>
            <div className="right_cls">
              <Text
                type= 'number'
                storeKey="demandeCredit.revenu"
              >{i18n.t("infoPerso13.label")}
                <div className="input-label-icon">
                  <div className="icon-class">{i18n.t('simulateur7.label')}</div>
                </div>
              </Text>
            </div>
          </div>
          <div className="control_group clearfix">
            <div className="left_cls">
              <Select
                items={activityOptions}
                storeKey="demandeCredit.activite"
                placeholder={i18n.t("infoPerso6.label")}
              > {i18n.t("infoPerso14.label")}</Select>
            </div>
            <div className="right_cls">

            < SelectDate
                storeKey="demandeCredit.date_embauche"
                yearFrom={(new Date()).getFullYear()} yearTo={1940}
              >
               {i18n.t("infoPerso25.label")}
              </SelectDate>
            </div>
          </div>
          <div className="control_group radio_group clearfix">
            <div className="left_cls">
              <Radio
                items={Secteurs} storeKey="demandeCredit.secteur"
                styleClass="button-input-trois"
                isLabel
              >
              {i18n.t("infoPerso16.label")}
              </Radio>
            </div>
            <div className="right_cls">
              <Radio
                items={autreRevenuOptions}
                storeKey="demandeCredit.boautrerevenu"
                styleClass="button-input-trois clearfix"
                isLabel
              >
              Autres Revenus Mensuels
              </Radio>
            </div>
          </div>

          {this.props.demandeCredit.boautrerevenu &&
            this.props.demandeCredit.boautrerevenu === 'Oui' &&
          <div className="control_group clearfix">
            <div className="left_cls">
              <div className="control_with_toolTip">
                <div className="control_area_alone">
                <Text
                  type="number"
                  storeKey="demandeCredit.autrerevenu"
                  disabled={this.props.disableAutre}
                >autres Revenus Mensuels
                {!this.props.disableAutre ?
                  <div className="input-label-icon">
                    <div className="icon-class">DHS</div>
                  </div>
                  : null}
                </Text>
                </div>
                <div className="help_tuto_icn tooltip">
                  <span className="help_tuto_icn tooltip" >
                    <img src={icone_info} alt="" id="infos_icn" />
                    <span className="tooltiptext tooltiWidthResp"> {infobulleMsg.InfoAuresRevenu} </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="right_cls">
              <Select
                items={sourceAutreRevenu}
                storeKey="demandeCredit.sourceAutreRevenu"
                placeholder="Veuillez sélectionner la source des autres revenus"
              >  Source autres revenus</Select>
            </div>
          </div>
        }

          <div className="control_group mb30 clearfix" >
            <div className="left_cls">
              <div className="control_with_toolTip">
                <div className="control_area_alone">
                  <Text
                    type="number"
                    maxLength="35"
                    storeKey="demandeCredit.autresecheances"
                  > {i18n.t('infoPerso19.label')}
                    <div className="input-label-icon">
                      <div className="icon-class">{i18n.t('simulateur7.label')}</div>
                    </div>
                  </Text>
                </div>
                <div className="help_tuto_icn tooltip">
                  <span className="help_tuto_icn tooltip" >
                    <img src={icone_info} alt="" id="infos_icn" />
                    <span className="tooltiptext tooltiWidthResp"> {infobulleMsg.InfoEcheanceAutresPrets} </span>
                  </span>
                </div>
              </div>
            </div>

          <div className="right_cls">
                <Select
                  items={situationLogementOptions}
                  storeKey="demandeCredit.situationLogementDemandeur"
                  placeholder={i18n.t("infoPerso6.label")}
                > {i18n.t("infoPerso24.label")}</Select>
            </div>
          </div>
        </div>
        <div className="next_btn_container">
      <NextButton
              action={this.suivant}
              gtm={true}
              gtmPush={this.gtmPush}
              label={i18n.t("suivant.label")}
              dataTxt={i18n.t("suivant.label")}
              fields={requiredFields}
              isFormulaire
              className="flat_btn purple_btn more_padding"
            />
            </div>
      </div>
    );
  }
}

InfosPerso.propTypes = {
  disableAutre: React.PropTypes.bool,
  goNext: React.PropTypes.func,
  saveDemande: React.PropTypes.func,
  status: React.PropTypes.string,
  displayErrors: React.PropTypes.func,

};
export default InfosPerso;

import React, { Component } from 'react';
// <a
//   href={this.props.lien}
//   data-text="action btn"
//   className="flat_btn purple_btn right_mr"
// >
//   <span className="plusDepadding">action btn</span>
// </a>
class SlideElements extends Component {
  render() {
    return (
      <div>
      {this.props.isAcceuil ?
        <div>
          <div className="slide_img relative_position" id="b">
            <img src={this.props.src1} alt="" />
          </div>
          <div className="accueil_text_bloc">
          <div className="slide_txt_container mr-t25">
              <p className="slide_txt  medium_style size30">
              {this.props.title}
              </p>
              <p className="slide_txt slide_txt_shadow bold_style size45">
              {`${this.props.subTitle}`}
              </p>

              <div className="align_left">

              </div>
            </div>

          </div>
        </div> :
        <div className="">
          <div className="cover_hover"></div>
          <div>
            <div className="produit_desc">
              <p className="produit_col">
                <h2 className="produit_title">{this.props.produitTitre}</h2>
                <span className="produit_subTitle">{this.props.produitSsTitre}</span>
              </p>
              <p className="produit_cover">
                <h2 className="produit_title">{this.props.produitTitre}</h2>
                <span className="hidden_desc">{this.props.hiddenDesc}</span>
              </p>
              <p>
                <a
                  href={this.props.lien}
                  data-text="détail"
                  className="flat_btn purple_btn produit_btn"
                >
                  <span className="plusDepadding">détail</span>
                </a>
              </p>
            </div>
          </div>
        </div>
         }
      </div>
    );
  }
}

SlideElements.propTypes = {
  isAcceuil: React.PropTypes.bool,
  src: React.PropTypes.string,
  src1: React.PropTypes.string,
  title: React.PropTypes.string,
  subTitle: React.PropTypes.string,
  desc: React.PropTypes.string,
  lien: React.PropTypes.string,
  produitTitre: React.PropTypes.string,
  produitSsTitre: React.PropTypes.string,
  hiddenDesc: React.PropTypes.string,
};
export default SlideElements;

import React, { Component } from 'react';
import { List } from 'immutable';


class TimelineEP extends Component {

  render() {
    return (
      <div className="titmeline_container mb30">
        <ul>
         {this.props.steps.map((stepPerso,index) => (
           <li
             key={stepPerso.get('step')}
             className={stepPerso.get('step') < this.props.current ?
                       'passed' :
                       (stepPerso.get('step') === this.props.current ?
                       'active' : '')}
           >
           <span className="timeline_img">
              <img src={stepPerso.get('imgDefault').get('ImgsDefaults').get(index)} alt="" className="default_icn" width="56" />
              <img src={stepPerso.get('imgActive').get('ImgsActives').get(index)} alt="" className="active_icn" width="56" />
              <img src={stepPerso.get('imgValid').get('ImgValides').get(index)} alt="" className="passed_icn" width="56" />
         </span>
             <h2>{stepPerso.get('label')}</h2>
             <h3>{stepPerso.get('statut')}</h3>
           </li>
        ))}
        </ul>
      </div>
    );
  }
}

TimelineEP.propTypes = {
  steps: React.PropTypes.instanceOf(List),
  stepPerso: React.PropTypes.object,
  active: React.PropTypes.bool,
  valid: React.PropTypes.bool,
  className: React.PropTypes.string,
  current: React.PropTypes.number,
};
TimelineEP.defaultProps = {
  className: 'timeline-step',
};
export default TimelineEP;

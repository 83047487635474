/* eslint-disable */
import React, { Component } from 'react';
import { Map } from 'immutable';
import { every, isEmpty } from 'lodash';
import { fromJS } from 'immutable';
var msgError = "Afin de bénéficier d'un crédit immobilier, votre revenu doit être d'au moins 2000 DH";
const errors = {
  'nom.wrongFormat': 'Champs invalide',
  'prenom.wrongFormat': 'Champs invalide',
  'autreactivite.wrongFormat': 'Champs invalide',
  'revenu.wrongFormat': 'Champs invalide',
  'anciente.wrongFormat': 'Champs invalide',
  'autrerevenu.wrongFormat': 'Champs invalide',
  'mail.wrongFormat': 'Doit être une adresse mail valide (adresse@domaine.ext)',
  'telephone.wrongFormat': 'Numéro de téléphone non valide',
  'numpieceidentite.wrongFormat':
  'Le N° CIN ne peut contenir que des lettres majuscules puis des chiffres',
  'coutprojet.wrongFormat': 'Champs invalide',
  'montantCredit.wrongFormat': 'Champs invalide',
  'surfacebien.wrongFormat': 'Champs invalide',
  'localisationbien.wrongFormat': 'Champs invalide',
  'typetaux.wrongFormat': 'Champs invalide',
  'apportpersonnel.wrongFormat': 'Champs invalide',
  'date_naissance.wrongFormat': 'Champs invalide',
  'paysderesidence.wrongFormat': 'Champs invalide',
  'pieceidentite.wrongFormat': 'Champs invalide',
  'situationfamiliale.wrongFormat': 'Champs invalide',
  'profession.wrongFormat': 'Champs invalide',
  'employeur.wrongFormat': 'Champs invalide',
  'secteur.wrongFormat': 'Champs invalide',
  'activite.wrongFormat': 'Champs invalide',
  'autresecheances.wrongFormat': 'Champs invalide',
  'boautrerevenu.wrongFormat': 'Champs invalide',
  'date_naissance.underage': 'Vous devez être majeur pour créer une demande de crédit',
  'nbAnnee.isSeventy': 'L\'âge du client dépasse 70 ans à la dernière échéance',
  'montantCredit.isNonValid':'Le montant dépasse 110% du prix du bien',
  'montantCredit.isBig':'Le montant ne peut pas dépasser 20 000 000',
   'nbAnnee.isMaxDuree':'La durée maximale du crédit demandé ne doit pas dépasser 25 ans',
   'revenu.isValid': msgError,

};

class InputError extends Component {

  // componentWillReceiveProps = (nextProps) => {
  //   if(this.props.fieldsWithErrors !== nextProps.fieldsWithErrors || nextProps.fieldsWithErrors === ""){
  //     if(!nextProps.haveValue &&
  //       nextProps.nextPushed &&
  //       nextProps.errors.size === 0 &&
  //       nextProps.requiredFields.includes(nextProps.storeKey)) {
  //         nextProps.inputChange(nextProps.fieldsWithErrors + ";" + nextProps.storeKey.split('.')[1], "demandeCredit.fieldsWithErrors")
  //     }
  //   }
  // }

  render() {
    return (
      <div>
        <div className="input-error">
          {this.props.errors.filter((value) => value).keySeq().map((error) =>
            <div className="input-error__txt" key={error}>{errors[error] || error}</div>
          )}
        </div>
        <div className="input-error">
        {(
            !this.props.haveValue &&
            this.props.nextPushed &&
            this.props.errors.size === 0 &&
            this.props.requiredFields.includes(this.props.storeKey)
          ) ?
          <div className="input-error__txt" >Champs obligatoire</div>
          :
          null
         }
        </div>
      </div>
    );
  }
}

InputError.propTypes = {
  errors: React.PropTypes.instanceOf(Map),
  haveValue: React.PropTypes.bool,
  nextPushed: React.PropTypes.bool,
  inputChange: React.PropTypes.func,
  includes: React.PropTypes.func,
  requiredFields: React.PropTypes.instanceOf(Map),
};

InputError.defaultProps = {
  errors: new Map(),
  nextPushed: false,
};

export default InputError;

import { connect } from 'react-redux';
import Popin from '../components/Popin';
import { showHide } from '../action_creators';

function mapStateToProps(state, props) {
  const [store, ...storeKey] = props.storeKey.split('.');
  storeKey.push('value');
  return ({
    isChaabiClient: state.demandeCredit.getIn(['isChaabiClient', 'value']),
    showHideElm: state[store].getIn(storeKey),
    langues: state.demandeCredit.getIn(['langues', 'value']) || 'fr', 
    origin: state.demandeCredit.getIn(['origin', 'value']),
    montantCredit: state.simulateur.getIn(['montantCredit', 'value']),
    nbAnnee: state.simulateur.getIn(['nbAnnee', 'value']),
    dureeDiff: state.simulateur.getIn(['dureeDiff', 'value']),
    taux: state.simulateur.getIn(['taux', 'value']),
    echeance: state.simulateur.getIn(['echeance', 'value']),
  });
}

const mapDispatchToProps = { showHide };
export default connect(mapStateToProps, mapDispatchToProps)(Popin);

import { fromJS } from 'immutable';

const DEFAULT_DEMANDE = fromJS({
  nbAnnee: { value: 25 },
  fraisamg: { value: 0 },
  montantCredit: { value: '' },
  dureeDiff: { value: 0 },
  progressLevel: { value: 'progress' },
  cityAgence: { value: '780' },
  toggling: { value: true },
  popSimulate: { value: true },
  proposition: { value: true },
  statutEncours: { value: 'contrat' },
  showfaq: { value: false },
  authentificate: { value: true },
  showMdpOublie: { value: true },
  authentif: { value: true },
  mdpToChange: { value: true },
  isChaabiClient: { value: true },
  chaabiClient: { value: false },
  testChaabi: { value: true },
  yesChaabiClient: { value: true },
  popinItem: { value: 'connexion' },
  confirmedInfos: { value: false },
  confirmeAgence: { value: false },
  gtmlogin:{ value: false },
});
export default function (state = DEFAULT_DEMANDE, action) {
  switch (action.type) {
    case 'INPUT_CHANGE':
      if (action.store === 'demandeCredit') {
        return state.setIn(action.key.concat(['value']), fromJS(action.newValue));
      }
      return state;
    case 'CLEAN_STORE':
      return DEFAULT_DEMANDE;
    case 'DEMANDE_STATE':
      return state.setIn(['progressLevel', 'value'], 'done');
    case 'DEMANDE_REQUEST':
      return state.setIn(['request', 'status'], 'loading');
    case 'CLEAN_STATUS':
      return state.deleteIn(['request', 'status']);
    case 'DEMANDE_SAVE_SUCCESS':
      return state.withMutations((ctx) => {
        ctx.setIn(['request', 'result'], fromJS(action.result));
        ctx.setIn(['request', 'status'], fromJS(action.result).getIn(['status', 'message']));
      });
    case 'DEMANDE_SAVE_ERROR':
      return state.withMutations((ctx) => {
        ctx.setIn(['request', 'result'], fromJS(action.result));
        ctx.setIn(['request', 'status'], 'error');
      });
    case 'VERIF_PASSWORD_SUCCESS':
      return state.withMutations((ctx) => {
        ctx.setIn(['request', 'result'], fromJS(action.result));
        ctx.setIn(['request', 'status'], 'success');
      });
    case 'VERIF_PASSWORD_ERROR':
      return state.withMutations((ctx) => {
        ctx.setIn(['request', 'result'], fromJS(action.result));
        ctx.setIn(['request', 'status'], 'error');
      });
    case 'CHECK_CHANGE_PASSWORD_SUCCESS':
      return state.withMutations((ctx) => {
        ctx.setIn(['request', 'result'], fromJS(action.result));
        ctx.setIn(['request', 'status'], 'success');
      });
    case 'CHECK_CHANGE_PASSWORD_ERROR':
      return state.withMutations((ctx) => {
        ctx.setIn(['request', 'result'], fromJS(action.result));
        ctx.setIn(['request', 'status'], 'error');
      });
    case 'ALTERNATE':
      return state.setIn(['toggling', 'value'], fromJS(action.value));
    case 'SHOWHIDE':
      return state.withMutations((ctx) => {
        ctx.setIn([action.key.split('.')[1], 'value'],
          !state.getIn([action.key.split('.')[1], 'value']));
        ctx.setIn(['popinItem', 'value'], 'connexion');
      });
    case 'CHAABICLIENT':
      return state.withMutations((ctx) => {
        ctx.setIn(['chaabiClient', 'value'], action.value);
      });
    case 'SCROLL_TO_SECTION':
      return state.withMutations((ctx) => {
        ctx.setIn(['source', 'value'], action.source);
        ctx.setIn(['selecctor', 'value'], action.id);
      });
    case 'SWITCH_POPIN':
      return state.setIn(['popinItem', 'value'], 'showMdpOublie');
    case 'GET_DEMANDE_SUCCESS':
      return state.withMutations((ctx) => {
        ctx.set('maDemande', fromJS(action.result));
        ctx.set('maDemandeStatus', fromJS(action.result).get('status').get('successIndicator'));
      });
    case 'GET_REJET_PROP_SUCCESS':
      return state.withMutations((ctx) => {
        ctx.set('rejetPropComm', fromJS(action.result));
        ctx.set('rejetPropCommStatus', fromJS(action.result).get('status').get('successIndicator'));
      });
    case 'GET_DEMANDE_BY_EMAIL_SUCCESS':
      return state.withMutations((ctx) => {
        ctx.set('demandeMail', fromJS(action.result));
      });
    case 'GET_DEMANDE_BY_EMAIL_OR_RADICAL_SUCCESS':
      return state.withMutations((ctx) => {
        ctx.set('demandeMailRadical', fromJS(action.result));
      });
    case 'GET_DEMANDE':
      return state.withMutations((ctx) => {
        ctx.set('maDemande', fromJS([]));
        ctx.set('maDemandeStatus', fromJS('loading'));
      });
    case 'GET_DEMANDE_ERROR':
      return state.withMutations((ctx) => {
        ctx.set('maDemandeStatus', fromJS(action.result).get('status').get('successIndicator'));
      });
    case 'SEARCH_CLIENT_SUCCESS':
      return state.withMutations((ctx) => {
        ctx.set('clientDetails', fromJS(action.result).get('clientDetails'));
        ctx.set('clientDetailsStatus', fromJS(action.result).get('status').get('successIndicator'));
        ctx.setIn(['nom', 'value'], fromJS(action.result).getIn(['clientDetails', 'lastName']));
        ctx.setIn(['prenom', 'value'], fromJS(action.result).getIn(['clientDetails', 'firstName']));
        ctx.setIn(['civilite', 'value'], fromJS(action.result)
          .getIn(['clientDetails', 'civility']));
        ctx.setIn(['situationfamiliale', 'value'], fromJS(action.result)
          .getIn(['clientDetails', 'situationFamiliale']));
        ctx.setIn(['nbrenfants', 'value'], fromJS(action.result)
          .getIn(['clientDetails', 'nombreEnfant']));
        ctx.setIn(['mail', 'value'], fromJS(action.result).getIn(['clientDetails', 'email']));
        ctx.setIn(['telephone', 'value'], fromJS(action.result).getIn(['clientDetails', 'phone']));
        ctx.setIn(['numpieceidentite', 'value'], fromJS(action.result)
          .getIn(['clientDetails', 'cin']));
        ctx.setIn(['pieceidentite', 'value'], 'C.I.N'); // default value
        ctx.setIn(['paysderesidence', 'value'], fromJS(action.result)
          .getIn(['clientDetails', 'residenceCountry']));
        ctx.setIn(['revenu', 'value'], fromJS(action.result).getIn(['clientDetails', 'salary']));
        ctx.setIn(['agence', 'value'], fromJS(action.result)
          .getIn(['clientDetails', 'codeAgenceMNS']));
        ctx.setIn(['CodeAgencePrincipal', 'value'], fromJS(action.result)
          .getIn(['clientDetails', 'codeAgenceMNS']));
        ctx.setIn(['libelleagence', 'value'], fromJS(action.result)
          .getIn(['clientDetails', 'libelleAgenceMNS']));
        ctx.setIn(['date_naissanceDay', 'value'], parseInt(fromJS(action.result)
          .getIn(['clientDetails', 'clientBirthDateDay']), 10));
        ctx.setIn(['date_naissanceMonth', 'value'], parseInt(fromJS(action.result)
          .getIn(['clientDetails', 'clientBirthDateMonth']), 10));
        ctx.setIn(['date_naissanceYear', 'value'], fromJS(action.result)
          .getIn(['clientDetails', 'clientBirthDateYear']));
        ctx.setIn(['clientbcp', 'value'], 'Oui');
      });
    case 'SEARCH_CLIENT':
      return state.withMutations((ctx) => {
        ctx.set('clientDetails', fromJS([]));
        ctx.set('clientDetailsStatus', fromJS('loading'));
      });
    case 'SEARCH_CLIENT_ERROR':
      return state.withMutations((ctx) => {
        ctx.set('clientDetailsStatus', fromJS(action.result).get('status').get('successIndicator'));
      });
    case 'PROPOSAL_ACCEPTED_SUCCESS':
      return state.withMutations((ctx) => {
        ctx.set('propositionStatus', fromJS(action.result).get('successIndicator'));
      });
    case 'PROPOSAL_ACCEPTED_AGENCE_SUCCESS':
      return state.withMutations((ctx) => {
        ctx.set('propositionAgenceStatus', fromJS(action.result).get('successIndicator'));
      });
    case 'PROPOSITION_STEP':
      return state.withMutations((ctx) => {
        ctx.set('propositionStatus', fromJS('loading'));
      });
    case 'PROPOSITION_STEP_ERROR':
      return state.withMutations((ctx) => {
        ctx.set('propositionStatus', fromJS(action.result).get('successIndicator'));
      });
    case 'PROPOSITION_STEP_AGENCE':
      return state.withMutations((ctx) => {
        ctx.set('propositionAgenceStatus', fromJS('loading'));
      });
    case 'PROPOSITION_STEP_AGENCE_ERROR':
      return state.withMutations((ctx) => {
        ctx.set('propositionAgenceStatus', fromJS(action.result).get('successIndicator'));
      });
    case 'DISPLAY_ERRORS': {
      return state.withMutations((ctx) => {
        ctx.setIn(['nextPushed', 'value'], action.etat);
        ctx.setIn(['requiredFields', 'value'], action.requiredFields);
      });
    }
    default:
      return state;
  }
}

import { fromJS } from 'immutable';
const DEFAULT_SIMULATEUR = fromJS({
  toggled: { value: true },
  montantCredit: { value: 500000 },
  nbAnnee: { value: 25 },
  taux: { value: 4.99 },
  dureeDiff: { value: 0 },
  echeance: { value: 3067.15 },
  capaciteEmpruntfinal: { value: 1000 },
  autreCredit: { value: 0 },
  revenuNetMensuel: { value: 5000 },
  tauxHt: { value: 5.5 },
  duree: { value: 12 },
  capaciteEmprunt: { value: 229957.97 },
  dMontantCredit: { value: 500000 },
  dNbAnnee: { value: 12 },
  dDureeDiff: { value: 0 },
  dTaux: { value: 5.5 },
  dEcheance: { value: 3067.15 },
});
export default function (state = DEFAULT_SIMULATEUR, action) {
  switch (action.type) {
    case 'INPUT_CHANGE':
      if (action.store === 'simulateur') {
        return state.setIn(action.key.concat(['value']), fromJS(action.newValue));
      }
      return state;
    case 'CLEAN_STORE':
      return DEFAULT_SIMULATEUR;
    case 'CALCUL_MONTANT_ECHEANCE_MENSUEL_SUCCESS':
      return state.setIn(['simulateurEcheance', 'value'], fromJS(action.result));
    case 'CALCUL_MONTANT_ECHEANCE_MENSUEL':
      return state.setIn(['simulateurEcheance', 'value'], fromJS([]));
    case 'CALCUL_MONTANT_All_SUCCESS':
      return state.set('allResult', fromJS(action.result));
    case 'CALCUL_MONTANT_All':
      return state.set('allResult', fromJS([]));
    case 'CALCUL_FRAIS_SUCCESS':
      return state.set('simulateurFrais', fromJS(action.result));
    case 'CALCUL_FRAIS':
      return state.set('simulateurFrais', fromJS([]));
    case 'CALCUL_MONTANT_CAPACITE_EMPRUNT_SUCCESS':
      return state.setIn(['capaciteEmprunt', 'value'], fromJS(action.result));
    case 'CALCUL_MONTANT_CAPACITE_EMPRUNT':
      return state.setIn(['capaciteEmprunt', 'value'], fromJS([]));
    case 'CALCUL_MONTANT_CAPACITE_EMPRUNT_DEFAULT_SUCCESS':
      return state.set('simulateurCapaciteEmpruntDefault', fromJS(action.result));
    case 'CALCUL_MONTANT_CAPACITE_EMPRUNT_DEFAULT':
      return state.set('simulateurCapaciteEmpruntDefault', fromJS([]));
    case 'INTOGGLE':
      return state.setIn(['toggled', 'value'], fromJS(action.value));
    case 'SHOWHIDE':
      return state.setIn([action.key.split('.')[1], 'value'],
          !state.getIn([action.key.split('.')[1], 'value']));
    case 'CHANGE_VALUES':
      return state.withMutations((ctx) => {
        ctx.setIn(['montantCredit', 'value'], ctx.getIn(['dMontantCredit', 'value']));
        ctx.setIn(['nbAnnee', 'value'], ctx.getIn(['dNbAnnee', 'value']));
        ctx.setIn(['dureeDiff', 'value'], ctx.getIn(['dDureeDiff', 'value']));
        ctx.setIn(['taux', 'value'], ctx.getIn(['dTaux', 'value']));
        ctx.setIn(['echeance', 'value'], ctx.getIn(['dEcheance', 'value']));
      });
    case 'default_STATE':
      return state.withMutations((ctx) => {
        ctx.setIn(['taux', 'value'], '4.99');
      });
    default:
      return state;
  }
}

import React, { Component } from 'react';
import DetailSimulation from '../containers/DetailSimulation';
import { List } from 'immutable/dist/immutable';
import { hashHistory } from 'react-router';
import HeaderAuth from '../containers/HeaderAuth';
import Popin from '../containers/Popin';

export default class DetailResultatSMAP extends Component {
  constructor(props) {
    super(props);
    this.print = this.print.bind(this);
    this.click1 = this.click1.bind(this);
    this.saveSimulateur = this.saveSimulateur.bind(this);
    this.sendSimulateur = this.sendSimulateur.bind(this);
    this.successReturn = this.successReturn.bind(this);
  }
  componentWillMount() {
    this.props.getAllResult(this.props.taux, this.props.nbAnnee,
    1, this.props.dureeDiff,
    this.props.montantCredit);
  }
  successReturn() {
    this.props.clearState();
    hashHistory.push('/SMAP');
  }
  saveSimulateur(){
    this.props.saveInfoResultatSimulateurSmap();
  }
  sendSimulateur(){
    this.props.sendSimulateurSmap();
  }

  print() {
    window.print();
  }
  click1(s) {
    const { montantCredit, nbAnnee, dureeDiff } = this.props;
    this.props.inputChange(montantCredit, 'simulateur.montantCredit');
    this.props.inputChange(nbAnnee, 'simulateur.nbAnnee');
    this.props.inputChange(dureeDiff, 'simulateur.dureeDiff');
    this.props.show(s);
  }
  render() {
    const details = this.props.allResult
    .filter(f => f.get('id') === parseInt(this.props.nbAnnee)).get(0);
    return (
      <div>
        <DetailSimulation
          Result={details}
          montantCredit={this.props.montantCredit}
          taux={this.props.taux}
          dureeDiff={this.props.dureeDiff}
        />
        {this.props.statusEmail === 'success' &&
          <Popin
            classCss="flat_btn purple_btn more_padding"
            classCssWhite="flat_btn white_btn right_mr"
            dataText="Ok"
            datalabel="Ok"
            storeKey="demandeCredit.show"
            popin_modals="popin_dialog"
            popinTitle="Votre simulation a bien été envoyée"
            popinIcn={this.props.popinIcnInfo}
            withoneButton
            action={this.successReturn}
          />
        }
        <div className="align_center">
          <button
            data-text="Recevoir cette simulation par email"
            className="flat_btn purple_btn demande_detail_btn"
            onClick={this.saveSimulateur}
          >
            <span className="demande_pret_btn">Recevoir cette simulation par email</span>
          </button>

        </div>
        {this.props.isChaabiClient ?
          null :
          <HeaderAuth />
        }
      </div>
    );
  }
    }
DetailResultatSMAP.propTypes = {
  taux: React.PropTypes.number,
  storeKey: React.PropTypes.string.isRequired,
  montantCredit: React.PropTypes.number,
  dureeDiff: React.PropTypes.number,
  nbAnnee: React.PropTypes.number,
  allResult: React.PropTypes.instanceOf(List).isRequired,
  getAllResult: React.PropTypes.func,
  inputChange: React.PropTypes.func,
  sendMail: React.PropTypes.func,
  show: React.PropTypes.func,
  isChaabiClient: React.PropTypes.bool,
};
DetailResultatSMAP.defaultProps = {
  allResult: new List(),
};

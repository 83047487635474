import React, { Component } from 'react';
import Title from './Title';
import Text from '../containers/Text';
import { hashHistory } from 'react-router';

class MaReference extends Component {

  constructor(props) {
    super(props);
    this.search = this.search.bind(this);
  }

  componentWillMount() {
    // this.props.initialAccout();
  }
  search() {
    this.props.getDemande(this.props.reference);
  }
  render() {
    return (
      <div className="body">
        <Title>Ma réference</Title>
        <div className="control_group mb10 clearfix">
          <div className="left_cls">
            <Text maxLength="35" storeKey="demandeCredit.reference">
              Reference
            </Text>
          </div>
          <div className="right_cls">
            <button
              data-text="Search"
              className="flat_btn purple_btn right_mr"
              onClick={this.search}
            >
              <span className="plusDepadding">Search</span>
            </button>
            {this.props.maDemandeStat === 'success' ?
               hashHistory.push('/espaceperso')
               : this.props.maDemandeStat === 'error' ?
                 <div> Error Veuillez vérifier votre réference </div> : null}
            {this.props.flagClient === 'C' ?
              <div> Client connecté </div> : null}
             {this.props.flagClient === 'P' ?
               <div> Prospect connecté </div> : null}
          </div>
        </div>
      </div>
    );
  }
}

MaReference.propTypes = {
  reference: React.PropTypes.string,
  flagClient: React.PropTypes.string,
  getDemande: React.PropTypes.func,
  initialAccout: React.PropTypes.func,
  maDemandeStat: React.PropTypes.bool,
};

export default MaReference;

/* eslint-disable */
import { connect } from 'react-redux';
import { PropTypes } from 'react';

import InputError from '../../components/Inputs/InputError';
import { inputChange } from '../../action_creators';

const mapStateToProps = (state, props) => {
  const [store, ...storeKey] = props.storeKey.split('.');
  return ({
    errors: state[store].getIn(storeKey.concat(['errors'])),
    haveValue: (state[store].getIn(storeKey.concat(['value'])) &&
    state[store].getIn(storeKey.concat(['value'])) !== undefined
    && state[store].getIn(storeKey.concat(['value'])).size !== 0),
    nextPushed: state.demandeCredit.getIn(['nextPushed', 'value']) === 'oui',
    isChecked: state.demandeCredit.getIn(['confirmedInfos', 'value', 0]) === undefined ? state.demandeCredit.getIn(['confirmedInfos', 'value']) :
    state.demandeCredit.getIn(['confirmedInfos', 'value', 0]),
    requiredFields: state.demandeCredit.getIn(['requiredFields', 'value']),
    fieldsWithErrors: state.demandeCredit.getIn(['fieldsWithErrors', 'value']) || ""
  });
};

function mapDispatchToProps(dispatch) {
  return {
    inputChange: (...y) => dispatch(inputChange(...y)),
  };
}

const ConnectedInputError = connect(mapStateToProps, mapDispatchToProps)(InputError);

ConnectedInputError.PropTypes = {
  storeKey: PropTypes.string.isRequired,
};

export default ConnectedInputError;

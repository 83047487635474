import React, { Component } from 'react';
import Slider from 'react-slick-fix/lib/slider';
import Proposition from '../containers/Proposition';
import DetailSimulation from '../containers/DetailSimulation';
import { List } from 'immutable/dist/immutable';
import Crc from './Accueil/Crc';
import Produits from './Inputs/Produits';
import Chart from '../containers/Inputs/Chart';
import HeaderAuth from '../containers/HeaderAuth';
import i18n from '../i18n';
export default class DetailResultat extends Component {
  constructor(props) {
    super(props);
    this.print = this.print.bind(this);
    this.click1 = this.click1.bind(this);
    this.click2 = this.click2.bind(this);
  }
  componentWillMount() {
    window.scrollTo(0, 0);
    this.props.getAllResult(this.props.taux, this.props.nbAnnee,
    1, this.props.dureeDiff,
    this.props.montantCredit);
  }

  click1(s) {
    const { montantCredit, nbAnnee, dureeDiff } = this.props;
    this.props.inputChange(montantCredit, 'simulateur.montantCredit');
    this.props.inputChange(nbAnnee, 'simulateur.nbAnnee');
    this.props.inputChange(dureeDiff, 'simulateur.dureeDiff');
    this.props.show(s);
  }
  click2() {
    const result = this.props.allResult.filter(f => f.get('id')
                     === this.props.nbAnnee).get(0);
    const mailRequest = { montant: this.props.montantCredit,
      duree: this.props.nbAnnee,
      taux: this.props.taux,
      differe: this.props.dureeDiff,
      echence: result.get('montant'),
      fraisDtc: result.get('dtc'),
      fraisDossier: result.get('dossier'),
      mail: 'aeljabiri@pca.ma' };
    this.props.sendMail(mailRequest);
  }
  print() {
    window.print();
  }
  render() {
    const details = this.props.allResult
    .filter(f => f.get('id') === parseInt(this.props.nbAnnee) ).get(0);
    const Capital = (((this.props.allResult
    .filter(f => f.get('id') === parseInt(this.props.nbAnnee)).get(0) || new List())
    .get('tableauAmortissement') || new List()).toArray() || []);
    // const Interet = this.props.allResult
    // .filter(f => f.get('id') === this.props.nbAnnee).get(0).get('tableauAmortissement').
    //  get(1);
    const items = this.props.allResult.sort((a, b) => a.get('id') - b.get('id'))
    .filter(f => f.get('id') !== this.props.nbAnnee)
     .map(item => (
       <div key={item.get('id')} className="checked-item-list">
         <Proposition
           storeKey={this.props.storeKey}
           key={item.get('id')}
           annee={item.get('id')}
           mensualite={item.get('montant')}
         />
       </div>
    ));

    const settings = {
      infinite: false,
      arrows: true,
      slidesToShow: 2.5,
      autoplay: false,
      adaptiveHeight: true,
      // variableWidth: true,
      slidesToScroll: 1,
      respondTo: 'window',
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 10,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <div>
        <DetailSimulation
          Result={details}
          montantCredit={this.props.montantCredit}
          taux={this.props.taux}
          dureeDiff={this.props.dureeDiff}
        />
        <div className="global_slider_container unprentable hidepermanently_onmobile">
          <div className="agencies_slider_container">
            <h2 className="votre_mensualite"> {i18n.t("recap46.label")}</h2>
            <div id="agencies_slider">
              {items.size > 0 ?
                <Slider {...settings}>
                      {items}
                </Slider> :
                <div>Une erreur est survenue </div>
                }
            </div>
          </div>
        </div>
        <div className="detailsimulateur_btns clearfix unprentable " >
        { //enlevement des icône de la page detail simulation
          /* <div className="float_right">
            <button className="download_links" onClick={() => this.click2()}>
              <img src="img/message.png" alt="banque populaire" />
            </button>
            <button className="download_links" onClick={() => this.print()} >
              <img src="img/impression_icone.png" alt="banque populaire" />
            </button>
            <button className="download_links">
              <img src="img/enregistrer_icone.png" alt="banque populaire" />
            </button>
          </div>
          */  }
          <div className="align_center">
            <button
              data-text={i18n.t("simulateur11.label")}
              className="flat_btn purple_btn demande_detail_btn"
              onClick={() => { this.click1('demandeCredit.isChaabiClient'); }}
            >
              <span className="demande_pret_btn">{i18n.t("simulateur11.label")}</span>
            </button>
          </div>
        </div>
        <div className="hidepermanently_onmobile">
          <Chart Result={Capital} />
        </div>
        <Produits />
        <div className="crc_container unprentable ">
          <Crc />
        </div>
        {this.props.isChaabiClient ?
          null :
          <HeaderAuth />
        }
      </div>
    );
  }
    }
DetailResultat.propTypes = {
  taux: React.PropTypes.number,
  storeKey: React.PropTypes.string.isRequired,
  montantCredit: React.PropTypes.number,
  dureeDiff: React.PropTypes.number,
  nbAnnee: React.PropTypes.number,
  allResult: React.PropTypes.instanceOf(List).isRequired,
  getAllResult: React.PropTypes.func,
  inputChange: React.PropTypes.func,
  sendMail: React.PropTypes.func,
  show: React.PropTypes.func,
  isChaabiClient: React.PropTypes.bool,
};
DetailResultat.defaultProps = {
  allResult: new List(),
};

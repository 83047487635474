import { connect } from 'react-redux/dist/react-redux';

import Text from '../components/Text';

const mapStateToProps = (state, props) => {
  const [store, ...storeKey] = props.storeKey.split('.');
  storeKey.push('value');
  return ({
    value: state[store].getIn(storeKey),
  });
};

export default connect(mapStateToProps)(Text);

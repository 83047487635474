import React, { Component } from 'react';
import i18n from '../../i18n';
import {isLoged} from '../../utile/utile';
import TagManager from 'react-gtm-module';
class Crc extends Component {
  componentDidMount() {
    if (!this.props.isHome && this.props.selector) {
      document.querySelector(this.props.selector) && document.querySelector(this.props.selector).scrollIntoView({
        behavior: "smooth",
      });
    }
  }
  componentDidUpdate(prevProps) {
  }
  
  clickToCall = () => {
    TagManager.dataLayer({
      dataLayer: isLoged(this.props.userId,{
          event: "clicktocall",
          userStatus: this.props.userStatus,
          langue: this.props.langues,
          BU: 'particulier'
      }),
    })
  }
  render() {
    return (
      <div className="align_center" id="contact_section">
        <h2 className="title_section purple_clr uppercase_txt text_shadow_white">
          {i18n.t("titleAv39.label")}
        </h2>
        <hr className="hr_header purple_bg" />
        <p className="need_info_txt purple_clr uppercase_txt text_shadow_white">
          {i18n.t("titleAv40.label")}
        </p>
        <p className="visit_us_txt purple_clr uppercase_txt text_shadow_white">
          {i18n.t("titleAv41.label")}
          <strong>{i18n.t("titleAv42.label")}</strong><br />
          <strong>{i18n.t("titleAv43.label")}</strong>
        </p>
        <p>
          <span className="contact_num_txt purple_clr text_shadow_white">
            <strong>
              <a onClick={this.clickToCall} className="call_num" href="tel:2121">2121</a>
            </strong>
          </span><br />
          <span className="call_price_txt purple_clr text_shadow_white">
            ({i18n.t("titleAv44.label")})
          </span>
        </p>
      </div>
    );
  }
}

export default Crc;

/* eslint-disable */
import React, { Component } from 'react';
import footer_logo_bankpopulaire from '../../assets/images/footer_logo_bankpopulaire.jpg';
import i18n from '../../i18n';
import PdfConditionGeneral from '../../assets/docs/CONDITIONS_GENERALES_Immo.pdf';
import PdfMentionsLegal from '../../assets/docs/Mentionslegale.pdf';
import { hashHistory } from 'react-router';
import TagManager from 'react-gtm-module';
import { isLoged } from "../../utile/utile"
class Footer extends Component {

  handleClick = (e, selector) => {
    e.preventDefault();
    if (document.querySelector(selector)) {
      document.querySelector(selector) && document.querySelector(selector).scrollIntoView({
        behavior: "smooth",
      });
    } else {
      this.props.scrolllToSection(this.props.isHome ? "home" : "other", selector);
      hashHistory.push('/')
    }
  }
  render() {
    return (
      <footer className="main_footer unprentable">
        <div className="main_footer_links">
          <ul className="clearfix">
            <li>
              <a href="#account_opening_steps"
                onClick={(e) => {
                  TagManager.dataLayer({
                    dataLayer: isLoged(this.props.userId, {
                      event:"user_navigation",
                      item: "footer",
                      target: "acceuil",
                      langue: this.props.langues,
                      userStatus: this.props.userStatus,
                      BU: "particulier"
                    })
                  })
                  this.props.scrollToSection(e, "#account_opening_steps")
                }}
              >
                {i18n.t("titleAv45.label")}
              </a>
            </li>
            <li>
              <a href="#faq_section"
                onClick={(e) => {
                  TagManager.dataLayer({
                    dataLayer: isLoged(this.props.userId, {
                      event:"user_navigation",
                      item: "footer",
                      target: "FAQ",
                      langue: this.props.langues,
                      userStatus: this.props.userStatus,
                      BU: "particulier"
                    })
                  })
                  this.handleClick(e, "#faq_section")
                }}
              >
                {i18n.t("titleAv25.label")}
              </a>
            </li>
            <li>
              <a
                href="#contact_section"
                onClick={(e) => {
                  TagManager.dataLayer({
                    dataLayer: isLoged(this.props.userId, {
                      event:"user_navigation",
                      item: "footer",
                      target: "contactez-nous",
                      langue: this.props.langues,
                      userStatus: this.props.userStatus,
                      BU: "particulier"
                    })
                  })
                  this.handleClick(e, "#contact_section")
                }}
              >
                {i18n.t("titleAv46.label")}
              </a>
            </li>
            <li>
              <a className="not_scrollable"
              onClick={()=> {
                TagManager.dataLayer({
                  dataLayer: isLoged(this.props.userId, {
                    event:"user_navigation",
                    item: "footer",
                    target: "conditions_utilisation",
                    langue: this.props.langues,
                    userStatus: this.props.userStatus,
                    BU: "particulier"
                  })
                })
              }}
                href={PdfConditionGeneral}
                target="_blank">
                {i18n.t("titleAv47.label")}
              </a>




            </li>
          </ul>
        </div>
        <div className="footer_contact clearfix">
          <div className="footer_logo">
            <img src={footer_logo_bankpopulaire} alt="" />
          </div>
          <div className="footer_contact_info">
            {i18n.t("titleAv48.label")}
            <br />
            {i18n.t("titleAv49.label")}
            <br />
            {i18n.t("titleAv50.label")}
          </div>
        </div>
        <p className="footer_rights_txt white_clr align_center">
          {i18n.t("footer.label")}
          <a href={PdfMentionsLegal} target="_blank" className="white_clr">{i18n.t("mleg.label")}</a>
        </p>

      </footer>
    );
  }
}

export default Footer;

import React, { Component } from 'react';
import Footer from '../containers/Inputs/Footer';
import Header from '../containers/Header';

class GlobalLayout extends Component {
  
  scrollToSection = (e, selector) => {
    e.preventDefault();
    document.querySelector(selector) && document.querySelector(selector).scrollIntoView({
      behavior: "smooth",
    }) ;
  };
  render() {
    return (
      <div className="">
        <Header  scrollToSection={this.scrollToSection} />
        <div className="main_body">
          {this.props.children}
        </div>
        <Footer  scrollToSection={this.scrollToSection} />
      </div>
    );
  }
}
GlobalLayout.propTypes = {
  children: React.PropTypes.node.isRequired,
};
export default GlobalLayout;

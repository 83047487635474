import React, { Component } from 'react';
import { hashHistory } from 'react-router/lib';
import { cleanStore } from '../action_creators';
import felicitation_icone1 from '../assets/images/felicitation_icone1.png';
import felicitation_icone2 from '../assets/images/felicitation_icone2.png';
import i18n from '../i18n';

class Confirmation extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(cleanStore());
    this.shoHidePop = this.shoHidePop.bind(this);
  }
  shoHidePop(s) {
    this.props.show(s);
  }
  render() {
    return (
      <section className="">
        <div className="felicitaion_bandeau">
          <div className="detail_sim_heading">
            <p className="slide_txt bold_style size58">
            </p>
          </div>
        </div>
        <div className="felicitation_wrapper">
          <div className="felicitation_message">
            <div className="title" id="title_container">
              <div className="block_title" id="titre">
                <h2 className="felicitation_title mb20">{i18n.t("recap9.label")}</h2>
                <p className="title_desc_step mb5">{i18n.t("recap10.label")} </p>
                <p className="title_desc_step"> {i18n.t("recap11.label")}
                </p>
              </div>
            </div>
          </div>
          <div className="felicitation_actions">
            <center>
              <ul className="avantages_detailproduit_blocs">
                <li>
                  <div className="felicitation_option">
                    <div className="felicitation_img">
                      <span>
                        <img src={felicitation_icone1} alt="" width="50%" />
                      </span>
                    </div>
                    <h4 className="felicitation_header mb20">
                      {i18n.t("recap12.label")}
                    </h4>
                    <p className="avantage_txt mb30">
                      {i18n.t("recap13.label")}
                    </p>
                  </div>
                  <p className="btns_felicitation" >
                    <button
                      onClick={() => { hashHistory.push('/'); }}
                      data-text={i18n.t("titleAv45.label")}
                      className="flat_btn purple_btn felicitation_btn"
                    >
                      <span className="plusDepadding">{i18n.t("titleAv45.label")}</span>
                    </button>
                  </p>
                </li>
                <li>
                  <div className="felicitation_option">
                    <div className="felicitation_img">
                      <span>
                        <img src={felicitation_icone2} alt="" width="50%" />
                      </span>
                    </div>
                    <h4 className="felicitation_header mb20">
                      {i18n.t("recap14.label")}
                    </h4>
                    <p className="avantage_txt mb30">
                      {i18n.t("recap15.label")}
                    </p>
                  </div>
                  <p className="btns_felicitation" >
                    <button
                      data-text={i18n.t("espacePerso.label")}
                      className="flat_btn purple_btn felicitation_btn"
                      onClick={() => { this.shoHidePop('demandeCredit.authentificate'); }}
                    >
                      <span className="plusDepadding">{i18n.t("espacePerso.label")}</span>
                    </button>
                  </p>
                </li>

              </ul>
            </center>
          </div>
        </div>
      </section>
    );
  }
}
Confirmation.propTypes = {
  cleanStore: React.PropTypes.func,
  dispatch: React.PropTypes.func,
};
export default Confirmation;

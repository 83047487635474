import { fromJS } from 'immutable';

const DEFAULT_ACCOUNT = fromJS({});
export default function (state = DEFAULT_ACCOUNT, action) {
  switch (action.type) {
    case 'INPUT_CHANGE':
      if (action.store === 'account') {
        return state.setIn(action.key.concat(['value']), fromJS(action.newValue));
      }
      return state;
    case 'INITIAL_STATE':
      return DEFAULT_ACCOUNT;
    case 'AUTHENTICATION_STEP_SUCCESS':
      return state.withMutations((ctx) => {
        ctx.set('authenticationStatus', fromJS('success'));
        ctx.set('LoggedUser', fromJS(action.result).get('user'));
        ctx.remove('motDePasse');
        localStorage.setItem('userToken', fromJS(action.result).getIn(['user', 'token']));
      });
    case 'AUTHENTICATION_STEP':
      return state.withMutations((ctx) => {
        ctx.set('authenticationStatus', fromJS('loading'));
      });
    case 'AUTHENTICATION_STEP_ERROR':
      return state.withMutations((ctx) => {
        ctx.set('authenticationStatus', fromJS('error'));
        ctx.set('authenticationError', fromJS(action.result).get('status'));
      });
    case 'AUTHENTICATION_SERVER_ERROR':
      return state.withMutations((ctx) => {
        ctx.set('authenticationStatus', fromJS('error'));
        ctx.set('authenticationError', fromJS(action.result));
      });

    case 'AUTHENTICATION_FORGET_SUCCESS':
      return state.withMutations((ctx) => {
        ctx.setIn(['oubliPass', 'status'], fromJS('success'));
        ctx.setIn(['oubliPass', 'result'], fromJS(action.result));
      });
    case 'AUTHENTICATION_FORGET':
      return state.withMutations((ctx) => {
        ctx.setIn(['oubliPass', 'status'], fromJS('loading'));
      });
    case 'AUTHENTICATION_FORGET_ERROR':
      return state.withMutations((ctx) => {
        ctx.setIn(['oubliPass', 'status'], fromJS('error'));
        ctx.setIn(['oubliPass', 'error'], fromJS(action.result));
      });

    case 'AUTHENTICATION_RESET_SUCCESS':
      return state.withMutations((ctx) => {
        ctx.setIn(['oubliPass', 'status'], fromJS('success'));
        ctx.setIn(['oubliPass', 'result'], fromJS(action.result));
      });
    case 'AUTHENTICATION_DEFINE_SUCCESS':
      return state.withMutations((ctx) => {
        ctx.setIn(['definePass', 'status'], fromJS('success'));
        ctx.setIn(['definePass', 'result'], fromJS(action.result));
      });
    case 'AUTHENTICATION_RESET':
      return state.withMutations((ctx) => {
        ctx.setIn(['oubliPass', 'status'], fromJS('loading'));
      });
    case 'AUTHENTICATION_RESET_ERROR':
      return state.withMutations((ctx) => {
        ctx.setIn(['oubliPass', 'status'], fromJS('error'));
        ctx.setIn(['oubliPass', 'error'], fromJS(action.result));
      });
    default:
      return state;
  }
}

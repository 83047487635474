import { connect } from 'react-redux/dist/react-redux';
import Layout from '../components/Layout';
import { showHide, scrolllToSection } from '../action_creators';

function mapStateToProps(state) {
  return {
    showHideElm: state.demandeCredit.getIn(['authentif', 'value']),
    popinKey: 'demandeCredit.authentif',
    userId: state.account.getIn(['identifiant', 'value']) || "",
    langues: state.demandeCredit.getIn(['langues', 'value']) || "fr",
    userStatus: state.account.get('authenticationStatus') === "success" ? "logged": "not-logged",
  };
}
function mapDispatchToProps(dispatch) {
  return {
    show: (s) => dispatch(showHide(s)),
    scrolllToSection: (source, id) => dispatch(scrolllToSection(source, id))
  };
}
function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    show: () => dispatchProps.show(stateProps.popinKey),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Layout);

/* eslint-disable */
import React, { Component } from "react";
import Text from "../containers/Text";
import Title from "./Title";
import SlideBar from "../containers/Inputs/SlideBar";
import Popup from "../containers/Popup";
import Popin from "../containers/Popin";
import { fromJS } from "immutable";
import Select from "../containers/Inputs/Select";
import MyAgency from "../containers/MyAgency";
import SelectCity from "../containers/Inputs/SelectCity";
import { hashHistory } from "react-router";
import NavigationBar from "../containers/NavigationBar";
import ReCAPTCHA from "react-recaptcha/dist/react-recaptcha";
import icone_info from "../assets/images/icone_info.png";
import i18n from "../i18n";
import Promoteur from "../containers/Promoteur";
import TagManager from "react-gtm-module";
import { isLoged } from "../utile/utile";
import Accordion from "./Inputs/Accordion";
import SelectEvent from "../containers/Inputs/SelectEvent";

const option1 = { numeral: true, numeralDecimalMark: ".", delimiter: " " };
const option2 = { blocks: [2] };
const option3 = { numeral: true, numeralDecimalScale: 2 };
const option4 = { blocks: [2] };
const option5 = { numeral: true, numeralDecimalMark: ".", delimiter: " " };
const etatOptions = fromJS([
  { label: "Neuf" },
  { label: "Ancien (deuxième main)" },
]);
const priorityOptions = fromJS([
  { label: "Principale" },
  { label: "Secondaire" },
  { label: "Autre" },
]);
const typeBienOptions = fromJS([
  { label: "Construction" },
  { label: "Acquisition logement" },
  { label: "Acquisition terrain" },
  { label: "Acquisition terrain + Construction" },
  { label: "Rachat crédit immobilier" },
  { label: "Aménagement" },
]);
const infobulleMsg = {
  InfoDureeiff:
    " Vous pouvez souhaiter que le prêt ne soit pas immédiatement mis en remboursement. Vous pouvez demander de décaler le départ du remboursement. Vous ne souhaitez pas commencer à rembourser de manière immédiate? Choisissez la durée de différé (franchise) qui vous convient.",
  InfoAuntreFin:
    "Si votre projet est partiellement financé par une autre personne (conjoint, parent...), renseignez le montant financé",
  InfoApportPerso:
    "Il s'agit de la somme que vous investissez dans ce projet, en dehors de ce crédit.",
  InfoEstimFrais:
    "Si le montant demandé est égal ou supérieur au prix du bien, merci d'indiquer le montrant des autres frais que vous souhaiter financer par le prêt demandé.",
};

const typetauxOptions = fromJS([
  { label: "Fixe", value: "Fixe", checked: true },
  { label: "Variable", value: "Variable" },
]);

const requiredFields = [
  "demandeCredit.etatbien",
  "demandeCredit.typebien",
  "demandeCredit.usagebien",
  "demandeCredit.coutprojet",
  "demandeCredit.surfacebien",
  "demandeCredit.localisationbien",
  "demandeCredit.montantCredit",
  "demandeCredit.nbAnnee",
  "demandeCredit.typetaux",
  "demandeCredit.dureeDiff",
  "demandeCredit.apportpersonnel",
  "demandeCredit.financconjoint",
  "demandeCredit.autresfrais",
  "demandeCredit.promoteur",
  "demandeCredit.fraisamg",
];

var isFromHeadere;

class InfoProjet extends Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
    this.push = this.push.bind(this);
    this.shoHidePop = this.shoHidePop.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.verifyForm = this.verifyForm.bind(this);
    this.gtmPush = this.gtmPush.bind(this);
    this.isFromHeader = this.isFromHeader.bind(this);
    this.state = { btnDisabled: true };
  }
  componentWillMount() {
    window.scrollTo(0, 0);
    this.props.displayErrors("non");
    this.props.cleanStatus();
    this.props.inputChange(true, "demandeCredit.toggling");
    this.props.inputChange("Fixe", "demandeCredit.typetaux");
    isFromHeadere = this.isFromHeader();
  }
  componentDidMount() {
    TagManager.dataLayer({
      dataLayer: isLoged(this.props.userId, {
        event: "form_pret_step_4",
        origin: this.props.origin,
        userStatus: this.props.userStatus,
        langue: this.props.langues,
        sp_montant:
          this.props.origin === "header" ? null : this.props.montantCredit,
        sp_duree: this.props.origin === "header" ? null : this.props.nbAnnee,
        sp_differe:
          this.props.origin === "header" ? null : this.props.dureeDiff,
        sp_taux: this.props.origin === "header" ? null : this.props.taux,
        sp_echeance:
          this.props.origin === "header" ? null : this.props.echeance,
        BU: "particulier",
      }),
    });
  }
  componentDidUpdate(prevProps) {
    if (
      isFromHeadere === true &&
      prevProps.coutProjet !== this.props.coutProjet
    ) {
      this.props.inputChange(
        this.props.coutProjet,
        "demandeCredit.montantCredit"
      );
    }
    if (prevProps.coutProjet !== this.props.coutProjet) {
      this.props.inputChange(
        this.props.EstimFraisProjet,
        "demandeCredit.autresfrais"
      );
    }
  }

  isFromHeader() {
    if (this.props.montantPret === "" || this.props.montantPret === undefined)
      return true;
    else return false;
  }

  push() {
    hashHistory.push("/recapitulatif");
  }
  shoHidePop() {
    this.props.showHide("demandeCredit.popSimulate");
  }
  changeValue() {
    this.props.changeValues();
    this.shoHidePop();
  }
  validateForm() {
    if (
      !this.state.reCaptchaResponse ||
      this.state.reCaptchaResponse.trim().length !== 0
    ) {
      this.setState({ btnDisabled: false });
      return true;
    }
    return false;
  }
  verifyCallback(response) {
    response &&
      response.length &&
      this.props.inputChange(response, "demandeCredit.reCaptchaResponse");
    this.setState({
      reCaptchaResponse: response,
    });
    this.validateForm();
  }

  gtmPush() {
    let inputsError = (
      this.props.etatbienError == undefined ? "[etatbien]," : ""
    )
      .concat(this.props.usagebienError == undefined ? "[usagebien]," : "")
      .concat(
        this.props.surfacebienError == undefined ||
          this.props.surfacebienError == true
          ? "[surfacebien],"
          : ""
      )
      .concat(this.props.typebienError == undefined ? "[typebien]," : "")
      .concat(
        this.props.coutprojetError == undefined ||
          this.props.coutprojetError == true
          ? "[coutprojet],"
          : ""
      )
      .concat(
        this.props.localisationbienError == undefined
          ? "[numpieceidentite],"
          : ""
      )
      .concat(this.props.promoteurError == undefined ? "[promoteur]," : "")
      .concat(
        this.props.montantCreditError == undefined ||
          this.props.montantCreditError == true
          ? "[montantCredit],"
          : ""
      )
      .concat(
        this.props.apportpersonnelError == undefined ||
          this.props.apportpersonnelError == true
          ? "[apportpersonnel],"
          : ""
      )
      .concat(
        this.props.nbAnneeIsSeventyError == true ||
          this.props.nbAnneeisMaxDureeError == true
          ? "[nbAnnee],"
          : ""
      )
      .concat(this.props.dureeDiffError == undefined ? "[dureeDiff]," : "")
      .concat(
        this.props.financconjointError == undefined ? "[financconjoint]," : ""
      );

    TagManager.dataLayer({
      dataLayer: isLoged(this.props.userId, {
        event: "form_pret_step_4",
        origin: this.props.origin,
        item: "suivant",
        userStatus: this.props.userStatus,
        langue: this.props.langues,
        BU: "particulier",
        champs_refuse: inputsError
        //gclid: this.props.gclid,
      }),
    });
  }

  verifyForm() {
    if (this.state.btnDisabled === false) {
      if (this.props.clientbcp === "Oui") {
        this.props.goNext();
      }
      this.props.inputChange(false, "demandeCredit.toggling");
    }
  }
  render() {
    return (
      <div className="body">
        <Accordion
          title={i18n.t("infoProjetEvent.label")}
          style={{ marginBottom: 10 }}
          li={{ width: "94%", margin: "0 auto" }}
        >
          <div className="title_desc_step_event">
            <span>{i18n.t("subtitleFormEvent1.label")}</span>
            <span style={{ color: "darkgoldenrod" }}>
              {i18n.t("subtitleFormEvent2.label")}
            </span>
          </div>
          <div className="control_group clearfix" style={{ width: "70%" }}>
            <div className="left_cls mb10">
              <SelectEvent
                storeKey="demandeCredit.eventCompany"
                placeholder={i18n.t("recapEvent.label")}
                name="demandeCredit.eventCompany"
                placeholderIcon={i18n.t("recapEventIcon.label")}
                zIndex={"10000"}
                style={{
                  marginTop: 20,
                  width: 300,
                }}
              >
                {i18n.t("infoProjet0.label")}
              </SelectEvent>
            </div>
          </div>
        </Accordion>
        <div className="form_wrapper">
          <div className="title" id="title_container">
            <div className="block_title mb10" id="titre">
              <Title>{i18n.t("infoProjet1.label")}</Title>
              <p className="title_desc_step">{i18n.t("subtitleForm1.label")}</p>
            </div>
          </div>
          <div className="control_group clearfix">
            <div className="left_cls">
              <Select
                items={etatOptions}
                storeKey="demandeCredit.etatbien"
                placeholder={i18n.t("recap18.label")}
              >
                {i18n.t("infoProjet2.label")}
              </Select>
            </div>
            <div className="right_cls">
              <Select
                items={typeBienOptions}
                storeKey="demandeCredit.typebien"
                placeholder={i18n.t("infoProjet3.label")}
              >
                {i18n.t("infoProjet3.label")}
              </Select>
            </div>
          </div>
          <div className="control_group clearfix">
            <div className="left_cls">
              <Select
                items={priorityOptions}
                storeKey="demandeCredit.usagebien"
                placeholder={i18n.t("infoProjet4.label")}
              >
                {i18n.t("infoProjet4.label")}
              </Select>
            </div>
            <div className="right_cls">
              <Text
                type="number"
                maxLength="35"
                storeKey="demandeCredit.coutprojet"
              >
                {i18n.t("infoProjet5.label")}
                <div className="input-label-icon">
                  <div className="icon-class">
                    {i18n.t("simulateur7.label")}
                  </div>
                </div>
              </Text>
            </div>
          </div>
          <div className="control_group clearfix">
            <div className="left_cls">
              <Text
                type="number"
                maxLength="35"
                storeKey="demandeCredit.surfacebien"
              >
                {i18n.t("infoProjet6.label")}
                <div className="input-label-icon">
                  <div className="icon-class">
                    {i18n.t("infoProjet17.label")}
                  </div>
                </div>
              </Text>
            </div>
            <div className="right_cls">
              <SelectCity
                storeKey="demandeCredit.localisationbien"
                placeholder={i18n.t("infoPerso6.label")}
              >
                {i18n.t("infoProjet7.label")}
              </SelectCity>
            </div>
          </div>
          <div className="control_group clearfix mb35">
            <Promoteur storeKey="demandeCredit.promoteur" />
          </div>
          <div className="title" id="title_container">
            <div className="block_titles mb10" id="titre">
              <Title> {i18n.t("infoProjet9.label")}</Title>
              <p className="title_desc_step mb20">
                {i18n.t("subtitleForm1.label")}
              </p>
            </div>
          </div>
          <div className="control_group clearfix">
            <div className="left_cls">
              <Text
                type="numberM"
                maxLength="35"
                storeKey="demandeCredit.montantCredit"
              >
                {i18n.t("infoProjet10.label")}
                <div className="input-label-icon">
                  <div className="icon-class">
                    {i18n.t("simulateur7.label")}
                  </div>
                </div>
              </Text>
            </div>
            <div className="right_cls">
              <Text
                type="number"
                maxLength="35"
                storeKey="demandeCredit.nbAnnee"
              >
                {i18n.t("infoProjet11.label")}
                <div className="input-label-icon">
                  <div className="icon-class">
                    {i18n.t("simulateur8.label")}
                  </div>
                </div>
              </Text>
            </div>
          </div>
          <div className="control_group clearfix">
            <div className="left_cls">
              <Select
                items={typetauxOptions}
                storeKey="demandeCredit.typetaux"
                placeholder="Type de taux"
              >
                Type de taux
              </Select>
            </div>
            <div className="right_cls">
              <div className="control_with_toolTip">
                <div className="control_area_alone">
                  <Text
                    type="number"
                    maxLength="35"
                    storeKey="demandeCredit.dureeDiff"
                  >
                    Durée du différé
                    <div className="input-label-icon">
                      <div className="icon-class">
                        {i18n.t("simulateur9.label")}
                      </div>
                    </div>
                  </Text>
                </div>
                <div className="help_tuto_icn tooltip">
                  <span className="help_tuto_icn tooltip">
                    <img src={icone_info} alt="" id="infos_icn" />
                    <span className="tooltiptext tooltiWidthResp">
                      {" "}
                      {infobulleMsg.InfoDureeiff}{" "}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="control_group clearfix">
            <div className="left_cls">
              <div className="control_with_toolTip">
                <div className="control_area_alone">
                  <Text
                    type="number"
                    maxLength="35"
                    storeKey="demandeCredit.apportpersonnel"
                    className="input-fields"
                  >
                    {i18n.t("infoProjet14.label")}
                    <div className="input-label-icon">
                      <div className="icon-class">
                        {i18n.t("simulateur7.label")}
                      </div>
                    </div>
                  </Text>
                </div>
                <div className="help_tuto_icn tooltip">
                  <span className="help_tuto_icn tooltip">
                    <img src={icone_info} alt="" id="infos_icn" />
                    <span className="tooltiptext tooltiWidthResp">
                      {" "}
                      {infobulleMsg.InfoApportPerso}{" "}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="right_cls clearfix">
              <div className="control_with_toolTip">
                <div className="control_area_alone">
                  <Text
                    type="number"
                    maxLength="35"
                    storeKey="demandeCredit.financconjoint"
                  >
                    {i18n.t("infoProjet15.label")}
                  </Text>
                </div>
                <div className="help_tuto_icn tooltip">
                  <span className="help_tuto_icn tooltip">
                    <img src={icone_info} alt="" id="infos_icn" />
                    <span className="tooltiptext tooltiWidthResp">
                      {" "}
                      {infobulleMsg.InfoAuntreFin}{" "}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="control_group clearfix ">
            <div className="left_cls">
              <div className="control_with_toolTip">
                <div className="control_area_alone">
                  {/* <Text
                type="number"
                maxLength="35"
                storeKey="demandeCredit.fraisamg"
                className="input-fields"
              >
                  FRAIS D&apos;AMÉNAGEMENT A FINANCER PAR LE PRÊT DEMANDÉ
                <div className="input-label-icon">
                  <div className="icon-class">DHS</div>
                </div>
              </Text> */}
                  <Text
                    type="number"
                    maxLength="35"
                    storeKey="demandeCredit.autresfrais"
                    disabled
                  >
                    {i18n.t("infoProjet16.label")}
                  </Text>
                </div>
                <div className="help_tuto_icn tooltip">
                  <span className="help_tuto_icn tooltip">
                    <img src={icone_info} alt="" id="infos_icn" />
                    <span className="tooltiptext tooltiWidthResp">
                      {" "}
                      {infobulleMsg.InfoEstimFrais}{" "}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="right_cls recap_area">
              <ReCAPTCHA
                //sitekey="6LcGYl0UAAAAAMMYE2yY5wMzbhJKCU3YgLtPYZwn" - old key
                //sitekey="6LciedcfAAAAAIQE8yLFg0hGMsVQ3fUhDMNzmrdX" // - new DEV-PREPROD key
                sitekey="6LdJJzcgAAAAAOvnqvD9T0ZVy-HZuwDSoKnSHrIV" // - PROD SiteKey
                render="explicit"
                onloadCallback={() => true}
                verifyCallback={this.verifyCallback}
              />
              <div className="input--text">
                <div className="input-error">
                  {this.state.btnDisabled === true &&
                  this.props.nextPushed === "oui" ? (
                    <div className="input-error__txt">reCaptcha non valide</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <NavigationBar
          action={this.verifyForm}
          save={this.props.saveDemande}
          status={this.props.status}
          gtm={true}
          gtmPush={this.gtmPush}
          fields={requiredFields}
          isFormulaire
          linkTo="/infos-perso"
        />
        {this.props.clientbcp === "Non" ? (
          <Popup
            valueToggle={this.props.valueToggle}
            toggled={this.props.toggled}
            action={this.props.goNext}
            save={this.props.saveDemande}
            status={this.props.status}
            showNavigBar={false}
          >
            <MyAgency />
          </Popup>
        ) : (
          ""
        )}
        {this.props.showHideElm ? null : (
          <Popin
            secondBtnclassCss="flat_btn purple_btn"
            classCss="flat_btn purple_btn more_padding"
            classCssWhite="flat_btn white_btn right_mr"
            dataText="valider"
            datalabel="valider"
            secondBtnDataText="Annuler"
            secondBtnDatalabel="Annuler"
            storeKey="demandeCredit.popSimulate"
            action={this.changeValue}
            secondAction={this.shoHidePop}
            popin_modals="popin_dialog"
            popinTitle="faire une simulation"
            withWhiteBtn
          >
            <div className="popin_sim">
              <SlideBar
                min={0}
                max={5000000}
                step={50000}
                options={option1}
                typeLibelle="Dhs"
                libelle="Montant"
                storeKey="simulateur.dMontantCredit"
              />
              <SlideBar
                min={0}
                max={25}
                step={1}
                options={option2}
                typeLibelle="Ans"
                libelle="Durée"
                storeKey="simulateur.dNbAnnee"
              />
              <SlideBar
                min={0}
                max={24}
                step={1}
                options={option4}
                typeLibelle="Mois"
                libelle="Différé"
                storeKey="simulateur.dDureeDiff"
              />
              <SlideBar
                min={0.01}
                max={15}
                options={option3}
                step={0.01}
                typeLibelle="%"
                libelle="Taux"
                libelleInfo="(ht)"
                storeKey="simulateur.dTaux"
              />
              <SlideBar
                max={100000}
                min={0}
                step={100}
                options={option5}
                typeLibelle="Dhs"
                libelle="Échéance"
                libelleInfo="(ttc)"
                storeKey="simulateur.dEcheance"
              />
            </div>
          </Popin>
        )}
      </div>
    );
  }
}

InfoProjet.propTypes = {
  verifyForm: React.PropTypes.func,
  goNext: React.PropTypes.func,
  saveDemande: React.PropTypes.func,
  changeValues: React.PropTypes.func,
  status: React.PropTypes.bool,
  valueToggle: React.PropTypes.func,
  inputChange: React.PropTypes.func,
  toggled: React.PropTypes.bool,
  showHide: React.PropTypes.func,
  showHideElm: React.PropTypes.bool,
  flag: React.PropTypes.bool,
  cleanStatus: React.PropTypes.func.isRequired,
  storeKey: React.PropTypes.string.isRequired,
  siteKey: React.PropTypes.string.isRequired,
  clientbcp: React.PropTypes.string,
  EstimFraisProjet: React.PropTypes.string,
  nextPushed: React.PropTypes.string,
  displayErrors: React.PropTypes.func,
};

export default InfoProjet;

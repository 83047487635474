import sha256 from 'sha256';

export function format(item = '') {
  const parts = item.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
}

export function verifyPassword(password){
 return  /(?=.*\d)(?=.*[a-z])(?=.*).{6,}/.test(password) && password.length >7
}
// format date to dd/mm/yy
export function formatDatedd(date) {
  const d = new Date(date.split('/').reverse().join('-'));
  let dd = (d.getDate()).toString();
  let mm = (d.getMonth() + 1).toString();
  const y = (d.getFullYear()).toString();
  if (mm.length === 1) {
    mm = '0'.concat(mm);
  }
  if (dd.length === 1) {
    dd = '0'.concat(dd);
  }
  return dd.concat("/").concat(mm).concat("/").concat(y);
}

export function capaciteEmprunt(revenuNetMensuel,autreCredit,duree,tauxHt){
  let revenue = (0.45 * revenuNetMensuel) - autreCredit;
  let taux =  ((0.45 * revenuNetMensuel - autreCredit)/duree)*12;
  let tvaTaux = ((0.45 * revenuNetMensuel - autreCredit) * (1 - (Math.pow(1 + (tauxHt/100) / 12 * (1 + 0.1), -(duree * 12 - 0)))));
  let tvaTauxFinal = tvaTaux / ((((tauxHt/100) / 12) * (1 + 0.1)) * (1 + (tauxHt/100) / 12 * (1 + 0.1) * 0));
  let capaciteEmprunt = revenue<0 ? 0 : tauxHt/100 === 0 ? taux : tvaTauxFinal;
  return capaciteEmprunt;
}

export function formaterNombre(Nombre) {

  var parts;

  if(!isNumeric(Nombre)){
    if(Nombre.length>1){
      if(Nombre[Nombre.length-1]==="." ) return Nombre.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      else return extraitNombre(Nombre).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
   } else return "";
  }else if( Nombre!==null && !isNaN(Nombre) && Nombre!==undefined){
      if (isBigThan20M(Nombre)) return "20 000 000";
      else {
      parts = Nombre.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
          }  
 } 
 if (Nombre==="NaN") return "" ;
}

export function extraitNombre(str){ 
  return str.replace(/[^\d]/g, ""); 
}

export function isBigThan20M(Nombre){

if(parseFloat(Nombre.toString().replace(/ /g,""))>20000000)return true;
else return false ;
}

export function isNumeric(s) {
  return !isNaN(s.toString().replace(/ /g,"") - parseFloat(s.toString().replace(/ /g,"")));
}

export function verifyEmail(email){
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
}
export function formatDureCreditMois(duree){
	let ans = parseInt(duree/12);
	let mois  = duree-(ans * 12 );
	return mois;
	
}

export function formatDureCreditAns(duree){
	let ans = parseInt(duree/12);
	return ans ;
	
}

export const isLoged = (userId, data) => {
  if(userId) {
    const hashUserID = sha256(userId);
    return {...data, userId: hashUserID};
  }
    return data;
}
/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux/dist/react-redux';
import logo_bankpopulaire from '../assets/images/logo_bankpopulaire.jpg';
import smapimmo from '../assets/images/smapimmo.png';
import chaabi_bank from '../assets/images/chaabi_bank.png';
import footer_logo_bankpopulaire from '../assets/images/footer_logo_bankpopulaire.jpg';

class LayoutSMAP extends Component {

  componentDidMount() {
  //  window.addEventListener('scroll', this.handleScroll.bind(this));
  }
  componentWillMount() {

  }

  handleScroll() {

  }
  render() {
    return (
      <div className="unprentable">
        <header className="public_header">
          <div className="main_head_SPAM clearfix">
            <div className="logo_container-smap">
              <h1>
                <a href="#/accueil" >
                  <img src={logo_bankpopulaire} alt="banque populaire" width="198" />
                </a>
                <a href="#/accueil" >
                  <img src={smapimmo} alt="banque populaire" width="198" />
                </a>
                <a href="#/accueil" >
                  <img src={chaabi_bank} alt="banque populaire" width="80" className="logo-chanbi-bank-smap" />
                </a>

              </h1>
            </div>
          </div>
        </header>
        <section className="presentation_area">
          <div className="presentation_content-smap">
            <p className="accompany_txt white_clr uppercase_txt txt_shadow">
                <p> La Banque Populaire vous accompagne pour le financement de votre bien au maroc</p>
                <p> Bienvenue dans votre espace credit immobilier</p>
            </p>
          </div>
        </section>
          {this.props.children}
        <footer className="main_footer">
          <div className="footer_contact clearfix">
            <div className="footer_logo">
              <img src={footer_logo_bankpopulaire} alt="" />
            </div>
            <div className="footer_contact_info">
                Banque Centrale Populaire- 101, boulevard Mohamed Zerktouni
              <br />
                BP 10.622 - 20100
              <br />
                Casablanca
            </div>
          </div>
          <p className="footer_rights_txt white_clr align_center">
              Copyright @ 2018 Groupe Banque Populaire -
            <a href ="" className="white_clr">Mentions légales</a>
          </p>
        </footer>
      </div>
    );
  }
}
LayoutSMAP.propTypes = {
  children: React.PropTypes.node.isRequired,
  dispatch: React.PropTypes.func,
};
export default connect()(LayoutSMAP);

import React, { Component } from 'react';
import NavigationBarButton from './NavigationBarButton';
import NextButton from '../containers/NextButton';
import { hashHistory } from 'react-router';
import i18n from '../i18n';
import TagManager from 'react-gtm-module';
import {isLoged} from '../utile/utile'

const EVENT_VALUE = {
  "/coordonnees": "3",
  "/infos-perso": "4"
}
class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    TagManager.dataLayer({
      dataLayer: isLoged(this.props.userId,{
          event: "form_pret_step_" + EVENT_VALUE[this.props.linkTo],
          item: "previous_step",
          userStatus: this.props.userStatus,
          langue: this.props.langues,
          BU: 'particulier'
      }),
    })
    hashHistory.push(this.props.linkTo);
  }

  render() {
    return (
      <div className="nav_btns_container">
        <div className="nav_btns">
          <div className="">
            <NavigationBarButton
              action={this.goBack}
              disabled={this.props.disableBack}
            >
              {i18n.t("precedent.label")}
            </NavigationBarButton>
          </div>
          <div className="next_btn_container">
          {this.props.a ?
             this.props.children :
            <NextButton
              label={this.props.progressLevel === 'done' ? 'Enregistrer' : i18n.t("suivant.label")}
              dataTxt={this.props.progressLevel === 'done' ? 'Enregistrer' : i18n.t("suivant.label")}
              disabled={this.props.disabled}
              action={this.props.action}
              gtm={this.props.gtm}
              gtmPush={this.props.gtmPush}
              progressLevel={this.props.progressLevel}
              className={this.props.className}
              fields={this.props.fields}
              isFormulaire={this.props.isFormulaire}
            />}
          </div>
          <div className="align_right">
          {this.props.progressLevel === 'done' ?
            <div>
            </div>
          :
            <button
              data-text="Sauvegarder"
              onClick={this.props.save}
              disabled={this.props.disabled}
              className={this.props.status === 'success' ?
              'hidden' : 'hidden flat_btn white_btn sauv_btn'}
            >
              <span>
               Sauvegarder
              </span>
            </button>

          }
          </div>
          </div>
      </div>
    );
  }
}

NavigationBar.propTypes = {
  disableNext: React.PropTypes.bool,
  disabled: React.PropTypes.bool,
  a: React.PropTypes.bool,
  status: React.PropTypes.string,
  disableBack: React.PropTypes.bool,
  progressLevel: React.PropTypes.bool,
  goBack: React.PropTypes.func.isRequired,
  goNext: React.PropTypes.func.isRequired,
  action: React.PropTypes.func.isRequired,
  save: React.PropTypes.func.isRequired,
  children: React.PropTypes.node,
  isFormulaire: React.PropTypes.bool,
  displayErrors: React.PropTypes.func.isRequired,
  className: React.PropTypes.string,
  fields: React.PropTypes.object,
  linkTo: React.PropTypes.string,
};
NavigationBar.defaultProps = {
  isFormulaire: false,
  disabled: false,
  className: 'flat_btn purple_btn more_padding',
};

export default NavigationBar;

import React, { Component } from 'react';
import Crc from './Accueil/Crc';


class Produits extends Component {

  render() {
    return (
      <div className="body">
        <div className="espace_perso_bandeau mb35">
          <div className="detail_sim_heading">
            <p className="slide_txt medium_style size30">Lorem ipsum dolor amit</p>
            <p className="slide_txt bold_style size58">Lorem ipsum dolor amit</p>
          </div>
        </div>
        <div className="title align_center" id="title_container">
          <div className="block_title mb50" id="titre">
            <div className="inlineDisplay">
              <h2 className="step_big_title">Nos prêts Sakane Mabrouk</h2>
            </div>
            <hr className="hr_header" />
            <p className="title_desc mb20">
            </p>
          </div>
        </div>
        <section className="products_list">
          <div className="product_img_block" data-product="manzil">
            &nbsp;
          </div>
          <div className="product_desc_block" data-product="manzil">
            <h3>SAKAN MABROUK MANZIL</h3>
            <h4>(Crédit FOGARIM garanti par Damane Assakane)</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
              quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
          <div className="product_desc_block" data-product="mabrouk">
            <h3>SAKAN MABROUK</h3>
            <h4>(Crédit FOGARIM garanti par Damane Assakane)</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
              quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
          <div className="product_img_block" data-product="mabrouk">
            &nbsp;
          </div>
          <div className="product_img_block" data-product="dari">
            &nbsp;
          </div>
          <div className="product_desc_block" data-product="dari">
            <h3>SAKAN MABROUK DARI</h3>
            <h4>(Crédit FOGARIM garanti par Damane Assakane)</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
              quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
          <div className="product_desc_block" data-product="najah">
            <h3>SAKAN MABROUK DARI</h3>
            <h4>(Crédit FOGARIM garanti par Damane Assakane)</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
              quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
          <div className="product_img_block" data-product="najah">
            &nbsp;
          </div>
        </section>
        <div className="crc_container">
          <Crc />
        </div>
      </div>
    );
  }
}

export default Produits;

import React, { Component } from 'react';
import { List, fromJS } from 'immutable/dist/immutable';
import SelectAgence from '../containers/Inputs/SelectAgence';
import MapPage from '../containers/MapPage';

class MyAgency extends Component {
  constructor(props) {
    super(props);
    this.props.inputChange(7805, 'demandeCredit.agence');
    this.props.inputChange('ROMANDIE', 'demandeCredit.libelleagence');
    this.state = {
      redText: false,
    };
    this.addClass = this.addClass.bind(this);
    this.removeClass = this.removeClass.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.cityAgence !== this.props.cityAgence) {
      this.props.inputChange((this.props.agenceList
        .filter(item => item.get('codeVille') ===
         this.props.cityAgence).get(0)
         || fromJS([])).get('value'), 'demandeCredit.agence');
      // this.props.inputChange((this.props.agenceList
      //   .filter(item => item.get('codeVille') ===
      //    this.props.cityAgence).get(0)
      //    || fromJS([])).get('label'), 'demandeCredit.libelleagence');
    }
  }
  addClass() {
    this.setState({
      redText: true,
    });
  }
  removeClass() {
    this.setState({
      redText: false,
    });
  }
  returnInput() {
    return (
      <div>
        <input
          className="agencys_search"
          type="text"
          id="pac-input"
          placeholder="RECHERCHER UNE ADRESSE A PROXIMITE"
        />
      </div>

    );
  }

  render() {
    return (
      <div className="my_map_agency">
        <div className="myAgencies_map_container">
          <div className="search_agence_container">
            <div className="search_box_container mb20">
              {this.returnInput()}
            </div>
            <div className="select_agence_container">
              <SelectAgence
                agence={this.props.agence}
                agenceList={this.props.agenceList}
                cityAgence={this.props.cityAgence}
              />
            </div>
         </div>

          <MapPage
            currentLat={this.props.currentLat}
            currentLong={this.props.currentLong}
            currentAgence={this.props.currentAgence}
          />
        </div>
      </div>
    );
  }
}
MyAgency.propTypes = {
  currentAgence: React.PropTypes.object,
  disableNext: React.PropTypes.bool,
  goNext: React.PropTypes.func.isRequired,
  saveUpdate: React.PropTypes.func.isRequired,
  currentLat: React.PropTypes.string,
  currentLong: React.PropTypes.string,
  progressLevel: React.PropTypes.bool,
  agenceList: React.PropTypes.instanceOf(List).isRequired,
  cityAgence: React.PropTypes.string,
  inputChange: React.PropTypes.func,
};
MyAgency.defaultProps = {
  disableNext: false,
  dataTxt: 'Suivant',
};

export default MyAgency;

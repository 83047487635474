import { connect } from 'react-redux/dist/react-redux';
import DetailResultatCapaciteEmprunt from '../components/DetailResultat';
import { getAllResult, inputChange, sendMail } from '../action_creators';
const mapStateToProps = (state) => ({
  allResult: state.simulateur.get('allResult'),
  montantCredit: state.simulateur.getIn(['capaciteEmpruntfinal', 'value']),
  nbAnnee: state.simulateur.getIn(['duree', 'value']),
  taux: state.simulateur.getIn(['tauxHt', 'value']),
  storeKey: 'simulateur.duree',
  dureeDiff: 0,
  langues: state.demandeCredit.getIn(['langues', 'value']), 
});
function mapDispatchToProps(dispatch) {
  return {
    getAllResult: (...s) => dispatch(getAllResult(...s)),
    sendMail: (...s) => dispatch(sendMail(...s)),
    inputChange: (s, h) => dispatch(inputChange(s, h)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DetailResultatCapaciteEmprunt);

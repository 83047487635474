import MyAgency from '../components/MyAgency';
import { connect } from 'react-redux/dist/react-redux';
import { goNextState, inputChange } from '../action_creators';
import { push } from 'react-router-redux';
import { fromJS } from 'immutable';

const mapStateToProps = (state) => {
  function isAgence(z) {
    return z.get('value') === state.demandeCredit.getIn(['agence', 'value']);
  }
  const currentVille = ((state.configuration.get('agenceList')).filter((z) => (
    z.get('codeVille') === state.demandeCredit.getIn(['cityAgence', 'value'])
  )).get(0) || fromJS([]));
  const currentAgency = ((state.configuration.get('agenceList')).filter((z) => (
   z.get('codeVille') === state.demandeCredit.getIn(['cityAgence', 'value'])
    ))).find(isAgence);
  return ({
    cityAgence: state.demandeCredit.getIn(['cityAgence', 'value']),
    agenceList: state.configuration.get('agenceList'),
    currentAgence: currentAgency,
    currentLat: currentVille.get('latitude'),
    currentLong: currentVille.get('longitude'),
    agence: state.demandeCredit.get('agence'),
  });
};
const mapDispatchToProps = {
  goNext: goNextState,
  saveUpdate: () => push('/my-rdv'),
  inputChange,
};
export default connect(mapStateToProps, mapDispatchToProps)(MyAgency);

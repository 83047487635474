import { connect } from 'react-redux/dist/react-redux';
import MaReference from '../components/MaReference';
import { getDemande, initialAccout } from '../action_creators';

function mapStateToProps(state) {
  const maDemande = state.demandeCredit.get('maDemande');
  return {
    referenceDemande: state.demandeCredit.getIn(['reference', 'value']),
    maDemande,
    maDemandeStat: state.demandeCredit.get('maDemandeStatus'),
    flagClient: state.account.getIn(['LoggedUser', 'flag']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDemande: (referenceDemande) => dispatch(getDemande(referenceDemande)),
    initialAccout: () => dispatch(initialAccout()),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const referenceDemande = stateProps.referenceDemande;
  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    getDemande: () => dispatchProps.getDemande(referenceDemande),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MaReference);

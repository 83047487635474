/* eslint-disable */
import { fromJS } from 'immutable';
function formatAgences(agences) {
  return agences.agencesWeb.map(agence => {
    const labeL = agence.libelleagence;
    const valeur = agence.codeagence;
    const code = agence.codeville;
    const Latitude = agence.latitude;
    const Longitude = agence.longitude;
    const Adresse = agence.adresseagence;
    const Telephone = agence.telephoneagence;
    const OuverteSamedi = agence.ouvertesamedi;
    const ImpressionCarte = agence.impressioncarte;
    const CodeT24 = agence.codeagenceT24;
    const newAgence = {
      label: labeL, value: valeur, codeVille: code,
      latitude: Latitude, longitude: Longitude, adresse: Adresse,
      telephone: Telephone, ouverteSamedi: OuverteSamedi, impressionCarte: ImpressionCarte, codeagenceT24: CodeT24
    };
    return newAgence;
  });
}
function formatCity(cities) {
  return cities.map(city => {
    const valeur = city.codeVille;
    const labeL = city.libelleVille;
    const newCity = { label: labeL, value: valeur };
    return newCity;
  });
}
function formatIndicatifs(indicatifs) {
  return indicatifs.map(indicatif => {
    const Name = indicatif.name;
    const labeL = `${indicatif.name} (${indicatif.dial_code})`;
    const valuE = indicatif.dial_code;
    const Code = indicatif.code;
    const CB = indicatif.CB;
    const newIndicatif = { name: Name, label: labeL, value: valuE, code: Code, cb: CB };
    return newIndicatif;
  });
}
function formatPromoteur(promoteurs) {
  return promoteurs.filter(prom => {
    if (prom.show) {
      return true; //
    }
    return false; // skip
  }).map(promoteur => {
    if (promoteur.show) {
      const valeur = promoteur.id;
      const labelle = promoteur.libelle;
      const newPrmoteur = { value: valeur, label: labelle };
      return newPrmoteur;
    }
  });
}
function formatEvent(events) {
  return events.map(event => {
      const valeur = event.codeCompaie;
      const labelle = event.titre;
      const newEvent = { value: valeur, label: labelle };
      return newEvent;
  });
}
const DEFAULT_DEMANDE = fromJS({
  progressLevel: { value: 'progress' },
  cityAgence: { value: 780 },
  toggling: { value: true },
});
export default function (state = DEFAULT_DEMANDE, action) {
  switch (action.type) {
    case 'COUNTRY_LIST_DATA_SUCCESS':
      return state.set('countryList', fromJS(action.result));
    case 'EVENEMENT_COMPANIES_LIST_DATA_SUCCESS':
      return state.set('evenementCompanies', fromJS(formatEvent(action.result.evenementsCompanie)));
    case 'GET_COUNTRY_LIST_DATA':
      return state.set('countryList', fromJS([]));
    case 'GET_EVENEMENT_COMPANIES_LIST_DATA':
      return state.set('evenementCompanies', fromJS([]));
    case 'CITY_LIST_DATA_SUCCESS':
      return state.set('cityList', fromJS(formatCity(action.result)));
    case 'GET_CITY_LIST_DATA':
      return state.set('cityList', fromJS([]));
    case 'AGENCIES_LIST_DATA_SUCCESS':
      return state.set('agenceList', fromJS(formatAgences(action.result)));
    case 'GET_AGENCIES_LIST_DATA':
      return state.set('agenceList', fromJS([]));
    case 'GET_INDICATIFS_LIST_DATA':
      return state.set('indicatifsList', fromJS([]));
    case 'INDICATIFS_LIST_DATA_SUCCESS':
      return state.set('indicatifsList', fromJS(formatIndicatifs(action.result)));
    case 'GET_PROMOTEUR_LIST_DATA':
      return state.set('promoteurList', fromJS([]));
    case 'PROMOTEUR_LIST_DATA_SUCCESS':
      return state.set('promoteurList', fromJS(formatPromoteur(action.result.promoteurs)));
    default:
      return state;
  }
}

import React, { Component } from 'react';

class Title extends Component {
  render() {
    return (
      <div className="inlineDisplay">
        <h2 className="step_big_title">{this.props.children}</h2>
      </div>
    );
  }
}

Title.propTypes = {
  children: React.PropTypes.node.isRequired,
  center: React.PropTypes.bool,
};

export default Title;

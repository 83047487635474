import { fromJS, Map } from 'immutable';
import ActiveIconeTraitement from '../assets/images/traitement_encours_active_icn@2x.png';
import DefaultIconeTraitement from '../assets/images/traitement_encours_default_icn@2x.png';
import ValideIconeTraitement from '../assets/images/traitement_encours_passed_icn@2x.png';
import ActiveIconePropCom from '../assets/images/proposition_comm_active_icn@2x.png';
import DefaultIconePropCom from '../assets/images/proposition_comm_default_icn@2x.png';
import ValideIconePropCom from '../assets/images/proposition_comm_passed_icn@2x.png';
import ActiveIconeConstDossier from '../assets/images/constitution_dossier_active_icn@2x.png';
import DefaultIconeConstDossier from '../assets/images/constitution_dossier_default_icn@2x.png';
import ValideIconeConstDossier from '../assets/images/constitution_dossier_passed_icn@2x.png';
import ActiveIconeFormalisation from '../assets/images/formalisation_active_icn@2x.png';
import DefaultIconeFormalisation from '../assets/images/formalisation_default_icn@2x.png';
import ValideIconeFormalisation from '../assets/images/formalisation_passed_icn@2x.png';
import ActiveIconeConcretisation from '../assets/images/concretisation_active_icn@2x.png';
import DefaultIconeConcretisation from '../assets/images/concretisation_default_icn@2x.png';
import ValideIconeConcretisation from '../assets/images/concretisation_passed_icn@2x.png';

const ImgsActives=[ActiveIconeTraitement,ActiveIconePropCom,ActiveIconeConstDossier,ActiveIconeFormalisation,ActiveIconeConcretisation];
const ImgsDefaults=[DefaultIconeTraitement,DefaultIconePropCom,DefaultIconeConstDossier,DefaultIconeFormalisation,DefaultIconeConcretisation];
const ImgValides=[ValideIconeTraitement,ValideIconePropCom,ValideIconeConstDossier,ValideIconeFormalisation,ValideIconeConcretisation];

const steps = [
  {
    step: 0, label: 'TRAITEMENT EN COURS',
    regex: 'en cours', statut: 'Demande envoyée',
    imgActive: {ImgsActives},
    imgDefault: {ImgsDefaults},
    imgValid: {ImgValides},
  },
  {
    step: 1, label: 'PROPOSITION COMMERCIALE',
    regex: 'reception', statut: 'Réponse du client',
    imgActive: {ImgsActives},
    imgDefault: {ImgsDefaults},
    imgValid: {ImgValides},
  },
  {
    step: 2, label: 'CONSTITUTION DU DOSSIER',
    regex: 'constitution', statut: 'Dossier reçu / Traitement',
    imgActive: {ImgsActives},
    imgDefault: {ImgsDefaults},
    imgValid: {ImgValides},
  },
  {
    step: 3, label: 'CONTRACTUALISATION & FORMALISATION',
    regex: 'contrat', statut: 'Contrat / Documents de garantie',
    imgActive: {ImgsActives},
    imgDefault: {ImgsDefaults},
    imgValid: {ImgValides},
  },
  {
    step: 4, label: 'CONCRÉTISATION',
    regex: 'concretisation', statut: 'Déblocage des fonds',
    imgActive: {ImgsActives},
    imgDefault: {ImgsDefaults},
    imgValid: {ImgValides},
  },
];



function getCurrentStep(statut) {
  switch (statut) {
    case "Nouvelle Demande":
      return  steps.find(v => v.regex === "en cours").step;
    case "Etude":
      return steps.find(v => v.regex === "en cours").step;
    case  'etude middle office':
        return steps.find(v => v.regex === "en cours").step;
    case  'decision':
      return steps.find(v => v.regex === "en cours").step;
    case  'Reponse Client':
      return steps.find(v => v.regex === "reception").step;
    case  'reception':
      return steps.find(v => v.regex === "reception").step;  
    case  'communication liste':
      return steps.find(v => v.regex === 'constitution').step; 
    case  'constitution':
      return steps.find(v => v.regex === 'constitution').step;        
    case  'contrat':
      return steps.find(v => v.regex === "contrat").step;
    case 'edition contrat en cours':
      return steps.find(v => v.regex === "contrat").step;
    case  'concretisation':
      return steps.find(v => v.regex === "concretisation").step;
    default:
          return 0;
  }
}

export default function (state = new Map(), action) {
  switch (action.type) {
    case 'GET_DEMANDE_SUCCESS':
      return state.set('current',
       getCurrentStep(fromJS(action.result).getIn(['demande', 'statut','libelle'])));
    case 'INIT_APP':
      return state.set('steps', fromJS(steps));
    default:
      return state;
  }
}

import { push, go } from 'react-router-redux';
import { List } from 'immutable';


const routing = {
  '/simulateur': '/infos-perso',
  '/infos-perso': '/infos-projet',
  '/infos-projet': '/coordonnees',
  '/coordonnees': '/recapitulatif',
};


export default store => next => action => {
  if (action.type === 'GO_NEXT_STATE') {
    const state = store.getState();
    const currentPath = state.routing.locationBeforeTransitions.pathname;
    const nextPath = routing[currentPath];
    if (nextPath) {
      if (nextPath.call) {
        store.dispatch(push(nextPath(state)));
      } else {
        store.dispatch(push(nextPath));
      }
    } else {
      /* eslint-disable no-console */
      console.error(`Cannot find next state for ${currentPath}.`);
    }
  }
  if (action.type === 'GO_BACK_TO_STATE') {
    const history = store.getState().myhistory;
    let delta = 0;
    if (action.state instanceof List) {
      delta = history.get('stack')
        .slice(0, history.get('stack').findIndex(e => e.get('current')) + 1)
        .reverse()
        .findIndex(state => action.state.includes(state.get('path')));
    } else {
      delta = history.get('stack')
        .slice(0, history.get('stack').findIndex(e => e.get('current')) + 1)
        .reverse()
        .findIndex(state => state.get('path') === action.state);
    }
    if (delta > 0) {
      store.dispatch(go(-delta));
    }
  }
  return next(action);
};

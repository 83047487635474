import { connect } from 'react-redux';

import Promoteur from '../components/Promoteur';

const mapStateToProps = (state, props) => {
  const [store, ...storeKey] = props.storeKey.split('.');
  storeKey.push('value');
  return ({
    items: state.configuration.get('promoteurList'),
    value: state[store].getIn(storeKey),
  });
};

export default connect(mapStateToProps)(Promoteur);

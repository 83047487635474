import { connect } from 'react-redux/dist/react-redux';
import DetailResultatSMAP from '../components/detailResultatSMAP';
import { getAllResult, inputChange, showHide,saveInfoResultatSimulateurSmap,sendSimulateurSmap,clearState } from '../action_creators';
import { fromJS } from 'immutable';
import InfoService from '../assets/images/img/popin_info_icn.png';
import { formatDatedd } from '../utile/utile';
function mapStateToProps(state) {
  const itemsEmpty = fromJS([{}]);
  const montantCredit = state.simulateur.getIn(['montantCredit', 'value']);
  const nbAnnee = state.simulateur.getIn(['nbAnnee', 'value']);
  const taux = state.simulateur.getIn(['taux', 'value']);
  const dureeDiff = state.simulateur.getIn(['dureeDiff', 'value']);
  const details = state.simulateur.get('allResult') ? state.simulateur.get('allResult')
                                                .filter(f => f.get('id') === nbAnnee).get(0)
                                                : itemsEmpty;
 const assuranceDtc= details ? details.get('dtc') : null;
 const assuranceIe=  details ? details.get('ie') :null;
 const coutInterets = details ?  (details.get('montant') * details.get('id') * 12 - montantCredit).toFixed(2) : null;
 const montantTtc =  details ? details.get('montant'): null;
 const dossier =  details ? details.get('dossier'): null;
 
  return {
    allResult: state.simulateur.get('allResult'),
    montantCredit,
    nbAnnee,
    taux,
    storeKey: 'simulateur.nbAnnee',
    dureeDiff,
    popinTitle: 'tt',
    popinIcnInfo: InfoService,
    showHideElm: state.demandeCredit.getIn(['isChaabiClient', 'value']),
    smapResultat : {
      referenceId : state.smap.getIn(['referenceId', 'value']),
      clientbcp : state.smap.getIn(['clientbcp', 'value']),
      nom : state.smap.getIn(['nom', 'value']),
      prenom : state.smap.getIn(['prenom', 'value']),
      adresse : state.smap.getIn(['adresse', 'value']),
      telephone : state.smap.getIn(['telephone', 'value']),
      villeresidence : state.smap.getIn(['ville', 'value']),
      indicatif : state.smap.getIn(['indicatif', 'value']),
      typebien : state.smap.getIn(['typebien', 'value']),
      paysderesidence : state.smap.getIn(['paysderesidence', 'value']),
      email : state.smap.getIn(['mail', 'value']),
      montantCredit : montantCredit,
      duree: nbAnnee,
      duree_diff : dureeDiff,
      taux : taux,
      assuranceDTC : assuranceDtc,
      assuranceIE : assuranceIe,
      coutTotalCredit : coutInterets,
      mensualite : montantTtc,
      fraisDossier: dossier > 1000 ? 1000 : dossier,
      dateCreation: formatDatedd(Date())
    },
    statusEmail : state.smap.getIn(['successIndicatorSave', 'value']),
    sendSimulation : {
      nom : state.smap.getIn(['nom', 'value']),
      prenom : state.smap.getIn(['prenom', 'value']),
      adresse : state.smap.getIn(['adresse', 'value']),
      montantCredit: state.simulateur.getIn(['montantCredit', 'value']),
      nbAnnee: state.simulateur.getIn(['nbAnnee', 'value']),
      taux: state.simulateur.getIn(['taux', 'value']),
      dureeDiff: state.simulateur.getIn(['dureeDiff', 'value']),
      assuranceDtc,
      assuranceIe,
      coutInterets,
      montantTtc,
    }
  };
}
function mergeProps(stateProps, dispatchProps, ownProps) {
  const smapResultat = stateProps.smapResultat;
  const sendSimulation = stateProps.sendSimulation;
  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    saveInfoResultatSimulateurSmap: () => dispatchProps.saveInfoResultatSimulateurSmap(smapResultat),
    sendSimulateurSmap: () => dispatchProps.sendSimulateurSmap(sendSimulation),
  });
}
function mapDispatchToProps(dispatch) {
  return {
    getAllResult: (...s) => dispatch(getAllResult(...s)),
    inputChange: (s, h) => dispatch(inputChange(s, h)),
    show: (s) => dispatch(showHide(s)),
    saveInfoResultatSimulateurSmap : (s) => dispatch(saveInfoResultatSimulateurSmap(s)),
    sendSimulateurSmap : (s) => dispatch(sendSimulateurSmap(s)),
    clearState : (s) => dispatch(clearState(s)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DetailResultatSMAP);

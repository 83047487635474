import React from 'react';
import { Router, Route, hashHistory } from 'react-router/lib';
import InfosPerso from '../containers/InfosPerso';
import Coordonnees from '../containers/Coordonnees';
import Recapitulatif from '../containers/Recapitulatif';
import InfoProjet from '../containers/InfoProjet';
import Layout from '../containers/Layout';
import DetailCapacite from '../containers/DetailCapacite';
import FormSMAP from '../containers/FormSMAP';
import GlobalLayout from '../components/GlobalLayout';
import LayoutSMAP from '../components/LayoutSMAP';
import Accueil from '../containers/Accueil';
import DetailResultat from '../containers/DetailResultat';
import DetailResultatCapaciteEmprunt from '../containers/DetailResultatCapaciteEmprunt';
import DetailProduit from '../components/DetailProduit';
import Felicitation from '../containers/Felicitation';
import EspacePerso from '../containers/EspacePerso';
import MaReference from '../containers/MaReference';
import Produits from '../containers/Produits';
import HomeSMAP from '../components/HomeSMAP';
import SimulateurSMAP from '../containers/SimulateurSMAP';
import DetailResultatSMAP from '../containers/DetailResultatSMAP';
import VerificationPassword from '../containers/VerificationPassword';
import Confirmation from '../containers/Confirmation';
import ChangePassword from '../containers/ChangePassword';

const App = () => (
  <div className="page">
    <Router history={hashHistory}>
      <Route component={LayoutSMAP}>
        <Route path="/SMAP" component={HomeSMAP} />
        <Route path="/formSMAP" component={FormSMAP} />
        <Route path="/detail-resultat-SMAP" component={DetailResultatSMAP} />
      </Route>
      <Route path="/accueil" component={Accueil} />
      <Route path="/simulateurSMAP" component={SimulateurSMAP} />
      <Route component={GlobalLayout}>
        <Route path="/detail-resultat" component={DetailResultat} />
        <Route path="/detail-resultat-emprunt" component={DetailResultatCapaciteEmprunt} />
        <Route path="/detail-produit" component={DetailProduit} />
        <Route path="/felicitation" component={Felicitation} />
        <Route path="/confirmation" component={Confirmation} />
        <Route path="/espaceperso" component={EspacePerso} />
        <Route path="/searchByref" component={MaReference} />
        <Route path="/VerificationPassword/token=:token/ref=:ref/email=:email" component={VerificationPassword} />
        <Route path="/ChangePassword/token=:token/email=:email" component={ChangePassword} />
        <Route path="/produits" component={Produits} />
      </Route>
      <Route component={Layout}>
        <Route path="/coordonnees" component={Coordonnees} />
        <Route path="/infos-perso" component={InfosPerso} />
        <Route path="/infos-projet" component={InfoProjet} />
        <Route path="/recapitulatif" component={Recapitulatif} />
      </Route>
      <Route path="/DetailCapacite" component={DetailCapacite} />
      <Route path="*" component={Accueil} />
    </Router>
  </div>
);

export default App;

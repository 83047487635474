import React, { Component, PropTypes } from 'react';
import ReactHighcharts from 'react-highcharts';
import highcharts from 'highcharts';
import { List } from 'immutable';
import i18n from '../../i18n';
class Chart extends Component {
  render() {
    const config = {
      /* HighchartsConfig */
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        // plotBackgroundImage: '../../../img/chart_bg.jpg',
      },
      colors: ['#bb6b6a', '#ea932c'],
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [
          '1 ' +i18n.t("chart3.label"), '2 '+i18n.t("chart3.label"), '3 '+i18n.t("chart3.label"), '4 '+i18n.t("chart3.label"), '5 '+i18n.t("chart3.label"), '6 '+i18n.t("chart3.label"), '7 '+i18n.t("chart3.label"),
          '8 '+i18n.t("chart3.label"), '9 '+i18n.t("chart3.label"), '10 '+i18n.t("chart3.label"), '11 '+i18n.t("chart3.label"), '12 '+i18n.t("chart3.label"), '13 '+i18n.t("chart3.label"), '14 '+i18n.t("chart3.label"),
          '15 '+i18n.t("chart3.label"), '16 '+i18n.t("chart3.label"), '17 '+i18n.t("chart3.label"), '18 '+i18n.t("chart3.label"), '19 '+i18n.t("chart3.label"), '20 '+i18n.t("chart3.label"), '21 '+i18n.t("chart3.label"),
          '22 '+i18n.t("chart3.label"), '23 '+i18n.t("chart3.label"), '24 '+i18n.t("chart3.label"), '25 '+i18n.t("chart3.label"),
        ],
        labels: {
          align: 'center',
          rotation: '0',
          style: {
            color: '#4c4b49',
            fontSize: '19px',
          },
        },
      },
      yAxis: {
        labels: {
          x: -20,
          y: 4,
          style: {
            fontWeight: 'bold'
          },
          formatter() {
            return '<span class="chart_lbl">' +
            `${highcharts.numberFormat(this.value, 0)}` +
            '</span>' +
            '<span class="chart_txt">'+ i18n.t("simulateur7.label") + '</span>';
          },
        },
        allowDecimals: true,
        min: 0,
        title: {
          text: '',
        },
        plotBands: [{
          from: 500,
          to: 200,
          color: {
            width: 6,
            height: 6,
          },
        }],
      },
      legend: {
        layout: 'horizontal',
        align: 'right',
        verticalAlign: 'top',
        x: -20,
        y: 10,
        floating: false,
        borderColor: '#CCC',
        shadow: false,
        symbolWidth: 28,
        symbolHeight: 10,
        symbolRadius: 0,
      },
      tooltip: {
        formatter() {
          return `<b> ${this.x} </b><br/> ${this.series.name}:${this.y}<br/>
          Total: ${this.point.stackTotal}`;
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      series:
      [{
        name: i18n.t("chart2.label"),
        data: (this.props.Result[1] || new List()).toArray(),
        pointWidth: 15,
      }, {
        name: i18n.t("chart1.label"),
        data: (this.props.Result[0] || new List()).toArray(),
        pointWidth: 15,
      }],
    };
    return (
      <div className="chart_container unprentable">
        <h2 className="chart_header">{i18n.t("recap47.label")} </h2>
        <div className="chart_bloc">
          <ReactHighcharts config={config} />
        </div>
      </div>
    );
  }
}
export default Chart;
Chart.propTypes = {
  Result: PropTypes.object,
};
Chart.defaultProps = {
  Result: new List(),
};

import React, { Component } from 'react';
import Crc from './Accueil/Crc';
import Produits from './Inputs/Produits';
import crownproduit1 from '../assets/images/crownproduit1.png';
import crownproduit2 from '../assets/images/crownproduit2.png';
import crownproduit3 from '../assets/images/crownproduit3.png';

export default class DetailProduit extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <div className="detailproduit_presentation">
          <div className="detailproduit_content">
            <p className="accompany_txt_detailproduit white_clr uppercase_txt txt_shadow">
               SAKANE mabrouk
            </p>
            <p className="accompany_txt_detail white_clr uppercase_txt txt_shadow">
               garanti par damane assakane (fogaloge)
            </p>
          </div>
        </div>
        <div className="detailproduit_container align_center">
          <div className="title" id="title_container">
            <div className="detailproduit_title mb50" id="titre">
              <div>
                <h2 className="step_big_title">Pourquoi choisir sakane mabrouk ?</h2>
              </div>
              <hr className="hr_header" />
              <p className="sub_desc mb35">Lorem Ipsum is simply dummy text of the
                printing and typesetting Lorem Ipsum is simply dummy text of the
                  printing and typesetting Lorem Ipsum is simply dummy text of the
                  printing and typesetting Lorem Ipsum is simply dummy text of the
                    printing and typesetting Lorem Ipsum is simply dummy text of the
                      printing and typesetting Lorem Ipsum is
                        printing and typesetting
              </p>
              <p className="sub_desc mb20">Lorem Ipsum is simply dummy text of the
                printing and typesetting Lorem Ipsum is simply dummy text of the
                  printing and typesetting Lorem Ipsum is simply dummy text of the
                  printing and typesetting Lorem Ipsum is simply dummy text of the
                    printing and typesetting Lorem Ipsum is simply dummy text of the
                      printing and typesetting
              </p>
            </div>
          </div>
        </div>
        <div className="avantage_detailproduit_container align_center">
          <div className="title" id="title_container">
            <div className="avantages_title mb50" id="titre">
              <div>
                <h2 className="step_big_title">Avantages</h2>
              </div>
              <hr className="hr_header white_bg" />
              <p className="title_desc mb7">Lorem ipsum dolor sit amet, consectetur
              </p>
            </div>
          </div>
          <ul className="avantages_detailproduit_blocs">
            <li>
              <span className="avantages_img">
                <img src={crownproduit1} alt="" />
              </span>
              <br />
              <span className="avantage_header">
              Lorem Ipsum dolor
              </span>
              <span className="avantage_txt">
              Lorem Ipsum dolor sit amet Lorem Ipsum dolor Lorem Ipsum dolor Lorem
              Ipsum dolor Lorem Ipsum dolor Lorem Ipsumaai dolor Lorem Ipsum dolor
              </span>
            </li>
            <li>
              <span className="avantages_img">
                <img src={crownproduit2} alt="" />
              </span>
              <br />
              <span className="avantage_header">
              Lorem Ipsum dolor
              </span>
              <span className="avantage_txt">
              Lorem Ipsum dolor sit amet Lorem Ipsum dolor Lorem Ipsum dolor Lorem
              Ipsum dolor Lorem Ipsum dolor Lorem Ipsumaai dolor Lorem Ipsum dolor
              </span>
            </li>
            <li>
              <span className="avantages_img">
                <img src={crownproduit3} alt="" />
              </span>
              <br />
              <span className="avantage_header">
              Lorem Ipsum dolor
              </span>
              <span className="avantage_txt">
              Lorem Ipsum dolor sit amet Lorem Ipsum dolor Lorem Ipsum dolor Lorem
              Ipsum dolor Lorem Ipsum dolor Lorem Ipsumaai dolor Lorem Ipsum dolor
              </span>
            </li>
          </ul>
        </div>
        <div className="avantage_container align_center">
          <div className="title" id="title_container">
            <div className="avantages_title mb50" id="titre">
              <div>
                <h2 className="step_big_title white-txt">voulez vous qu&apos;on en parle ?</h2>
              </div>
              <hr className="hr_header white_bg" />
              <p className="title_desc mb7 white-txt">être appeler par un conseiller
              </p>
              <p className="sub_desc mb35 white-txt">Lorem Ipsum is simply dummy text of the
                printing and typesetting Lorem Ipsum is simply dummy text of the
                  printing and typesetting Lorem Ipsum is simply dummy text of the
                  printing and typesetting
              </p>
            </div>
          </div>
          <div className="produit_choixHoraire">
            <button
              className="btn_choixHoraire"
            >
              <span>
                choisir un horaire
              </span>
            </button>
          </div>
        </div>
        <div className="produit_container align_center">
          <div className="block_title mb50 ">
            <h2 className="step_big_title">nous vous proposons aussi ...</h2>
            <hr className="hr_header" />
            <p className="title_desc mb20">
              Notre sélection de produits
            </p>
          </div>
          <Produits />
        </div>
        <div className="crc_container">
          <Crc />
        </div>
      </div>
    );
  }
    }
DetailProduit.propTypes = {

};
DetailProduit.defaultProps = {
};

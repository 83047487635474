import { connect } from 'react-redux/dist/react-redux';
import Recapitulatif from '../components/Recapitulatif';
import { saveDemande, stateDemande, displayErrors, inputChange } from '../action_creators';


function mapStateToProps(state) {

  return {
    reCaptchaResponse: state.demandeCredit.getIn(['reCaptchaResponse', 'value']),
    agenceList: state.configuration.get('agenceList'),
    langues: state.demandeCredit.getIn(['langues', 'value']),
    clientbcp: state.demandeCredit.getIn(['clientbcp', 'value']),
    codeAgenceSelected: state.demandeCredit.getIn(['codeAgence', 'value']),
    libelleagence: state.demandeCredit.getIn(['libelleagence', 'value']),
    cityAgence: state.demandeCredit.getIn(['cityAgence', 'value']),
    ville: state.configuration.get('cityList'),
    demandeCredit: {
      reference: state.demandeCredit.getIn(['request', 'result', 'reference']),
      prenom: state.demandeCredit.getIn(['prenom', 'value']),
      nom: state.demandeCredit.getIn(['nom', 'value']),
      civilite: state.demandeCredit.getIn(['civilite', 'value']),
      clientbcp: state.demandeCredit.getIn(['clientbcp', 'value']),
      mail: state.demandeCredit.getIn(['mail', 'value']),
      telephone: state.demandeCredit.getIn(['telephone', 'value']),
      indicatif: state.demandeCredit.getIn(['indicatif', 'value']),
      date_naissance: {
        day: state.demandeCredit.getIn(['date_naissanceDay', 'value']),
        month: state.demandeCredit.getIn(['date_naissanceMonth', 'value']),
        year: state.demandeCredit.getIn(['date_naissanceYear', 'value']),
      },
      dateEmbauche: {
        day: state.demandeCredit.getIn(['date_embaucheDay', 'value']),
        month: state.demandeCredit.getIn(['date_embaucheMonth', 'value']),
        year: state.demandeCredit.getIn(['date_embaucheYear', 'value']),
      },
      paysderesidence: state.demandeCredit.getIn(['paysderesidence', 'value']),
      situationLogement: state.demandeCredit.getIn(['situationLogementDemandeur', 'value']),
      pieceidentite: state.demandeCredit.getIn(['pieceidentite', 'value']),
      numpieceidentite: state.demandeCredit.getIn(['numpieceidentite', 'value']),
      situationfamiliale: state.demandeCredit.getIn(['situationfamiliale', 'value']),
      activite: state.demandeCredit.getIn(['activite', 'value']),
      profession: state.demandeCredit.getIn(['profession', 'value']),
      employeur: state.demandeCredit.getIn(['employeur', 'value']),
      secteur: state.demandeCredit.getIn(['secteur', 'value']),
      anciente: state.demandeCredit.getIn(['anciente', 'value']),
      revenu: state.demandeCredit.getIn(['revenu', 'value']),
      boautrerevenu: state.demandeCredit.getIn(['boautrerevenu', 'value']),
      autreactivite: state.demandeCredit.getIn(['autreactivite', 'value']),
      autrerevenu: state.demandeCredit.getIn(['autrerevenu', 'value']),
      etatbien: state.demandeCredit.getIn(['etatbien', 'value']),
      typebien: state.demandeCredit.getIn(['typebien', 'value']),
      usagebien: state.demandeCredit.getIn(['usagebien', 'value']),
      coutprojet: state.demandeCredit.getIn(['coutprojet', 'value']),
      apportpersonnel: state.demandeCredit.getIn(['apportpersonnel', 'value']),
      autresecheances: state.demandeCredit.getIn(['autresecheances', 'value']),
      localisationbien: state.demandeCredit.getIn(['localisationbien', 'value']),
      eventCompany: state.demandeCredit.getIn(['eventCompany', 'value']),
      surfacebien: state.demandeCredit.getIn(['surfacebien', 'value']),
      montantpret: state.demandeCredit.getIn(['montantCredit', 'value']),
      dureesouhaitable: state.demandeCredit.getIn(['nbAnnee', 'value']),
      typetaux: state.demandeCredit.getIn(['typetaux', 'value']),
      dureediffere: state.demandeCredit.getIn(['dureeDiff', 'value']),
      codeAgence: state.demandeCredit.getIn(['agence', 'value']),
      libelleagence: state.demandeCredit.getIn(['libelleagence', 'value']),
      naturecontrat: state.demandeCredit.getIn(['naturecontrat', 'value']),
      nbrenfants: state.demandeCredit.getIn(['nbrenfants', 'value']),
      financconjoint: state.demandeCredit.getIn(['financconjoint', 'value']),
      promoteur: state.demandeCredit.getIn(['promoteur', 'value']),
      fraisamg: state.demandeCredit.getIn(['fraisamg', 'value']),
      autresfrais: state.demandeCredit.getIn(['autresfrais', 'value']),
      radical: state.account.getIn(['identifiant', 'value']),
      apiAction: 'saveNotify',
      sourceAutreRevenu: state.demandeCredit.getIn(['sourceAutreRevenu', 'value']),
      promotor: {
        id: state.demandeCredit.getIn(['promoteur', 'value']),
        prmotorLibelle: state.demandeCredit.getIn(['promoteurLibelle', 'value']),
        autrePromoteur: state.demandeCredit.getIn(['autrePromoteur', 'value']),
      },
    },
    progressLevel: state.demandeCredit.getIn(['progressLevel', 'value']),
    status: state.demandeCredit.getIn(['request', 'status']),
    reference: state.demandeCredit.getIn(['request', 'result', 'reference']),
    isAgenceSelected: (state.demandeCredit.getIn(['codeAgence', 'value']) === undefined || state.demandeCredit.getIn(['codeAgence', 'value']) === null) ? undefined : state.demandeCredit.getIn(['codeAgence', 'value']),
    isChecked: state.demandeCredit.getIn(['confirmedInfos', 'value', 0]) === undefined ? state.demandeCredit.getIn(['confirmedInfos', 'value']) :
      state.demandeCredit.getIn(['confirmedInfos', 'value', 0]),
    isConfirmeAgence: state.demandeCredit.getIn(['confirmeAgence', 'value', 0]) === undefined ? state.demandeCredit.getIn(['confirmeAgence', 'value']) :
      state.demandeCredit.getIn(['confirmeAgence', 'value', 0])
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveDemande: (demandeRequest, demandeQuery) => dispatch(saveDemande(demandeRequest, demandeQuery)),
    updateState: () => dispatch(stateDemande()),
    displayErrors: (a) => dispatch(displayErrors(a)),
    inputChange: (...y) => dispatch(inputChange(...y))
  };
}
function mergeProps(stateProps, dispatchProps, ownProps) {
  const demandeRequest = stateProps.demandeCredit;
  const demandeQuery = {reCaptchaResponse: stateProps.reCaptchaResponse};
  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    saveDemande: () => dispatchProps.saveDemande(demandeRequest, demandeQuery)
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Recapitulatif);

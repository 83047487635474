/* eslint-disable */
import i18next from 'i18next';

const sousSlider1 = "Devenir propriétaire n'a jamais été aussi simple";
const titreSimulator = "SIMULATEUR DE CAPACITÉ D'EMPRUNT";
const Capacité = "Capacité d'emprunt";

i18next
  .init({
    interpolation: {
      escapeValue: false,
	},
	debug: false,
	lng: 'fr',
    resources: {
      fr: {
        translation: {
          slider1: { label: "Sakane Mabrouk, frais de dossier offerts pour les moins de 35 ans", },
          sousSlider1: { label: null, },
          slider2: { label: 'Sakane Mabrouk, frais de dossier offerts pour les moins de 35 ans', },
          sousSlider2: { label: null, },
          slider3: { label: 'Sakane Mabrouk, frais de dossier offerts pour les moins de 35 ans', },
          sousSlider3: { label: null, },
          pret: { label: 'demander un prêt', },
          contacter: { label: 'contactez nous', },
          espacePerso: { label: 'Espace personnel', },
          HeaderAuth : { label:'ETES VOUS DEJA CLIENT DE LA BANQUE POPULAIRE*?'},
          oui:{label:'Oui'},
          non:{label:'Non'},
          etoile:{label:'*avec un abonnement ChaabiNet'},
          gammeOffre :{label:'LA BANQUE POPULAIRE VOUS PROPOSE UNE GAMME DE PRÊTS ADAPTÉS À VOS BESOINS ET À VOTRE BUDGET'},
          formulaire:{label:'FORMULAIRE DE DEMANDE DE PRÊT'},
          produitBP :{label:'LA BANQUE POPULAIRE MET À VOTRE DISPOSITION, EN LIGNE OU EN AGENCE, UNE PANOPLIE DE PRODUITS'},
          step01:{label:'VOS COORDONNÉES'},
          step02:{label:'INFORMATIONS PERSONNELLES'},
          step03:{label:'Informations sur le projet'},
          step04:{label:'récapitulatif'},
          titleForm1 :{label:'JE RENSEIGNE MES COORDONNÉES'},
          subtitleForm1 : {label:'Veuillez renseigner l’ensemble des champs'},
          att1Form1 : {label:'ÊTES-VOUS DÉJÀ CLIENT ?'},
          civiliteM : {label:'M'},
          civiliteMme : {label:'MME'},
          att2Form1 :{label:'CIVILITÉ'},
          att3Form1 :{label:'NOM'},
          att4Form1 :{label:'PRÉNOM'},
          att5Form1 :{label:'ADRESSE EMAIL'},
          att6Form1 :{label:'N° Tel mobile ( exepmle : +212 6********)'},
          suivant:{label:'Suivant'},
          avantage: { label: 'Envoyez votre demande de financement et bénéficiez d’une expérience personnalisée en ligne ou en agence', },
          titleAv: {label: 'LES AVANTAGES', },
          titleAv2: {label: 'Parcours Simplifié', },
          titleAv3: {label: 'Décrivez votre projet en quelques clics, nos conseillers s’occupent du reste', },
          titleAv4: {label: 'OFFRE PERSONNALISÉE', },
          titleAv5: {label: 'Bénéficiez d’une offre personnalisée adaptée à votre besoin', },
          titleAv6: {label: 'Suivi de la demande en ligne', },
          titleAv7: {label: 'Restez informé de l’avancement du traitement de votre dossier là où vous êtes', },
          titleAv8: {label: 'SIMULATEUR DÉTAILLÉ', },
          titleAv9: {label: 'Simulez votre capacité d’emprunt, votre mensualité et les frais annexes', },
          titleAv10: {label: 'Ma demande de prêt immobilier en ligne en 4 étapes', },
          titleAv11: {label: 'LA BANQUE POPULAIRE VOUS FACILITE LE FINANCEMENT DE VOTRE PROJET IMMOBILIER.', },
          titleAv12: {label: 'BENEFICIEZ D’UN ACCOMPAGNEMENT PERSONNALISE, D’UN TRAITEMENT RAPIDE ET TRANSPARENT', },
          titleAv13: {label: 'simulation & envoi de la demande', },
          titleAv14: {label: 'Je réalise mes simulations, je renseigne mes informations et celles de mon projet puis j’envoie ma demande', },
          titleAv15: {label: 'Réponse à votre demande', },
          titleAv16: {label: 'Je reçois la réponse à ma demande en ligne', },
          titleAv17: {label: 'Dépôt des pièces justificatives', },
          titleAv18: {label: 'Je dépose mes pièces justificatives auprès de mon agence', },
          titleAv19: {label: 'Signature du contrat', },
          titleAv20: {label: 'Je signe mon contrat de crédit immobilier', },
          titleAv21: {label: 'Nos prêts Sakane Mabrouk', },
          titleAv22: {label: 'La Banque Populaire vous propose une gamme de prêts immobiliers adaptée  à vos besoins, votre profil et votre budget', },
          titleAv23: {label: 'Visitez notre site institutionnel pour plus de détail', },
          titleAv24: {label: 'détail', },
          titleAv25: {label: 'foire aux questions', },
          titleAv26: {label: 'Accédez à notre FAQ, vous y trouverez les réponses aux questions les plus courantes concernant la demande de crédit immobilier en ligne', },
          titleAv27: {label: 'Est-ce que je dois être client BP pour demander un crédit ?', },
          titleAv28: {label: 'Vous n’êtes pas obligé d’être client de la Banque Populaire pour pouvoir faire votre demande de crédit immobilier. Une fois qu’une réponse favorable vous a été accordée, l’ouverture de compte, dans l’agence de votre choix, vous sera demandée comme préalable à la contractualisation.', },
          titleAv29: {label: 'Ai-je besoin de me rendre à l’agence pour avoir une réponse à ma demande ?', },
          titleAv30: {label: 'Vous pouvez saisir votre demande et l’envoyer 100% en ligne. Après le délai de traitement, une proposition de financement basée sur les informations saisies vous sera envoyée en cas d’acceptation. Vous serez également informé en cas de refus.', },
          titleAv32: {label: 'Est-ce que je peux bénéficier du suivi en ligne pour une demande initiée à l’agence ?', },
          titleAv33: {label: 'Vous pouvez bénéficier du suivi de l’état d’avancement du traitement de votre dossier, et ce, que vous ayez initié votre demande via notre site web ou directement en agence.', },
          titleAv34: {label: 'Est-ce que la réponse à ma demande est définitive ?', },
          titleAv35: {label: 'La réponse donnée par la banque est basée sur les informations saisies lors de votre demande. Elle n’est valable que si vous nous fournissez les documents prouvant la véracité de l’ensemble des informations saisies ainsi que les documents de garanties listés dans la proposition de financement.', },
          titleAv36: {label: 'Quelle est la durée de validité de la proposition de financement ?', },
          titleAv37: {label: 'Afficher plus', },
          titleAv38: {label: 'Afficher moins', },
          titleAv39: {label: 'contactez-nous', },
          titleAv40: {label: 'Besoin d’informations ou d’assistance ?', },
          titleAv41: {label: 'vous pouvez également joindre notre ', },
          titleAv42: {label: 'Centre de Relations Clients', },
          titleAv43: {label: 'du lundi au dimanche de 8h à 20h', },
          titleAv44: {label: 'Coût d’un appel local', },
          titleAv45: {label: 'accueil', },
          titleAv46: {label: 'Nous contacter', },
          titleAv47: {label: 'conditions d’utilisation', },
          titleAv48: {label: 'Banque Centrale Populaire- 101, boulevard Mohamed Zerktouni', },
          titleAv49: {label: 'BP 10.622 - 20100', },
          titleAv50: {label: 'Casablanca', },
          titleAv51: {label: 'Comment connaitre le sort  de traitement de ma demande de crédit?', },
          simulateur1: {label: 'Simulateur', },
          simulateur2: {label: 'Montant', },
          simulateur3: {label: 'Durée', },
          simulateur4: {label: 'Différé', },
          simulateur44: {label: 'Différé', },
          simulateur5: {label: 'Taux', },
          simulateur6: {label: 'Échéance', },
          simulateur7: {label: 'Dhs', },
          simulateur8: {label: 'Ans', },
          simulateur9: {label: 'Mois', },
          simulateur10: {label: titreSimulator, },
          simulateur11: {label: 'faire une demande de prêt', },
          simulateur12: {label: 'Revenu net mensuel', },
          simulateur13: {label: 'Échéance autres crédits', },
          simulateur14: {label: Capacité, },
          simulateur15: {label: "Choisir mon montant", },
          simulateur16: {label: "Le résultat de cette simulation est non contractuel et revêt un caractère strictement informatif", },
          simulateur17: {label: "Choisissez le montant que vous souhaitez emprunter afin de connaitre votre échéance",},
          simulateur18: {label: "Choisissez la durée totale du remboursement du prêt souhaité",},
          simulateur19: {label: "Vous ne souhaitez pas commencer à rembourser de manière immédiate? Choisissez la durée de différé (franchise) qui vous convient ",},
          simulateur20: {label: "Le taux d’intérêt sélectionné  est mentionné à titre indicatif et vous permet de réaliser vos différentes simulations. Il n’est pas engageant pour la banque. Un taux adapté à votre profil vous sera communiqué après étude de votre dossier",},
          simulateur21: {label: "L’échéance est le montant à rembourser selon la périodicité définie. Le calcul de l’échéance se base sur le montant, la durée, le taux ainsi que sur le différé",},
          simulateur22: {label: "Renseigner la somme de votre revenu net mensuel perçu",},
          simulateur23: {label: "Merci d’indiquer la somme des échéances payées mensuellement pour les crédits contractés auprès des différents établissements financiers",},
          simulateur24: {label: "Choisissez la durée totale du remboursement du prêt souhaité",},
          simulateur25: {label: "Le taux d’intérêt sélectionné  est mentionné à titre indicatif et vous permet de réaliser vos différentes simulations. Il n’est pas engageant pour la banque. Un taux adapté à votre profil vous sera communiqué après étude de votre dossier",},
          simulateur26: {label: "La Capacité d’emprunt est le montant maximal que vous souhaitez emprunter selon le revenu net mensuel. Le calcul de la capacité d’emprunt se base sur le revenu net mensuel, la durée, le taux ainsi que sur le différé",},
          infoPerso1: {label: "JE RENSEIGNE MES INFORMATIONS PERSONNELLES", },
          infoPerso2: {label: "Date de naissance", },
          infoPerso3: {label: "Pays de résidence", },
          infoPerso4: {label: "Pièce d'identité", },
          infoPerso5: {label: "N° Pièce identité", },
          infoPerso6: {label: "choisir...", },
          infoPerso7: {label: "Situation familiale", },
          infoPerso8: {label: "Nombre d'enfants", },
          infoPerso9: {label: "Catégorie socio-professionnelle", },
          infoPerso10: {label: "Nature du contrat", },
          infoPerso12: {label: "employeur/ raison sociale", },
          infoPerso13: {label: "Salaire/ Revenu fixe principal (Net mensuel)", },
          infoPerso14: {label: "Profession", },
          infoPerso15: {label: "Années d'ancienneté (employeur actuel)", },
          infoPerso16: {label: "Secteur", },
          infoPerso17: {label: "Autres Revenus Mensuels", },
          infoPerso18: {label: "Source autres revenus", },
          infoPerso19: {label: "Echéance autres prêts", },
          infoPerso20: {label: "CDI", },
          infoPerso21: {label: "CDD", },
          infoPerso22: {label: "Public", },
          infoPerso23: {label: "Privé", },
          infoPerso24: {label: "Vous êtes", },
          infoPerso25: {label: "date d'embauche", },
          precedent: {label: "Précédent", },
          infoProjetEvent: {label: `"EVÈNEMENT - COMPAGNE LIÉ À LA DEMANDE DE PRÊT"`, },
          subtitleFormEvent1: {label: "Merci de sélectionner ou mettre à jour l'évènement - compagne liée à cette demande", },
          subtitleFormEvent2: {label: "* Indiquer si cette demande est liée au Programme d'aide direct au logement", },
          recapEvent: {label: "Choisir Evènement - Compagne", },
          recapEventIcon: {label: "Cliquer pour retirer la liaison de cette demande à un évènement - compagne", },
          recapEventLabel: {label: "Evènement - Compagne", },
          infoProjet1: {label: "je renseigne les informations sur le bien", },
          infoProjet2: {label: "État du logement", },
          infoProjet3: {label: "Objet de la demande", },
          infoProjet4: {label: "Usage du bien", },
          infoProjet5: {label: "prix du bien", },
          infoProjet6: {label: "Superficie", },
          infoProjet7: {label: "Localisation du bien", },
          infoProjet8: {label: "Autre Promoteur", },
          infoProjet9: {label: "je renseigne les informations sur la demande", },
          infoProjet10: {label: "Montant du prêt souhaité", },
          infoProjet11: {label: "Durée souhaitée", },
          infoProjet12: {label: "Type de taux", },
          infoProjet13: {label: "Durée du différé", },
          infoProjet14: {label: "Apport personnel (hors prêts)", },
          infoProjet15: {label: "Montant d'autre financement", },
          infoProjet16: {label: "ESTIMATION DES FRAIS LIÉS AU PROJet", },
          infoProjet17: {label: "M²", },
          infoProjet18: {label: "Promoteur", },
          recap1: {label: "RÉCAPITULATIF DE VOTRE DEMANDE DE PRÊT", },
          recap2: {label: "Veuillez vérifier l’ensemble des informations renseignées", },
          recap3: {label: "COORDONNÉES", },
          recap4: {label: "INFORMATIONS PERSONNELLES", },
          recap5: {label: "INFORMATIONS SUR LE PROJET", },
          recap6: {label: "J'ai lu et j'accepte les termes et", },
          recap7: {label: "J’accepte de recevoir des offres promotionnelles de la Banque Populaire", },
          recap8: {label: "Votre demande sera affectée à l'agence", },
          recap9: {label: "PLUS QUE QUELQUES CLICS POUR VALIDER VOTRE DEMANDE", },
          recap10: {label: "Nous avons bien reçu votre demande", },
          recap11: {label: "Afin de finaliser votre demande, nous vous invitons à cliquer sur le lien reçu par email et qui vous permettra de définir votre mot de passe de connexion à votre espace personnel et valider votre demande", },
          recap12: {label: "RETOUR VERS L'ACCUEIL", },
          recap13: {label: "Vous souhaitez refaire une simulation ou avoir des informations sur les prochaines étapes ?", },
          recap14: {label: "Avancement de ma demande", },
          recap15: {label: "Vous souhaitez consulter l’avancement de votre demande?", },
          recap16: {label: "Employeur", },
          recap17: {label: "revenu", },
          recap18: {label: "État du bien", },
          recap19: {label: "située à", },
          recap20: {label: "et j'accepte les termes et", },
          recap21: {label: "CONNECTEZ VOUS À VOTRE ESPACE PERSONNEL", },
          recap22: {label: "Veuillez saisir vos identifiants de connexion", },
          recap23: {label: "Mot de passe oublié", },
          recap24: {label: "se connecter", },
          recap25: {label: "Récupérer", },
          recap26: {label: "Créer un espace", },
          recap27: {label: "Saisissez votre identifiant", },
          recap28: {label: "Veuillez renseigner votre identifiant", },
          recap29: {label: "login/email/radical/...", },
          recap30: {label: "mot de passe", },
          recap31: {label: "Se souvenir de moi", },
          recap32: {label: "Résultat de ma simulation", },
          recap33: {label: "CI-DESSOUS LE DÉTAIL DES COÛTS RELATIFS À VOTRE DEMANDE DE PRÊT IMMOBILIER", },
          recap34: {label: "MONTANT DU CRÉDIT", },
          recap35: {label: "DURÉE DU CRÉDIT", },
          recap36: {label: "TAUX (HT)", },
          recap38: {label: "assurance décés toutes causes", },
          recap39: {label: "assurance incendie explosion", },
          recap40: {label: "coût total du crédit", },
          recap41: {label: "Dhs(Intérêts)", },
          recap42: {label: "Dhs(Assurance)", },
          recap43: {label: "Dhs(Frais de dossier)", },
          recap44: {label: "Le résultat de cette simulation est non contractuel et revét un caractère strictement informatif", },
          recap45: {label: "MENSUALITÉ TTC", },
          recap46: {label: "VOTRE MENSUALITE SUR :", },
          recap47: {label: "Amortissement - Graphique", },
          chart1: {label: "Capital", },
          chart2: {label: "Interêt", },
          chart3 :{label:"Ans"},
          chart4 :{label:"Ans"},
          propos :{label:"Choisir"},
          footer : {label: "Copyright @ 2022 Groupe Banque Populaire - "},
          mleg :{label:"Mentions légales"},
        },
      },
      en: {
        translation: {
        slider1: { label: 'Get your housing loan agreed online and without supporting documents', },
        sousSlider1: { label: 'Becoming a homeowner has never been so simple', },
        slider2: { label: 'Follow your housing loan application in real time', },
        sousSlider2: { label: 'Stay informed about the progress of your file status via your personal area', },
        slider3: { label: 'Enjoy a simpler and quicker procedure', },
        sousSlider3: { label: 'Enjoy a personalised offer tailored to your needs', },
        pret: { label: 'apply for a loan', },
        contacter: { label: 'Contact us', },
        espacePerso: { label: 'Personal area', },
        HeaderAuth : { label:'ARE YOU ALREADY A BANQUE POPULAIRE CUSTOMER*?'},
        oui:{label:'Yes'},
        non:{label:'No'},
        etoile:{label:'*with a ChaabiNet subscription'},
        gammeOffre :{label:'BANQUE POPULAIRE OFFERS YOU A RANGE OF LOANS TAILORED TO YOUR NEEDS AND BUDGET'},
        formulaire:{label:'LOAN APPLICATION FORM'},
        produitBP :{label:'BANQUE POPULAIRE OFFERS YOU A WIDE RANGE OF PRODUCTS ONLINE OR IN BRANCH'},
        step01:{label:'YOUR CONTACT DETAILS'},
        step02:{label:'PERSONAL INFORMATION'},
        step03:{label:'INFORMATION ON THE PLAN'},
        step04:{label:'summary'},
        titleForm1 :{label:'PLEASE FILL IN YOUR CONTACT DETAILS'},
        subtitleForm1 : {label:'Please complete all fields'},
        att1Form1 : {label:'ARE YOU ALREADY A CUSTOMER?'},
        civiliteM : {label:'Mr.'},
        civiliteMme : {label:'Ms.'},
        att2Form1 :{label:'TITLE'},
        att3Form1 :{label:'Surname'},
        att4Form1 :{label:'First name'},
        att5Form1 :{label:'E-mail address'},
        att6Form1 :{label:'Mobile telephone number (e.g.: +212 6********)'},
        suivant:{label:'NEXT'},
        avantage: { label: 'SEND YOUR FINANCING APPLICATION AND ENJOY A PERSONALISED EXPERIENCE ONLINE OR IN BRANCH', },
        titleAv: {label: 'THE BENEFITS', },
        titleAv2: {label: 'SIMPLIFIED PROCEDURE', },
        titleAv3: {label: 'Describe your plan in a few clicks; our advisers will take care of the rest', },
        titleAv4: {label: 'PERSONALISED OFFER', },
        titleAv5: {label: 'Enjoy a personalised offer tailored to your needs', },
        titleAv6: {label: 'FOLLOW THE APPLICATION ONLINE', },
        titleAv7: {label: 'Stay informed about the progress of your file status via your personal area', },
        titleAv8: {label: 'DETAILED SIMULATOR', },
        titleAv9: {label: 'Simulate your borrowing capacity, your monthly payment, and the related costs', },
        titleAv10: {label: 'MY 4-STEP ONLINE HOUSING LOAN APPLICATION', },
        titleAv11: {label: 'BANQUE POPULAIRE MAKES IT EASIER FOR YOU TO OBTAIN FINANCING FOR YOUR HOUSING PLAN.', },
        titleAv12: {label: 'ENJOY PERSONALISED SUPPORT AS WELL AS QUICK AND TRANSPARENT PROCESSING', },
        titleAv13: {label: 'SIMULATING AND SENDING THE APPLICATION', },
        titleAv14: {label: 'Carry out your simulation, enter information relating to you and your plan, and send your application', },
        titleAv15: {label: 'RESPONSE TO YOUR APPLICATION', },
        titleAv16: {label: 'Receive the response to your online application', },
        titleAv17: {label: 'SUBMITTING SUPPORTING DOCUMENTS', },
        titleAv18: {label: 'Submit your supporting documents to your branch', },
        titleAv19: {label: 'SIGNING THE CONTRACT', },
        titleAv20: {label: 'Sign your housing-loan agreement', },
        titleAv21: {label: 'OUR SAKANE MABROUK LOANS', },
        titleAv22: {label: 'Banque Populaire offers you a range of housing loans tailored to your needs, your profile, and your budget', },
        titleAv23: {label: 'Please visit our institutional website for more details', },
        titleAv24: {label: 'Details', },
        titleAv25: {label: 'FREQUENTLY ASKED QUESTIONS', },
        titleAv26: {label: 'Access our FAQs, where you will find answers to the commonest questions on applying for housing loans online', },
        titleAv27: {label: 'Must I be a BP customer to apply for a loan ?', },
        titleAv28: {label: 'You are not required to be a Banque Populaire customer to apply for a housing loan. Once you have received a favourable response, you will be asked to open an account at the branch of your choice prior to entering into the contractual agreement.', },
        titleAv29: {label: 'Must I go to the branch to get a response to my application ?', },
        titleAv30: {label: 'You can complete your application and send it entirely online. After the application has been processed, a financing offer based on the information submitted will be sent to you in case of acceptance. You will also be informed in case of refusal.', },
        titleAv32: {label: 'If I start an application at a branch, can I follow it online?', },
        titleAv33: {label: 'You can follow the progress of your file status online, regardless of whether you started your application via our website or directly in a branch.', },
        titleAv34: {label: 'Est-ce que la réponse à ma demande est définitive ?', },
        titleAv35: {label: 'La réponse donnée par la banque est basée sur les informations saisies lors de votre demande. Elle n’est valable que si vous nous fournissez les documents prouvant la véracité de l’ensemble des informations saisies ainsi que les documents de garanties listés dans la proposition de financement.', },
        titleAv36: {label: 'Quelle est la durée de validité de la proposition de financement ?', },
        titleAv37: {label: 'Display more', },
        titleAv38: {label: 'Display less', },
        titleAv39: {label: 'CONTACT US', },
        titleAv40: {label: 'WOULD YOU LIKE MORE INFORMATION OR HELP ?', },
        titleAv41: {label: 'YOU CAN ALSO CONTACT OUR ', },
        titleAv42: {label: 'CUSTOMER SERVICE CENTRE', },
        titleAv43: {label: 'FROM MONDAY TO SATURDAY, FROM 9:00 A.M. TO 8:00 P.M', },
        titleAv44: {label: 'Cost of a local call', },
        titleAv45: {label: 'home', },
        titleAv46: {label: 'Contact us', },
        titleAv47: {label: 'conditions of use', },
        titleAv48: {label: 'Banque Centrale Populaire - 101, boulevard Mohamed Zerktouni', },
        titleAv49: {label: 'BP 10.622 - 20100', },
        titleAv50: {label: 'Casablanca', },
        titleAv51: {label: 'How to know the fate of processing of my credit application', },
        simulateur1: {label: 'Simulator', },
        simulateur2: {label: 'Amount', },
        simulateur3: {label: 'Duration', },
        simulateur4: {label: 'Deferred', },
        simulateur44: {label: 'Deferred', },
        simulateur5: {label: 'Rate', },
        simulateur6: {label: 'Repayment', },
        simulateur7: {label: 'Dhs', },
        simulateur8: {label: 'Years', },
        simulateur9: {label: 'Month', },
        simulateur10: {label: 'Borrowing-capacity simulator', },
        simulateur11: {label: 'Apply for a loan', },
        simulateur12: {label: 'Net monthly income', },
        simulateur13: {label: 'Repayment of other loans', },
        simulateur14: {label: 'Borrowing capacity', },
        simulateur15: {label: "Please choose your amount", },
        simulateur16: {label: "The result of this simulation is non-binding and is for information purposes only", },
        simulateur17: {label: "Please choose the amount that you wish to borrow so that you can be given a repayment amount",},
        simulateur18: {label: "Please choose the total repayment period of the required loan",},
        simulateur19: {label: "If you prefer not to start repayments immediately, please choose the period of deferral (grace period) that suits you",},
        simulateur20: {label: "The interest rate selected is given for information purposes and enables you to carry out your various simulations. It is not binding upon the bank. You will be informed of a rate tailored to your profile after your application has been considered.",},
        simulateur21: {label: "The repayment is the amount to be repaid at the frequency that is set. The repayment is calculated on the basis of the amount, the period, and the rate, as well as the deferred period.",},
        simulateur22: {label: "Please enter your net monthly income",},
        simulateur23: {label: "Please indicate the amounts of monthly repayments made for loans taken out with various financial establishments",},
        simulateur24: {label: "Please choose the total duration of the loan required",},
        simulateur25: {label: "The interest rate selected is given for information purposes and enables you to carry out your various simulations. It is not binding upon the bank. You will be informed of a rate tailored to your profile after your application has been considered.",},
        simulateur26: {label: "Borrowing Capacity is the maximum amount that you will be able to borrow based on monthly net income. The calculation of borrowing capacity is based on monthly net income, duration, rate and deferred",},
        infoPerso1: {label: "PLEASE ENTER YOUR PERSONAL INFORMATION", },
        infoPerso2: {label: "Date of birth", },
        infoPerso3: {label: "Country of residence", },
        infoPerso4: {label: "Pièce d'identité", },
        infoPerso5: {label: "Identity document no", },
        infoPerso6: {label: "please select…", },
        infoPerso7: {label: "Family status", },
        infoPerso8: {label: "Number of children", },
        infoPerso9: {label: "Socio-professional category", },
        infoPerso10: {label: "NATURE OF CONTRACT", },
        infoPerso12: {label: "employer / company name", },
        infoPerso13: {label: "Salary / Main fixed income (net per month)", },
        infoPerso14: {label: "PROFESSION", },
        infoPerso15: {label: "Years of seniority (current employer)", },
        infoPerso16: {label: "SECTOR", },
        infoPerso17: {label: "OTHER MONTHLY INCOME", },
        infoPerso18: {label: "Source autres revenus", },
        infoPerso19: {label: "Repayments of other loans", },
        infoPerso20: {label: "PERMANENT CONTRACT", },
        infoPerso21: {label: "FIXED-TERM CONTRACT", },
        infoPerso22: {label: "PUBLIC", },
        infoPerso23: {label: "PRIVATE", },
        infoPerso24: {label: "you are", },
        infoPerso25: {label: "hiring date", },
        precedent: {label: "Previous", },
        infoProjetEvent: {label: `"EVENT - CAMPAIGN RELATED TO LOAN REQUEST"`, },
        subtitleFormEvent1: {label: "Please select or update the event - campaign related to this request", },
        subtitleFormEvent2: {label: "* Indicate if this request is related to the Direct Housing Assistance Program", },
        recapEvent: {label: "Choose Event - Campaign", },
        recapEventIcon: {label: "Click to remove the link of this request to an event - campaign", },
        recapEventLabel: {label: "Event - Compagne", },
        infoProjet1: {label: "PLEASE ENTER INFORMATION CONCERNING THE ASSET", },
        infoProjet2: {label: "STATE OF THE ACCOMMODATION", },
        infoProjet3: {label: "Purpose of the application", },
        infoProjet4: {label: "Use of the asset", },
        infoProjet5: {label: "price of the asset", },
        infoProjet6: {label: "Area", },
        infoProjet7: {label: "Location of the asset", },
        infoProjet8: {label: "Other Developer", },
        infoProjet9: {label: "PLEASE ENTER THE INFORMATION RELATING TO YOUR APPLICATION", },
        infoProjet10: {label: "Required loan amount", },
        infoProjet11: {label: "Required duration", },
        infoProjet12: {label: "Type de taux", },
        infoProjet13: {label: "Durée du différé", },
        infoProjet14: {label: "Personal contribution (excluding loans)", },
        infoProjet15: {label: "Amount of other financing", },
        infoProjet16: {label: "ESTIMATE OF COSTS LINKED TO PLAN", },
        infoProjet17: {label: "M²", },
        infoProjet18: {label: "Developer", },
        recap1: {label: "SUMMARY OF YOUR LOAN APPLICATION", },
        recap2: {label: "Please check all the information provided", },
        recap3: {label: "contact details", },
        recap4: {label: "My personal information", },
        recap5: {label: "INFORMATION ON THE PLAN", },
        recap6: {label: "I have read and agree to the ", },
        recap7: {label: "I agree to receive promotional offers from Banque Populaire", },
        recap8: {label: "Your request will be allocated to the branch", },
        recap9: {label: "JUST A FEW MORE CLICKS TO VALIDATE YOUR APPLICATION", },
        recap10: {label: "We have received your application", },
        recap11: {label: "In order to finalise your application, please click on the link that you have received by e-mail. It will allow you to set your password to log in to your personal area and validate your application", },
        recap12: {label: "RETURN TO HOME PAGE", },
        recap13: {label: "Would you like to carry out another simulation or receive information concerning the next steps?", },
        recap14: {label: "PROGRESS OF MY APPLICATION", },
        recap15: {label: "Would you like to see how your application is progressing?", },
        recap16: {label: "employer", },
        recap17: {label: "income", },
        recap18: {label: "State of the asset", },
        recap19: {label: "situated at", },
        recap20: {label: "et j'accepte les termes et", },
        recap21: {label: "LOG IN TO YOUR PERSONAL AREA", },
        recap22: {label: "Please enter your log-in details", },
        recap23: {label: "Password forgotten", },
        recap24: {label: "log in", },
        recap25: {label: "Récupérer", },
        recap26: {label: "Créer un espace", },
        recap27: {label: "Saisissez votre identifiant", },
        recap28: {label: "Please enter your ID", },
        recap29: {label: "log-in / e-mail /root /…", },
        recap30: {label: "password", },
        recap31: {label: "Remember me", },
        recap32: {label: "RESULT OF MY SIMULATION", },
        recap33: {label: "GIVEN BELOW ARE DETAILS OF THE COSTS RELATING TO YOUR APPLICATION FOR A HOUSING LOAN", },
        recap34: {label: "LOAN AMOUNT", },
        recap35: {label: "LOAN DURATION", },
        recap36: {label: "RATE (EXCLUDING VAT)", },
        recap38: {label: "ALL-CAUSES DEATH INSURANCE", },
        recap39: {label: "FIRE AND EXPLOSION INSURANCE", },
        recap40: {label: "TOTAL COST OF THE LOAN", },
        recap41: {label: "Moroccan dirhams(Interest)", },
        recap42: {label: "Moroccan dirhams(Insurance)", },
        recap43: {label: "Moroccan dirhams(Application fee)", },
        recap44: {label: "The result of this simulation is non-binding and is for information purposes only", },
        recap45: {label: "MONTHLY PAYMENT INCLUDING VAT", },
        recap46: {label:"YOUR MONTHLY PAYMENT OVER:", },
        recap47: {label: "Amortisation - Graphic", },
        chart1: {label: "Capital", },
        chart2: {label: "Interests", },
        chart3 :{label:"Yrs"},
      chart4 :{label:"Years"},
      propos :{label:"please select"},
      footer : {label: "Copyright @ 2022 Groupe Banque Populaire - "},
      mleg :{label:"Legal notices"},
      },
	},
	  ar: {
      translation: {
		    slider1: { label: 'سكن مبروك رسوم الملف مهداة للأشخاص الذين يقل عمرهم عن 35 سنة', },
        sousSlider1: { label: null },
        slider2: { label: 'سكن مبروك رسوم الملف مهداة للأشخاص الذين يقل عمرهم عن 35 سنة', },
        sousSlider2: { label: null, },
        slider3: { label: 'سكن مبروك رسوم الملف مهداة للأشخاص الذين يقل عمرهم عن 35 سنة', },
        sousSlider3: { label: null, },
        pret: { label: 'طلب قرض', },
        contacter: { label: 'اتصلوا بنا', },
        espacePerso: { label: 'الفضاء الشخصي', },
        HeaderAuth : { label:'هل أنتم من زبناء البنك الشعبي*؟'},
        oui:{label:'نعم'},
        non:{label:' لا'},
        etoile:{label:'مع اشتراك في الشعبي نت* '},
        gammeOffre :{label:'يقدم لكم البنك الشعبي مجموعة من القروض العقارية التي تناسب احتياجاتكم، وملفكم الشخصي وميزانيتكم'},
        formulaire:{label:'استمارة طلب القرض'},
        produitBP :{label:'يضع البنك الشعبي رهن إشارتكم، على الأنترنت أو بالوكالة، تشكيلة من المنتجات'},
        step01:{label:'بيانات الاتصال الخاصة بكم'},
        step02:{label:'معلومات شخصية'},
        step03:{label:'معلومات عن المشروع'},
        step04:{label:'ملخص'},
        titleForm1 :{label:'أقوم بملء بياناتي'},
        subtitleForm1 : {label:'يرجى ملء جميع الحقول'},
        att1Form1 : {label:'هل أنتم زبون لدينا؟'},
        civiliteM : {label:'السيد'},
        civiliteMme : {label:'السيدة'},
        att2Form1 :{label:'الجنس'},
        att3Form1 :{label:'الاسم العائلي'},
        att4Form1 :{label:'الاسم الشخصي'},
        att5Form1 :{label:'البريد الإلكتروني'},
        att6Form1 :{label:'  (+رقم الهاتف النقال (مثال: ******** 6 212  '},
        suivant:{label:'التالي'},
        avantage: { label: 'أرسلوا طلب التمويل الخاص بكم واستفيدوا من تجربة شخصية على الإنترنت أو في الوكالة'},
        titleAv: {label: 'المزايا', },
        titleAv2: {label: 'مسار مبسط', },
        titleAv3: {label: 'قوموا بشرح مشروعكم بنقرات قليلة، وسيتكلف مستشارونا بالباقي', },
        titleAv4: {label: 'عرض شخصي', },
        titleAv5: {label: 'استفيدوا من عرض شخصي يتناسب مع احتياجاتكم', },
        titleAv6: {label: 'تتبع الطلب عبر الأنترنت', },
        titleAv7: {label: 'تابعوا مراحل تطور معالجة ملفكم عبر فضائكم الشخصي', },
        titleAv8: {label: 'جدول محاكاة مفصل', },
        titleAv9: {label: 'قوموا بمحاكاة قدرتكم الاقتراضية، ومبلغكم الشهري والمصاريف ذات الصلة', },
        titleAv10: {label: 'طلبي الخاص بالقرض العقاري على الانترنت في 4 خطوات', },
        titleAv11: {label: '.البنك الشعبي يسهّل تمويل مشروعكم العقاري', },
        titleAv12: {label: 'استفيدوا من مواكبة شخصية، ومن معالجة سريعة وشفافة', },
        titleAv13: {label: 'المحاكاة و إرسال الطلب', },
        titleAv14: {label: 'أجري عمليات المحاكاة الخاصة بي، وأسجل معلوماتي وتلك الخاصة بمشروعي، ثم أرسل طلبي', },
        titleAv15: {label: 'الإجابة على طلبكم', },
        titleAv16: {label: 'أتلقى الإجابة على طلبي عبر الأنترنت', },
        titleAv17: {label: 'تقديم الوثائق الداعمة', },
        titleAv18: {label: 'أقدم الوثائق الداعمة الخاصة بي لوكالتي', },
        titleAv19: {label: 'توقيع العقد', },
        titleAv20: {label: 'أوقع عقدي الخاص بالقرض العقاري', },
        titleAv21: {label: 'قروضنا سكن مبروك', },
        titleAv22: {label: 'يقدم لكم البنك الشعبي مجموعة من القروض العقارية التي تناسب احتياجاتكم، وملفكم الشخصي وميزانيتكم', },
        titleAv23: {label: 'لمزيد من التفاصيل زوروا موقعنا المؤسساتي', },
        titleAv24: {label: 'التفاصيل', },
        titleAv25: {label: 'الأسئلة الشائعة', },
        titleAv26: {label: 'انقروا على الأسئلة الشائعة، ستجدون هناك إجابات على الأسئلة الأكثر شيوعاً حول طلب القرض العقاري عبر الأنترنت', },
        titleAv27: {label: 'هل يجب علي أن أكون زبوناً للبنك الشعبي من أجل طلب قرض ؟', },
        titleAv28: {label: 'ليس من الضروري أن تكونوا زبوناً للبنك الشعبي لتتمكنوا من التقدم بطلب من أجل الحصول على قرض عقاري. بمجرد أن يحظى طلبكم بالقبول، سيطلب منكم فتح حساب، في وكالة من اختياركم، كتمهيد للتعاقد', },
        titleAv29: {label: 'هل أحتاج للذهاب إلى الوكالة من أجل الحصول على إجابة على طلبي ؟', },
        titleAv30: {label: 'يمكنكم إنجاز طلبكم وإرساله عبر الأنترنت بنسبة 100٪. بعد فترة المعالجة، ستتوصل باقتراح تمويل، في حالة القبول، بناءً على المعلومات المقدمة. كما سيتم إبلاغكم أيضاً في حالة الرفض', },
        titleAv32: {label: 'هل يمكنني الاستفادة من التتبع عبر الأنترنت لطلب تم تقديمه لدى الوكالة ؟', },
        titleAv33: {label: 'يمكنكم الاستفادة من تتبع مراحل معالجة ملفكم، سواء قدمتم طلبكم عبر موقعنا الإلكتروني أو مباشرة لدى إحدى وكالاتنا', },
        titleAv34: {label: 'Est-ce que la réponse à ma demande est définitive ?', },
        titleAv35: {label: 'La réponse donnée par la banque est basée sur les informations saisies lors de votre demande. Elle n’est valable que si vous nous fournissez les documents prouvant la véracité de l’ensemble des informations saisies ainsi que les documents de garanties listés dans la proposition de financement.', },
        titleAv36: {label: 'Quelle est la durée de validité de la proposition de financement ?', },
        titleAv37: {label: 'عرض المزيد', },
        titleAv38: {label: 'عرض أقل', },
        titleAv39: {label: 'اتصلوا بنا', },
        titleAv40: {label: 'هل أنتم بحاجة إلى معلومات أو مساعدة ؟', },
        titleAv41: {label: 'يمكنكم أيضا التواصل مع مركز العلاقة مع', },
        titleAv42: {label: 'الزبناء الخاص بنا ', },
        titleAv43: {label: 'من الاثنين إلى السبت ابتداءً من الساعة 9 صباحاً حتى الساعة 8 مساءً', },
        titleAv44: {label: 'سعر مكالمة محلية', },
        titleAv45: {label: 'الرئيسية', },
        titleAv46: {label: 'اتصلوا بنا', },
        titleAv47: {label: 'شروط الاستخدام', },
        titleAv48: {label: 'البنك الشعبي المركزي - 101 شارع محمد الزرقطوني', },
        titleAv49: {label: 'ص.ب. 10.622 - 20100', },
        titleAv50: {label: 'الدار البيضاء', },
        titleAv51: {label: 'كيف أعرف مصير معالجة طلب القرض الخاص بي؟', },
        simulateur1: {label: 'جدول المحاكاة', },
        simulateur2: {label: 'المبلغ', },
        simulateur3: {label: 'المدة', },
        simulateur4: {label: 'المؤجل', },
        simulateur44: {label: '(المؤجل (الشهر', },
        simulateur5: {label: '(سعر الفائدة (دون احتساب الرسوم', },
        simulateur6: {label: 'الاستحقاق مع احتساب جميع الرسوم', },
        simulateur7: {label: 'الدرهم', },
        simulateur8: {label: 'السنوات', },
        simulateur9: {label: 'الشهر', },
        simulateur10: {label: 'جدول محاكاة قدرتكم الاقتراضية', },
        simulateur11: {label: 'تقديم طلب للحصول على قرض', },
        simulateur12: {label: 'الدخل الشهري الصافي', },
        simulateur13: {label: 'استحقاق قروض أخرى', },
        simulateur14: {label: 'القدرة الاقتراضية', },
        simulateur15: {label: "اختيار المبلغ", },
        simulateur16: {label: "نتيجة هذه المحاكاة غير تعاقدية وذات طبيعة إعلامية محضة", },
        simulateur17: {label: "اختاروا المبلغ الذي تريدون اقتراضه لمعرفة استحقاقكم",},
        simulateur18: {label: "اختاروا مدة السداد الإجمالية للقرض المطلوب",},
        simulateur19: {label: "لا تريدون بدء الأداء على الفور؟ اختر مدة المؤجل (خلوص) التي تناسبك",},
        simulateur20: {label: "تتم الإشارة إلى سعر الفائدة المحدد كمؤشر، بحيث يمكنكم من إجراء مختلف عمليات المحاكاة الخاصة بكم دون أن يكون ملزما للبنك. وسيتم إبلاغكم بسعر الفائدة الذي يناسب بياناتكم الشخصية بعد دراسة ملفكم",},
        simulateur21: {label: "إن الاستحقاق هو المبلغ الذي يجب سداده وفقاً للوتيرة المحددة. يتم حساب الاستحقاق على أساس المبلغ، والمدة، وسعر الفائدة وكذا المؤجل",},
        simulateur22: {label: "يرجى إدخال مجموع صافي دخلكم الشهري",},
        simulateur23: {label: "يرجى تحديد مجموع الاستحقاقات المدفوعة شهرياً للقروض المتعاقد عليها مع مختلف المؤسسات المالية",},
        simulateur24: {label: "اختر مدة السداد الإجمالية للقرض المطلوب",},
        simulateur25: {label: "تتم الإشارة إلى سعر الفائدة المحدد كمؤشر، بحيث يمكنكم من إجراء مختلف عمليات المحاكاة الخاصة بكم دون أن يكون ملزما للبنك. وسيتم إبلاغكم بسعر الفائدة الذي يناسب بياناتكم الشخصية بعد دراسة ملفكم",},
        simulateur26: {label: "قدرة الاقتراض هي الحد الأقصى للمبلغ الذي تريدون اقتراضه بناءً على صافي الدخل الشهري.يعتمد حساب قدرة الاقتراض على صافي الدخل الشهري والمدة والسعر والمؤجل",},
        infoPerso1: {label: "أقوم بملء معلوماتي الشخصية", },
        infoPerso2: {label: "تاريخ الميلاد", },
        infoPerso3: {label: "بلد الإقامة", },
        infoPerso4: {label: "بطاقة التعريف", },
        infoPerso5: {label: "رقم بطاقة التعريف", },
        infoPerso6: {label: "...اختيار", },
        infoPerso7: {label: "الحالة العائلية", },
        infoPerso8: {label: "عدد الأطفال", },
        infoPerso9: {label: "الفئة الاجتماعية المهنية", },
        infoPerso10: {label: "طبيعة العقد", },
        infoPerso12: {label: "المشغل / الشركة", },
        infoPerso13: {label: "( الراتب / الدخل الثابت الأساسي (الصافي الشهري   ", },
        infoPerso14: {label: "المهنة", },
        infoPerso15: {label:   "( سنوات الأقدمية (المشغل الحالي", },
        infoPerso16: {label: "القطاع", },
        infoPerso17: {label: "مداخيل شهرية أخرى", },
        infoPerso18: {label: "Source autres revenus", },
        infoPerso19: {label: "استحقاق القروض الأخرى", },
        infoPerso20: {label: "عقد غير محدد المدة", },
        infoPerso21: {label: "عقد محدد المدة", },
        infoPerso22: {label: "عام", },
        infoPerso23: {label: "خاص", },
        infoPerso24: {label: "أنتم", },
        infoPerso25: {label: "تاريخ التوظيف", },
        precedent: {label: "السابق", },
        infoProjetEvent: {label: `"حدث - حملة مرتبطة بطلب القرض"`, },
        subtitleFormEvent1: {label: "يرجى اختيار أو تحديث الحدث - الحملة المرتبطة بهذا الطلب", },
        subtitleFormEvent2: {label: "* حدد ما إذا كان هذا الطلب مرتبطًا ببرنامج المساعدة المباشرة للإسكان", },
        recapEvent: {label: "اختيار حدث - حملة", },
        recapEventIcon: {label: "انقر لإزالة الارتباط بين هذا الطلب وحدث - حملة", },
        recapEventLabel: {label: "حدث - حملة", },
        infoProjet1: {label: "أقوم بملء المعلومات حول العقار", },
        infoProjet2: {label: "حالة المسكن", },
        infoProjet3: {label: "موضوع الطلب", },
        infoProjet4: {label: "استخدام العقار", },
        infoProjet5: {label: "سعر العقار", },
        infoProjet6: {label: "المساحة", },
        infoProjet7: {label: "موقع العقار", },
        infoProjet8: {label: "المنعش", },
        infoProjet9: {label: "ملء المعلومات عن الطلب", },
        infoProjet10: {label: "مبلغ القرض المطلوب", },
        infoProjet11: {label: "المدة المطلوبة", },
        infoProjet12: {label: "Type de taux", },
        infoProjet13: {label: "Durée du différé", },
        infoProjet14: {label: "( المساهمة الشخصية (باستثناء القروض", },
        infoProjet15: {label: "مبلغ تمويل آخر", },
        infoProjet16: {label: "تقدير التكاليف المرتبطة بالمشروع", },
        infoProjet17: {label: "متر مربع", },
        infoProjet18: {label: "المنعش", },
        recap1: {label: "ملخص طلب القرض الخاص بكم", },
        recap2: {label: "يرجى التحقق من جميع المعلومات المقدمة", },
        recap3: {label: "بيانات الاتصال", },
        recap4: {label: "معلوماتي الشخصية", },
        recap5: {label: "معلومات عن المشروع", },
        recap6: {label: "لقد قرأت", },
        recap7: {label: "أوافق على تلقي العروض الترويجية من البنك الشعبي", },
        recap8: {label: "سيتم إحالة طلبكم على الوكالة", },
        recap9: {label: "بضع نقرات أخرى ويتم تأكيد طلبكم", },
        recap10: {label: "تلقينا طلبكم بنجاح", },
        recap11: {label: "لإتمام طلبكم، ندعوكم للنقر على الرابط المستلم عبر البريد الإلكتروني والذي يمكنكم من وضع كلمة سر من أجل تسجيل الدخول في فضائكم الشخصي وتأكيد طلبكم", },
        recap12: {label: "العودة إلى الصفحة الرئيسية", },
        recap13: {label: "هل ترغبون في إعادة المحاكاة أو الحصول على معلومات حول الخطوات التالية؟", },
        recap14: {label: "مراحل تقدم طلبي", },
        recap15: {label: "هل ترغبون في الاطلاع على مراحل تقدم طلبكم؟", },
        recap16: {label: "المشغل", },
        recap17: {label: "الدخل", },
        recap18: {label: "حالة العقار", },
        recap19: {label: "تقع في", },
        recap20: {label: "وأوافق عليها", },
        recap21: {label: "سجلوا دخولكم إلى فضائكم الشخصي", },
        recap22: {label: "يرجى إدخال بيانات تسجيل الدخول الخاصة بكم", },
        recap23: {label: "نسيت كلمة السر", },
        recap24: {label: "تسجيل الدخول", },
        recap25: {label: "Récupérer", },
        recap26: {label: "Créer un espace", },
        recap27: {label: "Saisissez votre identifiant", },
        recap28: {label: "يرجى إدخال اسم المستخدم الخاص بكم", },
        recap29: {label: " ... / تسجيل الدخول/البريد الإلكتروني/جذر الحساب", },
        recap30: {label: "كلمة السر", },
        recap31: {label: "تذكرني", },
        recap32: {label: "نتيجة المحاكاة الخاصة بي", },
        recap33: {label: "أدناه تفاصيل التكاليف المتعلقة بطلب القرض العقاري الخاص بكم", },
        recap34: {label: "مبلغ القرض المطلوب", },
        recap35: {label: "مدة القرض", },
        recap36: {label: "(سعر الفائدة (دون احتساب الرسوم", },
        recap38: {label: "تأمين على الوفاة لجميع الأسباب", },
        recap39: {label: "تأمين على الحريق أو الانفجار", },
        recap40: {label: "الكلفة الإجمالية للقرض", },
        recap41: {label: "الفوائد)الدرهم) ", },
        recap42: {label: "التأمين)الدرهم)", },
        recap43: {label: "مصاريف الملف)الدرهم)", },
        recap44: {label: "نتيجة هذه المحاكاة غير تعاقدية وذات طبيعة إعلامية محضة", },
        recap45: {label: "المبلغ الشهري مع احتساب جميع الرسوم", },
        recap46: {label:": المبلغ الشهري على", },
        recap47: {label: "الاستخماد - الرسم البياني", },
        chart1: {label: "رأس المال", },
        chart2: {label: "الفائدة", },
        chart3 :{label:"سنة"},
        chart4 :{label:"سنة"},
        propos :{label:"اختيار"},
        footer : {label: "Copyright @ 2022 Groupe Banque Populaire - "},
        mleg :{label:"Mentions légales"},
      },
      },
	},
  });

export default i18next;

import { connect } from 'react-redux/dist/react-redux';
import Header from '../components/Inputs/Header';
import { showHide, inputChange, scrolllToSection } from '../action_creators';

function mapStateToProps(state) {
  const popin = state.demandeCredit.getIn(['popinItem', 'value']);

  return {
    showHideElm: state.demandeCredit.getIn(['isChaabiClient', 'value']),
    origin: state.demandeCredit.getIn(['origin', 'value']),
    authenticationStatus: state.account.get('authenticationStatus'),
    userId: state.account.getIn(['identifiant', 'value']) || "",
    langues: state.demandeCredit.getIn(['langues', 'value']) || "fr",
    userStatus: state.account.get('authenticationStatus') === "success" ? "logged": "not-logged",
    popin,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    show: (s) => dispatch(showHide(s)),
    inputChange: (value, key) => dispatch(inputChange(value, key)),
    scrolllToSection: (source, id) => dispatch(scrolllToSection(source, id))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

/* eslint-disable */
import React, { PureComponent } from "react";
import SlideBar from "../containers/Inputs/SlideBar";
import SlideElements from "./Inputs/SlideElements";
import Slider from "react-slick-fix/lib/slider";
import Faq from "./Accueil/FAQ";
import Crc from "./Accueil/Crc";
import Footer from "./Inputs/Footer";
import Header from "../containers/Header";
import Parcours from "../containers/Inputs/Parcours";
import DetailCapacite from "../components/DetailCapacite";
import Produits from "./Inputs/Produits";
import Avantages from "../containers/Inputs/Avantages";
import image from "../assets/images/img/Visuel_JDP.jpeg";

import HeaderAuth from "../containers/HeaderAuth";
import * as utiles from "../utile/utile";
import Img1 from "../assets/images/info_txt.png";
import AccordionSum from "./Inputs/AccordionSum";
import i18n from "../i18n";
import {isLoged} from '../utile/utile';
import TagManager from 'react-gtm-module';

const option1 = { numeral: true, numeralDecimalMark: ".", delimiter: " " };
const option2 = { blocks: [2] };
const option3 = { numeral: true, numeralDecimalScale: 2 };
const option5 = { numeral: true, numeralDecimalMark: ".", delimiter: " " };
const divStyle = { display: "block", height: "470px", transition: "all 0.5s " };
const lienStyle = { marginRight: "28%", float: "right" };
const divStyle2 = { height: "0px", opacity: "0", display: "block", "padding-top": "0px", "transition-timing-function": "ease", transition: "all 0.5s ", "padding-bottom": "0px" };
class Accueil extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      divStyle1: divStyle,
      divStyle4: divStyle2
    };
    this.toggle = this.toggle.bind(this);
    this.click1 = this.click1.bind(this);
    this.shoHidePop = this.shoHidePop.bind(this);
  }
  componentWillMount() {
    window.scrollTo(0, 0);
    this.props.cleanStore();
    this.props.initialAccout();
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {
    // if (
    //   prevProps.revenuNetMensuel !== this.props.revenuNetMensuel ||
    //   prevProps.autreCredit !== this.props.autreCredit ||
    //   prevProps.duree !== this.props.duree ||
    //   prevProps.tauxHt !== this.props.tauxHt
    // ) {
    //   this.simulateurCapaciteEmprunt();
    // }

    if (
      prevProps.revenuNetMensuel !== this.props.revenuNetMensuel ||
      prevProps.autreCredit !== this.props.autreCredit ||
      prevProps.duree !== this.props.duree ||
      prevProps.tauxHt !== this.props.tauxHt
    ) {
      this.simulateurCapaciteEmprunt();
    }
    if (prevProps.langues !== this.props.langues) {
      i18n.changeLanguage(this.props.langues);
      this.forceUpdate();

    }

  }
  /**
   * calcul capacité d'emprunt
   */
  simulateurCapaciteEmprunt() {
    let capaciteEmprunt = utiles.capaciteEmprunt(
      this.props.revenuNetMensuel,
      this.props.autreCredit,
      this.props.duree,
      this.props.tauxHt
    );
    this.props.inputChange(
      capaciteEmprunt.toFixed(2),
      "simulateur.capaciteEmprunt"
    );
  }
  clickAccord = (id, event) => {
    if (id === 1) {
      this.setState({ divStyle4: divStyle2 });
      if (this.state.divStyle1 && this.state.divStyle1 === divStyle)
        this.setState({ divStyle1: divStyle2 });
      else this.setState({ divStyle1: divStyle });
    } else if (id === 2) {
      this.setState({ divStyle1: divStyle2 });
      if (this.state.divStyle4 && this.state.divStyle4 === divStyle)
        this.setState({ divStyle4: divStyle2 });
      else this.setState({ divStyle4: divStyle });
    }
    this.scrollToSection(event, "#section")
  };
  click1() {
    const { capaciteEmpruntfinal, duree } = this.props;
    this.props.inputChange(capaciteEmpruntfinal, "simulateur.montantCredit");
    this.props.inputChange(duree, "simulateur.nbAnnee");
    this.props.inputChange(0, "simulateur.dureeDiff");
    this.props.inputChange("simulateur", 'demandeCredit.origin');
  }
  shoHidePop(s) {
    this.props.showHide(s);
  }
  shoHide(s) {
    this.props.showHide(s);
  }
  showHide() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  toggle() {
    TagManager.dataLayer({
      dataLayer: isLoged(this.props.userId, {
        event:"simulateur_capacite",
        item: "choisir_mon_montant",
        sc_revenu:this.props.revenuNetMensuel,
        sc_echeanche_ac: this.props.echeance,
        sc_duree: this.props.nbAnnee,
        sc_taux: this.props.taux,
        sc_cap_emprunt: this.props.capaciteEmprunt,
        langue: this.props.langues,
        userStatus: this.props.userStatus,
        BU: "particulier"
      })
    })
    this.props.valueToggle(this.props.toggled);
  }
  scrollToSection = (e, selector) => {
    e.preventDefault();
    document.querySelector(selector) && document.querySelector(selector).scrollIntoView({
      behavior: "smooth",
    });
  };
  render() {
    const settings = {
      dots: true,
      infinite: false,
      arrows: false,
      slidesToShow: 1,
      // variableWidth: true,
      slidesToScroll: 1,
      respondTo: "window",
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    const isHome = true;
    return (
      <div
        id="account_opening_steps"
        className=""
        horizontalAlignment="AlignLeft"

      >
        <Header scrollToSection={this.scrollToSection} isHome />
        <section id="accueil_section" className="align_center main_body">
          <div className="sliders_container">
            <div className="global_slider_container">
              <Slider {...settings}>
                <div className="acceuil_slide">
                  <SlideElements
                    isAcceuil
                    src1={image}
                    subTitle={i18n.t("slider1.label")}
                    desc={i18n.t("sousSlider1.label")}
                    legalien="#/coordonnees"
                  />
                </div>
                <div className="acceuil_slide">
                  <SlideElements
                    isAcceuil
                    src1={image}
                    subTitle={i18n.t("slider2.label")}
                    desc={i18n.t("sousSlider2.label")}
                    lien="#/infos-perso"
                  />
                </div>
                <div className="acceuil_slide">
                  <SlideElements
                    isAcceuil
                    src1={image}
                    subTitle={i18n.t("slider3.label")}
                    desc={i18n.t("sousSlider3.label")}
                    lien="#/detail-simulation"
                  />
                </div>
              </Slider>
            </div>
            {this.props.toggled ? (
              <ul className="accordion_list simulateur_position">
                <AccordionSum
                  title={i18n.t("simulateur1.label")}
                  classNamesecond="accordion_content"
                  style={this.state.divStyle1}
                  onClick={(e) => this.clickAccord(1, e)}
                >
                  <SlideBar
                    min={0}
                    max={20000000}
                    step={50000}
                    typeLibelle={i18n.t("simulateur7.label")}
                    libelle={i18n.t("simulateur2.label")}
                    storeKey="simulateur.montantCredit"
                    tooltipTxt={i18n.t("simulateur17.label")}
                  />
                  <SlideBar
                    min={5}
                    max={25}
                    step={1}
                    typeLibelle={i18n.t("simulateur8.label")}
                    libelle={i18n.t("simulateur3.label")}
                    storeKey="simulateur.nbAnnee"
                    tooltipTxt={i18n.t("simulateur18.label")}
                  />
                  <SlideBar
                    min={0}
                    max={24}
                    step={1}
                    typeLibelle={i18n.t("simulateur9.label")}
                    libelle={i18n.t("simulateur4.label")}
                    storeKey="simulateur.dureeDiff"
                    tooltipTxt={i18n.t("simulateur19.label")}
                  />
                  <SlideBar
                    min={2}
                    max={10}
                    step={0.01}
                    typeLibelle="%"
                    libelle={i18n.t("simulateur5.label")}
                    libelleInfo="(ht)"
                    storeKey="simulateur.taux"
                    tooltipTxt={i18n.t("simulateur20.label")}
                  />
                  <SlideBar
                    min={0}
                    max={300000}
                    step={100}
                    typeLibelle={i18n.t("simulateur7.label")}
                    libelle={i18n.t("simulateur6.label")}
                    libelleInfo="(ttc)"
                    storeKey="simulateur.echeance"
                    disabled
                    tooltipTxt={i18n.t("simulateur21.label")}
                  />
                  <div className="btns_action_area">
                    <a
                      href="#/detail-resultat"
                      data-text={i18n.t("titleAv24.label")}
                      className="flat_btn white_btn right_mr"
                      onClick={()=> {
                        TagManager.dataLayer({
                          dataLayer: isLoged(this.props.userId, {
                            event:"simulateur_pret",
                            item: "detail",
                            sp_montant:this.props.montantCredit,
                            sp_duree: this.props.nbAnnee,
                            sp_differe: this.props.dureeDiff,
                            sp_taux: this.props.taux,
                            sp_echeanche: this.props.echeance,
                            langue: this.props.langues,
                            userStatus: this.props.userStatus,
                            BU: "particulier"
                          })
                        })
                      }}
                    >
                      <span className="plusDepadding">{i18n.t("titleAv24.label")}</span>
                    </a>
                    <button
                      data-text={i18n.t("simulateur11.label")}
                      className="flat_btn purple_btn demandeBtn"
                      onClick={() => {
                        this.shoHidePop("demandeCredit.isChaabiClient");
                        this.props.inputChange("simulateur", "demandeCredit.origin")
                        TagManager.dataLayer({
                          dataLayer: isLoged(this.props.userId, {
                            event:"simulateur_pret",
                            item: "demande_de_pret",
                            sp_montant:this.props.montantCredit,
                            sp_duree: this.props.nbAnnee,
                            sp_differe: this.props.dureeDiff,
                            sp_taux: this.props.taux,
                            sp_echeanche: this.props.echeance,
                            langue: this.props.langues,
                            userStatus: this.props.userStatus,
                            BU: "particulier"
                          })
                        })
                      }}
                    >
                      <span className="plusDepadding">
                        {i18n.t("simulateur11.label")}
                      </span>
                    </button>
                    <p className="info_txt">
                      <img src={Img1} alt="" />
                      <span>
                        {i18n.t("simulateur16.label")}
                      </span>
                    </p>
                  </div>
                </AccordionSum>
                <AccordionSum
                  title={i18n.t("simulateur10.label")}
                  classNamefirst="accordion_title accor_simulator simulateur_inverse"
                  classNamesecond="accordion_content"
                  style={this.state.divStyle4}
                  onClick={(e) => this.clickAccord(2, e)}
                >
                  <SlideBar
                    min={0}
                    max={200000}
                    step={100}
                    options={option1}
                    typeLibelle={i18n.t("simulateur7.label")}
                    libelle={i18n.t("simulateur12.label")}
                    storeKey="simulateur.revenuNetMensuel"
                    tooltipTxt={i18n.t("simulateur22.label")}
                  />
                  <SlideBar
                    min={0}
                    max={10000}
                    step={500}
                    options={option5}
                    typeLibelle={i18n.t("simulateur7.label")}
                    libelle={i18n.t("simulateur13.label")}
                    libelleInfo="(ttc)"
                    storeKey="simulateur.autreCredit"
                    tooltipTxt={i18n.t("simulateur23.label")}
                  />
                  <SlideBar
                    min={0}
                    max={25}
                    step={1}
                    options={option2}
                    typeLibelle={i18n.t("simulateur8.label")}
                    libelle={i18n.t("simulateur3.label")}
                    storeKey="simulateur.duree"
                    tooltipTxt={i18n.t("simulateur24.label")}
                  />
                  <SlideBar
                    min={0}
                    max={12}
                    step={0.01}
                    options={option3}
                    typeLibelle="%"
                    libelle={i18n.t("simulateur5.label")}
                    libelleInfo="(ht)"
                    storeKey="simulateur.tauxHt"
                    tooltipTxt={i18n.t("simulateur25.label")}
                  />
                  <SlideBar
                    max={6740696.4}
                    min={0}
                    step={100}
                    options={option5}
                    typeLibelle={i18n.t("simulateur7.label")}
                    libelle={i18n.t("simulateur14.label")}
                    storeKey="simulateur.capaciteEmprunt"
                    tooltipTxt={i18n.t("simulateur26.label")}
                  />
                  <div className="align_center btns_action_area">
                    <button
                      onClick={this.toggle}
                      data-text={i18n.t("simulateur15.label")}
                      className="flat_btn purple_btn choix_mnt"
                    >
                      <span className="plusDepaddings">
                        {i18n.t("simulateur15.label")}
                      </span>
                    </button>
                    <p className="info_txt">
                      <img src={Img1} alt="" />
                      <span>
                        {i18n.t("simulateur16.label")}
                      </span>
                    </p>
                  </div>
                </AccordionSum>
              </ul>
            ) : (
                <DetailCapacite
                  onClick={this.click1}
                  max={this.props.capaciteEmprunt}
                  toggle={this.toggle}
                />
              )}

          </div>

          <Avantages />
          <Parcours />
          <Produits />
          <Faq scrollToSection={this.scrollToSection} {...this.props} />
          <div className="crc_container">
            <Crc {...this.props} />
          </div>
        </section>
        <Footer scrollToSection={this.scrollToSection} isHome {...this.props} />
        {this.props.isChaabiClient ? null : <HeaderAuth />}
      </div>
    );
  }
}

Accueil.propTypes = {
  calculEcheance: React.PropTypes.func,
  inputChange: React.PropTypes.func,
  cleanStore: React.PropTypes.func,
  calculInverse: React.PropTypes.func,
  valueToggle: React.PropTypes.func,
  montantCredit: React.PropTypes.number,
  capaciteEmprunt: React.PropTypes.number,
  nbAnnee: React.PropTypes.number,
  taux: React.PropTypes.number,
  dureeDiff: React.PropTypes.number,
  echeance: React.PropTypes.number,
  resultat: React.PropTypes.number,
  toggled: React.PropTypes.bool,
  simulateurCapaciteEmprunt: React.PropTypes.func,
  revenuNetMensuel: React.PropTypes.number,
  autreCredit: React.PropTypes.number,
  duree: React.PropTypes.number,
  capaciteEmpruntfinal: React.PropTypes.number,
  tauxHt: React.PropTypes.number,
  initialAccout: React.PropTypes.func,
  showHide: React.PropTypes.func,
  isChaabiClient: React.PropTypes.bool
};

export default Accueil;

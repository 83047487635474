import { connect } from 'react-redux/dist/react-redux';
import TimelinePure from '../components/Timeline/Timeline';

function mapStateToProps(state) {
  return {
    langues: state.demandeCredit.getIn(['langues', 'value']), 
    steps: state.timeline.get('steps'),
    current: state.timeline.get('current'),
  };
}

const Timeline = connect(mapStateToProps, null, null)(TimelinePure);

export default Timeline;

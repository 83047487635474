import React, { Component } from 'react';
import crown1 from '../assets/images/crown1.png';
import crown2 from '../assets/images/crown2.png';
import crown3 from '../assets/images/crown3.png';
import crown4 from '../assets/images/crown4.png';
import i18n from '../i18n';

class Avantages extends Component {
  render() {
    return (
      <div className="avantage_container">
        <div className="title" id="title_container">
          <div className="avantages_title mb50" id="titre">
            <div>
              <h2 className="step_big_title white-txt">{i18n.t("titleAv.label")}</h2>
            </div>
            <hr className="hr_header white_bg" />
            <p className="title_desc mb35 white-txt">{i18n.t("avantage.label")}
            </p>
          </div>
          <ul className="avantages_blocs">
            <li>
              <span className="avantages_img">
                <img src={crown1} alt="" />
              </span>
              <br />
              <span className="avantage_header white-txt">
              {i18n.t("titleAv2.label")}
              </span>
              <span className="avantage_txt white-txt">
              {i18n.t("titleAv3.label")}
              </span>
            </li>
            <li>
              <span className="avantages_img">
                <img src={crown2} alt="" />
              </span>
              <br />
              <span className="avantage_header white-txt">
              {i18n.t("titleAv4.label")} 
              </span>
              <span className="avantage_txt white-txt">
              {i18n.t("titleAv5.label")} 
              </span>
            </li>
            <li>
              <span className="avantages_img">
                <img src={crown3} alt="" />
              </span>
              <br />
              <span className="avantage_header white-txt">
              {i18n.t("titleAv6.label")}
              </span>
              <span className="avantage_txt white-txt">
              {i18n.t("titleAv7.label")}
              </span>
            </li>
            <li>
              <span className="avantages_img">
                <img src={crown4} alt="" />
              </span>
              <br />
              <span className="avantage_header white-txt">
              {i18n.t("titleAv8.label")}
              </span>
              <span className="avantage_txt white-txt">
              {i18n.t("titleAv9.label")}
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Avantages;

/* eslint-disable */
import React, { Component } from 'react';
import i18n from '../../i18n';
class Produits extends Component {
  render() {
    return (
      <div className="produit_container align_center unprentable">
        <div className="block_title">
          <h2 className="step_big_title white-txt">{i18n.t("titleAv21.label")}</h2>
          <hr className="hr_header white_bg mb30" />
          <p className="light_section_desc white-txt mb30">
          {i18n.t("titleAv22.label")}
            <br />
            {i18n.t("titleAv23.label")}
          </p>
          <a href="https://particulier.groupebcp.com/fr/Pages/acquerir-un-logement.aspx" data-text={i18n.t("titleAv24.label")} className="flat_btn white_btn more_padding" target="_blank">
            <span>{i18n.t("titleAv24.label")}</span></a>
        </div>
      </div>
    );
  }
}

export default Produits;

import React, { Component } from 'react';
import { inputChange } from '../../action_creators';

class Radio extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.isChecked = this.isChecked.bind(this);
  }

  componentWillMount() {
    if (!this.props.value) {
      const defaultItem = this.props.items.find(item => item.checked);
      if (defaultItem || (this.props.items.length > 0 && !this.props.multiple)) {
        const value = defaultItem ? defaultItem.value : this.props.items[0].value;
        this.props.dispatch(inputChange(value, this.props.storeKey));
      }
    }
  }

  onChange(event) {
    let value;
    if (this.props.multiple) {
      value = this.props.items
        .map(item => (this.refs[`item:${item.label}`].checked ? item.value : null))
        .filter(v => v != null);
    } else {
      value = event.target.value;
    }
    return this.props.dispatch(inputChange(value, this.props.storeKey));
  }

  isChecked(item) {
    if (this.props.multiple) {
      return this.props.value && this.props.value.find(value => value === item.value);
    }
    return this.props.value === item.value;
  }

  render() {
    return (
      <div className={`radio-input${this.props.multiple ? '-multi checkbox_group' : ''}`}>

      {this.props.isLabel ?
        <div className={`radio-input-label${this.props.multiple ? '-multi' : ''}`}>
         {this.props.children}
        </div> :
      null}
        <div>
          <div className={`radio-input-items${this.props.multiple ? '-multi' : ''}`}>
          {this.props.items.map((item,index) => (
            <label className={`radio-input-item${this.props.multiple ? '-multi' : ''}`}>
              <input
              key={index}
                ref={`item:${item.label}`}
                type={this.props.multiple ? 'checkbox' : 'radio'}
                disabled={!!item.isDisabled || this.props.disabled}
                checked={this.isChecked(item)}
                value={item.value}
                onChange={this.onChange}
              />
              <span className={`mask_radio_btn${this.props.multiple ? '-multi' : ''}`}>&nbsp;
              </span>
              <span className={`label_radio_txt${this.props.multiple ? '-multi' : ''}`}>
                {item.label}
              </span>
            </label>
          ))}
          </div>
        </div>
      </div>
    );
  }
}

Radio.propTypes = {
  dispatch: React.PropTypes.func.isRequired,
  items: React.PropTypes.array.isRequired,
  storeKey: React.PropTypes.string.isRequired,
  value: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.object]),
  disabled: React.PropTypes.bool,
  children: React.PropTypes.node,
  multiple: React.PropTypes.bool,
  isLabel: React.PropTypes.bool,
};

Radio.defaultProps = {
  disabled: false,
};

export default Radio;

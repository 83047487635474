import React, { Component } from 'react';

class NextButton extends Component {
  constructor(props) {
    super(props);
    this.actionForm = this.actionForm.bind(this);
    this.actionNotForm = this.actionNotForm.bind(this);
  }
  actionForm() {
    if (this.props.gtm) {
       this.props.gtmPush();
    }
    if (!this.props.disableNext) {
      return this.props.action();
    }
    window.scrollTo(0, 0);
    return this.props.displayErrors('oui', this.props.fields);
  }
  actionNotForm() {
    return this.props.action();
  }
  render() {
    return (
      <button
        data-text={this.props.dataTxt}
        className={this.props.className}
        onClick={this.props.isFormulaire ? this.actionForm : this.actionNotForm}
        disabled={this.props.disabled}
      >
        <span>{this.props.label}</span>
      </button>
    );
  }
}

NextButton.propTypes = {
  dataTxt: React.PropTypes.string,
  label: React.PropTypes.string,
  className: React.PropTypes.string,
  disableNext: React.PropTypes.bool,
  disabled: React.PropTypes.bool,
  action: React.PropTypes.func.isRequired,
  displayErrors: React.PropTypes.func.isRequired,
  isFormulaire: React.PropTypes.bool,
  fields: React.PropTypes.array,
};

NextButton.defaultProps = {
  isFormulaire: false,
  disabled: false,
  className: 'flat_btn purple_btn more_padding',
  fields: [],
};
export default NextButton;

/* eslint-disable */ // no-nested-ternary
import React, { Component } from 'react';
import { List } from 'immutable';
import Step from '../../containers/Step';

class Timeline extends Component {
  render() {
    return (
      <div className="titmeline_container mb30">
        <ul>
          {this.props.steps.map(step => (
            <Step
              key={step.get('step')}
              step={step}
              active={step.get('step') === this.props.current}
              valid={step.get('step') < this.props.current}
              clickable={step.get('step') < this.props.current}
              className={step.get('step') < this.props.current ?
                        'passed' :
                        (step.get('step') === this.props.current ?
                        'active' : '')}
            />
          ))}
        </ul>
      </div>
    );
  }
}

Timeline.propTypes = {
  steps: React.PropTypes.instanceOf(List),
  current: React.PropTypes.number,
};

export default Timeline;

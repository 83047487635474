import { connect } from 'react-redux/dist/react-redux';
import DetailResultat from '../components/DetailResultat';
import { getAllResult, inputChange, sendMail, showHide } from '../action_creators';

function mapStateToProps(state) {
  return {
    allResult: state.simulateur.get('allResult'),
    montantCredit: state.simulateur.getIn(['montantCredit', 'value']),
    nbAnnee: state.simulateur.getIn(['nbAnnee', 'value']),
    taux: state.simulateur.getIn(['taux', 'value']),
    storeKey: 'simulateur.nbAnnee',
    dureeDiff: state.simulateur.getIn(['dureeDiff', 'value']),
    showHideElm: state.demandeCredit.getIn(['isChaabiClient', 'value']),
    langues: state.demandeCredit.getIn(['langues', 'value']), 
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getAllResult: (...s) => dispatch(getAllResult(...s)),
    sendMail: (...s) => dispatch(sendMail(...s)),
    inputChange: (s, h) => dispatch(inputChange(s, h)),
    show: (s) => dispatch(showHide(s)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DetailResultat);

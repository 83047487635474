import { connect } from 'react-redux/dist/react-redux';
import NavigationBar from '../components/NavigationBar';
import every from 'lodash/every';
import { displayErrors } from '../action_creators';

function mapStateToProps(state, props) {
  if (props.fields) {
    const allInputs = props.fields
      .map(field => field.split('.'))
      .map(([store, ...path]) => state[store].getIn(path));
    const haveValue = every(allInputs
        .map(input => (input && input.get('value') !== undefined
          && input.get('value') !== null && input.get('value').size !== 0)));
    const noError = every(allInputs
      .map((input) => (input && input.get('errors')
        ? input.get('errors').valueSeq().every((value) => (value !== true))
        : true)));
    return ({
      disableNext: !haveValue || !noError,
      userId: state.account.getIn(['identifiant', 'value']) || "",
      langues: state.demandeCredit.getIn(['langues', 'value']) || "fr",
      userStatus: state.account.get('authenticationStatus') === "success" ? "logged": "not-logged",
      origin: state.demandeCredit.getIn(['origin', 'value']), 
    });
  }
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    displayErrors: (a) => dispatch(displayErrors(a)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);

const INIT_APP = {
  type: 'INIT_APP' ,
};

export { INIT_APP };
export function valueToggle(newValue) {
  return Object.assign(
    {},
    { type: 'INTOGGLE',
      value: !newValue,
    }
  );
}
export function showHide(key) {
  return Object.assign(
    {},
    { type: 'SHOWHIDE',
      key,
    }
  );
}
export function scrolllToSection(source,id) {
  return Object.assign(
    {},
    { type: 'SCROLL_TO_SECTION',
      source,
      id,
    }
  );
}
export function chaabiClient(value) {
  return Object.assign(
    {},
    { type: 'CHAABICLIENT',
      value,
    }
  );
}
export function switchPopin() {
  return {
    type: 'SWITCH_POPIN',
  };
}
export function valueChange(newValue) {
  return Object.assign(
    {},
    { type: 'ALTERNATE',
      value: !newValue,
    }
  );
}
export function inputChange(value, key) {
  const [store, ...storeKey] = key.split('.');
  return Object.assign(
    {},
    {
      type: 'INPUT_CHANGE',
      store,
      key: storeKey,
      newValue: value,
    });
}
export function changeValues() {
  return { type: 'CHANGE_VALUES' };
}

export function textInputChange(value, key, type) {
  return Object.assign(
    {},
    inputChange(value, key),
    { inputType: type }
  );
}

export function goBackToState(state) {
  return {
    type: 'GO_BACK_TO_STATE',
    state,
  };
}

export function goNextState() {
  return {
    type: 'GO_NEXT_STATE',
  };
}

export function stateDemande() {
  return {
    type: 'DEMANDE_STATE',
  };
}
export function cleanStatus() {
  return {
    type: 'CLEAN_STATUS',
  };
}
export function cleanStore() {
  return {
    type: 'CLEAN_STORE',
  };
}
export function initialAccout() {
  return {
    type: 'INITIAL_STATE',
  };
}
export function defaulteState() {
  return {
    type: 'default_STATE',
  };
}
export function clearState() {
  return {
    type: 'CLEAR_STATE',
  };
}

export function displayErrors(etat, requiredFields) {
  return {
    type: 'DISPLAY_ERRORS',
    etat,
    requiredFields,
  };
}

export function evolanSimulation() {
  throw new Error('MISSING')
}
 
export function simulateurMontant() {
  throw new Error('MISSING')
}


export const getApiMeta = (type,url,method,body,query,actionSuccess,actionError,actionError500=actionError) => (

  {
  type,
  meta:{
    API_CALL: (window.BACKEND_URL || '')+'/api/v1/'.concat((url || '').replace(/^\/+/, '')),
    API_METHOD: method,
    API_PAYLOAD: body,
    API_QUERY:query,
    API_SUCCESS: actionSuccess,
    API_ERRORS:
    {
      400: actionError,
      401: actionError,
      404: actionError,
      408: actionError,
      500: actionError500,
      503: actionError,
      504: actionError,
    },
  }});

  // export const getApiMeta1 = (type,url,method,body,actionSuccess,actionError) => (

  //   {
  //   type,
  //   meta:{
  //     API_CALL: (window.BACKEND_URL || '')+'/api/v1/'.concat((url || '').replace(/^\/+/, '')),
  //     API_METHOD: method,
  //     API_PAYLOAD: body,
  //     API_SUCCESS: actionSuccess,
  //     API_ERRORS:
  //     {
  //       400: actionError,
  //       401: actionError,
  //       404: actionError,
  //       408: actionError,
  //       500: actionError,
  //       503: actionError,
  //       504: actionError,
  //     },
  //   }});

 export const saveDemande=(demande, query)=>getApiMeta('DEMANDE_REQUEST','/demande','POST',demande,query,'DEMANDE_SAVE_SUCCESS','DEMANDE_SAVE_ERROR');
 //export const getAllResult=(taux, nbAnnee, freqRemboursement, dureeDiff, montantCredit)=>getApiMeta('CALCUL_MONTANT_All','/result','POST',{taux, nbAnnee, freqRemboursement, dureeDiff, montantCredit},null,'CALCUL_MONTANT_All_SUCCESS',null);
  // export function saveDemande(demande) {
  //   return {
  //     type: 'DEMANDE_REQUEST',
  //     meta: {
  //       API_CALL: 'api/v1/demande/InitDemande',
  //       API_METHOD: 'POST',
  //       API_PAYLOAD: demande,
  //       API_SUCCESS: 'DEMANDE_SAVE_SUCCESS',
  //       API_ERRORS: {
  //         400: 'DEMANDE_SAVE_ERROR',
  //         401: 'DEMANDE_SAVE_ERROR',
  //         500: 'DEMANDE_SAVE_ERROR',
  //         504: 'DEMANDE_SAVE_ERROR',
  //         408: 'DEMANDE_SAVE_ERROR',
  //       },
  //     },
  //   };
  // }


export const simulateurEcheance =(taux, nbAnnee, montantCredit, freqRemboursement, dureeDiff = 0) => getApiMeta('CALCUL_MONTANT_ECHEANCE_MENSUEL','/echeance','GET',null,{taux, nbAnnee, montantCredit, freqRemboursement, dureeDiff},'CALCUL_MONTANT_ECHEANCE_MENSUEL_SUCCESS','CALCUL_MONTANT_ECHEANCE_MENSUEL_ERROR',null);
export const simulateurDefaultEcheance=(nbAnnee, montantCredit,freqRemboursement, dureeDiff = 0)=>getApiMeta('CALCUL_MONTANT_DEFAULT_ECHEANCE_MENSUEL','/echeancedefault','GET',null,{nbAnnee,montantCredit,freqRemboursement,dureeDiff},'CALCUL_MONTANT_DEFAULT_ECHEANCE_MENSUEL_SUCCESS',null);
export const simulateurFrais = (montantCredit, nbAnnee) => getApiMeta('CALCUL_FRAIS','/frais','GET',null,{montantCredit, nbAnnee},'CALCUL_FRAIS_SUCCESS',null);
export const getIndicatifs = () => getApiMeta('GET_INDICATIFS_LIST_DATA','/indicatif','GET',null,null,'INDICATIFS_LIST_DATA_SUCCESS',null);
export const simulateurCapaciteEmprunt=(revenuMensuel,autreEcheanceMensuelle, tauxCE, dureeEndetement, differe, bol)=> getApiMeta('CALCUL_MONTANT_CAPACITE_EMPRUNT','/CapaciteEmpruntApi','GET',null,{revenuMensuel, autreEcheanceMensuelle, tauxCE, dureeEndetement, differe, bol},'CALCUL_MONTANT_CAPACITE_EMPRUNT_SUCCESS',null);
export const simulateurCapaciteEmpruntDefault=(revenuMensuel,autreEcheanceMensuelle, dureeEndetement, differe = 0)=> getApiMeta('CALCUL_MONTANT_CAPACITE_EMPRUNT_DEFAULT','/emprunt','POST',null,{revenuMensuel, autreEcheanceMensuelle, dureeEndetement, differe},'CALCUL_MONTANT_CAPACITE_EMPRUNT_DEFAULT_SUCCESS',null);
export const getCountries = () => getApiMeta('GET_COUNTRY_LIST_DATA','/lieux/country','GET',null,null,'COUNTRY_LIST_DATA_SUCCESS',null);
export const getEvenementCompanies = () => getApiMeta('GET_EVENEMENT_COMPANIES_LIST_DATA','/evenements-companie','GET',null,null,'EVENEMENT_COMPANIES_LIST_DATA_SUCCESS',null);
export const getCities = () => getApiMeta('GET_CITY_LIST_DATA','/lieux/cities','GET',null,null,'CITY_LIST_DATA_SUCCESS',null);
export const getAllResult=(taux, nbAnnee, freqRemboursement, dureeDiff, montantCredit)=>getApiMeta('CALCUL_MONTANT_All','/result','POST',{taux, nbAnnee, freqRemboursement, dureeDiff, montantCredit},null,'CALCUL_MONTANT_All_SUCCESS',null);
export const getAgences = () => getApiMeta('GET_AGENCIES_LIST_DATA','/agence','GET',null,null,'AGENCIES_LIST_DATA_SUCCESS',null);
export const verifPassword=(tokenRequest)=>getApiMeta('VERIF_REQUEST','/verifPassword','POST',tokenRequest,null,'VERIF_PASSWORD_SUCCESS','VERIF_PASSWORD_ERROR');
export const sendMail=(SimulationMail)=>getApiMeta('SEND_MAIL','/mail','POST',SimulationMail,null,'SEND_MAIL_SUCCESS',null);
export const saveInfoSmap=(infoSmap)=>getApiMeta('SMAP_SAVE','/smap','POST',infoSmap,null,'SMAP_SAVE_SUCCESS','SMAP_SAVE_ERROR');
export const saveInfoResultatSimulateurSmap=(infoSmap)=>getApiMeta('SMAP_SAVE_SIMULATEUR','/smapSimulateur','POST',infoSmap,null,'SMAP_SAVE_SIMULATEUR_SUCCESS','SMAP_SAVE_ERROR');
export const sendSimulateurSmap=(simulationSmap)=>getApiMeta('SMAP_SAVE_SIMULATEUR','/smapnotif','POST',simulationSmap,null,'SMAP_SEND_SIMULATEUR_SUCCESS','SMAP_SEND_ERROR');
//export const getDocCondition=()=>getApiMeta('GET_DOC_CONDI','/document','GET',null,null,'GET_DOC_CONDI_SUCCESS',null);
export const getRejetPropComm=(rejetProp)=>getApiMeta('GET_REJET_PROP','/propositionClient/rejet','POST',rejetProp,null,'GET_DOC_CONDI_SUCCESS','GET_REJET_PROP_ERROR');
export const getDemande=(identifiant)=>getApiMeta('GET_DEMANDE','/espace-personnel','POST',identifiant,null,'GET_DEMANDE_SUCCESS','GET_DEMANDE_ERROR');
export const getDemandeByEmail=(email)=>getApiMeta('GET_DEMANDE','/demande/check','GET',null,{email},'GET_DEMANDE_BY_EMAIL_SUCCESS','GET_DEMANDE_ERROR');
export const getDemandeByEmailOrRadical=(radicalOremail)=>getApiMeta('GET_DEMANDE_BY_EMAIL_OR_RADICAL','/checkMotDePasseDemande','GET',null,{radicalOremail},'GET_DEMANDE_BY_EMAIL_OR_RADICAL_SUCCESS','GET_DEMANDE_BY_EMAIL_OR_RADICAL_ERROR');
export const searchClient=(authChaabiNetRequest)=>getApiMeta('SEARCH_CLIENT','/client/search','POST',authChaabiNetRequest,null,'SEARCH_CLIENT_SUCCESS','SEARCH_CLIENT_ERROR'); 
export const authentication=(authChaabiNetRequest)=>getApiMeta('AUTHENTICATION_STEP','/auth','POST',authChaabiNetRequest,null,'AUTHENTICATION_STEP_SUCCESS','AUTHENTICATION_STEP_ERROR','AUTHENTICATION_SERVER_ERROR');
export const forgetPassword=(mail)=>getApiMeta('AUTHENTICATION_FORGET','/auth/generatePassword','POST',mail,null,'AUTHENTICATION_FORGET_SUCCESS','AUTHENTICATION_FORGET_ERROR');  
export const resetPassword=(payload)=>getApiMeta('AUTHENTICATION_RESET','/resetPassword','POST',payload,null,'AUTHENTICATION_RESET_SUCCESS','AUTHENTICATION_RESET_ERROR');
export const definePassword=(payload)=>getApiMeta('AUTHENTICATION_RESET','/definePassword','POST',payload,null,'AUTHENTICATION_DEFINE_SUCCESS','AUTHENTICATION_RESET_ERROR');
export const checkExpirationPassword=(payload)=>getApiMeta('CHECK_CHANGE_PASSWORD','/checkExpirationPassword','POST',payload,null,'CHECK_CHANGE_PASSWORD_SUCCESS','CHECK_CHANGE_PASSWORD_ERROR'); 
export const accepterProposition=(propositionRequest)=>getApiMeta('PROPOSITION_STEP','/propositionClient','POST',propositionRequest,null,'PROPOSAL_ACCEPTED_SUCCESS','PROPOSITION_STEP_ERROR'); 
export const accepterPropositionAgence=(propositionRequest)=>getApiMeta('PROPOSITION_STEP_AGENCE','/propositionAgence','POST',propositionRequest,null,'PROPOSAL_ACCEPTED_AGENCE_SUCCESS','PROPOSITION_STEP_AGENCE_ERROR');
//getCities = () => getApiMeta('GET_CITY_LIST_DATA','/lieux/cities','GET',null,null,'CITY_LIST_DATA_SUCCESS',null);
export const getPromoteurs = () => getApiMeta('GET_PROMOTEUR_LIST_DATA','/promoteur','GET',  null,null,'PROMOTEUR_LIST_DATA_SUCCESS', null);
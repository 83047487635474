import { connect } from 'react-redux';

import Avantages from '../../components/Avantages';
function mapStateToProps(state) {
  return {
    langues: state.demandeCredit.getIn(['langues', 'value']),
  };
}

export default connect(mapStateToProps)(Avantages);

import { connect } from 'react-redux';

import Textarea from '../../components/Inputs/Textarea';

const mapStateToProps = (state, props) => {
  const [store, ...storeKey] = props.storeKey.split('.');
  storeKey.push('value');
  return ({
    value: state[store].getIn(storeKey),
  });
};

export default connect(mapStateToProps)(Textarea);

import React, { Component } from 'react';
import { inputChange} from '../../action_creators';
import map from 'lodash/map';
import { List } from 'immutable';
import InputError from '../../containers/Inputs/InputError';

class Select extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    if (!props.value && !props.placeholder) {
     const defaultItem = props.items.find(item => item.get('checked'));
      if (defaultItem || props.items.size > 0 ) {
        const value = defaultItem ? defaultItem.get('value') : props.items.first().get('value');
        props.dispatch(inputChange(value, props.storeKey));
      }
    }
  }

  onChange(event) {
    const value = (this.props.multiple) ?
      map(event.target.selectedOptions, o => o.value) :
      event.target.value;
    return this.props.dispatch(inputChange(value, this.props.storeKey));
  }

  render() {
    const value = this.props.value && this.props.value.toJS
      ? this.props.value.toJS()
      : this.props.value;

    const finalValue = (value !== undefined) ? value : '';
    return (
      <div className="input--select">
        <label
          className={this.props.iconName ? 'input-has-icon' : ''}
          style={{ textAlign: 'left' }}
        >
          <select
            className={this.props.className}
            onChange={this.onChange}
            value={finalValue}
            multiple={this.props.multiple}
            disabled={this.props.disabled}
          >
             {(this.props.placeholder) ? (
              <option disabled hidden value="">{this.props.placeholder}</option>
            ) : null}
            {this.props.items && this.props.items.map(item => (
              <option key={item.get('value')} value={item.get('value')}>
                {item.get('label')}
              </option>
            ))}
          </select>
          <div className="input-label">
            {!this.props.iconName ? null :
              <div className="input-label-icon">
                <div className={`icon-${this.props.iconName}`}></div>
              </div>
            }
            {this.props.children}
          </div>
        </label>
        <InputError storeKey={this.props.storeKey} />
      </div>
    );
  }
}

Select.propTypes = {
  dispatch: React.PropTypes.func.isRequired,
  items: React.PropTypes.instanceOf(List).isRequired,
  storeKey: React.PropTypes.string.isRequired,
  iconName: React.PropTypes.string,
  className: React.PropTypes.string,
  placeholder: React.PropTypes.string,
  multiple: React.PropTypes.bool,
  disabled: React.PropTypes.bool,
  value: React.PropTypes.oneOfType([
    React.PropTypes.number,
    React.PropTypes.string,
    React.PropTypes.instanceOf(List),
  ]),
  children: React.PropTypes.node,
};

Select.defaultProps = {
  className: 'input-field',
};

export default Select;

/* eslint-disable */
import React, { Component } from "react";
import i18n from '../../i18n';
import TagManager from 'react-gtm-module';
import { isLoged } from "../../utile/utile"
const ITEMS = {
  0: i18n.t("titleAv27.label"),
  1: i18n.t("titleAv29.label"),
  2: i18n.t("titleAv27.label"),
  3: "Est-ce que la réponse à ma demande est définitive ?",
  4: "Quelle est la durée de validité de la proposition de financement ?",
  5: "Où est-ce que je dois déposer mon dossier ?",
  6: "Est-ce que je peux modifier ma demande après l’envoi ?",
  7: "Votre question ne figure pas dans la FAQ ? Que faire ?",
  8: "Puis-je obtenir un crédit immobilier sans apport personnel ?",
  9: "Est-ce que j’ai le droit de changer d’avis ?",
  10: "Est-ce que je peux faire ma demande si j\’ai déjà un crédit immobilier ?",
  11: " Est-ce que je peux faire ma demande si je n’ai jamais eu de crédit immobilier ?",
  12: "Quelles sont les durées possibles pour le remboursement du prêt ?",
  13: "Quels sont les taux appliqués ?",
  14: "Quel est le montant maximal que je peux demander ?",
  15: "Est-il nécessaire d’assurer mon crédit ?"
}
class Faq extends Component {
  constructor(props) {
    super(props);
    var FAQ = [];
    for(var i = 0 ; i<16 ; i++){
      FAQ.push({ display: "none" });
    }
    this.state = {
      FAQ
      ,
      showMore : {}
    }
    this.onClickFAQ = this.onClickFAQ.bind(this);

    this.onCLickPuls = this.onCLickPuls.bind(this);
  }
  onCLickPuls(){
    if(this.state.showMore.display === "list-item")
      this.setState({showMore :  {}})
    else
      this.setState({showMore : {display: "list-item"}})
      TagManager.dataLayer({
        dataLayer: isLoged(this.props.userId, {
          event:"FAQ_afficher-plus",
          langue: this.props.langues,
          userStatus: this.props.userStatus,
          BU: "particulier"
        })
      })
  }

  onClickFAQ(id, event, selector) {

    const { FAQ } = this.state;
    if(FAQ[id].display === "none"){
      FAQ.map(item => item.display = "none" );
      FAQ[id].display = "block";

    }
    else
      FAQ[id].display = "none";

    // update state
    this.setState({
      FAQ,
    });
    this.props.scrollToSection(event, selector)
    TagManager.dataLayer({
      dataLayer: isLoged(this.props.userId, {
        event:"FAQ",
        item: ITEMS[id],
        langue: this.props.langues,
        userStatus: this.props.userStatus,
        BU: "particulier"
      })
    })

  }

  render() {

    return (
      <section id="faq_section" className="faq_section_paddings align_center">
        <div className="section_description_container">
          <h2 className="title_section orange_clr uppercase_txt">
            {i18n.t("titleAv25.label")}
          </h2>
          <hr className="hr_header" />
          <p className="light_section_desc">
          {i18n.t("titleAv26.label")}
          </p>
        </div>
        <ul className="accordion_list1">
          <li>
            <a  className="accordion_title1" onClick = {(e) => this.onClickFAQ(0,e,"#section") }>
            {i18n.t("titleAv27.label")}{" "}
            </a>
            <div style={this.state.FAQ[0]} className="accordion_content1">
              <p>
              {i18n.t("titleAv28.label")}
              </p>
            </div>
          </li>
          <li>
            <a  className="accordion_title1"  onClick = {(e) => this.onClickFAQ(1,e,"#section") }>
            {i18n.t("titleAv29.label")}{" "}
            </a>
            <div style={this.state.FAQ[1]} className="accordion_content1">
              <p>
              {i18n.t("titleAv30.label")}
              </p>
            </div>
          </li>
          <li>
            <a className="accordion_title1" onClick = {(e) => this.onClickFAQ(2,e,"#section") }>
            {i18n.t("titleAv51.label")}
            </a>
            <div style={this.state.FAQ[2]} className="accordion_content1">
              <p>
              {i18n.t("titleAv33.label")}
              </p>
            </div>
          </li>
          <li style={this.state.showMore}>
            <a  className="accordion_title1"  onClick = {(e) => this.onClickFAQ(3,e,"#section") }>
              Est-ce que la réponse à ma demande est définitive ?
            </a>
            <div style={this.state.FAQ[3]} className="accordion_content1">
              <p>
                La réponse donnée par la banque est basée sur les informations
                saisies lors de votre demande. Elle n’est valable que si vous
                nous fournissez les documents prouvant la véracité de l’ensemble
                des informations saisies ainsi que les documents de garanties
                listés dans la proposition de financement.
              </p>
            </div>
          </li>
          <li style={this.state.showMore}>
            <a className="accordion_title1"  onClick = {(e) => this.onClickFAQ(4,e,"#section") }>
              Quelle est la durée de validité de la proposition de financement ?
            </a>
            <div style={this.state.FAQ[4]} className="accordion_content1">
              <p>
                La proposition de financement a une durée de validité de 3 mois
                à condition que votre situation n’ait pas changé.
              </p>
            </div>
          </li>
          <li style={this.state.showMore}>
            <a className="accordion_title1"  onClick = {(e) => this.onClickFAQ(5,e,"#section") }>
              Où est-ce que je dois déposer mon dossier ?
            </a>
            <div style={this.state.FAQ[5]} className="accordion_content1">
              <p>
                Si vous n’êtes pas encore client, vous pouvez choisir l’agence
                qui vous convient le plus lors de l’étape de sélection de
                l’agence.
              </p>
              <p>
                Si vous êtes déjà client, votre demande doit être déposée auprès
                de votre agence.
              </p>
            </div>
          </li>
          <li style={this.state.showMore}>
            <a className="accordion_title1"  onClick = {(e) => this.onClickFAQ(6,e,"#section") }>
              Est-ce que je peux modifier ma demande après l’envoi ?
            </a>
            <div style={this.state.FAQ[6]} className="accordion_content1">
              <p>
                Une fois validée, votre demande n’est plus modifiable. Toute
                modification doit faire l’objet d’une nouvelle demande.
              </p>
            </div>
          </li>
          <li style={this.state.showMore}>
            <a className="accordion_title1"  onClick = {(e) => this.onClickFAQ(7,e,"#section") }>
              Votre question ne figure pas dans la FAQ ? Que faire ?
            </a>
            <div style={this.state.FAQ[7]} className="accordion_content1">
              <p>
                Vous pouvez poser votre question directement à notre centre de
                relation client (CRC) joignable au 2121 ou directement à
                un chargé de clientèle dans l’une de nos agences.
              </p>
            </div>
          </li>
          <li style={this.state.showMore}>
            <a className="accordion_title1"  onClick = {(e) => this.onClickFAQ(8,e,"#section") }>
              Puis-je obtenir un crédit immobilier sans apport personnel ?
            </a>
            <div style={this.state.FAQ[8]} className="accordion_content1">
              <p>
                Il est possible de financer votre bien immobilier sans apport,
                et d’obtenir un financement pouvant atteindre 100% voire 110% du
                prix de votre bien
              </p>
            </div>
          </li>
          <li style={this.state.showMore}>
            <a className="accordion_title1"  onClick = {(e) => this.onClickFAQ(9,e,"#section") }>
              Est-ce que j’ai le droit de changer d’avis ?
            </a>
            <div style={this.state.FAQ[9]} className="accordion_content1">
              <p>
                La proposition de financement de la banque ne vous engage pas à
                souscrire au crédit.
              </p>
              <p>
                Après présentation des pièces justificatives et la signature de
                l’offre préalable de crédit qui est établie en application des
                conditions prévues par la loi n°31-08 édictant des mesures de
                protection du consommateur, vous bénéficiez d’un délai de
                rétractation de 10 jours francs.
              </p>
            </div>
          </li>
          <li style={this.state.showMore}>
            <a className="accordion_title1" onClick = {(e) => this.onClickFAQ(10,e,"#section") }>
              Est-ce que je peux faire ma demande si j’ai déjà un crédit
              immobilier ?
            </a>
            <div style={this.state.FAQ[10]} className="accordion_content1">
              <p>
                Vous pouvez demander un autre crédit immobilier tant que votre
                capacité d’endettement ne dépasse pas le seuil de 45% de vos
                revenus.
              </p>
            </div>
          </li>
          <li style={this.state.showMore}>
            <a  className="accordion_title1" onClick = {(e) => this.onClickFAQ(11,e,"#section") }>
              Est-ce que je peux faire ma demande si je n’ai jamais eu de crédit
              immobilier ?
            </a>
            <div style={this.state.FAQ[11]} className="accordion_content1">
              <p>
                Il est possible de faire votre demande de crédit immobilier via
                notre site même si vous n’avez jamais eu de crédit immobilier.
              </p>
            </div>
          </li>
          <li style={this.state.showMore}>
            <a className="accordion_title1" onClick = {(e) => this.onClickFAQ(12,e,"#section") }>
              Quelles sont les durées possibles pour le remboursement du prêt ?
            </a>
            <div style={this.state.FAQ[12]} className="accordion_content1">
              <p>
                Vous pouvez rembourser votre crédit immobilier sur une durée
                allant de 7 à 25 ans. Sans pour autant dépasser l’âge de 70 ans
                au remboursement de la dernière échéance.
              </p>
            </div>
          </li>
          <li style={this.state.showMore}>
            <a className="accordion_title1"  onClick = {(e) => this.onClickFAQ(13,e,"#section") }>Quels sont les taux appliqués ?</a>
            <div style={this.state.FAQ[13]} className="accordion_content1">
              <p>
                Nos taux sont parmi les meilleurs du marché. La Banque Populaire
                dispose de nombreuses conventions avec des organismes étatiques
                et privés, et propose également des taux préférentiels en
                fonction de packs et des produits choisis.
              </p>
            </div>
          </li>
          <li style={this.state.showMore}>
            <a className="accordion_title1"  onClick = {(e) => this.onClickFAQ(14,e,"#section") }>
              Quel est le montant maximal que je peux demander ?
            </a>
            <div style={this.state.FAQ[14]} className="accordion_content1">
              <p>
                Vous pouvez financer jusqu'à 100% du cout d’acquisition y
                compris les frais annexes.
              </p>
            </div>
          </li>
          <li style={this.state.showMore}>
            <a className="accordion_title1" onClick = {(e) => this.onClickFAQ(15,e,"#section") }>
              Est-il nécessaire d’assurer mon crédit ?
            </a>
            <div style={this.state.FAQ[15]} className="accordion_content1">
              <p>
                Les assurances Décès toute cause (DTC ) et Incendie Explosion
                (IE) sur le bien à acquérir sont obligatoires.
              </p>
            </div>
          </li>
        </ul>
        <p className="load_more_area">
          <button
            type="button"
            data-text={i18n.t("titleAv37.label")}
            className="flat_btn purple_btn load_questions"
            onClick={this.onCLickPuls}
          >
            <span>{this.state.showMore.display === "list-item" ?  i18n.t("titleAv38.label") : i18n.t("titleAv37.label") }</span>
          </button>
        </p>
      </section>
    );
  }
}
export default Faq;

import React, { Component, PropTypes } from 'react';
import SlideBar from '../containers/Inputs/SlideBar';
import info_txt from '../assets/images/info_txt.png';
const option3 = { numeral: true, numeralDecimalScale: 2 };
const option2 = { blocks: [2] };
const option5 = { numeral: true,
  numeralDecimalMark: '.',
  delimiter: ' ' };

class DetailCapacite extends Component {
  render() {
    return (
      <div className="simulateur_position accordion_list">
        <div className="detail_capacite">
          <span className="span_detail_capacite" >choix du montant du crédit</span>
          <div className="croix-ferme" onClick={this.props.toggle} />
        </div>
        <div className="detail_capacite_content">
          <br />
          <p className="info2_txt">
            <span >Veuillez sélectionner ci-dessous le montant souhaité en fonction de votre capacité d&apos;emprunt.</span> <br />
            <span>Le simulateur ci-dessous vous permet de sélectionner le montant maximal
            qu&apos;il est possible d&apos;emprunter au vu des éléments renseignés .</span>
          </p>
          <br />
          <br />
          <SlideBar
            max={this.props.max}
            min={0}
            step={100}
            options={option5}
            typeLibelle="Dhs"
            libelle="Montant"
            storeKey="simulateur.capaciteEmpruntfinal"
          />
          <SlideBar
            disabled
            min={0.01}
            max={12}
            step={0.01}
            options={option3}
            typeLibelle="%"
            libelle="Taux"
            libelleInfo="(ht)"
            storeKey="simulateur.tauxHt"
          />
          <SlideBar
            disabled
            min={0}
            max={25}
            step={1}
            options={option2}
            typeLibelle="Ans"
            libelle="Durée"
            storeKey="simulateur.duree"
          />
          <br />
          <div>
            <a
              href="#/detail-resultat-emprunt"
              data-text="Détail"
              className="flat_btn white_btn right_mr"
            >
              <span className="plusDepadding">Détail</span>
            </a>
            <a
              href="#/coordonnees"
              onClick={this.props.onClick}
              data-text="faire une demande de prêt"
              className="flat_btn purple_btn"
            >
              <span className="plusDepadding">faire une demande de prêt</span>
            </a>
            <br />
            <br />
            <p className="info_txt">
              <img src={info_txt} alt="" />
              <span >Le résultat de cette simulation est non contractuel
              et revét un caractère strictement informatif</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
DetailCapacite.propTypes = {
  max: PropTypes.number,
  toggle: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
export default DetailCapacite;

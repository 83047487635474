import { connect } from 'react-redux/dist/react-redux';
import Checkbox from '../../components/Inputs/Checkbox';


const mapStateToProps = (state, props) => {
  const [store, ...storeKey] = props.storeKey.split('.');
  return ({
    docCond: state.demandeCredit.get('docCond'),
    value: state[store].get(storeKey[0]),
    langues: state.demandeCredit.getIn(['langues', 'value']),
  });
};
export default connect(mapStateToProps)(Checkbox);

import { connect } from 'react-redux/dist/react-redux';
import HeaderAuth from '../components/Inputs/HeaderAuth';
import { showHide, switchPopin, authentication, initialAccout,
  resetPassword, forgetPassword, searchClient,chaabiClient, inputChange } from '../action_creators';
  import i18n from '../i18n';
function mapStateToProps(state) {
  const popin = state.demandeCredit.getIn(['popinItem', 'value']);
  return {
    gtmErrorLogin : state.demandeCredit.getIn(['gtmlogin', 'value']),
    langues: state.demandeCredit.getIn(['langues', 'value']) || "fr", 
    origin: state.demandeCredit.getIn(['origin', 'value']), 
    fpmail: state.account.getIn(['identifiant', 'value']),
   // demandeByMailOrRadical: state.demandeCredit.get('demandeMailRadical') != undefined ? state.demandeCredit.get('demandeMailRadical').toJS() : null,
    showHideElm: state.demandeCredit.getIn(['authentificate', 'value']),
    popin,
    label: popin === 'connexion' ? i18n.t("recap24.label") : i18n.t("recap25.label"),
    secondLabel: popin === 'connexion' ? i18n.t("recap26.label") : 'Annuler',
    popinTitle: popin !== 'connexion' ? i18n.t("recap23.label") :
    i18n.t("recap21.label"),
    popinDesc: popin === 'connexion' ? i18n.t("recap22.label") :
      i18n.t("recap27.label"),
    isClient: state.demandeCredit.getIn(['clientbcp', 'value']) === 'Oui',
    mdpOubli: state.demandeCredit.getIn(['popinItem', 'value']) === 'showMdpOublie',
    mdpToChange: state.demandeCredit.getIn(['mdpToChange', 'value']),
    isChaabiClient: state.demandeCredit.getIn(['isChaabiClient', 'value']),
    yesChaabiClient: state.demandeCredit.getIn(['yesChaabiClient', 'value']),
    authChaabiNetRequest: {
      radical: state.account.getIn(['identifiant', 'value']),
      motDePasse: state.account.getIn(['motDePasse', 'value']),
    },
    passwordRequest: {
      mail: state.account.getIn(['LoggedUser', 'mail']),
      oldPassword: state.account.getIn(['oldPassword', 'value']),
      newPassword: state.account.getIn(['newPassword', 'value']),
    },
    authenticationStatus: state.account.get('authenticationStatus'),
    authenticationError: state.account.getIn(['authenticationError', 'successIndicator']),
    resetStatus: state.account.getIn(['oubliPass', 'status']),
    resetError: state.account.getIn(['oubliPass', 'error']),
    nbreConnexion: state.account.getIn(['LoggedUser', 'nbconnexion']),
    resetRequest: {
      radical: state.account.getIn(['fpmail', 'value']),
    },
    authchaabiNetRequest: {
      radical: state.account.getIn(['identifiant', 'value']),
      motDePasse: state.account.getIn(['motDePasse', 'value']),
    },
    clientDetailsStatus: state.demandeCredit.get('clientDetailsStatus'),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    show: (s) => dispatch(showHide(s)),
    chaabiClient : (s) => dispatch(chaabiClient(s)),
    switchPopin: (s) => dispatch(switchPopin(s)),
    authentication: (authChaabiNetRequest) => dispatch(authentication(authChaabiNetRequest)),
    resetPassword: (request) => dispatch(resetPassword(request)),
    forgetPassword: (mail) => dispatch(forgetPassword(mail)),
    initialAccout: () => dispatch(initialAccout()),
    searchClient: (a) => dispatch(searchClient(a)),
    inputChange: (...y) => dispatch(inputChange(...y)),
    //getDemandeByEmailOrRadical: (a) => dispatch(getDemandeByEmailOrRadical(a)),
  };
}
function mergeProps(stateProps, dispatchProps, ownProps) {
  const authChaabiNetRequest = stateProps.authChaabiNetRequest;
  const passwordRequest = stateProps.passwordRequest;
  const authchaabiNetRequest = stateProps.authchaabiNetRequest;
  const mail = stateProps.resetRequest;
 // const radicalOremail = stateProps.fpmail;
  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    authentication: () => dispatchProps.authentication(authChaabiNetRequest),
    resetPassword: () => dispatchProps.resetPassword(passwordRequest),
    forgetPassword: () => dispatchProps.forgetPassword(mail),
    searchClient: () => dispatchProps.searchClient(authchaabiNetRequest),
    //getDemandeByEmailOrRadical: () => dispatchProps.getDemandeByEmailOrRadical(radicalOremail),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(HeaderAuth);

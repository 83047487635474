import { connect } from 'react-redux/dist/react-redux';
import InfoProjet from '../components/InfoProjet';
import { extraitNombre } from '../utile/utile'
import {
  goNextState, cleanStatus, valueChange, saveDemande, inputChange,
  showHide, changeValues, displayErrors
}
  from '../action_creators';

function mapStateToProps(state) {
  const prixBien = state.demandeCredit.getIn(['coutprojet', 'value']);
  return {
    //gclid: localStorage.getItem('gclid'),
    prixBien,
    montantPret: state.demandeCredit.getIn(['montantCredit', 'value']),
    coutProjet: state.demandeCredit.getIn(['coutprojet', 'value']),
    EstimFraisProjet: prixBien === undefined ? 0 : ((0.1 * parseFloat(extraitNombre(prixBien))).toFixed(0)),
    demandeCredit: {
      reference: state.demandeCredit.getIn(['request', 'result', 'reference']),
      prenom: state.demandeCredit.getIn(['prenom', 'value']),
      nom: state.demandeCredit.getIn(['nom', 'value']),
      civilite: state.demandeCredit.getIn(['civilite', 'value']),
      clientbcp: state.demandeCredit.getIn(['clientbcp', 'value']),
      mail: state.demandeCredit.getIn(['mail', 'value']),
      telephone: state.demandeCredit.getIn(['telephone', 'value']),
      date_naissance: {
        day: state.demandeCredit.getIn(['date_naissanceDay', 'value']),
        month: state.demandeCredit.getIn(['date_naissanceMonth', 'value']),
        year: state.demandeCredit.getIn(['date_naissanceYear', 'value']),
      },
      paysderesidence: state.demandeCredit.getIn(['paysderesidence', 'value']),
      pieceidentite: state.demandeCredit.getIn(['pieceidentite', 'value']),
      numpieceidentite: state.demandeCredit.getIn(['numpieceidentite', 'value']),
      situationfamiliale: state.demandeCredit.getIn(['situationfamiliale', 'value']),
      activite: state.demandeCredit.getIn(['activite', 'value']),
      employeur: state.demandeCredit.getIn(['employeur', 'value']),
      secteur: state.demandeCredit.getIn(['secteur', 'value']),
      anciente: state.demandeCredit.getIn(['anciente', 'value']),
      revenu: state.demandeCredit.getIn(['revenu', 'value']),
      boautrerevenu: state.demandeCredit.getIn(['boautrerevenu', 'value']),
      autreactivite: state.demandeCredit.getIn(['autreactivite', 'value']),
      autrerevenu: state.demandeCredit.getIn(['autrerevenu', 'value']),
      etatbien: state.demandeCredit.getIn(['etatbien', 'value']),
      typebien: state.demandeCredit.getIn(['typebien', 'value']),
      usagebien: state.demandeCredit.getIn(['usagebien', 'value']),
      coutprojet: state.demandeCredit.getIn(['coutprojet', 'value']),
      apportpersonnel: state.demandeCredit.getIn(['apportpersonnel', 'value']),
      autresecheances: state.demandeCredit.getIn(['autresecheances', 'value']),
      localisationbien: state.demandeCredit.getIn(['localisationbien', 'value']),
      eventCompany: state.demandeCredit.getIn(['eventCompany', 'value']),
      surfacebien: state.demandeCredit.getIn(['surfacebien', 'value']),
      montantpret: state.demandeCredit.getIn(['montantCredit', 'value']),
      dureesouhaitable: state.demandeCredit.getIn(['nbAnnee', 'value']),
      typetaux: state.demandeCredit.getIn(['typetaux', 'value']),
      dureediffere: state.demandeCredit.getIn(['dureeDiff', 'value']),
      apiAction: 'save',
      fraisamg: state.demandeCredit.getIn(['fraisamg', 'value']),
      autresfrais: state.demandeCredit.getIn(['autresfrais', 'value']),
      promotor: {
        id: state.demandeCredit.getIn(['promoteur', 'value']),
        prmotorLibelle: state.demandeCredit.getIn(['promoteurLibelle', 'value']),
        autrePromoteur: state.demandeCredit.getIn(['autrePromoteur', 'value']),
      }
    },
    progressLevel: state.demandeCredit.getIn(['progressLevel', 'value']),
    reference: state.demandeCredit.getIn(['request', 'result', 'reference']),
    toggled: state.demandeCredit.getIn(['toggling', 'value']),
    showHideElm: state.demandeCredit.getIn(['popSimulate', 'value']),
    clientbcp: state.demandeCredit.getIn(['clientbcp', 'value']),
    status: state.demandeCredit.getIn(['request', 'status']),
    nextPushed: state.demandeCredit.getIn(['nextPushed', 'value']),
    userId: state.account.getIn(['identifiant', 'value']) || "",
    langues: state.demandeCredit.getIn(['langues', 'value']) || "fr",
    userStatus: state.account.get('authenticationStatus') === "success" ? "logged": "not-logged",
    origin: state.demandeCredit.getIn(['origin', 'value']),
    montantCredit: state.simulateur.getIn(['montantCredit', 'value']),
    nbAnnee: state.simulateur.getIn(['nbAnnee', 'value']),
    dureeDiff: state.simulateur.getIn(['dureeDiff', 'value']),
    taux: state.simulateur.getIn(['taux', 'value']),
    echeance: state.simulateur.getIn(['echeance', 'value']),

    etatbienError: state.demandeCredit.getIn(['etatbien',  'value']),
    usagebienError: state.demandeCredit.getIn(['usagebien',  'value']),
    surfacebienError: state.demandeCredit.getIn(['surfacebien',  'errors','surfacebien.wrongFormat']),
    typebienError: state.demandeCredit.getIn(['typebien',  'value']),
    coutprojetError: state.demandeCredit.getIn(['coutprojet',   'errors','coutprojet.wrongFormat']),
    localisationbienError: state.demandeCredit.getIn(['localisationbien',  'value']),
    promoteurError: state.demandeCredit.getIn(['promoteur',  'value']),
    montantCreditError: state.demandeCredit.getIn(['montantCredit',   'errors','montantCredit.wrongFormat']),
    apportpersonnelError: state.demandeCredit.getIn(['apportpersonnel',   'errors','apportpersonnel.wrongFormat']),
    nbAnneeIsSeventyError: state.demandeCredit.getIn(['nbAnnee',   'errors','nbAnnee.isSeventy']),
    nbAnneeisMaxDureeError: state.demandeCredit.getIn(['nbAnnee',   'errors','nbAnnee.isMaxDuree']),
    dureeDiffError: state.demandeCredit.getIn(['dureeDiff',  'value']),
    financconjointError: state.demandeCredit.getIn(['financconjoint',  'value']),
  };
}


function mapDispatchToProps(dispatch) {
  return {
    saveDemande: (demandeRequest) => dispatch(saveDemande(demandeRequest)),
    goNext: () => dispatch(goNextState()),
    cleanStatus: () => dispatch(cleanStatus()),
    valueToggle: (s) => dispatch(valueChange(s)),
    showHide: (s) => dispatch(showHide(s)),
    inputChange: (s, h) => dispatch(inputChange(s, h)),
    changeValues: () => dispatch(changeValues()),
    displayErrors: (a) => dispatch(displayErrors(a)),
  };
}
function mergeProps(stateProps, dispatchProps, ownProps) {
  const demandeRequest = stateProps.demandeCredit;
  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    saveDemande: () => dispatchProps.saveDemande(demandeRequest),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(InfoProjet);

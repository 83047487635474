const excludedFields = [
  'SMSCode',
  'identifiant',
  'motDePasse',
  'fpmail',
  'oldPassword',
  'newPassword',
  'confirmdPassword',
];

export default () => next => action => {
  const newAction = Object.assign({}, action);

  if (
    action.type === 'INPUT_CHANGE'
    && action.inputType === 'text'
    && excludedFields.indexOf(action.key.join('.')) === -1
  ) {
    newAction.newValue = action.newValue.toUpperCase();
  }

  return next(newAction);
};

import { fromJS, Map } from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import step01_active from '../assets/images/step01_active.png';
import step01  from '../assets/images/step01.png';
import step01_valid from '../assets/images/step01_valid.png';
import step02_active from '../assets/images/step02_active.png';
import step02 from '../assets/images/step02.png';
import step02_valid from '../assets/images/step02_valid.png';
import step03_active from'../assets/images/step03_active.png';
import step03 from '../assets/images/step03.png';
import step03_valid from '../assets/images/step03_valid.png';
import step04_active from '../assets/images/step04_active.png';
import step04 from '../assets/images/step04.png';
import step04_valid from '../assets/images/step04_valid.png';

/*const initLang(state){
const lang = state.demandeCredit.getIn(['langues','value']);
i18n.changeLanguage(lang);
}*/
const steps = [
  {
    step: 0, label: 'step02.label',
    regex: /^\/infos-perso($|\/)/, canonicalUrl: '/infos-perso',
    imgActive: step02_active, imgDefault: step02,
    imgValid: step02_valid,
  },
  {
    step: 1, label: 'step03.label',
    regex: /^\/infos-projet($|\/)/, canonicalUrl: '/infos-projet',
    imgActive: step03_active, imgDefault: step03,
    imgValid: step03_valid,
  },
  {
    step: 2, label:  'step01.label',
    regex: /^\/coordonnees($|\/)/, canonicalUrl: '/coordonnees',
    imgActive: step01_active, imgDefault: step01,
    imgValid: step01_valid,
  },
  {
    step: 3, label: 'step04.label',
    regex: /^\/recapitulatif($|\/)/, canonicalUrl: '/recapitulatif',
    imgActive: step04_active, imgDefault: step04,
    imgValid: step04_valid,
  },
];

function getCurrentStep(path) {
  const match = steps.find(v => v.regex.test(path));
  return (match ? match.step : 0);
}

export default function (state = new Map(), action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.set('current', getCurrentStep(action.payload.pathname));
    case 'INIT_APP':
      return state.set('steps', fromJS(steps));
    default:
      return state;
  }
}

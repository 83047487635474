import React, { Component } from 'react';
import SlideBar from '../containers/Inputs/SlideBar';
import SlideElements from './Inputs/SlideElements';
import Slider from 'react-slick-fix/lib/slider';
import DetailCapacite from '../components/DetailCapacite';
import $ from 'jquery';
import image from '../assets/images/img/home_slide1.jpg';
import image2 from '../assets/images/img/home_slide2.jpg';
import logo_bankpopulaire from '../assets/images/logo_bankpopulaire.jpg';
import footer_logo_bankpopulaire from '../assets/images/footer_logo_bankpopulaire.jpg';
import chaabi_bank from '../assets/images/chaabi_bank.png';
import smapimmo from '../assets/images/smapimmo.png';
import info_txt from '../assets/images/info_txt.png';
import { hashHistory } from 'react-router';
const divStyle = { display: 'block' };
class SimulateurSMAP extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.click1 = this.click1.bind(this);
    this.shoHidePop = this.shoHidePop.bind(this);
  }
  componentWillMount() {
    this.props.initialAccout();
    this.props.defaulteState();
  }
  componentDidMount() {
    /* eslint-disable */
    (function () {
        var bp = {};
        bp = {
            dom: {
            },
            init: function () {
              this.accordion();
              this.accordionFQ();
              this.loadHideQuestions();
            },
            accordion : function(){
                if ($('.accordion_title').length) {
                    $('.accordion_title').on('click touchend', function(){
                        if ($(this).hasClass('active')) {
                            $('.accordion_content').stop().slideUp(300);
                            $('.accordion_title').removeClass('active');
                        }else{
                            $('.accordion_content').stop().slideUp(300);
                            $(this).next('.accordion_content').stop().slideDown(300);
                            $('.accordion_title').removeClass('active');
                            $(this).addClass('active');
                        }
                        return false;
                    });
                }
            },
            accordionFQ : function(){
                if ($('.accordion_title1').length) {
                    $('.accordion_title1').on('click touchend', function(){
                        if ($(this).hasClass('active')) {
                            $('.accordion_content1').stop().slideUp(300);
                            $('.accordion_title1').removeClass('active');
                        }else{
                            $('.accordion_content1').stop().slideUp(300);
                            $(this).next('.accordion_content1').stop().slideDown(300);
                            $('.accordion_title1').removeClass('active');
                            $(this).addClass('active');
                        }
                        return false;
                    });
                }
            },
            loadHideQuestions : function(){
              $('.load_questions').on('click touchend', function(){
                  $('.accordion_list1 li:hidden').slideDown(300);
                  $('.hide_more_area').fadeIn()
                  $('.load_more_area').fadeOut()
                  return false;
              })
              $('.hide_questions').on('click touchend', function(){
                  $('.accordion_list1 li:nth-child(n+4)').slideUp(300);
                  $('.hide_more_area').fadeOut()
                  $('.load_more_area').fadeIn()
                  return false;
              })
          }
        };

    //call init function on document ready
        $(function () {
            bp.init();
        });
    })
    (window.jQuery);
  }

   componentDidUpdate(prevProps) {
     if (
      prevProps.revenuNetMensuel !== this.props.revenuNetMensuel
      || prevProps.autreCredit !== this.props.autreCredit
      || prevProps.duree !== this.props.duree
      || prevProps.tauxHt !== this.props.tauxHt) {
       this.props.simulateurCapaciteEmprunt(this.props.revenuNetMensuel,
         this.props.autreCredit, this.props.tauxHt, this.props.duree, 0, false);
     }
   }
   click1() {
     const { capaciteEmpruntfinal, duree } = this.props;
     this.props.inputChange(capaciteEmpruntfinal, 'smap.montantCredit');
     this.props.inputChange(duree, 'smap.nbAnnee');
     this.props.inputChange(0, 'smap.dureeDiff');
   }
   shoHidePop(s) {
     this.props.showHide(s);
   }
   toggle() {
     this.props.valueToggle(this.props.toggled);
   }
   render() {
     const settings = {
       dots: true,
       infinite: false,
       arrows: false,
       slidesToShow: 1,
       // variableWidth: true,
       slidesToScroll: 1,
       respondTo: 'window',
       mobileFirst: true,
       responsive: [
         {
           breakpoint: 1024,
           settings: {
             slidesToShow: 1,
           },
         },
       ],
     };
     return (
       <div className="">
       <header className="public_header">
          <div className="main_head_SPAM clearfix">
            <div className="logo_container-smap">
              <h1>
                <a onClick={() => hashHistory.push('/')} >
                  <img src={logo_bankpopulaire} alt="banque populaire" width="198" />
                </a>
                <a onClick={() => hashHistory.push('/')}  >
                  <img src={smapimmo} alt="banque populaire" width="198" />
                </a>
                <a onClick={() => hashHistory.push('/')} >
                  <img src={chaabi_bank} alt="banque populaire" width="80" className="logo-chanbi-bank-smap" />
                </a>
                
              </h1>
            </div>
          </div>
        </header>
         <section className="align_center">
           <div className="sliders_container">
             <div className="global_slider_container">
               <Slider {...settings}>
                 <div>
                   <SlideElements
                     isAcceuil
                     src1={image}
                     subTitle=""
                     legalien="#/coordonnees"
                   />
                 </div>
                 <div>
                   <SlideElements
                     isAcceuil
                     src1={image2}
                     subTitle="Financez jusqu’à 100% de la valeur de votre bien"
                     desc="Choisissez entre un taux fixe ou variable"
                     lien="#/infos-perso"
                   />
                 </div>
                 <div>
                   <SlideElements
                     isAcceuil
                     src1={image}
                     subTitle="Suivez votre demande de crédit Immobilier en temps réel"
                     desc="Créer votre espace personnel et restez informé
                      de l’avancement du traitement de votre dossier"
                     lien="#/detail-simulation"
                   />
                 </div>
               </Slider>
             </div>
            {this.props.toggled ?
              <ul className="accordion_list simulateur_position simulateur_center">
                <li>
                  <a href="#" className="accordion_title accor_simulator simulateur_normal active">
                    Simulateur
                  </a>
                  <div className="accordion_content" style={divStyle}>
                    <SlideBar
                      min={0}
                      max={10000000}
                      step={50000}
                      typeLibelle="Dhs"
                      libelle="Montant"
                      storeKey="simulateur.montantCredit"
                      tooltipTxt="Choisissez le montant que vous souhaitez emprunter afin de connaitre votre échéance"
                    />
                    <SlideBar
                      min={5}
                      max={25}
                      step={1}
                      typeLibelle="Ans"
                      libelle="Durée"
                      storeKey="simulateur.nbAnnee"
                      tooltipTxt="Choisissez la durée totale du remboursement du prêt souhaité"
                    />
                    <SlideBar
                      min={0}
                      max={24}
                      step={1}
                      typeLibelle="Mois"
                      libelle="Différé"
                      storeKey="simulateur.dureeDiff"
                      tooltipTxt="Vous ne souhaitez pas commencer à rembourser
                      de manière immédiate? Choisissez la durée de différé (franchise) qui vous convient "
                    />
                    <SlideBar
                      min={2}
                      max={15}
                      step={0.01}
                      typeLibelle="%"
                      libelle="Taux"
                      libelleInfo="(ht)"
                      storeKey="simulateur.taux"
                      tooltipTxt="Le taux d’intérêt sélectionné  est mentionné
                      à titre indicatif et vous permet de réaliser vos
                      différentes simulations. Il n’est pas engageant pour la
                      banque. Un taux adapté à votre profil vous sera communiqué
                      après étude de votre dossier"
                    />
                    <SlideBar
                      min={0}
                      max={300000}
                      step={100}
                      typeLibelle="Dhs"
                      libelle="Échéance"
                      libelleInfo="(ttc)"
                      storeKey="simulateur.echeance"
                      disabled
                      tooltipTxt="L’échéance est le montant à rembourser selon
                      la périodicité définie. Le calcul de l’échéance se base
                      sur le montant, la durée, le taux ainsi que sur le différé"
                    />
                    <div className="btns_action_area">
                      <a
                        href="#/detail-resultat-SMAP"
                        data-text="Détail"
                        className="flat_btn white_btn right_mr"
                      >
                        <span className="plusDepadding">Détail</span>
                      </a>
                      <p className="info_txt">
                        <img src={info_txt} alt="" />
                        <span >Le résultat de cette simulation est non contractuel
                        et revét un caractère strictement informatif</span>
                      </p>
                    </div>
                  </div>
                </li>

              </ul> :
              <DetailCapacite
                onClick={this.click1}
                max={this.props.capaciteEmprunt}
                toggle={this.toggle}
              />
            }
           </div>
         </section>
         <br />
         <br />
         <br />
         <div className="footer_contact clearfix">
           <div className="footer_logo">
             <img src={footer_logo_bankpopulaire} alt="" />
           </div>
           <div className="footer_contact_info">
               Banque Centrale Populaire- 101, boulevard Mohamed Zerktouni
             <br />
               BP 10.622 - 20100
             <br />
               Casablanca
           </div>
         </div>
         <p className="footer_rights_txt white_clr align_center">
             Copyright @ 2018 Groupe Banque Populaire -
           <a href="" className="white_clr">Mentions légales</a>
         </p>
        </div>
     );
   }
}

 SimulateurSMAP.propTypes = {
   calculEcheance: React.PropTypes.func,
   inputChange: React.PropTypes.func,
   cleanStore: React.PropTypes.func,
   calculInverse: React.PropTypes.func,
   valueToggle: React.PropTypes.func,
   montantCredit: React.PropTypes.number,
   capaciteEmprunt: React.PropTypes.number,
   nbAnnee: React.PropTypes.number,
   taux: React.PropTypes.number,
   dureeDiff: React.PropTypes.number,
   echeance: React.PropTypes.number,
   resultat: React.PropTypes.number,
   toggled: React.PropTypes.bool,
   simulateurCapaciteEmprunt: React.PropTypes.func,
   revenuNetMensuel: React.PropTypes.number,
   autreCredit: React.PropTypes.number,
   duree: React.PropTypes.number,
   capaciteEmpruntfinal: React.PropTypes.number,
   tauxHt: React.PropTypes.number,
   initialAccout: React.PropTypes.func,
   showHide: React.PropTypes.func,
   defaulteState: React.PropTypes.func,
   isChaabiClient: React.PropTypes.bool,
 };

 export default SimulateurSMAP;

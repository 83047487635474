import { connect } from 'react-redux';
import Accueil from '../components/accueil';
import {
  simulateurEcheance, simulateurCapaciteEmprunt,
  valueToggle, inputChange, cleanStore, initialAccout, showHide
} from '../action_creators';

function mapStateToProps(state) {
  return {
    showHideElm: state.demandeCredit.getIn(['isChaabiClient', 'value']),
    montantCredit: state.simulateur.getIn(['montantCredit', 'value']),
    nbAnnee: state.simulateur.getIn(['nbAnnee', 'value']),
    dureeDiff: state.simulateur.getIn(['dureeDiff', 'value']),
    taux: state.simulateur.getIn(['taux', 'value']),
    echeance: state.simulateur.getIn(['echeance', 'value']),
    revenuNetMensuel: state.simulateur.getIn(['revenuNetMensuel', 'value']),
    autreCredit: state.simulateur.getIn(['autreCredit', 'value']),
    duree: state.simulateur.getIn(['duree', 'value']),
    tauxHt: state.simulateur.getIn(['tauxHt', 'value']),
    capaciteEmprunt: state.simulateur.getIn(['capaciteEmprunt', 'value']),
    toggled: state.simulateur.getIn(['toggled', 'value']),
    capaciteEmpruntfinal: state.simulateur.getIn(['capaciteEmpruntfinal', 'value']),
    isHome: state.demandeCredit.getIn(['source', 'value']) === 'home',
    selector: state.demandeCredit.getIn(['selecctor', 'value']),
    userId: state.account.getIn(['identifiant', 'value']) || "",
    langues: state.demandeCredit.getIn(['langues', 'value']) || "fr",
    userStatus: state.account.get('authenticationStatus') === "success" ? "logged": "not-logged"
  };
}

const mapDispatchToProps = {
  simulateurEcheance, simulateurCapaciteEmprunt,
  valueToggle, inputChange, cleanStore, initialAccout, showHide
};
export default connect(mapStateToProps, mapDispatchToProps)(Accueil);

import { fromJS } from 'immutable';
const DEFAULT_DEMANDE = fromJS({
  clientbcp: { value: null },
  nom: { value: null },
  prenom: { value: null },
  telephone : { value: null },
  ville :{ value: null },
  typebien : {value: null},
  adresse : {value: null},
  indicatif : {value: null},
  echeance: { value: 4892.2 },
  taux: { value: '4.99' },
  dTaux: { value: '4.99' },
});

export default function (state = DEFAULT_DEMANDE, action) {
  switch (action.type) {
    case 'INPUT_CHANGE':
      if (action.store === 'smap') {
        return state.setIn(action.key.concat(['value']), fromJS(action.newValue));
      }
      return state;
    case 'CLEAN_STORE':
      return DEFAULT_DEMANDE;
    case 'CALCUL_MONTANT_ECHEANCE_MENSUEL_SUCCESS':
      return state.setIn(['simulateurEcheance', 'value'], fromJS(action.result));
    case 'CALCUL_MONTANT_ECHEANCE_MENSUEL':
      return state.setIn(['simulateurEcheance', 'value'], fromJS([]));
    case 'CALCUL_MONTANT_All_SUCCESS':
      return state.set('allResult', fromJS(action.result));
    case 'CALCUL_MONTANT_All':
      return state.set('allResult', fromJS([]));
    case 'CALCUL_FRAIS_SUCCESS':
      return state.set('simulateurFrais', fromJS(action.result));
    case 'CALCUL_FRAIS':
      return state.set('simulateurFrais', fromJS([]));
    case 'CALCUL_MONTANT_CAPACITE_EMPRUNT_SUCCESS':
      return state.setIn(['capaciteEmprunt', 'value'], fromJS(action.result));
    case 'CALCUL_MONTANT_CAPACITE_EMPRUNT':
      return state.setIn(['capaciteEmprunt', 'value'], fromJS([]));
    case 'CALCUL_MONTANT_CAPACITE_EMPRUNT_DEFAULT_SUCCESS':
      return state.set('simulateurCapaciteEmpruntDefault', fromJS(action.result));
    case 'CALCUL_MONTANT_CAPACITE_EMPRUNT_DEFAULT':
      return state.set('simulateurCapaciteEmpruntDefault', fromJS([]));
    case 'INTOGGLE':
      return state.setIn(['toggled', 'value'], fromJS(action.value));
    case 'SHOWHIDE':
      return state.setIn([action.key.split('.')[1], 'value'],
          !state.getIn([action.key.split('.')[1], 'value']));
    case 'CHANGE_VALUES':
      return state.withMutations((ctx) => {
        ctx.setIn(['montantCredit', 'value'], ctx.getIn(['dMontantCredit', 'value']));
        ctx.setIn(['nbAnnee', 'value'], ctx.getIn(['dNbAnnee', 'value']));
        ctx.setIn(['dureeDiff', 'value'], ctx.getIn(['dDureeDiff', 'value']));
        ctx.setIn(['taux', 'value'], ctx.getIn(['dTaux', 'value']));
        ctx.setIn(['echeance', 'value'], ctx.getIn(['dEcheance', 'value']));
      });

      case 'SMAP_SAVE_SIMULATEUR_SUCCESS':
      return state.setIn(['successIndicatorSave', 'value'], fromJS(action.result.successIndicator));
      case 'SMAP_SAVE_ERROR':
        return state.setIn(['errorSave', 'value'], true);
      case 'SMAP_SAVE_SUCCESS':
       return state.setIn(['referenceId', 'value'], fromJS(action.result.message.split(':')[1]));

       
    case 'CLEAR_STATE': return DEFAULT_DEMANDE;
    default:
      return state;
  }
}

import { connect } from 'react-redux/dist/react-redux';
import EspPersoSteps from '../components/EspacePerso/EspPersoSteps';
import { fromJS } from 'immutable';
import { showHide } from '../action_creators';

function mapStateToProps(state) {
  const maDemande = state.demandeCredit.getIn(['maDemande', 'demande']);
  const maProposition = state.demandeCredit.getIn(['maDemande', 'proposition']) || fromJS({});
  const mesDocs = state.demandeCredit.getIn(['maDemande', 'suiviDocuments']) || fromJS({});
  return {
    maDemande,
    maProposition,
    mesDocs,
    showHideElm: state.demandeCredit.getIn(['proposition', 'value']),
    showHideElmD: state.demandeCredit.getIn(['propositionD', 'value']),
    showHideElmRejetPop: state.demandeCredit.getIn(['propositionRejetPop', 'value']),
    statutEncours: maDemande.get('statut').get('libelle'),
    buttonAccProp: maProposition.get('reponseclient') === 'proposition acceptee',
    langues: state.demandeCredit.getIn(['langues', 'value']), 
  };
}
function mapDispatchToProps(dispatch) {
  return {
    showHide: (s) => dispatch(showHide(s)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EspPersoSteps);

import React, { Component } from 'react';
import { inputChange } from '../action_creators';
import { format } from '../utile/utile';
import i18n from '../i18n';
export default class Proposition extends Component {
  constructor() {
    super();
    this.change = this.change.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.langues !== this.props.langues) {
        i18n.changeLanguage(this.props.langues);
        this.forceUpdate();
    }
  }
  change() {
    this.props.dispatch(inputChange(
      this.props.annee, this.props.storeKey
    ));
  }

  render() {
    return (
      <ul className="div_container" >
        <li className="proposition_element_left">
          <span className="detail_sim_num_duree">
            {this.props.annee}
            <span>
            {i18n.t("chart4.label")}
            </span>
          </span>
        </li>
        <li className="proposition_center">
          <span className="detail_sim_num_mens">
                 {format(this.props.mensualite)}
            <span>
            {i18n.t("simulateur7.label")}
            </span>
          </span>
        </li>
        <li className="proposition_element_right">
          <button className="button_choisir" onClick={this.change}>
            <span className="detail_sim_num_choisir">
            {i18n.t("propos.label")}

            </span>
          </button>
        </li>
      </ul>
    );
  }
  }
Proposition.propTypes = {
  dispatch: React.PropTypes.func,
  annee: React.PropTypes.number,
  storeKey: React.PropTypes.string.isRequired,
  mensualite: React.PropTypes.number,
};

// Proposition.defaultProps = {
// };

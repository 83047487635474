import React, { Component } from 'react';
import i18n from '../../i18n';

class Step extends Component {

  render() {
    return (
      <li
        className={this.props.className}
        onClick={this.props.clickable ? this.props.go : () => null}
      >
        <span className="timeline_img">
          <img src={this.props.step.get('imgDefault')} alt="" className="default_icn" width="56"/>
          <img src={this.props.step.get('imgActive')} alt="" className="active_icn" width="56"/>
          <img src={this.props.step.get('imgValid')} alt="" className="passed_icn" width="56"/>
        </span>
        <h2>
        {i18n.t(this.props.step.get('label'))}

        </h2>
      </li>
    );
  }
}

Step.propTypes = {
  step: React.PropTypes.object,
  active: React.PropTypes.bool,
  valid: React.PropTypes.bool,
  className: React.PropTypes.string,
  clickable: React.PropTypes.bool,
  go: React.PropTypes.func.isRequired,
};
Step.defaultProps = {
  className: 'timeline-step',
};
export default Step;

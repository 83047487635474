import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import { syncHistoryWithStore, routerReducer, routerMiddleware } from 'react-router-redux';
import { hashHistory } from 'react-router';
import {
  INIT_APP,
  getCountries,
  getCities,
  getAgences,
  getIndicatifs,
  getPromoteurs,
  getEvenementCompanies
} from './action_creators';
import apiMiddleware from './middleware/api';
import routingMiddleware from './middleware/routing';
import toUpperCaseMiddleware from './middleware/toUpperCase';
import * as reducers from './reducers';

const hashMiddleware = routerMiddleware(hashHistory);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers({
    ...reducers,
    routing: routerReducer,
  }),
  composeEnhancers(
    applyMiddleware(apiMiddleware),
    applyMiddleware(routingMiddleware),
    applyMiddleware(hashMiddleware),
    applyMiddleware(toUpperCaseMiddleware)
  )
);

const init = () => {
  store.dispatch(INIT_APP);
  store.dispatch(getCountries());
  store.dispatch(getEvenementCompanies());
  store.dispatch(getAgences());
  store.dispatch(getCities());
  store.dispatch(getIndicatifs());
  store.dispatch(getPromoteurs());
};

const history = syncHistoryWithStore(hashHistory, store, {
  selectLocationState: (state) => state.routing,
});
init();

export default store;
export { history };

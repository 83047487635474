import { connect } from 'react-redux/dist/react-redux';
import { displayErrors, goNextState } from '../action_creators';
import FormSMAP from '../components/FormSMAP';
import { saveInfoSmap, inputChange } from '../action_creators';
import { formatDatedd } from '../utile/utile';
const mapStateToProps = (state) => ({
  progressLevel: state.demandeCredit.getIn(['progressLevel', 'value']) === 'done',
  clientDetailsStatus: state.demandeCredit.get('clientDetailsStatus'),
  isChaabiClient: state.demandeCredit.getIn(['isChaabiClient', 'value']),
  status : state.smap.getIn(['errorSave', 'value']),
  infoSmap : {
    clientbcp : state.smap.getIn(['clientbcp', 'value']),
    nom : state.smap.getIn(['nom', 'value']),
    prenom : state.smap.getIn(['prenom', 'value']),
    adresse : state.smap.getIn(['adresse', 'value']),
    telephone : state.smap.getIn(['telephone', 'value']),
    villeresidence : state.smap.getIn(['ville', 'value']),
    indicatif : state.smap.getIn(['indicatif', 'value']),
    typebien : state.smap.getIn(['typebien', 'value']),
    paysderesidence : state.smap.getIn(['paysderesidence', 'value']),
    email : state.smap.getIn(['mail', 'value']),
    numpieceidentite: state.smap.getIn(['numpieceidentite', 'value']),
    radical: state.smap.getIn(['radical', 'value']),
    dateCreation: formatDatedd(Date())
  }
});


function mapDispatchToProps(dispatch) {
  return {
    displayErrors: (a) => dispatch(displayErrors(a)),
    goNext: () => dispatch(goNextState()),
    saveInfoSmap: (infoSmap) => dispatch(saveInfoSmap(infoSmap)),
    inputChange: (...y) => dispatch(inputChange(...y)),
  };
}
function mergeProps(stateProps, dispatchProps, ownProps) {
  const infoSmap = stateProps.infoSmap;
  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    saveInfoSmap: () => dispatchProps.saveInfoSmap(infoSmap),
  });
}
export default connect(mapStateToProps, mapDispatchToProps,mergeProps)(FormSMAP);

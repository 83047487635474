import { Map } from 'immutable';
import { PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment';


function isValidCIN(value) {
  return /^[A-Z]+[0-9]+$/.test(value);
}
function isValidEmail(value) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>() [\]\\.,;:\s@"]+)*)|(".+"))@(( [[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
}

function isUnderage(day, month, year) {
  const birthdate = moment({ year, month: parseInt(month, 10) - 1, day });
  return moment().diff(birthdate, 'years') < 18;
}

function isValidMontantDuPret(coutProjet, value) {
  var coutp , valu;
  if(((typeof coutProjet) !== "undefined") && ((typeof value) !== "undefined") &&(value!=="")){

    coutp = coutProjet.replace(/\s/g, '');
    valu = (""+value).replace(/\s/g, '');
    return parseFloat(valu) <= (parseFloat(coutp) * 1.1);
  }
  return true;
}

function isBigMontantDuPret( value) {
  var   valu;
  if(((typeof value) !== "undefined") &&(value!=="")){

    valu = (""+value).replace(/\s/g, '');
    return parseFloat(valu) <= 20000000 ;
  }
  return true;
}


function isValidName(value) {
  return /^[A-Z\s]+$/.test(value);
}

function isValideGSM(value) {
  try {
    var phoneUtil = PhoneNumberUtil.getInstance();
    var number = phoneUtil.parseAndKeepRawInput(value, 'MA');
    return phoneUtil.isValidNumber(number);
  }catch(e) {

  }
}
function isSeventies(day, month, year, dureesouhaitable) {
  const birthdate = moment({ year, month: parseInt(month, 10) - 1, day });
  const age = moment().diff(birthdate, 'months', true) + parseInt(dureesouhaitable * 12, 10);
  return age > 840;
}
function isMaxDuree(dureesouhaitable) {
  return dureesouhaitable > 25;
}
function isNumeric(value) {
  return /^[+]?[0-9 ]+(\.[0-9 ]+)?$/.test(value);
}
function isAlphaNumerique(value) {
  return /^[A-Z0-9 _]*[A-Z0-9][A-Z0-9 _]*$/.test(value);
}
function isAlphaNum(value) {
  return /^[0-9a-z]+$/i.test(value);
}

function isPositiveNumber(value) {
  if (isEmpty(value)) return false;
  let valueWithoutSpaces = (value + "").replace(/\s/g, '');
  return valueWithoutSpaces === '0' || /^[1-9]\d*$/.test(valueWithoutSpaces);
}

function isValidRevenu(value) {
  if (!isPositiveNumber(value)) return false;
  try {
    let intValue = parseInt(value.replace(/\s/g, ''));
    return intValue >= 2000;
  } catch (e) {
    return false;
  }
}

function isEmpty(value) {
  return (value === undefined || value === null);
}

export default function (state = new Map(), action) {
  if (action.type !== 'INPUT_CHANGE' || action.store !== 'demandeCredit') {
    return state;
  }
  switch (action.key.join('.')) {
    case 'date_naissanceDay':
    return state.withMutations((ctx) => {
      const seventies = isSeventies(
          ctx.get('date_naissanceDay') ? ctx.get('date_naissanceDay').get('value') : '1',
          ctx.get('date_naissanceMonth') ?
          ctx.get('date_naissanceMonth').get('value') : '1',
          ctx.get('date_naissanceYear') ?
          ctx.get('date_naissanceYear').get('value') : '2000',
          ctx.get('nbAnnee') ?
          ctx.get('nbAnnee').get('value') : '25'
        )
          ctx.setIn(
            ['nbAnnee'].concat(['errors', 'nbAnnee.isSeventy']),seventies);

        const underage=  isUnderage(
          action.newValue,
          state.get('date_naissanceMonth') ?
            state.get('date_naissanceMonth').get('value') : '1',
          state.get('date_naissanceYear') ? state.get('date_naissanceYear').get('value') : '1'
        )
          ctx.setIn(
            ['date_naissanceDay'].concat(['errors', 'date_naissance.underage']),underage );
      });
    case 'date_naissanceMonth':

    return state.withMutations((ctx) => {
      const seventies = isSeventies(
          ctx.get('date_naissanceDay') ? ctx.get('date_naissanceDay').get('value') : '1',
          ctx.get('date_naissanceMonth') ?
          ctx.get('date_naissanceMonth').get('value') : '1',
          ctx.get('date_naissanceYear') ?
          ctx.get('date_naissanceYear').get('value') : '2000',
          ctx.get('nbAnnee') ?
          ctx.get('nbAnnee').get('value') : '25'
        )
          ctx.setIn(
            ['nbAnnee'].concat(['errors', 'nbAnnee.isSeventy']),seventies);

          ctx.setIn(
              ['date_naissanceDay'].concat(['errors', 'date_naissance.underage']),
              isUnderage(
                state.get('date_naissanceDay') ? state.get('date_naissanceDay').get('value') : '1',
                action.newValue,
                state.get('date_naissanceYear') ?
                  state.get('date_naissanceYear').get('value') : '1'
              )
            );
      });
    case 'date_naissanceYear':
     /* state.withMutations((ctx) => {
        const seventies = isSeventies(
            ctx.get('date_naissanceDay') ? ctx.get('date_naissanceDay').get('value') : '1',
            ctx.get('date_naissanceMonth') ?
            ctx.get('date_naissanceMonth').get('value') : '1',
            ctx.get('date_naissanceYear') ?
            ctx.get('date_naissanceYear').get('value') : '2000',
            ctx.get('nbAnnee') ?
            ctx.get('nbAnnee').get('value') : '25'
          )
            ctx.setIn(
              ['nbAnnee'].concat(['errors', 'nbAnnee.isSeventy']),seventies);
            ctx.setIn(
                ['date_naissanceDay'].concat(['errors', 'date_naissance.underage']),
                isUnderage(
                  state.get('date_naissanceDay') ? state.get('date_naissanceDay').get('value') : '1',
                  state.get('date_naissanceMonth') ?
                    state.get('date_naissanceMonth').get('value') : '1',
                  action.newValue
                )
              );
        });*/
       return state.setIn(
          ['date_naissanceDay'].concat(['errors', 'date_naissance.underage']),
          isUnderage(
            state.get('date_naissanceDay') ? state.get('date_naissanceDay').get('value') : '1',
            state.get('date_naissanceMonth') ?
              state.get('date_naissanceMonth').get('value') : '1',
            action.newValue
          )
        ).setIn(
              ['nbAnnee'].concat(['errors', 'nbAnnee.isSeventy']),isSeventies(
                state.get('date_naissanceDay') ? state.get('date_naissanceDay').get('value') : '1',
                state.get('date_naissanceMonth') ?
                state.get('date_naissanceMonth').get('value') : '1',
                state.get('date_naissanceYear') ?
                state.get('date_naissanceYear').get('value') : '2000',
                state.get('nbAnnee') ?
                state.get('nbAnnee').get('value') : '25'
          ));
    case 'nbAnnee':
      return state.withMutations((ctx) => {
        const value = ctx.get('nbAnnee') ? ctx.get('nbAnnee').get('value') : '25'
        const seventies = isSeventies(
            ctx.get('date_naissanceDay') ? ctx.get('date_naissanceDay').get('value') : '1',
            ctx.get('date_naissanceMonth') ?
            ctx.get('date_naissanceMonth').get('value') : '1',
            ctx.get('date_naissanceYear') ?
            ctx.get('date_naissanceYear').get('value') : '2000',
          value
          );
        const isMax = isMaxDuree(value);
        const isPositive = isPositiveNumber(value);
        ctx.setIn(['nbAnnee'].concat(['errors', 'nbAnnee.wrongFormat']), !isPositive);
        ctx.setIn(
              ['nbAnnee'].concat(['errors', 'nbAnnee.isSeventy']), seventies);
        ctx.setIn(
                ['nbAnnee'].concat(['errors', 'nbAnnee.isMaxDuree']), isMax);
      });
    case 'dureeDiff':
      return state.setIn(
        action.key.concat(['errors', 'surfacebien.wrongFormat']),
        !isPositiveNumber(action.newValue)
      );
    case 'nom':
    return state.setIn(
      action.key.concat(['errors', 'nom.wrongFormat']),
      !isValidName(action.newValue)
    );
    case 'demandeCredit':

    return state.setIn(
      action.key.concat(['errors', 'autreactivite.wrongFormat']),
      !isValidName(action.newValue)
    );
    case 'prenom':
    return state.setIn(
      action.key.concat(['errors', 'prenom.wrongFormat']),
      !isValidName(action.newValue)
    );
    case 'nbrenfants':
      return state.setIn(
        action.key.concat(['errors', 'nbrenfants.wrongFormat']),
        !isPositiveNumber(action.newValue)
      );
    case 'surfacebien':
    return state.setIn(
      action.key.concat(['errors', 'surfacebien.wrongFormat']),
      !isPositiveNumber(action.newValue)
    );

    case 'coutprojet':
    return state.withMutations((ctx) => {
      ctx.setIn(['montantCredit', 'errors','montantCredit.isNonValid'],  !isValidMontantDuPret(action.newValue , state.getIn(['montantCredit', 'value'])));
      ctx.setIn(action.key.concat(['errors', 'coutprojet.wrongFormat']), !isPositiveNumber(action.newValue));
    });

    case 'employeur':
    return state.setIn(
      action.key.concat(['errors', 'employeur.wrongFormat']),
      !isAlphaNum(action.newValue)
    );
    case 'apportpersonnel':
    return state.setIn(
      action.key.concat(['errors', 'apportpersonnel.wrongFormat']),
      !isPositiveNumber(action.newValue)
    );
    case 'mail':
    return state.setIn(
      action.key.concat(['errors', 'mail.wrongFormat']),
      !isValidEmail(action.newValue)
    );

    case 'montantCredit':
    return state.setIn(
      action.key.concat(['errors', 'montantCredit.isNonValid']),
      !isValidMontantDuPret(state.getIn(['coutprojet', 'value']) , action.newValue)
    )
    .setIn(
      ['montantCredit','errors', 'montantCredit.isBig'],
      !isBigMontantDuPret(state.getIn(['montantCredit', 'value']))
      )
    .setIn(
        action.key.concat(['errors', 'montantCredit.wrongFormat']),
        !isPositiveNumber(action.newValue)
      );

    case 'telephone':
    return state.withMutations((ctx) => {
      ctx.setIn(
        action.key.concat(['errors', 'telephone.wrongFormat']),
        !isValideGSM(ctx.getIn(['indicatif', 'value']).concat(action.newValue))
      );
    });
    case 'numpieceidentite':
      // TODO: check if is it 1 or 0 CIN validation
    if (state.getIn(['pieceidentite', 'value']) === '1') {
      return state.setIn(
        action.key.concat(['errors', 'numpieceidentite.wrongFormat']),
        !isValidCIN(action.newValue)
      );
    } else {
      return state.setIn(
        action.key.concat(['errors', 'numpieceidentite.wrongFormat']),
        !isAlphaNumerique(action.newValue)
      );
    }
    case 'revenu':
    return state.withMutations((ctx) => {
      const value = ctx.get('revenu') ? ctx.get('revenu').get('value') : '0';
      ctx.setIn(
        ['revenu'].concat(['errors', 'revenu.isValid']),
        !isValidRevenu(value));
    });
    case 'anciente':
    return state.setIn(
      action.key.concat(['errors', 'anciente.wrongFormat']),
      !isNumeric(action.newValue)
    );
    case 'autrerevenu':
    return state.setIn(
      action.key.concat(['errors', 'autrerevenu.wrongFormat']),
      !isPositiveNumber(action.newValue)
    );
    case 'autresecheances':
      return state.setIn(
        action.key.concat(['errors', 'autresecheances.wrongFormat']),
        !isPositiveNumber(action.newValue)
      );
    case 'autresfrais':
      return state.setIn(
        action.key.concat(['errors', 'autresfrais.wrongFormat']),
        !isPositiveNumber(action.newValue)
      )
    case 'financconjoint':
      return state.setIn(
        action.key.concat(['errors', 'financconjoint.wrongFormat']),
        !isPositiveNumber(action.newValue)
      )
    default:
    return state;
  }
}

import { Map } from 'immutable';
import { PhoneNumberUtil } from 'google-libphonenumber';
const tva = 0.1;
function calculInverse(echeance, taux, dureeDiff, nbAnnee) {
  const divis = echeance * (1 - (Math.pow(1 + taux
    / 1200 * (1 + tva), -(nbAnnee * 12 - dureeDiff))));
  const quot = ((taux / 1200) * (1 + tva)) * (1 + taux / 1200
    * (1 + tva) * dureeDiff);
  const resInv = (divis / quot).toFixed(2);
  return resInv;
}
function calculEcheance(montantCredit, taux, dureeDiff, nbAnnee) {
  montantCredit = parseFloat(montantCredit)
  let resultat = 0;
  const diviseur = (montantCredit + (montantCredit *
    taux / 100 * (1 + tva) * dureeDiff / 12)) *
    ((taux / 1200) * (1 + tva));
  const quotient = 1 - Math.pow((1 + (taux / 1200) * (1 + tva)),
    (-(nbAnnee * 12 - dureeDiff)));
  if (taux !== 0) {
    resultat = (diviseur / quotient).toFixed(2);
  } else {
    resultat = montantCredit / nbAnnee;
  }
  return resultat;
}
function isValideGSM(value) {
  try {
    var phoneUtil = PhoneNumberUtil.getInstance();
    var number = phoneUtil.parseAndKeepRawInput(value, 'MA');
    return phoneUtil.isValidNumber(number);
  }catch(e) {

  }
}
export default function (state = new Map(), action) {
  if (action.type !== 'INPUT_CHANGE' || action.store !== 'smap') {
    return state;
  }
  switch (action.key.join('.')) {
    case 'montantCredit':
      return state.setIn(['echeance', 'value'],
        calculEcheance(state.getIn(['montantCredit', 'value']),
          state.getIn(['taux', 'value']),
          state.getIn(['dureeDiff', 'value']),
          state.getIn(['nbAnnee', 'value']))
      );
    case 'taux':

      return state.setIn(['echeance', 'value'],
        calculEcheance(state.getIn(['montantCredit', 'value']),
          state.getIn(['taux', 'value']),
          state.getIn(['dureeDiff', 'value']),
          state.getIn(['nbAnnee', 'value']))
      );
    case 'dureeDiff':

      return state.setIn(['echeance', 'value'],
        calculEcheance(state.getIn(['montantCredit', 'value']),
          state.getIn(['taux', 'value']),
          state.getIn(['dureeDiff', 'value']),
          state.getIn(['nbAnnee', 'value']))
      );
    case 'nbAnnee':
      return state.setIn(['echeance', 'value'],
        calculEcheance(state.getIn(['montantCredit', 'value']),
              state.getIn(['taux', 'value']),
              state.getIn(['dureeDiff', 'value']),
              state.getIn(['nbAnnee', 'value']))
            );
    case 'echeance':
      if (state.getIn(['echeance', 'value']) < 500000) {
        return state.setIn(['montantCredit', 'value'],
          calculInverse(state.getIn(['echeance', 'value']),
            state.getIn(['taux', 'value']),
            state.getIn(['dureeDiff', 'value']),
            state.getIn(['nbAnnee', 'value']))
        );
      } else {
        return state.setIn(['montantCredit', 'value'], '100000');
      }
    case 'telephone' :
      return state.withMutations((ctx) => {
        ctx.setIn(
        action.key.concat(['errors', 'telephone.wrongFormat']),
       !isValideGSM(ctx.getIn(['indicatif', 'value']).concat(action.newValue))
     );
      });

    default:
      return state;
  }
}

import React, { Component } from 'react';
import Text from '../containers/Text';
import NextButton from '../containers/NextButton';
import Popin from '../containers/Popin'
import { verifyPassword} from '../utile/utile';
class ChangePassword extends Component {

  constructor(props,context){
    super(props,context);
    this.validate = this.validate.bind(this);
    this.successReturn = this.successReturn.bind(this)
    this.state = { 
      passwordIdentiqueError: false,
      passwordFormatError : false
     };
  }
  componentWillMount(){
    this.props.checkExpirationPassword();
  }
  validate() {
    if (!(this.props.password===this.props.confirmPass)){
      this.setState({ passwordIdentiqueError: true, passwordFormatError:false }, () => {
      });

 
    }else if (verifyPassword(this.props.password)){
      this.props.definePassword();
      this.setState({
        passwordFormatError:false,
        passwordIdentiqueError:false
      })
    }
    else{
      this.setState({
        passwordFormatError:true,
        passwordIdentiqueError:false});
    }
    this.forceUpdate();
    }
    successReturn(){
      this.props.history.push('/')
    }
  


    renderSuccess() {
    return (
      <div>
            <div>
        <div className="espace_perso_bandeau mb35">
          <div className="detail_sim_heading">
            <p className="slide_txt bold_style" style={{ fontSize: '31' }}>
            Bonjour {this.props.civilite}. {this.props.prenom} {this.props.nom},
            </p>
            <p className="slide_txt bold_style" style={{ fontSize: '31' }}>
          Bienvenue dans votre espace personnel
            </p>
          </div>
        </div>
        <center>
        <div style={{ color: '#df7a15', fontSize: '18' }}>Afin de valider votre demande, nous remercions de bien vouloir définir le mot de passe</div>
        <br />
        <br />
        <div className="control_group mb10 clearfix">
          <div className="left_cls_token">
            <Text maxLength="35" storeKey="demandeCredit.define.password" type="password">
                Mot de passe
            </Text>
          </div>
          <div className="left_cls_token">
            <Text maxLength="35" storeKey="demandeCredit.define.confirmPass" type="password">
                Confirmation mot de passe
            </Text>
          </div>
        </div>
        {this.state.passwordFormatError===true && <div className="error">Votre mot de passe doit comporter un minimum de 8 caractères, se composer de chiffres et de lettres et des caractères spéciaux (. , ; : ! ? @ & _ - *)</div> }
        {this.state.passwordIdentiqueError===true && <div className="error">les mots de passe doivent être identiques</div>  }  
        {this.props.definePassError==='error' &&  <div className="error">La réinitialisation de votre mot de passe a échoué, merci de bien vouloir réessayer</div>  }
        <br />
        <br />
        <NextButton
          action={this.annuler}
          label={'Annuler'}
          dataTxt={'Annuler'}
          isFormulaire
        />
        <NextButton
          action={this.validate}
          label={'Valider'}
          dataTxt={'Valider'}
          isFormulaire
        />
        </center>
        {this.props.definePass==='success' &&
        <Popin
            classCss="flat_btn purple_btn more_padding"
            classCssWhite="flat_btn white_btn right_mr"
            dataText="Ok"
            datalabel="Ok"
            showHideElm={false}
            storeKey="demandeCredit.newDemandeBlock"
            popin_modals="popin_dialog"
            popinTitle="Votre mot de passe a été modifié avec succès"
            popinIcn={this.props.popinIcnInfo}
            withoneButton
            action={this.successReturn}
          />
      }
     
      </div>
      </div>
    );
  }
  renderError() {
    return (
      <div>
        <br /><br /><br /><br /><br /><br /><br /><br />
        <center>
        <div className="confirmation_msg">
          <p>
          Ce lien a expiré, merci de bien vouloir envoyer un autre mail depuis l'espace personnel
          </p>
        </div>
        </center>
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      </div>
    );
  }
  render() {
    return (
      <div>
      {(() => {
        switch (this.props.verifPasswordStatus) {
          case 'success':
            return this.renderSuccess();
          case 'error':
            return this.renderError();
          default:
            return null;
        }
      })()}
      </div>
    );
  }
}


export default ChangePassword;

/* eslint-disable */
import React, { Component } from 'react';
import { inputChange } from '../../action_creators';
import i18n from '../../i18n';
import PdfConditionGeneral from '../../assets/docs/CONDITIONS_GENERALES_Immo.pdf';



class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      name: Math.random().toString(36).substring(7),
    };
  }

  onChange() {
    const values = this.props.items
      .map(item => (this.refs[`${this.state.name}-${item.label}`].checked ? item.value : false))
      .filter(value => value != null);
    return this.props.dispatch(inputChange(values, this.props.storeKey));
  }

  render() {
    return (
      <div className="radio-input">
        <div className="check_div">
          <div className="radio-label">{this.props.children}</div>
          <div className={this.props.isclient ? 'inline_checkboxe' : 'checkbox-container-block'}>
            {this.props.items.map(item => (
              <div
                className={this.props.isclient ? '' : 'bcp-checkbox'}
                key={`checkbox-${item.label}`}
              >

                <input
                  id={`${this.state.name}-${item.label}`} type="checkbox" name="general_terms"
                  className={this.props.isclient ? 'undisplayed' : ''}
                  ref={`${this.state.name}-${item.label}`}
                  checked={this.props.value !== undefined && this.props.value.find(value => value === item.value)}
                //  checked={this.props.value}
                  value={item.value}
                  onChange={this.onChange}
                  disabled={this.props.disabled || item.disabled || false}
                />
                <label
                  htmlFor={`${this.state.name}-${item.label}`}
                  className={this.props.isclient ? 'bigDark_checkbox_mask' : 'avantage_desc'}
                >
                </label>
                <span className="text">
                {item.label} &nbsp;
                {item.tag ? <a
                  className="avantage_desc"
                  href={PdfConditionGeneral}
                  target = "_blank"

                >
                  {i18n.t("titleAv47.label")}
                </a> : ''} {(this.props.langues ==='ar' && this.props.trouve) && i18n.t("recap20.label")}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

Checkbox.propTypes = {
  dispatch: React.PropTypes.func.isRequired,
  items: React.PropTypes.array.isRequired,
  storeKey: React.PropTypes.string.isRequired,
  value: React.PropTypes.arrayOf(React.PropTypes.string),
  disabled: React.PropTypes.bool,
  isLink: React.PropTypes.bool,
  children: React.PropTypes.node,
  isclient: React.PropTypes.bool,
  name: React.PropTypes.string,
};

export default Checkbox;

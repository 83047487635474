/* eslint-disable */
import React, { Component } from 'react';
import Timeline from '../containers/Timeline';
import Header from '../containers/Header';
import footer_logo_bankpopulaire from '../assets/images/footer_logo_bankpopulaire.jpg'
import i18n from '../i18n';
import PdfConditionGeneral from '../assets/docs/CONDITIONS_GENERALES_Immo.pdf';
import PdfMentionsLegal from '../assets/docs/Mentionslegale.pdf';
import { hashHistory } from 'react-router';
import TagManager from 'react-gtm-module';
import { isLoged } from "../utile/utile"
class Layout extends Component {

  /* componentDidMount() {
  }
  componentWillUnMount() {

  }
  handleScroll() {

  } */
  handleClick = (e, selector) => {
    e.preventDefault();
    if (document.querySelector(selector)) {
      document.querySelector(selector) && document.querySelector(selector).scrollIntoView({
        behavior: "smooth",
      });
    } else {
      this.props.scrolllToSection(this.props.isHome ? "home" : "other", selector);
      hashHistory.push('/')
    }
  }
  render() {
    return (
      <div className="unprentable main_body">
        <Header />
        <section className="presentation_area">
          <div className="presentation_content">
            <p className="accompany_txt white_clr uppercase_txt txt_shadow">
              {i18n.t("gammeOffre.label")}
            </p>
          </div>
        </section>
        <section className="section_etapes align_center">
          <div className="section_description_container">
            <h2
              className="title_section orange_clr width_small_separetor '\
              dark_separator uppercase_txt"
            >
              {i18n.t("formulaire.label")}
            </h2>
            <p className="strong_section_desc uppercase_txt">
              {i18n.t("produitBP.label")}

            </p>
          </div>
        </section>
        <div className="">
          <Timeline />
        </div>
        {this.props.children}
        <footer className="main_footer">
          <div className="main_footer_links">
            <ul className="clearfix">
              <li>
                <a onClick={() => {
                  TagManager.dataLayer({
                    dataLayer: isLoged(this.props.userId, {
                      event:"user_navigation",
                      item: "footer",
                      target: "acceuil",
                      langue: this.props.langues,
                      userStatus: this.props.userStatus,
                      BU: "particulier"
                    })
                  })
                  hashHistory.push('/')}} >
                  {i18n.t("titleAv45.label")}
                </a>
              </li>
              <li>
                <a href="#faq_section"
                  onClick={(e) => {
                    TagManager.dataLayer({
                      dataLayer: isLoged(this.props.userId, {
                        event:"user_navigation",
                        item: "footer",
                        target: "FAQ",
                        langue: this.props.langues,
                        userStatus: this.props.userStatus,
                        BU: "particulier"
                      })
                    })
                    this.handleClick(e, "#faq_section")}}
                >
                  {i18n.t("titleAv25.label")}
                </a>
              </li>
              <li>
                <a href="#contact_section"
                  onClick={(e) => {
                    TagManager.dataLayer({
                      dataLayer: isLoged(this.props.userId, {
                        event:"user_navigation",
                        item: "footer",
                        target: "contactez-nous",
                        langue: this.props.langues,
                        userStatus: this.props.userStatus,
                        BU: "particulier"
                      })
                    })
                    this.handleClick(e, "#contact_section")}}
                >
                  {i18n.t("titleAv46.label")}
                </a>
              </li>
              <li>
                <a className="not_scrollable"
                  href={PdfConditionGeneral}
                  target="_blank"
                  onClick={()=> {
                    TagManager.dataLayer({
                      dataLayer: isLoged(this.props.userId, {
                        event:"user_navigation",
                        item: "footer",
                        target: "conditions_utilisation",
                        langue: this.props.langues,
                        userStatus: this.props.userStatus,
                        BU: "particulier"
                      })
                    })
                  }}
                >
                  {i18n.t("titleAv47.label")}
                </a>
              </li>
            </ul>
          </div>
          <div className="footer_contact clearfix">
            <div className="footer_logo">
              <img src={footer_logo_bankpopulaire} alt="" />
            </div>
            <div className="footer_contact_info">
              {i18n.t("titleAv48.label")}
              <br />
              {i18n.t("titleAv49.label")}
              <br />
              {i18n.t("titleAv50.label")}
            </div>
          </div>
          <p className="footer_rights_txt white_clr align_center">
            Copyright @ 2018 Groupe Banque Populaire -
            <a className="white_clr"
              href={PdfMentionsLegal}
              target="_blank"
            >Mentions légales</a>
          </p>
        </footer>
      </div>
    );
  }
}
Layout.propTypes = {
  children: React.PropTypes.node.isRequired,
  dispatch: React.PropTypes.func,
};

export default Layout;

import React, { Component } from 'react';
import { inputChange ,goNextState } from '../action_creators';
import impressionIcn from '../assets/images/impression_instantanee_icn.png';
import ouveteSamedi from '../assets/images/ouverte_samedi_icn.png';
class MapButton extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.dispatch(
      inputChange(this.props.agence.get('value').toString(), 'demandeCredit.agence')
    );
    this.props.dispatch(
      inputChange(this.props.agence.get('codeVille').toString(), 'demandeCredit.cityAgence')
    );
    this.props.dispatch(
      inputChange(this.props.agence.get('label').toString(), 'demandeCredit.libelleagence')
    );
    this.props.dispatch(goNextState());
  }
  render() {
    return (
      <div className="infowindow_container">
        <h5 className="map-title__txt">AGENCE {this.props.agence.get('label')}</h5>
        <div className="details_container">
          <div className="map-adr-txt">{this.props.agence.get('adresse')}</div>
          <div className="map-adr-txt">{this.props.agence.get('telephone')}</div>
        </div>
        <div className="options_infos">

        {this.props.agence.get('impressionCarte') === 'oui' ?
          <div>
            <img src={impressionIcn} alt="" width="22" />
            <span>Impression instantanée</span>
          </div>
          : null
        }
      {this.props.agence.get('ouverteSamedi') === 'oui' ?
        <div>
          <img src={ouveteSamedi} alt="" width="17" />
          <span>Ouverte samedi</span>
        </div>
         : null
        }
        </div>
        <div className="align_center">
          <button
            type="button"
            data-text="SéLECTIONNER CETTE AGENCE"
            className="flat_btn purple_btn select-agence_btn"
            onClick={this.onClick}
          >
            <span>SéLECTIONNER CETTE AGENCE
            </span>
          </button>
        </div>
      </div>
    );
  }
}
MapButton.propTypes = {
  agence: React.PropTypes.object,
  selected: React.PropTypes.bool,
  dispatch: React.PropTypes.func.isRequired,
};

export default MapButton;

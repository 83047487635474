import { connect } from 'react-redux';
import SelectEvent from '../../components/Inputs/SelectEvent';

const mapStateToProps = (state, props) => {
  const [store, ...storeKey] = props.storeKey.split('.');
  storeKey.push('value');
  return {
    ...props,
    items: state.configuration.get('evenementCompanies'),
    value: state[store].getIn(storeKey),
    withError: false,
    style: {width: '33%', marginBottom: '0.1rem', marginTop: '0.1rem'},
  };
};

export default connect(mapStateToProps)(SelectEvent);

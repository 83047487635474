import { connect } from 'react-redux/dist/react-redux';
import VerificationPassword from '../components/VerificationPassword';
import { inputChange, verifPassword,definePassword } from '../action_creators';

function mapStateToProps(state, props) {
  return {
    langues: state.demandeCredit.getIn(['langues', 'value']), 
    civilite: state.demandeCredit.getIn(['request', 'result', 'demande', 'civilite']),
    prenom: state.demandeCredit.getIn(['request', 'result', 'demande', 'first_name']),
    nom: state.demandeCredit.getIn(['request', 'result', 'demande', 'last_name']),
    verifPasswordStatus: state.demandeCredit.getIn(['request', 'status']),
    passwordUpdated: state.account.getIn(['definePass','status']),
    password : state.demandeCredit.getIn(['password','value']),
    confirmPass : state.demandeCredit.getIn(['confirmPass','value']),
    tokenRequest: {
      referenceId: props.params.ref,
      token: props.params.token,
    },
    definePassword: {
      mail: props.params.email,
      password: state.demandeCredit.getIn(['confirmPass','value']),
      token: props.params.token,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    inputChange: (...y) => dispatch(inputChange(...y)),
    verifPassword: (s) => dispatch(verifPassword(s)),
    definePassword: (s) => dispatch(definePassword(s)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const tokenRequest = stateProps.tokenRequest;
  const definePassword = stateProps.definePassword;
  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    verifPassword: () => dispatchProps.verifPassword(tokenRequest),
    definePassword: ()  => dispatchProps.definePassword(definePassword),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(VerificationPassword);

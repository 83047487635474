import React, { Component } from 'react';
import SelectCity from '../../containers/Inputs/SelectCity';
import Select from '../../containers/Inputs/Select';
import { inputChange } from '../../action_creators';
import {fromJS, List} from 'immutable';
import sortBy from 'lodash/sortBy';
class SelectAgence extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.agence !== this.props.agence ) {
       this.props.dispatch(inputChange((this.props.agenceList!==null?this.props.agenceList
        .filter(item => item.get('value') ===
       this.props.agence).get(0).get('label'):null), 'demandeCredit.libelleagence'));
    }
  }
  render() {
    const agenceOptions = this.props.agenceList
                                    .filter(item => item.get('codeVille') === this.props.cityAgence);
    const agenceOptionsSorted = sortBy(agenceOptions.toJS(), o => o.label);
    return (
      <div className="control_group clearfix">
        <div className="left_cls">
        <SelectCity
            storeKey="demandeCredit.cityAgence"
          >ville </SelectCity>
        </div>
        <div className="right_cls">
          <Select
              items={fromJS(agenceOptionsSorted)}
              storeKey="demandeCredit.agence"
            >agence </Select>
        </div>
      </div>
    );
  }
}

SelectAgence.propTypes = {
  agenceList: React.PropTypes.instanceOf(List).isRequired,
  cityAgence: React.PropTypes.string,
  currentAgence: React.PropTypes.string,
  dispatch: React.PropTypes.func.isRequired,
};
export default SelectAgence;

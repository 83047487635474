/* eslint-disable */
import React, { Component } from 'react';
import Popin from '../../containers/Popin';
import Text from '../../containers/Text';
import Checkbox from '../../containers/Inputs/Checkbox';
import image from '../../assets/images/popin_personal_space_icn.png';
import icn from '../../assets/images/img/popin_info_icn.png';
import { hashHistory } from 'react-router';
import Title from '../Title';
import AttentionImage from '../../assets/images/attention.jpg';
import i18n from '../../i18n';
import TagManager from 'react-gtm-module';
import {isLoged} from '../../utile/utile'

const errorMessages = {
  '-1': 'Authentication error',
  1: 'votre compte est desactivé',
  2: 'Client non trouvé',
  4: 'votre couple identifiant / mot de passe n\'est pas valide',
  5: 'Nous sommes désolés, votre compte a été bloqué en raison d’une activité suspicieuse sur votre compte, Pour rétablir l’accès à votre espace personnel, nous vous invitons à contacter l’administrateur à l’adresse digitalys.immo@cpm.co.ma',
};

const errorRestMDP = {
  'EmailNotFound': "Il n'existe aucune demande avec l'identifiant renseigné. Merci de bien vouloir vérifier votre identifiant.",
  'RadicalNotFound': "Il n'existe aucune demande avec l'identifiant renseigné. Merci de bien vouloir vérifier votre identifiant.",
}

let notInCoordonnes = true;


class HeaderAuth extends Component {
  componentWillMount() {
    this.shoHidePop = this.shoHidePop.bind(this);
    this.action = this.action.bind(this);
    this.switchPopin = this.switchPopin.bind(this);
    this.authentication = this.authentication.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.forgetPassword = this.forgetPassword.bind(this);
    this.redirect = this.redirect.bind(this);
    this.chaabinetPopin = this.chaabinetPopin.bind(this);
    this.getSignalitique = this.getSignalitique.bind(this);
    this.okChaabiNet = this.okChaabiNet.bind(this);
    this.redirectPageAcceuil = this.redirectPageAcceuil.bind(this);
    //this.state= {showChaabiNetPopup:false}
    this.setState({ showChaabiNetPopup: false });
    //this.state= {showErroPopup:false, errorMessages: false}
    this.setState({ showErroPopup: false, errorMessages: false,gtmErrorLogin:false });
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.clientDetailsStatus !== this.props.clientDetailsStatus) && (this.props.clientDetailsStatus !== undefined) && (this.props.clientDetailsStatus !== 'error') && (this.props.clientDetailsStatus !== 'loading')) {
      hashHistory.push('/infos-perso');
      this.props.show('demandeCredit.yesChaabiClient');
    }

    if (prevProps.fpmail !== this.props.fpmail) {
      this.props.inputChange(this.props.fpmail, 'account.fpmail');
    }




  }


  getSignalitique() {
    this.props.inputChange(false, 'demandeCredit.gtmlogin');
    this.props.searchClient();
  }
  shoHidePop(s) {
    this.props.show(s);
    document.body.classList.toggle('hiddenScroll');
  }
  action() {
    if (this.props.popin === 'connexion') {
      this.props.switchPopin();
    } else { this.showHidePop('demandeCredit.authentificate'); }
  }
  /* switchPopin() {
     if (this.props.fpmail) {
       if (  this.props.fpmail.indexOf('@')==-1 || this.props.fpmail.indexOf('.')==-1) {
         if (this.props.demandeByMailOrRadical != null && this.props.demandeByMailOrRadical.length>0){
         this.shoHidePop('demandeCredit.authentificate');
         this.setState({showChaabiNetPopup:true});
         }
         else{
           this.setState({showErroPopup:true});
         }
       }
       else{
         if (this.props.demandeByMailOrRadical != null &&   this.props.demandeByMailOrRadical.length>0){
           this.props.switchPopin('demandeCredit.showMdpOublie');
       }
       else{
         this.setState({showErroPopup:true});
       }
     }
     }
   }*/
  switchPopin() {
    TagManager.dataLayer({
      dataLayer:{
          event: "password_forgot",
          langue: this.props.langues,
          BU: 'particulier'
      }})
    if (this.props.fpmail) {
      if (this.props.fpmail.indexOf('@') === -1 || this.props.fpmail.indexOf('.') === -1) {
        this.shoHidePop('demandeCredit.authentificate');
        this.setState({ showChaabiNetPopup: true });
      }
      else {
        this.props.switchPopin('demandeCredit.showMdpOublie');
      }
    }
    else {
      this.setState({ errorMessages: true });
    }
  }
  authentication() {
    this.props.authentication();
  }
  chaabinetPopin(isLoginCanceled) {
    isLoginCanceled && TagManager.dataLayer({
         dataLayer:{
            event: "login_canceled",
            langue: this.props.langues,
            BU: 'particulier'
        }})
    TagManager.dataLayer({
          dataLayer: isLoged(this.props.userId,{
              event: "form_pret_step_1",
              item: true,
              origin: this.props.origin,
              userStatus: this.props.userStatus,
              langue: this.props.langues,
              BU: 'particulier'
          }),
    })
    this.shoHidePop('demandeCredit.isChaabiClient');
    this.shoHidePop('demandeCredit.yesChaabiClient');
    this.props.chaabiClient(true);
    document.body.classList.remove('hiddenScroll');
  }
  resetPassword() {
    this.props.resetPassword();
  }
  forgetPassword() {
    this.props.forgetPassword();
  }

  okChaabiNet() {
    this.setState({ showChaabiNetPopup: false });
    window.location = "https://oauth2.gbp.ma/?cid=73829FB9-57A2-4BE6-9A95-7926E703A6C0&aid=742ef930-3ea4-44d4-a4eb-be04b15849ac";
  }

  redirectPageAcceuil() {
    this.setState({ showErroPopup: false });
    window.location = '/canalcredit';
  }

  redirect() {
    this.shoHidePop('simulateur.isChaabiClient');
    this.shoHidePop('smap.isChaabiClient');
    this.shoHidePop('demandeCredit.isChaabiClient');
    this.props.chaabiClient(false);
    hashHistory.push('/infos-perso');
    TagManager.dataLayer({
      dataLayer: isLoged(this.props.userId,{
          event: "form_pret_step_1",
          item: false,
          origin: this.props.origin,
          userStatus: this.props.userStatus,
          langue: this.props.langues,
          BU: 'particulier'
      }),
    })
  }

  searchSuccess() {
    TagManager.dataLayer({
      dataLayer:{
         event: "login-in_success",
         langue: this.props.langues,
         BU: 'particulier'
     }})
    if (notInCoordonnes) {
      notInCoordonnes = false;
      hashHistory.push('/infos-perso');
    } else return null;

  }
  searchError() {
     if (this.props.gtmErrorLogin == false){
      TagManager.dataLayer({
        dataLayer:{
           event: "login-in_failure",
           langue: this.props.langues,
           BU: 'particulier'
       }})
       this.props.inputChange(true, 'demandeCredit.gtmlogin');
    }
    return (
      <div>
        <div className="title" id="title_container">
          <div className="block_title mb30" id="titre">
            <Title>Erreur d'authentification</Title>
          </div>
        </div>
      </div>
    );
  }
  resetError() {
    return (
      <div>
        <div className="title" id="title_container">
          <div className="block_title mb30" id="titre">
            <Title>{errorRestMDP[this.props.resetError.get('message')]} </Title>
          </div>
        </div>
      </div>
    );
  }



  resetSuccess() {
    if (!this.props.mdpToChange) {
      this.shoHidePop('demandeCredit.mdpToChange');
    } else {
      this.shoHidePop('demandeCredit.authentificate');
    }
    this.props.initialAccout();
    hashHistory.push('/');
  }
  renderSuccess() {
    TagManager.dataLayer({
      dataLayer:{
         event: "login-in_success",
         langue: this.props.langues,
         BU: 'particulier'
     }})
    if (this.props.nbreConnexion === 0) {
      this.shoHidePop('demandeCredit.authentificate'); //  close popin connexio
      this.shoHidePop('demandeCredit.mdpToChange'); // open popin chang password
    } else {
      this.shoHidePop('demandeCredit.authentificate');
      // this.props.initialAccout();
      hashHistory.push('/espaceperso');
    }
  }
  renderError() {

    return (
      <div>
        <div className="title" id="title_container">
          <div className="block_title mb30" id="titre">
            <Title>{errorMessages[this.props.authenticationError]} </Title>
          </div>
        </div>
      </div>
    );
  }


  renderLoading() {
    return (
      <div className="informations--small">
        <div className="informations-block">
          <div className="informations-loader"></div>
          <div className="informations-title">Chargement</div>
        </div>
      </div>
    );
  }

  render() {
    const messageErrors = {
      mailRequired: <div className="input-error-test"> {i18n.t("recap28.label")} </div>
    };
    const souvenirmoi = [
      {
        label: i18n.t("recap31.label"),
        value: true,
      },
    ];
    return (
      <div>
        {this.props.showHideElm ?
          null :
          <Popin
            storeKey="demandeCredit.authentificate"
            popinTitle={this.props.popinTitle}
            popinTagLine={this.props.popinDesc}
            img={image}
            widthImg="61"
            popin_modals="size760 popinAuthForm"
            secondBtnDataText={this.props.label}
            secondBtnDatalabel={this.props.label}
            withWhiteBtn
            withPinkButton
            dataText={this.props.secondLabel}
            datalabel={this.props.secondLabel}
            secondBtnclassCss="flat_btn purple_btn"
            classCss="flat_btn white_btn"
            action={() => { this.shoHidePop('demandeCredit.authentificate'); }}
            secondAction={this.props.popin === 'connexion' ?
              this.authentication : this.forgetPassword}
          >

            <div className="popin_form_wrapper mb30">
              {this.props.popin === 'connexion' ?
                <div>
                  <div className="mb20">
                    <Text type="text" storeKey="account.identifiant" >
                      {i18n.t("recap29.label")}
                    </Text>
                  </div>
                  {this.state.errorMessages === true && messageErrors.mailRequired}
                  <div className="mb35">
                    <Text type="password" storeKey="account.motDePasse" >
                      {i18n.t("recap30.label")}
                    </Text>
                  </div>
                  <div className="loginoptions_container">
                    <div className="free_checkbox_style">
                      <Checkbox
                        items={souvenirmoi}
                        storeKey="demandeCredit.souvenirmoi"
                        isclient
                      />
                    </div>
                    <div className="forgotten_psw">
                      <button
                        data-text={i18n.t("recap23.label")}
                        className="unstyled_btn"
                        onClick={() => { this.switchPopin(); }}
                      >
                        <span>{i18n.t("recap23.label")}</span>
                      </button>
                    </div>
                  </div>
                  <div>
                    {(() => {
                      switch (this.props.authenticationStatus) {
                        case 'loading':
                          return this.renderLoading();
                        case 'error':
                          return this.renderError();
                        case 'success':
                          return this.renderSuccess();
                        default:
                          return null;
                      }
                    })()}
                  </div>
                </div>
                :
                <div>
                  <div>
                    <Text type="email" storeKey="account.fpmail" >
                      email
                    </Text>
                  </div>
                  <div>
                    {(() => {
                      switch (this.props.resetStatus) {
                        case 'loading':
                          return this.renderLoading();
                        case 'error':
                          return this.resetError();
                        case 'success':
                          return this.resetSuccess();
                        default:
                          return null;
                      }
                    })()}
                  </div>
                </div>
              }

            </div>
          </Popin>
        }
        {this.props.mdpToChange ? null :
          <Popin
            secondBtnclassCss="flat_btn purple_btn"
            classCss="flat_btn purple_btn more_padding"
            classCssWhite="flat_btn white_btn right_mr"
            dataText="valider"
            datalabel="valider"
            secondBtnDataText="Annuler"
            secondBtnDatalabel="Annuler"
            storeKey="demandeCredit.mdpToChange"
            popin_modals="size760"
            popinTitle="Changement de mot de passe"
            popinTagLine="Saisissez Votre nouveau mot passe"
            img={image}
            widthImg="61"
            withWhiteBtn
            secondAction={() => { this.shoHidePop('demandeCredit.mdpToChange'); }}
            action={this.resetPassword}
          >
            <div>
              <div className="mb20">
                <Text type="password" storeKey="account.oldPassword" >
                  Ancien mot de passe
                </Text>
              </div>
              <div className="mb20">
                <Text type="password" storeKey="account.newPassword" >
                  mot de passe
                </Text>
              </div>
              <div className="mb35">
                <Text type="password" storeKey="account.confirmdPassword" >
                  confirmation  mot de passe
                </Text>
              </div>
            </div>
            <div>
              {(() => {
                switch (this.props.resetStatus) {
                  case 'loading':
                    return this.renderLoading();
                  case 'error':
                    return this.resetError();
                  case 'success':
                    return this.resetSuccess();
                  default:
                    return null;
                }
              })()}
            </div>
          </Popin>
        }
        {this.props.isChaabiClient ? null :
          <Popin
            secondBtnclassCss="flat_btn purple_btn"
            classCss="flat_btn purple_btn more_padding"
            classCssWhite="flat_btn white_btn right_mr"
            dataText={i18n.t("oui.label")}
            datalabel={i18n.t("oui.label")}
            secondBtnDataText={i18n.t("non.label")}
            secondBtnDatalabel={i18n.t("non.label")}
            storeKey="demandeCredit.isChaabiClient"
            popin_modals="size760"
            popinTitle="INITIATION D'UNE DEMANDE"
            img={icn}
            widthImg="61"
            withWhiteBtn
            secondAction={this.redirect}
            action={() => this.chaabinetPopin(false)}
            modalContent={i18n.t("HeaderAuth.label")}
            asterisque={i18n.t("etoile.label")}
          />
        }
        {this.props.yesChaabiClient ? null :
          <Popin
            secondBtnclassCss="flat_btn purple_btn"
            classCss="flat_btn purple_btn more_padding"
            classCssWhite="flat_btn white_btn right_mr"
            dataText="récupérer mes informations"
            datalabel="récupérer mes informations"
            secondBtnDataText="annuler"
            secondBtnDatalabel="annuler"
            storeKey="demandeCredit.yesChaabiClient"
            popin_modals="size760"
            popinTitle="récupération de vos informations personnelles "
            popinTagLine="Veuillez saisir votre identifiant et mot de passe pour
            récupérer vos informations personnelles"
            img={image}
            widthImg="61"
            withWhiteBtn
            action={this.getSignalitique}
            secondAction={() => this.chaabinetPopin(true)}
          >
            <div className="popin_form_wrapper mb30">
              <div>
                <div className="mb20">
                  <Text type="text" storeKey="account.identifiant" >
                    veuillez saisir votre identifiant Chaabinet
                  </Text>
                </div>
                <div className="mb35">
                  <Text type="password" storeKey="account.motDePasse" >
                    veuillez saisir votre mot de passe
                  </Text>
                </div>
                <div className="loginoptions_container">
                  <div className="free_checkbox_style">
                  </div>
                  <div className="forgotten_psw align_right">
                    <a href="https://oauth2.gbp.ma/?cid=73829FB9-57A2-4BE6-9A95-7926E703A6C0&aid=0e9042c1-a605-44d9-a3b4-618a06e6c2f7">
                      <span>Mot de passe oublié</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {(() => {
                switch (this.props.clientDetailsStatus) {
                  case 'success':

                    return this.searchSuccess();


                  case 'error':
                    return this.searchError();
                  case 'loading':
                    return this.renderLoading();
                  default:
                    return null;
                }
              })()}
            </div>
          </Popin>
        }

        {this.state.showChaabiNetPopup === true &&
          <Popin
            classCss="flat_btn purple_btn more_padding"
            classCssWhite="flat_btn white_btn right_mr"
            dataText="Ok"
            datalabel="Ok"
            showHideElm={false}
            storeKey="demandeCredit.newDemandeBlock"
            popin_modals="popin_dialog"
            popinTitle="Afin de redéfinir votre mot de passe, nous allons vous rediriger vers votre espace Chaabi Net"
            popinIcn={this.props.popinIcnInfo}
            withoneButton
            action={this.okChaabiNet}
          />
        }
        <div>
          {this.state.showErroPopup === true &&
            <Popin
              classCss="flat_btn purple_btn more_padding"
              classCssWhite="flat_btn white_btn right_mr"
              dataText="Ok"
              datalabel="Ok"
              showHideElm={false}
              storeKey="demandeCredit.newDemandeBlock"
              popin_modals="popin_dialog"
              popinTitle="Il n'existe aucune demande avec l'identifiant renseigné. Merci de bien vouloir vérifier votre identifiant."
              popinIcn={AttentionImage}
              img={AttentionImage}
              withoneButton
              action={this.redirectPageAcceuil}
            />
          }
        </div>
      </div>
    );
  }
}
HeaderAuth.propTypes = {
  show: React.PropTypes.func,
  switchPopin: React.PropTypes.func,
  showHideElm: React.PropTypes.bool,
  mdpOubli: React.PropTypes.bool,
  mdpToChange: React.PropTypes.bool,
  isClient: React.PropTypes.bool,
  isChaabiClient: React.PropTypes.bool,
  yesChaabiClient: React.PropTypes.bool,
  authenticationStatus: React.PropTypes.bool,
  popin: React.PropTypes.string,
  title: React.PropTypes.string,
  popinIcn: React.PropTypes.string,
  label: React.PropTypes.string,
  popinTitle: React.PropTypes.string,
  popinDesc: React.PropTypes.string,
  authentication: React.PropTypes.func,
  authenticationError: React.PropTypes.string,
  nbreConnexion: React.PropTypes.number,
  resetPassword: React.PropTypes.func,
  forgetPassword: React.PropTypes.func,
  secondLabel: React.PropTypes.string,
  resetStatus: React.PropTypes.string,
  resetError: React.PropTypes.string,
  clientDetailsStatus: React.PropTypes.string,
  initialAccout: React.PropTypes.func,
  searchClient: React.PropTypes.func,
  inputChange: React.PropTypes.func,
};
export default HeaderAuth;

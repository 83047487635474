import { connect } from 'react-redux/dist/react-redux';
import NextButton from '../components/NextButton';
import every from 'lodash/every';
import { displayErrors } from '../action_creators';

function mapStateToProps(state, props) {
  if (props.fields) {
    const allInputs = props.fields
      .map(field => field.split('.'))
      .map(([store, ...path]) => state[store].getIn(path));
    const haveValue = every(allInputs
      .map(input => (input && input.get('value') !== undefined
        && input.get('value') !== null && input.get('value').size !== 0)));
    const noError = every(allInputs
      .map((input) => (input && input.get('errors')
        ? input.get('errors').valueSeq().every((value) => (value !== true))
        : true)));
    return ({
      disableNext: !haveValue || !noError,
    });
  }
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    displayErrors: (a, b) => dispatch(displayErrors(a, b)),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(NextButton);

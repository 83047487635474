import Step from '../components/Timeline/Step';
import { connect } from 'react-redux/dist/react-redux';
import { goBackToState } from '../action_creators';

function mapStateToProps(state) {
  return {
    langues: state.demandeCredit.getIn(['langues', 'value']),
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    go: () => dispatch(goBackToState(props.step.get('canonicalUrl'))),

  };
}

const Timeline = connect(
  mapStateToProps,
  mapDispatchToProps
)(Step);

export default Timeline;

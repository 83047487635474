import { connect } from 'react-redux';

import Parcours from '../../components/Accueil/Parcours';
function mapStateToProps(state) {
  return {
    langues: state.demandeCredit.getIn(['langues', 'value']),
  };
}

export default connect(mapStateToProps)(Parcours);

import React, { Component } from 'react';
import { inputChange } from '../action_creators';
import Slider from 'rc-slider/lib/Slider';
import {formaterNombre} from  '../utile/utile';
import icone from '../assets/images/icone_info.png';




class SlideBar extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
  }

  handleChange(e) {
    if(this.props.storeKey === "simulateur.montantCredit")
      this.props.dispatch(inputChange(e, "demandeCredit.montantCredit"));
    else if(this.props.storeKey === "simulateur.nbAnnee")
      this.props.dispatch(inputChange(e, "demandeCredit.nbAnnee"));
    else if(this.props.storeKey === "simulateur.dureeDiff")
      this.props.dispatch(inputChange(e, "demandeCredit.dureeDiff"));
    return this.props.dispatch(inputChange(e, this.props.storeKey));

  }
  handleChangeComplete(e) {
    if(e.target.value.length > 10)
      return false;
    else{
      if(this.props.storeKey === "simulateur.montantCredit")
        this.props.dispatch(inputChange(e.target.value, "demandeCredit.montantCredit"));
      else if(this.props.storeKey === "simulateur.nbAnnee")
        this.props.dispatch(inputChange(e.target.value, "demandeCredit.nbAnnee"));
      else if(this.props.storeKey === "simulateur.dureeDiff")
        this.props.dispatch(inputChange(e, "demandeCredit.dureeDiff"));
       //return this.props.dispatch(inputChange(e.target.value, this.props.storeKey));
       return this.props.dispatch(inputChange(e.target.value.toString().replace(/ /g,""), this.props.storeKey));
    }
  }

  render() {
    const { value } = this.props;
    return (
      <div disabled={!this.props.disabled}>
        <div className="mb_40">
          <div className="simulator_controlls clearfix">
            <span className="simulateur_labels sim_labels">{this.props.libelle}
              <span>&ensp;{this.props.libelleInfo}</span>
            </span>
            <div className="slidebar_bloc">
              <Slider
                disabled={this.props.disabled}
                min={this.props.min}
                max={this.props.max || this.props.maxi}
                step={this.props.step || this.props.steps}
                value={value}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="simulator_slider  clearfix">
            <input
              className="simulateur_input cell_tableIE simulator_controll_label"
              type="text"
              defaultValue={this.props.defaultValue}
              value={formaterNombre(value)}
              onChange={this.handleChangeComplete}
              disabled={this.props.disabled}
            />
            <span className="simulator_label_type sim_labels">{this.props.typeLibelle}</span>
            <span className="help_tuto_icn tooltip">
              <img src={icone} alt="" id="infos_icn" />
              <span className="tooltiptext">{this.props.tooltipTxt}</span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}
SlideBar.propTypes = {
  storeKey: React.PropTypes.string.isRequired,
  libelleInfo: React.PropTypes.string,
  typeLibelle: React.PropTypes.string,
  libelle: React.PropTypes.string,
  value: React.PropTypes.number,
  defaultValue: React.PropTypes.string,
  tooltipTxt: React.PropTypes.string,
  step: React.PropTypes.number,
  max: React.PropTypes.number,
  maxi: React.PropTypes.number,
  min: React.PropTypes.number,
  disabled: React.PropTypes.bool,
  options: React.PropTypes.object,
  dispatch: React.PropTypes.func.isRequired,
};

export default SlideBar;

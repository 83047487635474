import { connect } from 'react-redux';
import SlideBar from '../../components/SlideBar';


const mapStateToProps = (state, props) => {
  const [store, ...storeKey] = props.storeKey.split('.');
  storeKey.push('value');
 
    return ({
      value: state[store].getIn(storeKey),
      maxi: (state[store].getIn(storeKey)) * 2,
      steps: (state[store].getIn(storeKey)) / 100,
    });
  

};

export default connect(mapStateToProps)(SlideBar);

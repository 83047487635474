/* eslint-disable */
import React, { Component } from 'react';
import Text from '../containers/Text';
import Title from './Title';
import Radio from '../containers/Inputs/Radio';
import NextButton from '../containers/NextButton';
import SelectIndicatif from '../containers/Inputs/SelectIndicatif';
import Popin from '../containers/Popin';
import { hashHistory } from 'react-router';
import image from '../assets/images/popin_personal_space_icn.png';
import {verifyEmail } from '../utile/utile'
import i18n from '../i18n';
import TagManager from 'react-gtm-module';
import {isLoged} from '../utile/utile'
import NavigationBar from '../containers/NavigationBar';

const requiredFields = [
  'demandeCredit.civilite',
  'demandeCredit.nom',
  'demandeCredit.prenom',
  'demandeCredit.mail',
  'demandeCredit.telephone',
];
class Coordonnees extends Component {
  constructor(props) {
    super(props);
    this.suivant = this.suivant.bind(this);
    this.gtmPush = this.gtmPush.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.state = { showPopup: false };
  }
  componentWillMount() {
    window.scrollTo(0, 0);
    this.props.displayErrors('non');
    document.body.classList.remove('hiddenScroll');

  }
  componentDidMount() {
    TagManager.dataLayer({
      dataLayer: isLoged(this.props.userId,{
          event: "form_pret_step_2",
          origin: this.props.origin,
          userStatus: this.props.userStatus,
          langue: this.props.langues,
          sp_montant: this.props.origin === "header" ? null: this.props.montantCredit,
          sp_duree: this.props.origin === "header" ? null: this.props.nbAnnee,
          sp_differe: this.props.origin === "header" ? null: this.props.dureeDiff,
          sp_taux: this.props.origin === "header" ? null: this.props.taux,
          sp_echeance: this.props.origin === "header" ? null: this.props.echeance,
          BU: 'particulier'
      }),
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.langues !== this.props.langues) {
        i18n.changeLanguage(this.props.langues);
        this.forceUpdate();
    }
  }
  handleChangeEmail(event){
    if (verifyEmail(event.target.value)){
      this.props.getDemandeByEmail(""+event.target.value);
    }
  }
  gtmPush(){
    let inputsError = ( (this.props.nomError == undefined || this.props.nomError == true ) ? "[nom],":"")
                    .concat( this.props.prenomError == undefined || this.props.prenomError == true ? "[prenom],":"")
                    .concat( this.props.emailError == undefined || this.props.emailError == true ? "[Email],":"")
                    .concat( this.props.telephoneError == undefined || this.props.telephoneError == true ? "[Telephone]":"")

    TagManager.dataLayer({
      dataLayer: isLoged(this.props.userId,{
          event: "form_pret_step_2",
          origin: this.props.origin,
          item: "suivant",
          userStatus: this.props.userStatus,
          langue: this.props.langues,
          BU: 'particulier',
          champs_refuse: inputsError
          //gclid: this.props.gclid,
      }),
    })
  }
  suivant(){

    if (this.props.demandeByMail !== undefined && this.props.demandeByMail !==null &&  this.props.demandeByMail.length>0){
      this.setState({ showPopup: true });
    }
    else{
      this.props.goNext();
    }
  }

  successReturn() {
    hashHistory.push('/');
  }

  render() {
    const prospectOption = [
      { label: i18n.t("oui.label"), value: 'Oui' },
      { label: i18n.t("non.label"), value: 'Non', checked: true },
    ];
    const civilityOptions = [
      { label: i18n.t("civiliteM.label"), value: 'M', checked: true },
      { label: i18n.t("civiliteMme.label"), value: 'Mme' },
    ];

    const clientOption = [
      { label: 'Oui', value: 'Oui', checked: true },
      { label: 'Non', value: 'Non' },
    ];

    return (
      <div className="body">
        <div className="form_wrapper formAdaptation">
          <div className="title" id="title_container">
            <div className="block_title mb30" id="titre">
              <Title>{i18n.t("titleForm1.label")} </Title>
              <p className="title_desc_step">{i18n.t("subtitleForm1.label")} </p>
            </div>
          </div>
          <div className="control_group radio_group clearfix">
            <div className="left_cls">
            {this.props.chaabiClient ?
              <Radio
                items={clientOption} storeKey="demandeCredit.clientbcp"
                isLabel
                disabled
              >
                <div className="radio-input label_radio_txt radio-input-label">
                {i18n.t("att1Form1.label")}
                </div>
              </Radio>
              :
              <Radio
                items={prospectOption} storeKey="demandeCredit.clientbcp"
                isLabel
                disabled
              >
                <div className="radio-input label_radio_txt radio-input-label">
                {i18n.t("att1Form1.label")}
                </div>
              </Radio>
            }
            </div>
            <div className="right_cls">
            {this.props.isClientBcp==='Non' ?
              <Radio
                items={civilityOptions} storeKey="demandeCredit.civilite"
                isLabel
              >
                <div className="radio-input label_radio_txt radio-input-label">

              {i18n.t("att2Form1.label")}
                </div>
              </Radio> :   <Radio
                items={civilityOptions} storeKey="demandeCredit.civilite"
                isLabel
                disabled
              >
                <div className="radio-input label_radio_txt radio-input-label">
                {i18n.t("att2Form1.label")}
                </div>
              </Radio>
            }
            </div>
          </div>
          <div className="control_group mb10 clearfix">
            <div className="left_cls">
            {this.props.isClientBcp==='Non' ?
              <Text maxLength="35" storeKey="demandeCredit.nom" >
                   {i18n.t("att3Form1.label")}
              </Text> :   <Text maxLength="35" storeKey="demandeCredit.nom" disabled>
                   {i18n.t("att3Form1.label")}
              </Text>
            }
            </div>
            <div className="right_cls">
            {this.props.isClientBcp==='Non' ?
              <Text maxLength="35" storeKey="demandeCredit.prenom">
                {i18n.t("att4Form1.label")}
              </Text> : <Text maxLength="35" storeKey="demandeCredit.prenom" disabled>
              {i18n.t("att4Form1.label")}
          </Text> }
            </div>
          </div>
          <div className="control_group mb35 clearfix">
            <div className="left_cls">
              <Text
                type="email"
                storeKey="demandeCredit.mail"
                id="mail"
                onChangeParent={this.handleChangeEmail}
              >
                  {i18n.t("att5Form1.label")}
              </Text>
            </div>
            <div className="right_cls">
              <div className="control_group mb10 clearfix">
                <div className="left_cls_phone">
                  <SelectIndicatif
                    storeKey="demandeCredit.indicatif"
                  >

                  {i18n.t("att6Form1.label")}
                  </SelectIndicatif>
                </div>
                <div className="right_cls_phone">
                  <Text storeKey="demandeCredit.telephone" type="tel" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nav_btns_container">
          <div>

          </div>
          <div className="next_btn_container">
            <NavigationBar
              action={this.suivant}
              save={this.props.saveDemande}
              status={this.props.status}
              gtm={true}
              gtmPush={this.gtmPush}
              fields={requiredFields}
              isFormulaire
              linkTo="/infos-projet"
          />
          </div>

      {this.state.showPopup===true ?
          <div>
          <Popin
            classCss="flat_btn purple_btn more_padding"
            classCssWhite="flat_btn white_btn right_mr"
            dataText="Ok"
            datalabel="Ok"
            img= {image}
            showHideElm={false}
            storeKey="demandeCredit.newDemandeBlock"
            popin_modals="popin_dialog"
            popinTitle="VOUS AVEZ  DÉJÀ UNE DEMANDE DE CRÉDIT EN COURS. MERCI DE PRENDRE CONTACT AVEC LE CENTRE DE RELATION CLIENT PAR TÉLÉPHONE AU 2121"
            popinIcn={this.props.popinIcnInfo}
            withoneButton
            action={this.successReturn}
          />
          </div>
        : '' }
          <div>

          </div>
        </div>
      </div>
    );
  }
}

Coordonnees.propTypes = {
  currentAgence: React.PropTypes.object,
  goNext: React.PropTypes.func.isRequired,
  saveUpdate: React.PropTypes.func.isRequired,
  progressLevel: React.PropTypes.bool,
  displayErrors: React.PropTypes.bool,
  clientDetailsStatus: React.PropTypes.bool,
  isChaabiClient: React.PropTypes.bool,
};

Coordonnees.defaultProps = {
  disableNext: false,
  dataTxt: 'Suivant',
};

export default Coordonnees;

/* eslint-disable */
import React from "react";
import Select from "react-select";
import { inputChange } from "../../action_creators";
import filter from "lodash/filter";
import InputError from "../../containers/Inputs/InputError";

const onChangePromoteur = (event, dispatch) => {
  const value = event.label;
  if (value != "Autre promoteur") {
    return dispatch(inputChange("", "demandeCredit.autrePromoteur"));
  }
};

const onChangeLabel = (event, dispatch) => {
  const value = event.label;
  return dispatch(inputChange(value, "demandeCredit.promoteurLibelle"));
};

const onChange = (event, storeKey, dispatch) => {
  const value = event.value;
  onChangePromoteur(event, dispatch);
  onChangeLabel(event, dispatch);
  return dispatch(inputChange(value, storeKey));
};

const SelectSearch = (props) => {
  const finalValue = filter(props.items.toJS(), (item) => {
    return item.value == props.value;
  });
  return (
    <div style={{ marginTop: "20px" }}>
      <Select
        options={props.items.toJS()}
        onChange={(event) => onChange(event, props.storeKey, props.dispatch)}
        defaultValue={finalValue[0]}
        placeholder="Promoteur"
        name="Promoteur"
        className="z-index-2"
      />
      <InputError storeKey={props.storeKey} />
    </div>
  );
};

export default SelectSearch;

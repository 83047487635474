import { Map } from 'immutable';
import moment from 'moment';
const tva = 0.1;
function calculEcheance(montantCredit, taux, dureeDiff, nbAnnee) {
  montantCredit = parseFloat(montantCredit)
  let resultat = 0;
  const diviseur = (montantCredit + (montantCredit *
    taux / 100 * (1 + tva) * dureeDiff / 12)) *
    ((taux / 1200) * (1 + tva));
  const quotient = 1 - Math.pow((1 + (taux / 1200) * (1 + tva)),
    (-(nbAnnee * 12 - dureeDiff)));
  if (taux !== 0) {
    resultat = (diviseur / quotient).toFixed(2);
  } else {
    resultat = montantCredit / nbAnnee;
  }
  return resultat;
}
function calculInverse(echeance, taux, dureeDiff, nbAnnee) {
  const divis = echeance * (1 - (Math.pow(1 + taux
    / 1200 * (1 + tva), -(nbAnnee * 12 - dureeDiff))));
  const quot = ((taux / 1200) * (1 + tva)) * (1 + taux / 1200
    * (1 + tva) * dureeDiff);
  const resInv = (divis / quot).toFixed(2);
  return resInv;
}

function isUnderage(day, month, year) {
  const birthdate = moment({ year, month: parseInt(month, 10) - 1, day });
  return moment().diff(birthdate, 'years') < 18;
}
export default function (state = new Map(), action) {
  if (action.type !== 'INPUT_CHANGE' || action.store !== 'simulateur') {
    return state;
  }

  switch (action.key.join('.')) {
    case 'montantCredit':
      return state.setIn(['echeance', 'value'],
        calculEcheance(state.getIn(['montantCredit', 'value']),
          state.getIn(['taux', 'value']),
          state.getIn(['dureeDiff', 'value']),
          state.getIn(['nbAnnee', 'value']))
      );
    case 'taux':

      return state.setIn(['echeance', 'value'],
        calculEcheance(state.getIn(['montantCredit', 'value']),
          state.getIn(['taux', 'value']),
          state.getIn(['dureeDiff', 'value']),
          state.getIn(['nbAnnee', 'value']))
      );
    case 'dureeDiff':

      return state.setIn(['echeance', 'value'],
        calculEcheance(state.getIn(['montantCredit', 'value']),
          state.getIn(['taux', 'value']),
          state.getIn(['dureeDiff', 'value']),
          state.getIn(['nbAnnee', 'value']))
      );
    case 'date_naissanceDay':
      return state.setIn(
          ['date_naissanceDay'].concat(['errors', 'date_naissance.underage']),
          isUnderage(
            action.newValue,
            state.get('date_naissanceMonth') ?
              state.get('date_naissanceMonth').get('value') : '1',
            state.get('date_naissanceYear') ? state.get('date_naissanceYear').get('value') : '1'
          )
        );
    case 'date_naissanceMonth':
      return state.setIn(
          ['date_naissanceDay'].concat(['errors', 'date_naissance.underage']),
          isUnderage(
            state.get('date_naissanceDay') ? state.get('date_naissanceDay').get('value') : '1',
            action.newValue,
            state.get('date_naissanceYear') ?
              state.get('date_naissanceYear').get('value') : '1'
          )
        );
    case 'date_naissanceYear':
      return state.setIn(
          ['date_naissanceDay'].concat(['errors', 'date_naissance.underage']),
          isUnderage(
            state.get('date_naissanceDay') ? state.get('date_naissanceDay').get('value') : '1',
            state.get('date_naissanceMonth') ?
              state.get('date_naissanceMonth').get('value') : '1',
            action.newValue
          )
        );
    case 'nbAnnee':
    /*  return state.withMutations((ctx) => {
      const seventies =  isSeventies(
          ctx.get('date_naissanceDay') ? ctx.get('date_naissanceDay').get('value') : '1',
          ctx.get('date_naissanceMonth') ?
          ctx.get('date_naissanceMonth').get('value') : '1',
          ctx.get('date_naissanceYear') ?
          ctx.get('date_naissanceYear').get('value') : '2000',
          ctx.get('nbAnnee') ?
          ctx.get('nbAnnee').get('value') : '25'
        )
        if (seventies==true){
          ctx.setIn(
            ['nbAnnee'].concat(['errors', 'nbAnnee.isSeventy']),seventies);
        }
        ctx.setIn(['echeance', 'value'],
          calculEcheance(ctx.getIn(['montantCredit', 'value']),
            ctx.getIn(['taux', 'value']),
            ctx.getIn(['dureeDiff', 'value']),
            ctx.getIn(['nbAnnee', 'value']))
          );
      }); */
      return state.setIn(['echeance', 'value'],
        calculEcheance(state.getIn(['montantCredit', 'value']),
          state.getIn(['taux', 'value']),
          state.getIn(['dureeDiff', 'value']),
          state.getIn(['nbAnnee', 'value']))
      );
 /*   case 'echeance':
      if (state.getIn(['echeance', 'value']) < 500000) {
        return state.setIn(['montantCredit', 'value'],
          calculInverse(state.getIn(['echeance', 'value']),
            state.getIn(['taux', 'value']),
            state.getIn(['dureeDiff', 'value']),
            state.getIn(['nbAnnee', 'value']))
        );
      } else {
        return state.setIn(['montantCredit', 'value'], '100000');
      }*/
    case 'dMontantCredit':
      return state.setIn(['dEcheance', 'value'],
        calculEcheance(state.getIn(['dMontantCredit', 'value']),
          state.getIn(['dTaux', 'value']),
          state.getIn(['dDureeDiff', 'value']),
          state.getIn(['dNbAnnee', 'value']))
      );
    case 'dTaux':

      return state.setIn(['dEcheance', 'value'],
        calculEcheance(state.getIn(['dMontantCredit', 'value']),
          state.getIn(['dTaux', 'value']),
          state.getIn(['dDureeDiff', 'value']),
          state.getIn(['dNbAnnee', 'value']))
      );
    case 'dDureeDiff':

      return state.setIn(['dEcheance', 'value'],
        calculEcheance(state.getIn(['dMontantCredit', 'value']),
          state.getIn(['dTaux', 'value']),
          state.getIn(['dDureeDiff', 'value']),
          state.getIn(['dNbAnnee', 'value']))
      );
    case 'dNbAnnee':

      return state.setIn(['dEcheance', 'value'],
        calculEcheance(state.getIn(['dMontantCredit', 'value']),
          state.getIn(['dTaux', 'value']),
          state.getIn(['dDureeDiff', 'value']),
          state.getIn(['dNbAnnee', 'value']))
      );
    case 'dEcheance':
      return state.setIn(['dMontantCredit', 'value'],
        calculInverse(state.getIn(['dEcheance', 'value']),
          state.getIn(['dTaux', 'value']),
          state.getIn(['dDureeDiff', 'value']),
          state.getIn(['dNbAnnee', 'value']))
      );
    default:
      return state;
  }
}

import React, { Component } from 'react';
import { fromJS } from 'immutable';
import Text from '../containers/Text';
import Radio from '../containers/Inputs/Radio';
import NextButton from '../containers/NextButton';
import SelectIndicatif from '../containers/Inputs/SelectIndicatif';
import SelectCountry from '../containers/Inputs/SelectCountry';
import Select from '../containers/Inputs/Select';
import { hashHistory } from 'react-router';


const clientOption = [
  { label: 'Oui', value: 'Oui', checked: true },
  { label: 'Non', value: 'Non' },
];

const typeBienOptions = fromJS([
    { label: 'Construction' },
    { label: 'Acquisition logement' },
    { label: 'Acquisition terrain' },
    { label: 'Acquisition terrain + Construction'},
    { label: 'Rachat crédit immobilier' },
    { label: 'Aménagement' },
]);
const requiredFields = [
  'smap.nom',
  'smap.prenom',
  'smap.mail',
  'smap.telephone',
  'smap.paysderesidence',
  'smap.ville',
  'smap.clientbcp',
  'smap.typebien',
];

export default class FormSMAP extends Component {

  constructor(props) {
    super(props);
    this.saveInfoSmap = this.saveInfoSmap.bind(this);
  }
  goSimulateur() {
    hashHistory.push('/simulateurSMAP');
  }
  saveInfoSmap(){
    this.props.saveInfoSmap();
      if (this.props.status !== true){
      this.goSimulateur();
    }
  }
  componentWillMount(){
    this.props.inputChange("+33", 'smap.indicatif');
    this.props.inputChange("FR", 'smap.paysderesidence');
  }

  render() {
    return (
      <div >

        <div className="form_wrapper">
          <div className="control_group radio_group clearfix">
            <div className="left_cls">
              <Radio
                items={clientOption} storeKey="smap.clientbcp"
                isLabel
              >
                <div className="radio-input label_radio_txt radio-input-label">
                  Êtes-vous déjà client ?
                </div>
              </Radio>
            </div>
          </div>
          <div className="control_group mb10 clearfix">
            <div className="left_cls">
              <Text maxLength="35" storeKey="smap.nom">
                  Nom
              </Text>
            </div>
            <div className="right_cls">
              <Text maxLength="35" storeKey="smap.prenom">
              Prénom
              </Text>
            </div>
          </div>
          <div className="control_group mb10 clearfix">
            <div className="left_cls">
              <Text
                type="email"
                storeKey="smap.mail"
              >
                Adresse email
              </Text>
            </div>
            <div className="right_cls">
              <div className="control_group mb10 clearfix">
                <div className="left_cls_phone">
                  <SelectIndicatif
                    storeKey="smap.indicatif"
                    placeholder="choisir..."
                  >
                    N° Tel mobile ( exepmle : +212 6********)
                  </SelectIndicatif>
                </div>
                <div className="right_cls_phone">
                  <Text storeKey="smap.telephone" type="tel" />
                </div>
              </div>
            </div>
          </div>
          <div className="control_group mb35 clearfix">
            <div className="left_cls">
              <SelectCountry
                storeKey="smap.paysderesidence"
                placeholder="choisir..."
              >
                   Pays de résidence
              </SelectCountry>
            </div>
            <div className="right_cls">
              <Text storeKey="smap.ville">
                 Ville de résidence
              </Text>
            </div>
          </div>
          <div className="control_group mb35 clearfix">
            <div className="left_cls">
              <Select
                items={typeBienOptions}
                storeKey="smap.typebien"
                placeholder="Objet de la demande"
              >
                 Objet du credit
              </Select>
            </div>
            <div className="right_cls">
              <Text storeKey="smap.adresse">
                 Adresse (Facultatif)
              </Text>
            </div>
          </div>
        <div className="control_group mb35 clearfix">
          <div className="left_cls">
            <Text storeKey="smap.numpieceidentite">
               N° pièce d&apos;identité (Facultatif)
            </Text>
          </div>
          <div className="right_cls">
            <Text storeKey="smap.radical">
               Radical (Facultatif)
            </Text>
          </div>
        </div>
        </div>
        <div className="nav_btns_container">
          <div>

          </div>
          <div className="next_btn_container">
            <NextButton
              dataTxt="je realise ma simulation"
              action={this.saveInfoSmap}
              label="je realise ma simulation"
              fields={requiredFields}
              isFormulaire
              className="flat_btn purple_btn more_padding"
            />
          </div>
          { this.props.status &&
            <div className="error_save_smap">
                Sauvegarde des informations impossible
            </div>
          }

          <div>

          </div>
        </div>
      </div>
  );
  }
}
FormSMAP.propTypes = {
  goNext: React.PropTypes.func,
};

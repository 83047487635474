import { connect } from 'react-redux/dist/react-redux';
import ChangePassword from '../components/ChangePassword'
import { definePassword , checkExpirationPassword } from '../action_creators';

function mapStateToProps(state, props) {
  return {
    langues: state.demandeCredit.getIn(['langues', 'value']), 
    password : state.demandeCredit.getIn(['define','password','value']),
    confirmPass : state.demandeCredit.getIn(['define','confirmPass','value']),
    definePass : state.account.getIn(['definePass','status']),
    definePassError : state.account.getIn(['oubliPass','status']),
    verifPasswordStatus: state.demandeCredit.getIn(['request', 'status']),
    definePassword: {
      mail: props.params.email,
      password: state.demandeCredit.getIn(['define','password','value']),
      token: props.params.token,
    },
    checkValidUrl: {
      mail: props.params.email,
      password: state.demandeCredit.getIn(['define','password','value']),
      token: props.params.token,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    definePassword: (s) => dispatch(definePassword(s)),
    checkExpirationPassword: (s) => dispatch(checkExpirationPassword(s)),
    
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const definePassword = stateProps.definePassword;
  const checkValidUrl = stateProps.checkValidUrl;
  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    definePassword: ()  => dispatchProps.definePassword(definePassword),
    checkExpirationPassword: ()  => dispatchProps.checkExpirationPassword(checkValidUrl),
  });
}
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ChangePassword);

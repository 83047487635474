import React, { Component, PropTypes } from 'react';
import NavigationBar from '../containers/NavigationBar';
import image from '../assets/images/img/popup_icon.png';
import NextButton from '../containers/NextButton';
export default class Popup extends Component {
  constructor(props) {
    super(props);
    this.popup = this.popup.bind(this);
  }
  popup() {
    this.props.valueToggle(this.props.toggled);
  }
  render() {
    return (
      <div> 
        {this.props.showNavigBar &&
        <NavigationBar
          a
          action={this.props.action}
          save={this.props.save}
          status={this.props.status}
        >   
          <NextButton
            action={this.popup}
            label={'Suivant'}
            dataTxt={'Suivant'}
          />      
        </NavigationBar>
}
         {this.props.toggled ? null :
           <div id="popup" className="modal_container modal-backdrop align_center">
             <div className="modal_dialog">
               <button onClick={this.popup} className="close_popin hide_popin"></button>
               <div className="modal_content">
                 <div className="modal_body">
                   <img src={image} alt="" width="69" className="popin_head_img" />
                   <h4>selection de l&apos;agence</h4>
                   <div className="modal_content">
                     <p className="uppercase_txt">
                     </p>
                     <p className="uppercase_txt">
                     </p>
                   </div>
                 </div>
                 {this.props.children}
                 <div className="modal_footer">
                   <NextButton
                     action={this.props.action}
                     label={'Suivant'}
                     dataTxt={'Suivant'}
                   />
                 </div>
               </div>
             </div>
           </div>}
      </div>
    );
  }
}
Popup.propTypes = {
  children: PropTypes.node,
  valueToggle: PropTypes.func,
  action: PropTypes.func,
  save: PropTypes.func,
  toggled: PropTypes.bool,
  status: PropTypes.bool,
};

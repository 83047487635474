/* eslint-disable */
import React, { Component, PropTypes } from 'react';
import image from '../assets/images/img/icone_simulateur_popin.png';
import TagManager from 'react-gtm-module';
import {isLoged} from '../utile/utile'


export default class Popin extends Component {
  constructor(props) {
    super(props);
    this.popup = this.popup.bind(this);
    this.state = {
      gtmVirtualPage: false
    }

  }
  componentWillMount() {

  }
  componentDidMount = () => {
    if(!this.props.isChaabiClient){
      let gtmVirtualPage = false;
      if (gtmVirtualPage==false){

        gtmVirtualPage = true;
       // this.setState({ gtmVirtualPage:true });
      }

    }

  }

  popup() {
    TagManager.dataLayer({
      dataLayer:{
          event: "login_canceled",
          langue: this.props.langues,
          BU: 'particulier'
      }})
    this.props.showHide(this.props.storeKey);
    document.body.classList.remove('hiddenScroll');
  }
  render() {
    return (
      <div>
        {this.props.showHideElm ? null :
          <div
            id="popup"
            className="modal_container modal-backdrop"
          >
            <div className={`modal_dialog ${this.props.popin_modals}`}>
              <button onClick={this.popup} className="close_popin hide_popin"></button>
              <div className="modal_content">
                <div className="modal_header align_center">
                  <img
                    src={this.props.img || image}
                    alt=""
                    width={this.props.widthImg || '91'}
                    className="popin_head_img"
                  />
                  <h4>{this.props.popinTitle}</h4>
                  <h5>{this.props.popinTagLine}</h5>
                </div>
                <div className="modal_body">
                  <div className="modal_content">
                    <p className="uppercase_txt">
                    </p>
                    <div className="uppercase_txt align_center mb10">
                    {this.props.modalContent}
                    </div>
                  </div>
                 {this.props.children}
                </div>
                <div className="modal_footer align_center mt-20">
                {!this.props.withWhiteBtn ? null :
                  <div>
                    <button
                      data-text={this.props.secondBtnDataText}
                      className={this.props.secondBtnclassCss}
                      onClick={this.props.secondAction}
                    >
                      <span>{this.props.secondBtnDatalabel}</span>
                    </button>
                  {this.props.withPinkButton ? null :
                    <button
                      data-text={this.props.dataText}
                      className={this.props.classCss}
                      onClick={this.props.action}
                    >
                      <span>{this.props.datalabel}</span>
                    </button>
                  }
                  </div>
                }
                {this.props.withoneButton &&
                  <div className="modal_footer align_center">
                    <button
                      data-text={this.props.dataText}
                      className={this.props.classCss}
                      onClick={this.props.action}
                    >
                      <span>{this.props.datalabel}</span>
                    </button>
                  </div>

                }
                  <p className="align_right asterisque">{this.props.asterisque}</p>
                </div>
              </div>
            </div>
          </div>}
      </div>

    );
  }
}
Popin.propTypes = {
  children: PropTypes.node,
  showHide: PropTypes.func,
  flag: PropTypes.bool,
  showHideElm: PropTypes.bool,
  withWhiteBtn: PropTypes.bool,
  status: PropTypes.bool,
  popinTitle: React.PropTypes.string,
  popinTagLine: React.PropTypes.string,
  img: React.PropTypes.string,
  widthImg: React.PropTypes.string,
  secondBtnDatalabel: React.PropTypes.string,
  secondBtnclassCss: React.PropTypes.string,
  secondBtnDataText: React.PropTypes.string,
  storeKey: React.PropTypes.string,
  modalContent: React.PropTypes.string,
  classCss: React.PropTypes.string,
  popin_modals: React.PropTypes.string,
  dataText: React.PropTypes.string,
  datalabel: React.PropTypes.string,
  asterisque: React.PropTypes.string,
  action: React.PropTypes.func.isRequired,
  secondAction: React.PropTypes.func,
  withoneButton: PropTypes.bool,
};
Popin.default = {
  popin_modals: 'modal_container modal-backdrop align_center',
  withWhiteBtn: false,
  classCss: 'flat_btn purple_btn more_padding',
};

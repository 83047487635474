/* eslint-disable */
import React, { Component } from "react";
import Text from "../containers/Text";
import Title from "./Title";
import Select from "../containers/Inputs/Select";
import Checkbox from "../containers/Inputs/Checkbox";
import { fromJS } from "immutable/dist/immutable";
import SelectCity from "../containers/Inputs/SelectCity";
import SelectCountry from "../containers/Inputs/SelectCountry";
import NavigationBar from "../containers/NavigationBar";
import SelectDate from "./Inputs/SelectDate";
import { hashHistory } from "react-router/lib";
import SelectIndicatif from "../containers/Inputs/SelectIndicatif";
import SelectMyAgence from "../containers/Inputs/SelectMyAgence";
import AccordionRecap from "./Inputs/AccordionRecap";
import i18n from "../i18n";
import { inputChange } from "../action_creators";
import Promoteur from "../containers/Promoteur";
import SelectEvent from "../containers/Inputs/SelectEvent";

const civilityOptions = fromJS([
  { label: "M", value: "M", checked: true },
  { label: "Mme", value: "Mme" },
]);

const autreRevenuOptions = fromJS([{ label: "Oui" }, { label: "Non" }]);
const pieceIdentity = fromJS([
  { label: "C.I.N.", value: "1", checked: true },
  { label: "Passport", value: "2" },
  { label: "Carte De Séjour", value: "3" },
]);

const SituationFamiliale = fromJS([
  { label: "Célibataire" },
  { label: "Marié(e)" },
  { label: "Divorcé(e)" },
  { label: "Veuf(ve)" },
]);

const profession = fromJS([
  { label: "Chef d’entreprise", value: "0811" },
  { label: "Cadre de la fonction publique", value: "0791" },
  { label: "Cadre d’entreprise privée ou semi-publique", value: "0391" },
  { label: "Employé ou agent de la fonction publique", value: "3111" },
  { label: "Employé d’entreprise privée ou semi-publique", value: "3911" },
  { label: "Profession libérale", value: "2691" },
  { label: "Profession libérale médicale", value: "1151" },
  { label: "Commerçant et assimilés", value: "4181" },
  { label: "Artisan et assimilés", value: "6911" },
  { label: "Agriculteur exploitant ", value: "5131" },
  { label: "Ouvrier", value: "9911" },
  { label: "Etudiant ", value: "XX211" },
  { label: "Retraité ", value: "XX311" },
  { label: "Inactif / Recherche d’emploi ", value: "XX111" },
]);

const Secteurs = fromJS([
  { label: "Public", value: "Public", checked: true },
  { label: "Privé", value: "Prive" },
]);

const etatOptions = fromJS([
  { label: "Neuf" },
  { label: "Ancien(deuxième main )" },
]);
const priorityOptions = fromJS([
  { label: "Principale" },
  { label: "secondaire" },
  { label: "Autre" },
]);
const typeBienOptions = fromJS([
  { label: "Construction" },
  { label: "Acquisition logement" },
  { label: "Acquisition terrain" },
  { label: "Acquisition terrain + Construction" },
  { label: "Rachat crédit Immobilier" },
  { label: "Aménagement" },
]);
const typetauxOptions = fromJS([{ label: "Fixe" }, { label: "Variable" }]);

const activityOptions = fromJS([
  { label: "AGRICULTURE, SYLVICULTURE ET PECHE" },
  { label: "INDUSTRIES EXTRACTIVES" },
  { label: "INDUSTRIE MANUFACTURIERE" },
  {
    label:
      "PRODUCTION ET DISTRIBUTION D`ELECTRICITE, DE GAZ, DE VAPEUR ET D`AIR CONDITIONNE",
  },
  {
    label:
      "PRODUCTION ET DISTRIBUTION D`EAU, ASSAINISSEMENT, GESTION DES DECHETS ET DEPOLLUTION",
  },
  { label: "TRANSPORTS ET ENTREPOSAGE" },
  { label: "COMMERCE" },
  { label: "HEBERGEMENT ET RESTAURATION" },
  { label: "INFORMATION ET COMMUNICATION" },
  { label: "ACTIVITES FINANCIERES ET D&apos;ASSURANCE" },
  { label: "ACTIVITES IMMOBILIERES" },
  { label: "ACTIVITES SPECIALISEES, SCIENTIFIQUES ET TECHNIQUES" },
  { label: "ACTIVITES DE SERVICES ADMINISTRATIFS ET DE SOUTIEN" },
  { label: "ADMINISTRATION PUBLIQUE" },
  { label: "ENSEIGNEMENT" },
  { label: "SANTE HUMAINE ET ACTION SOCIALE" },
  { label: "ADMINISTRATION PUBLIQUE" },
  { label: "ARTS, SPECTACLES ET ACTIVITES RECREATIVES" },
  { label: "AUTRES ACTIVITES DE SERVICES" },
]);

const typeContrat = fromJS([
  { label: "CDI", value: "CDI", checked: true },
  { label: "CDD", value: "CDD" },
]);

const sourceAutreRevenu = fromJS([
  { label: "Location vide", value: "Location vide" },
  { label: "Location meublée", value: "Location meublée" },
  {
    label: "Revenus capitaux mobiliers ou plus-value de cessions",
    value: "Revenus capitaux mobiliers ou plus-value de cessions",
  },
  { label: "Profession indépendante", value: "Profession indépendante" },
  {
    label: "Bénéfices entreprise avec parts",
    value: "Bénéfices entreprise avec parts",
  },
]);

const divStyleFix0 = {
  display: "block",
  height: "125px",
  transition: "all 0.5s ",
};
const divStyleFix1 = {
  display: "block",
  height: "225px",
  transition: "all 0.5s ",
};
const divStyleFix2 = {
  display: "block",
  height: "600px",
  transition: "all 0.5s ",
};
const divStyleFix3 = {
  display: "block",
  height: "580px",
  transition: "all 0.5s ",
};
const divStyleNone = {
  height: "0px",
  opacity: "0",
  display: "block",
  "padding-top": "0px",
  "transition-timing-function": "ease",
  transition: "all 0.5s ",
  "padding-bottom": "0px",
};

class Recapitulatif extends Component {
  constructor(props) {
    super(props);
    this.verifyForm = this.verifyForm.bind(this);
    this.state = {
      showOblgaErrors: false,
      divStyle0: divStyleFix0,
      divStyle1: divStyleNone,
      divStyle2: divStyleNone,
      divStyle3: divStyleNone,
    };
  }
  componentWillMount() {
    this.props.displayErrors("non");
    this.props.updateState();
    window.scrollTo(0, 0);
  }

  clickAccord = (id) => {
    switch (id) {
      case 0:
        if (this.state.divStyle0 === divStyleFix0) {
          this.setState({ divStyle0: divStyleNone });
          this.setState({ divStyle1: divStyleNone });
          this.setState({ divStyle2: divStyleNone });
          this.setState({ divStyle3: divStyleNone });
        } else {
          this.setState({ divStyle0: divStyleFix0 });
          this.setState({ divStyle1: divStyleNone });
          this.setState({ divStyle2: divStyleNone });
          this.setState({ divStyle3: divStyleNone });
        }
        break;

      case 1:
        if (this.state.divStyle1 === divStyleFix1) {
          this.setState({ divStyle0: divStyleNone });
          this.setState({ divStyle1: divStyleNone });
          this.setState({ divStyle2: divStyleNone });
          this.setState({ divStyle3: divStyleNone });
        } else {
          this.setState({ divStyle0: divStyleNone });
          this.setState({ divStyle1: divStyleFix1 });
          this.setState({ divStyle2: divStyleNone });
          this.setState({ divStyle3: divStyleNone });
        }
        break;

      case 2:
        if (this.state.divStyle2 === divStyleFix2) {
          this.setState({ divStyle0: divStyleNone });
          this.setState({ divStyle2: divStyleNone });
          this.setState({ divStyle1: divStyleNone });
          this.setState({ divStyle3: divStyleNone });
        } else {
          this.setState({ divStyle0: divStyleNone });
          this.setState({ divStyle2: divStyleFix2 });
          this.setState({ divStyle1: divStyleNone });
          this.setState({ divStyle3: divStyleNone });
        }

        break;

      case 3:
        if (this.state.divStyle3 === divStyleFix3) {
          this.setState({ divStyle0: divStyleNone });
          this.setState({ divStyle3: divStyleNone });
          this.setState({ divStyle1: divStyleNone });
          this.setState({ divStyle2: divStyleNone });
        } else {
          this.setState({ divStyle0: divStyleNone });
          this.setState({ divStyle3: divStyleFix3 });
          this.setState({ divStyle1: divStyleNone });
          this.setState({ divStyle2: divStyleNone });
        }
        break;

      default:
        break;
    }
  };

  componentWillUpdate(prevProps) {
    if (prevProps.isAgenceSelected !== this.props.isAgenceSelected) {
      this.props.inputChange(
        this.props.codeAgenceSelected,
        "demandeCredit.agence"
      );
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isChecked !== this.props.isChecked) {
      this.props.inputChange(
        this.props.isChecked,
        "demandeCredit.confirmedInfos"
      );
    }

    if (prevProps.isAgenceSelected !== this.props.isAgenceSelected) {
      this.props.inputChange(true, "demandeCredit.confirmeAgence");
      this.props.inputChange(
        this.props.codeAgenceSelected,
        "demandeCredit.agence"
      );
      this.props.inputChange(
        this.props.agenceList !== null
          ? this.props.agenceList
              .filter(
                (item) => item.get("value") === this.props.codeAgenceSelected
              )
              .get(0)
              .get("label")
          : null,
        "demandeCredit.libelleagence"
      );
    }
    if (this.props.isAgenceSelected === "-1") {
      this.props.inputChange(false, "demandeCredit.confirmeAgence");
    }
  }

  renderLoading() {
    return (
      <div className="informations--small">
        <div className="informations-block">
          <div className="informations-loader"></div>
          <div className="informations-title">Chargement</div>
        </div>
      </div>
    );
  }
  renderError() {
    return (
      <div>
        <div className="title" id="title_container">
          <div className="block_title mb30" id="titre">
            <Title>Echec de sauvegarde</Title>
            <p className="title_desc mb20">Echec de sauvegarde des données</p>
          </div>
        </div>
        <div className="confirmation_msg">
          <p>
            Une erreur de traitement nous empêche de sauvegarder vos données
          </p>
          <p>Veuillez relancer</p>
        </div>
      </div>
    );
  }

  verifyForm() {
    this.props.inputChange(false, "demandeCredit.agence");
  }

  renderSuccess() {
    hashHistory.push("/confirmation");
  }

  render() {
    const confirmOptionsDefault = [
      {
        label: i18n.t("recap6.label"),
        value: true,
        checked: true,
        tag: true,
      },
    ];
    const nouveauté = [
      {
        label: i18n.t("recap7.label"),
        value: true,
        checked: true,
      },
    ];
    return (
      <div className="body">
        <div className="recap_info_container">
          <div className="form_wrapper mb20">
            <div className="title" id="title_container">
              <div className="block_title" id="titre">
                <div className="mb10">
                  <Title>{i18n.t("recap1.label")}</Title>
                </div>
                <p className="title_desc_step mb20">{i18n.t("recap2.label")}</p>
              </div>
            </div>
          </div>
          <div className="mb20">
            <ul className="accordion_list_recap">
              <AccordionRecap
                title={i18n.t("infoProjetEvent.label")}
                classNamefirst="accordion_title_recap active"
                classNamesecond="accordion_content_recap"
                style={this.state.divStyle0}
                onClick={() => this.clickAccord(0)}
              >
                <div className="title_desc_step_event">
                  <span>{i18n.t("recapEventLabel.label")}</span>
                </div>
                <div className="control_group clearfix">
                  <div className="left_cls mb10">
                    <SelectEvent
                      storeKey="demandeCredit.eventCompany"
                      placeholder={i18n.t("recapEvent.label")}
                      name="demandeCredit.eventCompany"
                      placeholderIcon={i18n.t("recapEventIcon.label")}
                      zIndex={"10000"}
                      style={{
                        marginTop: 20,
                        width: 300,
                      }}
                    >
                      {i18n.t("infoProjet0.label")}
                    </SelectEvent>
                  </div>
                </div>
              </AccordionRecap>

              <AccordionRecap
                title={i18n.t("recap3.label")}
                classNamefirst="accordion_title_recap active"
                classNamesecond="accordion_content_recap"
                style={this.state.divStyle1}
                onClick={() => this.clickAccord(1)}
              >
                <div className="control_group clearfix">
                  <div className="left_cls">
                    <Select
                      items={civilityOptions}
                      storeKey="demandeCredit.civilite"
                      placeholder={i18n.t("att2Form1.label")}
                    >
                      {i18n.t("att2Form1.label")}
                    </Select>
                  </div>
                </div>
                <div className="control_group mb10 clearfix">
                  <div className="left_cls">
                    {this.props.clientbcp === "Non" ? (
                      <Text maxLength="35" storeKey="demandeCredit.nom">
                        {i18n.t("att3Form1.label")}
                      </Text>
                    ) : (
                      <Text
                        maxLength="35"
                        storeKey="demandeCredit.nom"
                        disabled
                      >
                        {i18n.t("att3Form1.label")}
                      </Text>
                    )}
                  </div>
                  <div className="right_cls">
                    {this.props.clientbcp === "Non" ? (
                      <Text maxLength="35" storeKey="demandeCredit.prenom">
                        {i18n.t("att4Form1.label")}
                      </Text>
                    ) : (
                      <Text
                        maxLength="35"
                        storeKey="demandeCredit.prenom"
                        disabled
                      >
                        {i18n.t("att4Form1.label")}
                      </Text>
                    )}
                  </div>
                </div>
                <div className="control_group clearfix">
                  <div className="left_cls">
                    <Text type="email" storeKey="demandeCredit.mail" isRecap>
                      {i18n.t("att5Form1.label")}
                    </Text>
                  </div>
                  <div className="right_cls">
                    <div className="control_group mb10 clearfix">
                      <div className="left_cls_phone">
                        <SelectIndicatif storeKey="demandeCredit.indicatif">
                          {i18n.t("att6Form1.label")}
                        </SelectIndicatif>
                      </div>
                      <div className="right_cls_phone">
                        <Text
                          storeKey="demandeCredit.telephone"
                          type="tel"
                          isRecap
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionRecap>
              <AccordionRecap
                title={i18n.t("recap4.label")}
                classNamefirst="accordion_title_recap active"
                classNamesecond="accordion_content_recap"
                style={this.state.divStyle2}
                onClick={() => this.clickAccord(2)}
              >
                <div className="control_group mb10 clearfix">
                  <div className="left_cls">
                    {this.props.clientbcp === "Non" ? (
                      <SelectDate
                        storeKey="demandeCredit.date_naissance"
                        yearFrom={new Date().getFullYear()}
                        yearTo={1900}
                      >
                        {i18n.t("infoPerso2.label")}
                      </SelectDate>
                    ) : (
                      <SelectDate
                        storeKey="demandeCredit.date_naissance"
                        yearFrom={new Date().getFullYear()}
                        yearTo={1900}
                        disabled
                      >
                        {i18n.t("infoPerso2.label")}
                      </SelectDate>
                    )}
                  </div>
                  <div className="right_cls">
                    <SelectCountry
                      storeKey="demandeCredit.paysderesidence"
                      placeholder={i18n.t("infoPerso6.label")}
                    >
                      Lieu de résidence
                    </SelectCountry>
                  </div>
                </div>
                <div className="control_group mb10 clearfix">
                  <div className="left_cls">
                    {this.props.clientbcp === "Non" ? (
                      <Select
                        items={pieceIdentity}
                        storeKey="demandeCredit.pieceidentite"
                        placeholder="Type pièce"
                      >
                        {i18n.t("infoPerso4.label")}
                      </Select>
                    ) : (
                      <Select
                        items={pieceIdentity}
                        storeKey="demandeCredit.pieceidentite"
                        placeholder="Type pièce"
                        disabled
                      >
                        {i18n.t("infoPerso4.label")}
                      </Select>
                    )}
                  </div>
                  <div className="right_cls">
                    {this.props.clientbcp === "Non" ? (
                      <Text
                        maxLength="35"
                        storeKey="demandeCredit.numpieceidentite"
                        isRecap
                      >
                        {i18n.t("infoPerso5.label")}
                      </Text>
                    ) : (
                      <Text
                        Text
                        maxLength="35"
                        storeKey="demandeCredit.numpieceidentite"
                        isRecap
                        disabled
                      >
                        {i18n.t("infoPerso5.label")}
                      </Text>
                    )}
                  </div>
                </div>
                <div className="control_group mb10 clearfix">
                  <div className="left_cls">
                    <Select
                      items={SituationFamiliale}
                      storeKey="demandeCredit.situationfamiliale"
                      placeholder={i18n.t("infoPerso7.label")}
                    >
                      {i18n.t("infoPerso7.label")}{" "}
                    </Select>
                  </div>
                  <div className="right_cls">
                    <Text
                      type="number"
                      maxLength="35"
                      storeKey="demandeCredit.nbrenfants"
                    >
                      {i18n.t("infoPerso8.label")}
                    </Text>
                  </div>
                </div>
                <div className="control_group mb10 clearfix">
                  <div className="left_cls">
                    <Select
                      items={profession}
                      storeKey="demandeCredit.profession"
                    >
                      {" "}
                      {i18n.t("infoPerso14.label")}
                    </Select>
                  </div>
                  <div className="right_cls">
                    <Select
                      items={typeContrat}
                      storeKey="demandeCredit.naturecontrat"
                      isLabel
                    >
                      <div className="radio-input label_radio_txt radio-input-label">
                        {i18n.t("infoPerso10.label")}
                      </div>
                    </Select>
                  </div>
                </div>
                <div className="control_group mb10 clearfix">
                  <div className="left_cls">
                    <Text
                      maxLength="35"
                      storeKey="demandeCredit.employeur"
                      isRecap
                    >
                      {i18n.t("recap16.label")}
                    </Text>
                  </div>
                  <div className="right_cls">
                    <Text
                      type="number"
                      maxLength="35"
                      storeKey="demandeCredit.revenu"
                      isRecap
                    >
                      {i18n.t("recap17.label")}
                    </Text>
                  </div>
                </div>
                <div className="control_group mb10 clearfix">
                  <div className="left_cls">
                    <Text
                      type="number"
                      maxLength="35"
                      storeKey="demandeCredit.anciente"
                      isRecap
                    >
                      {i18n.t("infoPerso15.label")}
                    </Text>
                  </div>
                  <div className="right_cls">
                    <Select
                      items={activityOptions}
                      storeKey="demandeCredit.activite"
                      placeholder="Choisir une activité"
                    >
                      {" "}
                      secteur d&apos;activité
                    </Select>
                  </div>
                </div>
                <div className="control_group mb10 clearfix">
                  <div className="left_cls">
                    <Select items={Secteurs} storeKey="demandeCredit.secteur">
                      {" "}
                      {i18n.t("infoPerso16.label")}
                    </Select>
                  </div>
                  <div className="right_cls">
                    <Select
                      items={autreRevenuOptions}
                      storeKey="demandeCredit.boautrerevenu"
                      placeholder={i18n.t("infoPerso17.label")}
                    >
                      {i18n.t("infoPerso17.label")}
                    </Select>
                  </div>
                </div>
                {this.props.demandeCredit.boautrerevenu &&
                  this.props.demandeCredit.boautrerevenu === "Oui" && (
                    <div className="control_group mb10 clearfix">
                      <div className="left_cls">
                        <Text
                          type="number"
                          maxLength="35"
                          storeKey="demandeCredit.autrerevenu"
                          isRecap
                        >
                          {i18n.t("infoPerso17.label")}
                        </Text>
                      </div>
                      <div className="right_cls">
                        <Select
                          items={sourceAutreRevenu}
                          storeKey="demandeCredit.sourceAutreRevenu"
                          placeholder="Veuillez sélectionner la source des autres revenus"
                        >
                          {" "}
                          {i18n.t("infoPerso18.label")}
                        </Select>
                      </div>
                    </div>
                  )}
                <div className="control_group mb10 clearfix">
                  <div className="left_cls">
                    <Text
                      type="number"
                      maxLength="35"
                      storeKey="demandeCredit.autresecheances"
                      isRecap
                    >
                      {i18n.t("infoPerso19.label")}
                    </Text>
                  </div>
                </div>
              </AccordionRecap>
              <AccordionRecap
                title={i18n.t("recap5.label")}
                classNamefirst="accordion_title_recap active"
                classNamesecond="accordion_content_recap"
                onClick={() => this.clickAccord(3)}
                style={this.state.divStyle3}
              >
                <div className="control_group mb10 clearfix">
                  <div className="left_cls">
                    <Select
                      items={etatOptions}
                      storeKey="demandeCredit.etatbien"
                      placeholder={i18n.t("recap18.label")}
                    />
                  </div>
                  <div className="right_cls">
                    <Select
                      items={typeBienOptions}
                      storeKey="demandeCredit.typebien"
                      placeholder={i18n.t("infoProjet3.label")}
                    />
                  </div>
                </div>
                <div className="control_group mb10 clearfix">
                  <div className="left_cls">
                    <Select
                      items={priorityOptions}
                      storeKey="demandeCredit.usagebien"
                      placeholder={i18n.t("infoProjet4")}
                    />
                  </div>
                  <div className="right_cls">
                    <Text
                      type="number"
                      maxLength="35"
                      storeKey="demandeCredit.coutprojet"
                      isRecap
                    >
                      {i18n.t("infoProjet5.label")}
                    </Text>
                  </div>
                </div>
                <div className="control_group mb10 clearfix">
                  <div className="left_cls">
                    <Text
                      type="number"
                      maxLength="35"
                      storeKey="demandeCredit.surfacebien"
                      isRecap
                    >
                      {i18n.t("infoProjet6.label")}
                    </Text>
                  </div>
                  <div className="right_cls">
                    <SelectCity storeKey="demandeCredit.localisationbien">
                      {i18n.t("infoProjet7.label")}
                    </SelectCity>
                  </div>
                </div>
                <div className="control_group mb10 clearfix">
                  <Promoteur storeKey="demandeCredit.promoteur" />
                </div>
                <div className="control_group mb10 clearfix">
                  <div className="left_cls">
                    <Text
                      type="number"
                      maxLength="35"
                      storeKey="demandeCredit.montantCredit"
                      isRecap
                    >
                      {i18n.t("infoProjet10.label")}
                    </Text>
                  </div>
                  <div className="right_cls">
                    <Text
                      type="number"
                      maxLength="35"
                      storeKey="demandeCredit.nbAnnee"
                      isRecap
                    >
                      {i18n.t("infoProjet11.label")}
                    </Text>
                  </div>
                </div>
                <div className="control_group mb10 clearfix">
                  <div className="left_cls">
                    <Select
                      items={typetauxOptions}
                      storeKey="demandeCredit.typetaux"
                      placeholder={i18n.t("infoProjet12.label")}
                    />
                  </div>
                  <div className="right_cls">
                    <Text
                      type="number"
                      maxLength="35"
                      storeKey="demandeCredit.dureeDiff"
                      isRecap
                    >
                      {i18n.t("simulateur4.label")}
                    </Text>
                  </div>
                </div>
                <div className="control_group mb10 clearfix">
                  <div className="left_cls">
                    <Text
                      type="number"
                      maxLength="35"
                      storeKey="demandeCredit.apportpersonnel"
                      isRecap
                    >
                      {i18n.t("infoProjet14.label")}
                    </Text>
                  </div>
                  <div className="right_cls">
                    <Text
                      type="number"
                      maxLength="35"
                      storeKey="demandeCredit.financconjoint"
                      isRecap
                    >
                      {i18n.t("infoProjet15.label")}
                    </Text>
                  </div>
                </div>
                <div className="control_group mb10 clearfix">
                  <div className="left_cls">
                    {/* <Text
                      type="number"
                      maxLength="35"
                      storeKey="demandeCredit.fraisamg"
                      isRecap
                    >
                        FRAIS D&apos;AMÉNAGEMENT A FINANCER PAR LE PRÊT DEMANDÉ
                    </Text> */}
                    <Text
                      type="number"
                      maxLength="35"
                      storeKey="demandeCredit.autresfrais"
                      isRecap
                    >
                      AUTRES FRAIS A FINANCER PAR LE PRÊT DEMANDÉ (FRAIS DE
                      NOTAIRE, ASSURANCE…)
                    </Text>
                  </div>
                  <div className="right_cls">
                    {/* <Text
                      type="number"
                      maxLength="35"
                      storeKey="demandeCredit.autresfrais"
                      isRecap
                    >
                      AUTRES FRAIS A FINANCER PAR LE PRÊT DEMANDÉ (FRAIS DE NOTAIRE, ASSURANCE…)
                    </Text> */}
                  </div>
                </div>
                <div className="control_group mb10 clearfix"></div>
              </AccordionRecap>
            </ul>
          </div>
          <div>
            {this.props.clientbcp === "Oui" && (
              <div className="form_wrapper mb20">
                <div>
                  <SelectMyAgence
                    storeKey="demandeCredit.codeAgence"
                    placeholder={i18n.t("infoPerso6.label")}
                  >
                    Veuillez selectionner l'agence de votre choix :
                  </SelectMyAgence>
                </div>
              </div>
            )}
          </div>
          <div>
            {this.props.clientbcp === "Oui" ? (
              this.props.isConfirmeAgence === true ? (
                <Checkbox
                  items={confirmOptionsDefault}
                  storeKey="demandeCredit.confirmedInfos"
                  isclient
                  trouve
                />
              ) : (
                <Checkbox
                  items={confirmOptionsDefault}
                  storeKey="demandeCredit.confirmedInfos"
                  isclient
                  disabled
                  trouve
                />
              )
            ) : (
              <Checkbox
                items={confirmOptionsDefault}
                storeKey="demandeCredit.confirmedInfos"
                isclient
                trouve
              />
            )}
          </div>
          <div>
            <Checkbox
              items={nouveauté}
              storeKey="demandeCredit.nouveaute"
              isclient
            />
          </div>
          {this.props.clientbcp === "Non" ? (
            <div>
              <div className="style_text">
                <br />
                {i18n.t("recap8.label")} {this.props.libelleagence}{" "}
                {i18n.t("recap19.label")}{" "}
                {this.props.ville
                  .filter((eq) => eq.get("value") === this.props.cityAgence)
                  .map((item) => item.get("label"))}
              </div>
            </div>
          ) : null}

          {this.props.isChecked ? (
            <NavigationBar
              progressLevel={this.props.progressLevel}
              action={this.props.saveDemande}
              linkTo="/coordonnees"
            />
          ) : (
            <NavigationBar
              progressLevel={this.props.progressLevel}
              action={this.props.saveDemande}
              linkTo="/coordonnees"
              disabled
            />
          )}
          <div>
            {(() => {
              switch (this.props.status) {
                case "loading":
                  return this.renderLoading();
                case "error":
                  return this.renderError();
                case "successall":
                  return this.renderSuccess();
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    );
  }
}
Recapitulatif.propTypes = {
  verifyForm: React.PropTypes.func,
  progressLevel: React.PropTypes.bool,
  saveDemande: React.PropTypes.func.isRequired,
  status: React.PropTypes.string,
  updateState: React.PropTypes.func.isRequired,
  displayErrors: React.PropTypes.func,
  inputChange: React.PropTypes.func,
};
export default Recapitulatif;

import { connect } from 'react-redux/dist/react-redux';
import Coordonnees from '../components/Coordonnees';
import { goNextState, displayErrors, getDemandeByEmail, inputChange, saveDemande } from '../action_creators';

const mapStateToProps = (state) => ({
  //gclid: localStorage.getItem('gclid'),
  progressLevel: state.demandeCredit.getIn(['progressLevel', 'value']) === 'done',
  clientDetailsStatus: state.demandeCredit.get('clientDetailsStatus'),
  isChaabiClient: state.demandeCredit.getIn(['isChaabiClient', 'value']),
  chaabiClient: state.demandeCredit.getIn(['chaabiClient', 'value']),
  demandeByMail: state.demandeCredit.get('demandeMail') !== undefined ? state.demandeCredit.get('demandeMail').toJS() : null,
  mail: state.demandeCredit.getIn(['mail', 'value']),
  isClientBcp: state.demandeCredit.getIn(['clientbcp', 'value']),
  userId: state.account.getIn(['identifiant', 'value']) || "",
  langues: state.demandeCredit.getIn(['langues', 'value']) || "fr",
  userStatus: state.account.get('authenticationStatus') === "success" ? "logged": "not-logged",
  origin: state.demandeCredit.getIn(['origin', 'value']),
  montantCredit: state.simulateur.getIn(['montantCredit', 'value']),
  nbAnnee: state.simulateur.getIn(['nbAnnee', 'value']),
  dureeDiff: state.simulateur.getIn(['dureeDiff', 'value']),
  taux: state.simulateur.getIn(['taux', 'value']),
  echeance: state.simulateur.getIn(['echeance', 'value']),
  nomError: state.demandeCredit.getIn(['nom', 'errors','nom.wrongFormat']),
  prenomError: state.demandeCredit.getIn(['prenom', 'errors','prenom.wrongFormat']),
  emailError: state.demandeCredit.getIn(['mail', 'errors','mail.wrongFormat']),
  telephoneError: state.demandeCredit.getIn(['telephone', 'errors','telephone.wrongFormat']),
  status: state.demandeCredit.getIn(['request', 'status']),
  demandeCredit: {
    reference: state.demandeCredit.getIn(['request', 'result', 'reference']),
    prenom: state.demandeCredit.getIn(['prenom', 'value']),
    nom: state.demandeCredit.getIn(['nom', 'value']),
    civilite: state.demandeCredit.getIn(['civilite', 'value']),
    clientbcp: state.demandeCredit.getIn(['clientbcp', 'value']),
    mail: state.demandeCredit.getIn(['mail', 'value']),
    telephone: state.demandeCredit.getIn(['telephone', 'value']),
    date_naissance: {
      day: state.demandeCredit.getIn(['date_naissanceDay', 'value']),
      month: state.demandeCredit.getIn(['date_naissanceMonth', 'value']),
      year: state.demandeCredit.getIn(['date_naissanceYear', 'value']),
    },
    paysderesidence: state.demandeCredit.getIn(['paysderesidence', 'value']),
    pieceidentite: state.demandeCredit.getIn(['pieceidentite', 'value']),
    numpieceidentite: state.demandeCredit.getIn(['numpieceidentite', 'value']),
    situationfamiliale: state.demandeCredit.getIn(['situationfamiliale', 'value']),
    activite: state.demandeCredit.getIn(['activite', 'value']),
    employeur: state.demandeCredit.getIn(['employeur', 'value']),
    secteur: state.demandeCredit.getIn(['secteur', 'value']),
    anciente: state.demandeCredit.getIn(['anciente', 'value']),
    revenu: state.demandeCredit.getIn(['revenu', 'value']),
    boautrerevenu: state.demandeCredit.getIn(['boautrerevenu', 'value']),
    autreactivite: state.demandeCredit.getIn(['autreactivite', 'value']),
    autrerevenu: state.demandeCredit.getIn(['autrerevenu', 'value']),
    etatbien: state.demandeCredit.getIn(['etatbien', 'value']),
    typebien: state.demandeCredit.getIn(['typebien', 'value']),
    usagebien: state.demandeCredit.getIn(['usagebien', 'value']),
    coutprojet: state.demandeCredit.getIn(['coutprojet', 'value']),
    apportpersonnel: state.demandeCredit.getIn(['apportpersonnel', 'value']),
    autresecheances: state.demandeCredit.getIn(['autresecheances', 'value']),
    localisationbien: state.demandeCredit.getIn(['localisationbien', 'value']),
    eventCompany: state.demandeCredit.getIn(['eventCompany', 'value']),
    surfacebien: state.demandeCredit.getIn(['surfacebien', 'value']),
    montantpret: state.demandeCredit.getIn(['montantCredit', 'value']),
    dureesouhaitable: state.demandeCredit.getIn(['nbAnnee', 'value']),
    typetaux: state.demandeCredit.getIn(['typetaux', 'value']),
    dureediffere: state.demandeCredit.getIn(['dureeDiff', 'value']),
    apiAction: 'save',
    fraisamg: state.demandeCredit.getIn(['fraisamg', 'value']),
    autresfrais: state.demandeCredit.getIn(['autresfrais', 'value']),
    promotor: {
      id: state.demandeCredit.getIn(['promoteur', 'value']),
      prmotorLibelle: state.demandeCredit.getIn(['promoteurLibelle', 'value']),
      autrePromoteur: state.demandeCredit.getIn(['autrePromoteur', 'value']),
    }
  },
});

function mergeProps(stateProps, dispatchProps, ownProps) {
  const demandeRequest = stateProps.demandeCredit;
  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    getDemandeByEmail: (email) => dispatchProps.getDemandeByEmail(email),
    saveDemande: () => dispatchProps.saveDemande(demandeRequest),
  });
}

function mapDispatchToProps(dispatch) {

  return {
    saveDemande: (demandeRequest) => dispatch(saveDemande(demandeRequest)),
    goNext: () => dispatch(goNextState()),
    getDemandeByEmail: (email) => dispatch(getDemandeByEmail(email)),
    displayErrors: (a) => dispatch(displayErrors(a)),
    inputChange: (value , key) => dispatch(inputChange(value , key)),
  };
}



export default connect(mapStateToProps, mapDispatchToProps,mergeProps)(Coordonnees);

import React, { Component } from 'react';
import { inputChange } from '../../action_creators';


class Textarea extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);

  }

  onChange(event) {
    return this.props.dispatch(inputChange(event.target.value, this.props.storeKey));
  }
  render() {
    return (
      <div>
        <textarea
          className={this.props.className}
          placeholder={this.props.placeholder}
          rows={this.areaRows}
          cols={this.areaCols}
          onChange={this.onChange}
          maxLength="500"
          value={this.props.value}
          >
          {this.props.value}
        </textarea>

      </div>
    );
  }
}

Textarea.propTypes = {
  dispatch: React.PropTypes.func.isRequired,
  storeKey: React.PropTypes.string.isRequired,
  areaRows: React.PropTypes.string,
  value: React.PropTypes.string,
  areaCols: React.PropTypes.string,
  type: React.PropTypes.string,
  className: React.PropTypes.string,
  placeholder: React.PropTypes.string,
  disabled: React.PropTypes.bool,
  children: React.PropTypes.node,


};

export default Textarea;

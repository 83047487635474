import React, { Component } from 'react';
import Select from '../../containers/Inputs/Select';
import range from 'lodash/range';
import { fromJS } from 'immutable';

const dayOptions = fromJS(range(1, 31 + 1)
  .map(day => ({ value: day, label: day })));

const monthOptions = fromJS(range(1, 12 + 1)
  .map(month => ({ value: month, label: month })));

const yearOptions = (yearFrom, yearTo) =>
  fromJS(range(yearFrom, (yearTo + 1)).map(year => ({ value: year, label: year })));

class SelectDate extends Component {
  render() {
    return (
      <div className="bcp-select-group">
        <Select
          storeKey={`${this.props.storeKey}Day`}
          items={dayOptions}
          disabled={this.props.disabled}
        >
          {this.props.children}
        </Select>
        <Select
          storeKey={`${this.props.storeKey}Month`}
          items={monthOptions}
          disabled={this.props.disabled}
        />
        <Select
          storeKey={`${this.props.storeKey}Year`}
          items={yearOptions(this.props.yearFrom, this.props.yearTo)}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

SelectDate.propTypes = {
  storeKey: React.PropTypes.string.isRequired,
  yearFrom: React.PropTypes.number,
  yearTo: React.PropTypes.number,
  children: React.PropTypes.node,
  disabled: React.PropTypes.bool,
};

export default SelectDate;

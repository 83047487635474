import React, { Component } from 'react';
import Text from '../containers/Text';
import { Map } from 'immutable';
import { verifyPassword} from '../utile/utile';
import loading from '../assets/images/loading.gif';
import { hashHistory } from 'react-router';
import NextButton from '../containers/NextButton';


class VerificationPassword extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);
    this.props.verifPassword();
    this.validate = this.validate.bind(this);
    this.annuler = this.annuler.bind(this);

    this.setState(
      {
        passwordIdentiqueError: false,
        passwordFormatError : false
       }
    );


  }

  componentWillReceiveProps(prevProps,nextProps) {
    if (prevProps.passwordUpdated){
      hashHistory.push('/felicitation');
    }
    }
  validate() {
    if (!(this.props.password===this.props.confirmPass)){
      this.setState({ passwordIdentiqueError: true, passwordFormatError:false }, () => {
      });


    }else if (verifyPassword(this.props.password)){
      this.props.definePassword();
      this.setState({
        passwordFormatError:false,
        passwordIdentiqueError:false
      })
    }
    else{
      this.setState({
        passwordFormatError:true,
        passwordIdentiqueError:false});
    }
    }

    annuler(){

    }

  initierDemande() {
    hashHistory.push('/coordonnees');
  }
  renderLoading() {
    document.body.classList.remove('hiddenScroll');
    return (
      <div >
        <div className="loader_back">
          <img
            className="relativePosition"
            src={loading} alt="loading"
          />
        </div>
      </div>
    );
  }
  renderSuccess() {
    return (
      <div>
        <div className="espace_perso_bandeau mb35">
          <div className="detail_sim_heading">
            <p className="slide_txt bold_style" style={{ fontSize: '31' }}>
            Bonjour {this.props.civilite}. {this.props.prenom} {this.props.nom},
            </p>
            <p className="slide_txt bold_style" style={{ fontSize: '31' }}>
          Bienvenue dans votre espace personnel
            </p>
          </div>
        </div>
        <center className="mb20">
          <h2 style={{ color: '#df7a15', fontSize: '18' }}>Afin de valider votre demande, nous remercions de bien vouloir définir le mot de passe</h2>
        </center>
        <div className="verification_area">
          <div className="control_group mb10 clearfix">
            <div className="">
              <Text maxLength="35" storeKey="demandeCredit.password" type="password">
                  Mot de passe
              </Text>
            </div>
            <div className="">
              <Text maxLength="35" storeKey="demandeCredit.confirmPass" type="password">
                  Confirmation mot de passe
              </Text>
            </div>
          </div>
        </div>
        <div  className="container-center">
          {this.state.passwordFormatError===true && <div className="error">Votre mot de passe doit comporter un minimum de 8 caractères, se composer de chiffres et de lettres et des caractères spéciaux (. , ; : ! ? @ & _ - *)</div> }
          {this.state.passwordIdentiqueError===true && <div className="error">les mots de passe doivent être identiques</div>  }
        </div>
      <div className="verification_btn_area">
        <NextButton
          action={this.validate}
          label={'Annuler'}
          dataTxt={'Annuler'}
        />
        <NextButton
          action={this.validate}
          label={'Valider'}
          dataTxt={'Valider'}
        />
        </div>
      </div>
    );
  }
  renderError() {
    return (
      <div>
        <br /><br /><br /><br /><br /><br /><br /><br />
        <center>
        <div className="confirmation_msg">
          <p>
          Ce lien a expiré, merci de bien vouloir réinitialiser votre mot de passe depuis l'espace personnel
          </p>
        </div>
        </center>
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      </div>
    );
  }
  render() {
    return (
      <div>
      {(() => {
        switch (this.props.verifPasswordStatus) {
          case 'success':
            return this.renderSuccess();
          case 'error':
            return this.renderError();
          default:
            return null;
        }
      })()}
      </div>
    );
  }
}
VerificationPassword.propTypes = {
  inputChange: React.PropTypes.func,
  showHide: React.PropTypes.func,
  espacePerso: React.PropTypes.object,
  showHideElm: React.PropTypes.bool,
  verifPasswordStatus: React.PropTypes.bool,
  statutEncours: React.PropTypes.string,
  maProposition: React.PropTypes.instanceOf(Map),
  maDemande: React.PropTypes.instanceOf(Map),
  verifPassword: React.PropTypes.func,
  civilite: React.PropTypes.string,
  prenom: React.PropTypes.string,
  nom: React.PropTypes.string,
};


export default VerificationPassword;

import React, { Component } from 'react';
import { textInputChange } from '../action_creators';
import Cleave from 'cleave.js/dist/cleave-react';
import InputError from '../containers/Inputs/InputError';
const option1 = {
  numeral: true, numeralDecimalMark: '.',
  delimiter: ' '
};
class Text extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.props.dispatch(textInputChange(
      event.target.value, this.props.storeKey, this.props.type
    ));
    if (this.props.onChangeParent !== undefined) {
      this.props.onChangeParent(event);
    }
  }

  render() {
    if (this.props.type === 'numberM') {
      return (
        <div className="input--text">
          <label>
            <Cleave
              //type='number'
              options={option1}
              className={this.props.value === '' ? 'input-field empty' : 'input-field'}
              maxLength='10'
              onChange={this.onChange}
              value={this.props.value}
              placeholder={this.props.placeholder}
              disabled={this.props.disabled}
              min={this.props.min}
              max='20000000'
            />
            <div className="input-label">
              {!this.props.iconName ? null :
                <div className="input-label-icon">
                  <div className={`icon-${this.props.iconName}`}></div>
                </div>
              }
              {this.props.children}
            </div>
          </label>
          <InputError storeKey={this.props.storeKey} />
        </div>
      );
    } else {
      return (
        <div className="input--text">

          {!this.props.isRecap ?
            <label>
              {this.props.type === 'number' ?
                <Cleave
                  options={option1}
                  className={this.props.value === '' ? 'input-field empty' : 'input-field'}
                  maxLength={this.props.maxLength}
                  onChange={this.onChange}
                  value={this.props.value}
                  placeholder={this.props.placeholder}
                  disabled={this.props.disabled}
                  min={this.props.min}
                  max={this.props.max}
                />
                :
                <input
                  type={this.props.type}
                  id={this.props.id}
                  className={this.props.value === '' ? 'input-field  empty' : 'input-field'}
                  maxLength={this.props.maxLength}
                  onChange={this.onChange}
                  onChangeParent={this.props.onChangeParent}
                  value={this.props.value}
                  placeholder={this.props.placeholder}
                  disabled={this.props.disabled}
                  min={this.props.min}
                  max={this.props.max}

                />
              }
              <div className="input-label input-label-m">
                {!this.props.iconName ? null :
                  <div className="input-label-icon">
                    <div className={`icon-${this.props.iconName}`}></div>
                  </div>
                }
                {this.props.children}
              </div>
            </label>
            :
            <label>
              {this.props.type === 'number' ?
                <Cleave
                  options={option1}
                  className={this.props.value === '' ? 'input-field-recap empty' : 'input-field-recap'}
                  maxLength={this.props.maxLength}
                  onChange={this.onChange}
                  value={this.props.value}
                  placeholder={this.props.placeholder}
                  disabled={this.props.disabled}

                  min={this.props.min}
                  max={this.props.max}
                />
                :
                <input
                  type={this.props.type}
                  className={this.props.value === '' ? 'input-field-recap empty' : 'input-field-recap'}
                  maxLength={this.props.maxLength}
                  onChange={this.onChange}
                  value={this.props.value}
                  placeholder={this.props.placeholder}
                  disabled={this.props.disabled}

                  min={this.props.min}
                  max={this.props.max}
                />
              }
              <div className="input-label">
                {!this.props.iconName ? null :
                  <div className="input-label-icon">
                    <div className={`icon-${this.props.iconName}`}></div>
                  </div>
                }
                {this.props.children}
              </div>
            </label>
          }
          <InputError storeKey={this.props.storeKey} />
        </div>
      );
    }
  }
}

Text.propTypes = {
  dispatch: React.PropTypes.func.isRequired,
  storeKey: React.PropTypes.string.isRequired,
  iconName: React.PropTypes.string,
  value: React.PropTypes.string,
  maxLength: React.PropTypes.string,
  type: React.PropTypes.string,
  placeholder: React.PropTypes.string,
  disabled: React.PropTypes.bool,
  children: React.PropTypes.node,
  min: React.PropTypes.string,
  max: React.PropTypes.string,
};

Text.defaultProps = {
  value: '',
  type: 'text',
  disabled: false,
};

export default Text;

import React, { Component } from 'react';

export default class Accordion extends Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.handleClick();
  }
  /* eslint-disable */
  handleClick() {
     this.refs['myfirstdiv'].classList.contains('active') ?
      this.refs['myfirstdiv'].classList.remove('active') :
      this.refs['myfirstdiv'].classList.add('active');

    this.refs['myseconddiv'].style.display === 'block' ?
     this.refs['myseconddiv'].style.display = 'none'
    : this.refs['myseconddiv'].style.display = 'block';
  }

  render() {
    return (
      <li style={this.props.li}>
        <div
          className={this.props.classNamefirst}
          ref="myfirstdiv"
          onClick={this.handleClick}
        >
       {this.props.title}
        </div>
        <div className={this.props.classNamesecond} style={this.props.style} ref="myseconddiv" >
          {this.props.children}
        </div>
      </li>
    );
  }
}
Accordion.propTypes = {
  title: React.PropTypes.string,
  classNamefirst: React.PropTypes.string,
  classNamesecond: React.PropTypes.string,
  style: React.PropTypes.string,
  children: React.PropTypes.node,
};
Accordion.defaultProps = {
  classNamefirst: 'accordion_title_recap active',
  classNamesecond: 'accordion_content_recap',
  style: { display: 'block' },
};

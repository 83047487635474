import React, { Component } from 'react';
import { hashHistory } from 'react-router/lib';
import { cleanStore } from '../action_creators';
import { felicitation_icone1 } from '../assets/images/felicitation_icone1.png';
import { felicitation_icone2 } from '../assets/images/felicitation_icone2.png';

class Felicitation extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(cleanStore());
    this.shoHidePop = this.shoHidePop.bind(this);
  }
  shoHidePop(s) {
    this.props.show(s);
  }
  render() {
    return (
      <section className="">
        <div className="felicitaion_bandeau">
          <div className="detail_sim_heading">
            <p className="slide_txt bold_style size58">
            </p>
          </div>
        </div>
        <div className="felicitation_wrapper">
          <div className="felicitation_message">
            <div className="title" id="title_container">
              <div className="block_title" id="titre">
                <h2 className="felicitation_title mb20">Félicitation !</h2>
                <p className="title_desc_step mb5">Votre demande a bien été prise en compte</p>
                <p className="title_desc_step">Vous serez informés de l’avancement du traitement
                de votre dossier sur votre espace personnel ainsi que par email.
                </p>
              </div>
            </div>
          </div>
          <div className="felicitation_actions">
            <center>
              <ul className="avantages_detailproduit_blocs">
                <li>
                  <div className="felicitation_option">
                    <div className="felicitation_img">
                      <span>
                        <img src={felicitation_icone1} alt="" width="50%" />
                      </span>
                    </div>
                    <h4 className="felicitation_header mb20">
                      Retour vers l&apos;accueil
                </h4>
                    <p className="avantage_txt mb30">
                      Vous souhaitez refaire une simulation ou avoir des informations
                      sur les prochaines étapes ?
                </p>
                  </div>
                  <p className="btns_felicitation" >
                    <button
                      onClick={() => { hashHistory.push('/'); }}
                      data-text="Accueil"
                      className="flat_btn purple_btn"
                    >
                      <span className="plusDepadding">Accueil</span>
                    </button>
                  </p>
                </li>
                <li>
                  <div className="felicitation_option">
                    <div className="felicitation_img">
                      <span>
                        <img src={felicitation_icone2} alt="" width="50%" />
                      </span>
                    </div>
                    <h4 className="felicitation_header mb20">
                      Avancement de ma demande
                </h4>
                    <p className="avantage_txt mb30">
                      Vous souhaitez consulter l’avancement de votre demande?
                </p>
                  </div>
                  <div className="btns_felicitation" >
                    <button
                      data-text="Espace personnel"
                      className="flat_btn purple_btn"
                      onClick={() => { this.shoHidePop('demandeCredit.authentificate'); }}
                    >
                      <span className="plusDepadding">Espace personnel</span>
                    </button>
                  </div>
                </li>

              </ul>
            </center>
          </div>
        </div>
        {/*<div className="nav_btns_container"></div> */}
      </section>
    );
  }
}
Felicitation.propTypes = {
  cleanStore: React.PropTypes.func,
  dispatch: React.PropTypes.func,
};
export default Felicitation;

import React, { Component } from 'react';
import icone1 from '../../assets/images/etoile.png'
import i18n from '../../i18n';

class Parcours extends Component {
  render() {
    return (
      <div className="steps_container">
        <div className="title" id="title_container">
          <div className="block_title mb50" id="titre">
            <div>
              <h2 className="step_big_title">{i18n.t("titleAv10.label")}</h2>
            </div>
            <hr className="hr_header" />
            <p className="title_desc mb20">{i18n.t("titleAv11.label")}
              <br />
                  {i18n.t("titleAv12.label")}
            </p>
          </div>
        </div>
        <div>
          <span className="rocket">
          </span>
        </div>

        <div className="steps_global_container">
          <div className="step_container" data-num="01">
            <div className="step_bloc">
              <div className="step_icn">
                <img src={icone1} alt="" />
              </div>
              <div className="step_desc">
                <h2>
                {i18n.t("titleAv13.label")}
                </h2>
                <p>
                {i18n.t("titleAv14.label")}
                </p>
              </div>
            </div>
          </div>
          <div className="step_container" data-num="02">
            <div className="step_bloc">
              <div className="step_icn">
                <img src={icone1} alt="" />
              </div>
              <div className="step_desc">
                <h2>
                {i18n.t("titleAv15.label")}
                </h2>
                <p>
                {i18n.t("titleAv16.label")} 
                </p>
              </div>
            </div>
          </div>
          <div className="step_container" data-num="03">
            <div className="step_bloc">
              <div className="step_icn">
                <img src={icone1} alt="" />
              </div>
              <div className="step_desc">
                <h2>
                {i18n.t("titleAv17.label")} 
                </h2>
                <p>
                {i18n.t("titleAv18.label")} 
                </p>
              </div>
            </div>
          </div>
          <div className="step_container" data-num="04">
            <div className="step_bloc">
              <div className="step_icn">
                <img src={icone1} alt="" />
              </div>
              <div className="step_desc">
                <h2>
                {i18n.t("titleAv19.label")} 
                </h2>
                <p>
                {i18n.t("titleAv20.label")} 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span className="distination">
          </span>
        </div>
      </div>
    );
  }
}

export default Parcours;

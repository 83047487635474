import { connect } from 'react-redux/dist/react-redux';
import InfosPerso from '../components/InfosPerso';
import { goNextState, saveDemande, stateDemande, displayErrors,inputChange } from '../action_creators';

const mapStateToProps = (state) => ({
  clientbcp: state.demandeCredit.getIn(['clientbcp', 'value']),
  //gclid: localStorage.getItem('gclid'),
  demandeCredit: {
    reference: state.demandeCredit.getIn(['request', 'result', 'reference']),
    prenom: state.demandeCredit.getIn(['prenom', 'value']),
    nom: state.demandeCredit.getIn(['nom', 'value']),
    civilite: state.demandeCredit.getIn(['civilite', 'value']),
    clientbcp: state.demandeCredit.getIn(['clientbcp', 'value']),
    mail: state.demandeCredit.getIn(['mail', 'value']),
    telephone: state.demandeCredit.getIn(['telephone', 'value']),

    date_naissance: {
      day: state.demandeCredit.getIn(['date_naissanceDay', 'value']),
      month: state.demandeCredit.getIn(['date_naissanceMonth', 'value']),
      year: state.demandeCredit.getIn(['date_naissanceYear', 'value']),
    },

    situationLogement: state.demandeCredit.getIn(['situationLogementDemandeur', 'value']),
    paysderesidence: state.demandeCredit.getIn(['paysderesidence', 'value']),
    pieceidentite: state.demandeCredit.getIn(['pieceidentite', 'value']),
    numpieceidentite: state.demandeCredit.getIn(['numpieceidentite', 'value']),
    situationfamiliale: state.demandeCredit.getIn(['situationfamiliale', 'value']),
    activite: state.demandeCredit.getIn(['activite', 'value']),
    profession: state.demandeCredit.getIn(['profession', 'value']),
    employeur: state.demandeCredit.getIn(['employeur', 'value']),
    secteur: state.demandeCredit.getIn(['secteur', 'value']),
    anciente: state.demandeCredit.getIn(['anciente', 'value']),
    revenu: state.demandeCredit.getIn(['revenu', 'value']),
    boautrerevenu: state.demandeCredit.getIn(['boautrerevenu', 'value']),
    autreactivite: state.demandeCredit.getIn(['autreactivite', 'value']),
    autrerevenu: state.demandeCredit.getIn(['autrerevenu', 'value']),
    autresecheances: state.demandeCredit.getIn(['autresecheances', 'value']),
    apiAction: 'save',
    },
  disableAutre: state.demandeCredit.getIn(['boautrerevenu', 'value']) === '2',
  status: state.demandeCredit.getIn(['request', 'status']),
  userId: state.account.getIn(['identifiant', 'value']) || "",
  langues: state.demandeCredit.getIn(['langues', 'value']) || "fr",
  userStatus: state.account.get('authenticationStatus') === "success" ? "logged": "not-logged",
  origin: state.demandeCredit.getIn(['origin', 'value']),
  montantCredit: state.simulateur.getIn(['montantCredit', 'value']),
  nbAnnee: state.simulateur.getIn(['nbAnnee', 'value']),
  dureeDiff: state.simulateur.getIn(['dureeDiff', 'value']),
  taux: state.simulateur.getIn(['taux', 'value']),
  echeance: state.simulateur.getIn(['echeance', 'value']),
  dateNaissanceDayError: state.demandeCredit.getIn(['date_naissanceDay', 'errors','date_naissance.underage']),
  date_naissanceMonthError: state.demandeCredit.getIn(['date_naissanceMonth', 'errors','date_naissanceMonth.wrongFormat']),
  date_naissanceYearError: state.demandeCredit.getIn(['date_naissanceYear', 'errors','date_naissanceYear.wrongFormat']),
  paysderesidenceError: state.demandeCredit.getIn(['paysderesidence', 'value']),
  pieceidentiteError: state.demandeCredit.getIn(['pieceidentite', 'value']),
  numpieceidentiteError: state.demandeCredit.getIn(['numpieceidentite', 'errors','numpieceidentite.wrongFormat']),
  situationfamilialeError: state.demandeCredit.getIn(['situationfamiliale', 'value']),
  activiteError: state.demandeCredit.getIn(['activite',  'value']),
  employeurError: state.demandeCredit.getIn(['employeur', 'errors','employeur.wrongFormat']),
  revenuError: state.demandeCredit.getIn(['revenu', 'errors','revenu.isValid']),
  situationLogementDemandeurError: state.demandeCredit.getIn(['situationLogementDemandeur', 'value']),
  chaabiClient: state.demandeCredit.getIn(['chaabiClient', 'value']),
});

function mapDispatchToProps(dispatch) {
  return {
    saveDemande: (demandeRequest) => dispatch(saveDemande(demandeRequest)),
    updateState: () => dispatch(stateDemande()),
    goNext: () => dispatch(goNextState()),
    displayErrors: (a) => dispatch(displayErrors(a)),
    inputChange: (s, h) => dispatch(inputChange(s, h)),
  };
}
function mergeProps(stateProps, dispatchProps, ownProps) {
  const demandeRequest = stateProps.demandeCredit;
  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    saveDemande: () => dispatchProps.saveDemande(demandeRequest),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(InfosPerso);

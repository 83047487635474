import React, { Component } from 'react';
import Title from './Title';
import { List } from 'immutable/dist/immutable';
import { format } from '../utile/utile';
import montant_icone from '../assets/images/montant_icone.png';
import taux_icone from '../assets/images/taux_icone.png';
import duree_icone from '../assets/images/duree_icone.png';
import icone_differe from '../assets/images/icone-differe.png';
import dtc_icone from '../assets/images/dtc_icone.png';
import ie_icone from '../assets/images/ie_icone.png';
import somme_icone from '../assets/images/somme_icone.png';
import mensualite_icone from '../assets/images/mensualite_icone.png';
import info_txt from '../assets/images/info_txt.png';
import i18n from '../i18n';

class DetailSimulation extends Component {
  render() {
    const coutInterets =
    (this.props.Result.get('montant') *
     this.props.Result.get('id') * 12
      - this.props.montantCredit);

    return (
      <section className="align_center printable">
        <div className="title" id="title_container">
          <div className="block_title mb50" id="titre">
            <Title>{i18n.t("recap32.label")}</Title>
            <hr className="hr_header" />
            <p className="title_desc mb20">
             {i18n.t("recap33.label")}
            </p>
          </div>
        </div>
        <div>
          <table className="sim_cobtainer" >
            <tr>
              <td>
                <span>
                  <img src={montant_icone} alt="" width="43px" />
                </span>
                <span className="detail_sim_lable_container">
                  <span className="detail_sim_labels">
                  {i18n.t("recap34.label")}
                  </span>
                  <br />
                  <span className="detail_sim_num">
                {format(this.props.montantCredit)}
                    <span>
                    {i18n.t("simulateur7.label")}
                    </span>
                  </span>
                </span>
              </td>
              <td>
                <span>
                  <img src={taux_icone} alt="" width="43px" />
                </span>
                <span className="detail_sim_lable_container">
                  <span className="detail_sim_labels">
                  {i18n.t("recap36.label")}
                  </span>
                  <br />
                  <span className="detail_sim_num">
                  {format(this.props.taux)}
                    <span>
                      %
                    </span>
                  </span>
                </span>
              </td>
              <td>
                <span>
                  <img src={duree_icone} alt="" width="43px" />
                </span>
                <span className="detail_sim_lable_container">
                  <span className="detail_sim_labels">
                  {i18n.t("recap35.label")}
                  </span>
                  <br />
                  <span className="detail_sim_num">
                  {format(this.props.Result.get('id'))}
                    <span>
                    {i18n.t("simulateur8.label")}
                    </span>
                  </span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  <img src={icone_differe} alt="" width="43px" />
                </span>
                <span className="detail_sim_lable_container">
                  <span className="detail_sim_labels">
                  {i18n.t("simulateur44.label")}
                    {/* <span>(  {i18n.t("simulateur9.label")}  )</span> */}
                  </span>
                  <br />
                  <span className="detail_sim_num">
                  {format(this.props.dureeDiff)}
                    <span>
                    {i18n.t("simulateur9.label")}
                    </span>
                  </span>
                </span>
              </td>
              <td>
                <span>
                  <img src={dtc_icone} alt="" width="43px" />
                </span>
                <span className="detail_sim_lable_container">
                  <span className="detail_sim_labels">
                  {i18n.t("recap38.label")}
                  </span>
                  <br />
                  <span className="detail_sim_num">
                  {format(this.props.Result.get('dtc'))}
                    <span>
                    {i18n.t("simulateur7.label")}
                    </span>
                  </span>
                </span>
              </td>
              <td>
                <span>
                  <img src={ie_icone} alt="" width="43px" />
                </span>
                <span className="detail_sim_lable_container">
                  <span className="detail_sim_labels">
                  {i18n.t("recap39.label")}
                  </span>
                  <br />
                  <span className="detail_sim_num">
                  {format(this.props.Result.get('ie'))}
                    <span>
                    {i18n.t("simulateur7.label")}
                    </span>
                  </span>
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan="2" className="ligh_grey_bg">
                <span>
                  <img src={somme_icone} alt="" width="43px" />
                </span>
                <span className="detail_sim_lable_container">
                  <span className="detail_sim_labels">
                  {i18n.t("recap40.label")}
                  </span>
                  <br />
                  <span className="cout_txt">
                  {format(coutInterets.toFixed(2))}
                    <span>
                    {i18n.t("recap41.label")}
                    </span>
                  </span>
                </span>
                <span className="detail_sim_lable_container mleft">
                  <span className="detail_sim_labels">
                  </span>
                  <br />
                  <span className="cout_txt">
                  {format((this.props.Result.get('dtc') + this.props.Result.get('ie')).toFixed(2))}
                    <span>
                    {i18n.t("recap42.label")}
                    </span>
                  </span>
                </span>
                <span className="detail_sim_lable_container mleft">
                  <span className="detail_sim_labels">
                  </span>
                  <br />
                  <span className="cout_txt">
                  {format(this.props.Result.get('dossier')>1000?'1000':this.props.Result.get('dossier'))}
                    <span>
                    {i18n.t("recap43.label")}
                    </span>
                  </span>
                </span>
              </td>
              <td className="orange_bg">
                <span>
                  <img src={mensualite_icone} alt="" width="43px" />
                </span>
                <span className="detail_sim_lable_container">
                  <span className="detail_sim_labels white-txt">
                  {i18n.t("recap45.label")}
                  </span>
                  <br />
                  <span className="detail_sim_num white-txt">
                  {format(this.props.Result.get('montant'))}

                    <span className="white-txt">
                     {i18n.t("recap41.label")}
                    </span>
                  </span>
                </span>
              </td>
            </tr>
          </table>
          <p className="message_dinfo">
            <img src={info_txt} alt="" />
            <span>{i18n.t("recap44.label")}</span>
          </p>
        </div>
      </section>
    );
  }
}
DetailSimulation.propTypes = {
  Result: React.PropTypes.object,
  montantCredit: React.PropTypes.number,
  taux: React.PropTypes.number,
  dureeDiff: React.PropTypes.number,
  coutInterets: React.PropTypes.number,
};

DetailSimulation.defaultProps = {
  Result: new List(),
};
export default DetailSimulation;

/* eslint-disable */
import React, { Component } from 'react';



export default class AccordionSum extends Component {
  constructor() {
    super();
    this.state = { isOpen: true };
  }








  render() {
    return (
      <li>
        {this.props.style.opacity ?
       <a
       onClick = {this.props.onClick}
       className={this.props.isOpenLab ? " accordion_title accor_simulator simulateur_normal " : "accordion_title accor_simulator simulateur_normal" }
       >
       {this.props.title}
       </a>:

       <button
       onClick = {this.props.onClick}
       className={this.props.isOpenLab ? "accordion_title accor_simulator simulateur_normal active" : "accordion_title accor_simulator simulateur_normal" }

       >
       {this.props.title}
       </button>


      }



        <div className={this.props.classNamesecond } style={this.props.style} >
          {this.props.children}
        </div>
      </li>
    );
  }
}
AccordionSum.propTypes = {
  title: React.PropTypes.string,
  classNamefirst: React.PropTypes.string,
  classNamesecond: React.PropTypes.string,
  style: React.PropTypes.string,
  children: React.PropTypes.node,
  onClick: React.PropTypes.func,
};
AccordionSum.defaultProps = {
  classNamefirst: 'accordion_title_recap active',
  classNamesecond: 'accordion_content_recap',
  style: { display: 'block' },
  isOpenLab: true,
};

import { connect } from 'react-redux';
import SimulateurSMAP from '../components/SimulateurSMAP';
import { simulateurEcheance, simulateurCapaciteEmprunt,
  valueToggle, inputChange, cleanStore, initialAccout, showHide, defaulteState } from '../action_creators';

function mapStateToProps(state) {
  return {
    showHideElm: state.demandeCredit.getIn(['isChaabiClient', 'value']),
    montantCredit: state.simulateur.getIn(['montantCredit', 'value']),
    nbAnnee: state.simulateur.getIn(['nbAnnee', 'value']),
    dureeDiff: state.simulateur.getIn(['dureeDiff', 'value']),
    taux: state.simulateur.getIn(['taux', 'value']),
    echeance: state.simulateur.getIn(['echeance', 'value']),
    revenuNetMensuel: state.simulateur.getIn(['revenuNetMensuel', 'value']),
    autreCredit: state.simulateur.getIn(['autreCredit', 'value']),
    duree: state.simulateur.getIn(['duree', 'value']),
    tauxHt: state.simulateur.getIn(['tauxHt', 'value']),
    capaciteEmprunt: state.simulateur.getIn(['capaciteEmprunt', 'value']),
    toggled: state.simulateur.getIn(['toggled', 'value']),
    capaciteEmpruntfinal: state.simulateur.getIn(['capaciteEmpruntfinal', 'value']),
    langues: state.demandeCredit.getIn(['langues', 'value']), 
  };
}


const mapDispatchToProps = { simulateurEcheance, simulateurCapaciteEmprunt,
  valueToggle, inputChange, cleanStore, initialAccout, showHide, defaulteState };


export default connect(mapStateToProps, mapDispatchToProps)(SimulateurSMAP);

import { fromJS } from 'immutable';

const defaultState = fromJS({
  stack: [],
});

export default function (state = defaultState, action) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      if (action.payload.action === 'PUSH') {
        const current = state.get('stack')
          .findIndex(entry => entry.get('current'));
        return state.updateIn(['stack'], stack => stack.withMutations(ctx => {
          if (current > -1) {
            // Trucate every entry after the current one (forward history is lost)
            ctx.slice(0, current + 1);
            // Unmark the current as the current
            ctx.removeIn([current, 'current']);
          }
          // Add the new current state
          ctx.push(fromJS({
            path: action.payload.pathname,
            key: action.payload.key,
            current: true,
          }));
        }));
      }
      if (action.payload.action === 'POP') {
        const current = state.get('stack')
          .findIndex(entry => entry.get('current'));
        const index = state.get('stack')
          .findIndex(entry => entry.get('key') === action.payload.key);

        if (current > -1) {
          return state.withMutations(ctx => {
            // Unmark the current state
            ctx.updateIn(['stack'], stack => stack.removeIn([current, 'current']));
            // Mark the new current state
            ctx.updateIn(['stack'], stack => stack.setIn([index, 'current'], true));
          });
        }
      }
      return state;
    default:
      return state;
  }
}

/* eslint-disable */
import React, { Component } from "react";
import Text from "../containers/Text";
import SelectSearch from "../containers/Inputs/SelectSearch";
import i18n from "../i18n";

const NotifAutrePromoteur = {
  message: (
    <span
      style={{
        color: "rgb(133 77 86)",
        fonFamily: "roboto_bold",
        marginRight: "56%",
        fontSize: "14px",
      }}
    >
      Sélectionner 'Autre Promoteur' si indisponible sur la liste de choix
    </span>
  ),
};

class Promoteur extends Component {
  constructor(props) {
    super(props);
    this.showAutrePromoteurs = this.showAutrePromoteurs.bind(this);
    this.state = { showAutrePromoteur: false };
  }

  showAutrePromoteurs() {
    const selectedPromotuer = this.props.items.find(
      (item) => item.get("value") == this.props.value
    );
    const isAutrePromteur = selectedPromotuer
      ? selectedPromotuer.get("label") === "Autre promoteur"
      : false;
    if (isAutrePromteur) {
      this.setState({
        showAutrePromoteur: true,
      });
    } else {
      this.setState({
        showAutrePromoteur: false,
      });
    }
  }
  componentWillMount() {
    this.showAutrePromoteurs();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.showAutrePromoteurs();
    }
  }

  render() {
    return (
      <div>
        {this.state.showAutrePromoteur == false && NotifAutrePromoteur.message}
        <div className="left_cls">
          <SelectSearch
            items={this.props.items}
            defaultValue={this.props.value}
            storeKey={this.props.storeKey}
            placeholder={i18n.t("infoProjet18.label")}
          ></SelectSearch>
        </div>
        <div className="right_cls">
          <Text
            maxLength="35"
            type="text"
            storeKey="demandeCredit.autrePromoteur"
            // onChangeParent={() => console.info("")}
            disabled={!this.state.showAutrePromoteur}
          >
            {i18n.t("infoProjet8.label")}
          </Text>
        </div>
      </div>
    );
  }
}

Promoteur.propTypes = {};

export default Promoteur;

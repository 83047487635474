import { connect } from 'react-redux';
import Select from '../../components/Inputs/Select';
import { fromJS } from 'immutable';




const mapStateToProps = (state, props) => {

    const itemsEmpty = fromJS([{}]);

    const  listOtherOfficer = state.demandeCredit.getIn(['clientDetails','otherOfficer'])!== undefined 
    ? state.demandeCredit.getIn(['clientDetails','otherOfficer']).toJS() : null;

    
  
    const listeAgenceClientSecendaire = state.configuration.get('agenceList') ?state.configuration.get('agenceList')
    .filter(eqt =>(listOtherOfficer !=null ? (listOtherOfficer.indexOf(eqt.get('codeagenceT24'))!==-1) : null))
    : itemsEmpty;  

    const listeAgenceClientPrinciple = state.configuration.get('agenceList') ? state.configuration.get('agenceList')
    .filter(eqt => eqt.get('value') === state.demandeCredit.getIn(['CodeAgencePrincipal', 'value']))
    : itemsEmpty; 

    const listeAgenceClientAll= listeAgenceClientSecendaire.concat(listeAgenceClientPrinciple);

    const [store, ...storeKey] = props.storeKey.split('.');
    storeKey.push('value');

  return ({
    items: listeAgenceClientAll,
    value: state[store].getIn(storeKey)
  });
};

export default connect(mapStateToProps)(Select);
import { connect } from 'react-redux/dist/react-redux';
import { fromJS } from 'immutable';
import EspacePerso from '../components/EspacePerso';
import { inputChange, accepterProposition, showHide, getDemande, getRejetPropComm } from '../action_creators';

function mapStateToProps(state) {
  const maDemande = state.demandeCredit.getIn(['maDemande', 'demande']) || fromJS({});
  const maProposition = state.demandeCredit.getIn(['maDemande', 'proposition']) || fromJS({});
  return {
    langues: state.demandeCredit.getIn(['langues', 'value']), 
    maDemande,
    propositionStatus: state.demandeCredit.get('propositionStatus'),
    dateNaissance: state.demandeCredit.getIn(['maDemande', 'demande', 'date_naissance']),
    paysresid: state.demandeCredit.getIn(['maDemande', 'demande', 'pays_de_residence']),
    mail: state.demandeCredit.getIn(['maDemande', 'demande', 'email']),
    prenom: state.demandeCredit.getIn(['maDemande', 'demande', 'first_name']),
    nom: state.demandeCredit.getIn(['maDemande', 'demande', 'last_name']),
    teleph: state.demandeCredit.getIn(['maDemande', 'demande', 'telephone']),
    maProposition,
    civilite: maDemande.get('civilite'),
    maDemandeStatus: state.demandeCredit.get('maDemandeStatus'),
    clientsIdentifier: {
      clientIdentifier: state.account.getIn(['LoggedUser', 'mail']),
      clientType: state.account.getIn(['LoggedUser', 'flag']),
    },
    propositionRequest: {
      referenceDemande: maDemande.get('referenceId'),
      canal: 'web',
    },
    propositionRejetRequest: {
      idDemande:  maDemande.get('referenceId'),
      motifRejet: state.demandeCredit.getIn(['motifRejet','value']),
    },
    statutEncours: maDemande.get('statut'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    inputChange: (...y) => dispatch(inputChange(...y)),
    accepterProposition: (propositionRequest) => dispatch(accepterProposition(propositionRequest)),
    getRejetPropComm: (propositionRejetRequest) => dispatch(getRejetPropComm(propositionRejetRequest)),
    showHide: (s) => dispatch(showHide(s)),
    getDemande: (clientsIdentifier) => dispatch(getDemande(clientsIdentifier)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const propositionRequest = stateProps.propositionRequest;
  const clientsIdentifier = stateProps.clientsIdentifier;
  const propositionRejetRequest = stateProps.propositionRejetRequest;
  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    accepterProposition: () => dispatchProps.accepterProposition(propositionRequest),
    getRejetPropComm: () => dispatchProps.getRejetPropComm(propositionRejetRequest),
    getDemande: () => dispatchProps.getDemande(clientsIdentifier),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EspacePerso);

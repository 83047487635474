import React, { Component } from 'react';

class NavigationBarButton extends Component {
  render() {
    return (
      <button
        data-text={this.props.children}
        onClick={this.props.disabled ? () => null : this.props.action}
        className="flat_btn white_btn"
      >
        <span>
          {this.props.children}
        </span>
      </button>
    );
  }
}

NavigationBarButton.propTypes = {
  disabled: React.PropTypes.bool,
  action: React.PropTypes.func.isRequired,
  children: React.PropTypes.node,
};

export default NavigationBarButton;

import React, { Component } from 'react';
import Simul from '../assets/images/simulateur.png';
import NvDemande from '../assets/images/nouvelle_demande.png';
import { hashHistory } from 'react-router';


export default class HomeSMAP extends Component {

  click() {
    hashHistory.push('/');
  }
  simulClick() {
    hashHistory.push('/formSMAP');
  }

  render() {
    return (
      <div className="form_smap">
        <div
          className="flat_btn1 purple_btn flat_btnn alg simulateur"
          onClick={this.simulClick}
        >
          <img
            className="img_smap"
            src={Simul} alt="banque populaire" width="100px"
          />
          <div
            data-text="ESPACE SIMULATION"
            className="Img_container"
          >
            <p className="text_style">ESPACE SIMULATION</p>
            <p className="text_style"> Realisez votre simulation <br />
            et recevez la par email </p>
          </div>
        </div>
        <div
          className="flat_btn1 purple_btn flat_btnn alg ajoutD"
          onClick={this.click}
        >
          <img
            className="img_smap"
            src={NvDemande} alt="banque populaire" width="100px"
          />
          <div
            data-text="ESPACE DEMANDE"
            className="Img_container"
          >
            <p className="text_style">ESPACE DEMANDE</p>
            <p className="text_style"> Faites votre demande <br /> 100% en ligne <br />et suivez son avancement <br /> depuis votre espace personnel</p>

          </div>
        </div>
      </div>
    );
  }




}
